import React from "react";
import { styled } from "@material-ui/styles";
interface ButtonProps {
    icon?: any;
    text: string;
    buttonStyles?: React.CSSProperties;
    buttonClass?: string;
    type?: "default" | "outlined" | "link";
    iconPosition?: "start" | "end";
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>)=> void;
    disabled?: boolean;
}


const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
    const { icon, text, buttonStyles, type = "default", buttonClass, iconPosition = "start", onClick, disabled} = props;

    return (
        <StyledButton 
          className={`Custom_Button_Web_StyledButton ${type} ${buttonClass}`} 
          style={buttonStyles}
          onClick={(event)=>{
            if(onClick){
                onClick(event)
            }
          }}
          type="button"
          disabled={disabled}
        >
            {
                icon && iconPosition === "start" && (
                    <img src={icon} />
                )
            }
            <p>{ text }</p>
            {
                icon && iconPosition === "end" && (
                    <img src={icon} />
                )
            }
        </StyledButton>
    )
}

const StyledButton = styled("button")({
  "&.Custom_Button_Web_StyledButton":{
     border: "none",
     outline: "none",
     background: "linear-gradient(90deg, #FF7F77 0%, #FF6376 100%)",
     color: "rgba(255, 255, 255, 1)",
     fontSize: "1rem",
     fontWeight: 500,
     display:"flex",
     justifyContent: "center",
     alignItems: "center",
     gap: "0.3rem",
     height: "3rem",
     borderRadius: "62.5rem",
     padding:"0rem 0.75rem",
     cursor: "pointer",
     "&.outlined":{
        background: "transparent",
        border: "1px solid rgba(247, 83, 103, 1)",
        color: "rgba(247, 83, 103, 1)"
     },
     "&.link":{
        background: "transparent",
        color: "rgba(247, 83, 103, 1)",
        fontSize: "0.875rem",
        height: "100%",
        fontWeight: 500
     },
     "&:disabled":{
        opacity: 0.5,
        cursor: "inherit"
     }
  }
})

export default Button;