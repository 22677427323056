import React from "react";
import { SearchLocation } from "../AddAppointmentController";
import { styled } from "@material-ui/core";
interface LocationMenuProps {
    locations: SearchLocation[];
    onSelect: (location: SearchLocation) => void;
}


const LocationMenu: React.FC<LocationMenuProps> = (props: LocationMenuProps) => {
    const { locations, onSelect } = props
    return (
        <StyledLocationMenu className="locations-container Appointments_LocationMenu_StyledLocationMenu">
            {
                locations.map(prediction => {
                    return (
                        <div className="location" key={prediction.place_id} onClick={() => onSelect(prediction)}>
                            <div className="avatar">
                                <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.0019 9.36535C8.49948 9.36535 8.92487 9.18818 9.27807 8.83385C9.63127 8.47952 9.80787 8.05356 9.80787 7.55598C9.80787 7.05839 9.63071 6.633 9.27637 6.2798C8.92204 5.9266 8.49608 5.75 7.9985 5.75C7.50092 5.75 7.07552 5.92717 6.72232 6.2815C6.36912 6.63583 6.19252 7.06179 6.19252 7.55938C6.19252 8.05696 6.36969 8.48235 6.72402 8.83555C7.07836 9.18875 7.50431 9.36535 8.0019 9.36535ZM8.0002 19.0096C5.48355 16.8288 3.59637 14.7993 2.33867 12.9211C1.08099 11.0429 0.452148 9.31857 0.452148 7.74805C0.452148 5.44038 1.19862 3.57213 2.69157 2.14328C4.18451 0.714425 5.95405 0 8.0002 0C10.0463 0 11.8159 0.714425 13.3088 2.14328C14.8018 3.57213 15.5482 5.44038 15.5482 7.74805C15.5482 9.31857 14.9194 11.0429 13.6617 12.9211C12.404 14.7993 10.5168 16.8288 8.0002 19.0096Z" fill="#26EFF5" />
                                </svg>
                            </div>
                            <div className="details">
                                <div className="main-text">{prediction.structured_formatting.main_text}</div>
                                <div className="secondary-text">{prediction.structured_formatting.secondary_text}</div>
                            </div>
                        </div>
                    )
                })
            }
        </StyledLocationMenu>
    )
}

const StyledLocationMenu = styled("div")({
    "&.Appointments_LocationMenu_StyledLocationMenu": {
        display: "flex",
        flexDirection: "column",
        // gap:"0.5rem",
        "& .location": {
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            cursor: "pointer",
            padding: "0.5rem",
            "& .avatar": {
                width: "2rem",
                height: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "100%",
                border: "1px solid #26EFF5"
            },
            "& .details": {
                display: "flex",
                flexDirection: "column",
                "& .main-text": {
                    fontSize: "0.875rem",
                    color: "white"
                },
                "& .secondary-text": {
                    fontSize: "0.75rem",
                    color: "#FFFFFFB2"
                }
            },
            "&:hover": {
                background: "#00000026",
            }
        }
    }
})

export default LocationMenu;