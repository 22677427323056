import React from "react";
import { styled } from "@material-ui/styles";
import { IGroup } from "../types";
import { Button } from "../../../../components/src";
import GroupImage from "./GroupImage.web";
interface Props {
    groups: IGroup[];
    onClick: (event: IGroup)=> void;
    selectedGroup: IGroup | null;
}


const SharedGroups: React.FC<Props> = (props: Props) => {
    const { groups, onClick, selectedGroup } = props;

    const NoSharedGroupMessage = ()=>{
        return (
            <StyledNoSharedGroupMessage className="SharedGroups_StyledNoSharedGroupMessage">
                <p>No Shared Groups</p>
                <Button 
                  text="Trending Groups"
                  type="outlined"
                  buttonStyles={{height: "2.25rem", fontSize: "1rem"}}
                />
            </StyledNoSharedGroupMessage>
        )
    }
    return (
        <>
            {
                groups && groups.length > 0 && (
                    <StyledSharedGroups className="SharedGroups_StyledSharedGroups">
                       <p>Shared Groups</p>
                       <div className="groups">
                          {
                            groups.map(group=>{
                                return (
                                    <div className={`group ${!!selectedGroup && selectedGroup.id === group.id ? "selected" : ""}`} key={group.id} onClick={()=> onClick(group)}>
                                        <GroupImage image_url={group.image_url} />
                                        <div className="details">
                                            <p className="name">{group.name}</p>
                                            <p className="description">{group.description}</p>
                                        </div>
                                    </div>
                                )
                            })
                          }
                       </div>
                    </StyledSharedGroups>
                )
            }
            {
                groups.length === 0 && (
                    NoSharedGroupMessage()
                )
            }
        </>
    )
}

const StyledSharedGroups = styled("div")({
  "&.SharedGroups_StyledSharedGroups":{
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    "& > p":{
        fontSize: "0.75rem",
        fontWeight: 500,
        color: "rgba(255, 255, 255, 1)",
        marginLeft: "1rem"
    },
    "& .groups":{
        display: "flex",
        // gap: "1.5rem",
        flexDirection: "column",
        "& .group":{
            display: "flex",
            gap: "1rem",
            padding: "0.5rem 1rem",
            cursor: "pointer",
            transition: "background-color 0.3s ease-in-out",
            "&.selected":{
                backgroundColor: "rgba(0, 0, 0, 0.15)",
                borderLeft: "3px solid rgba(255, 103, 118, 1)"
            },
            "& .details":{
                display: "flex",
                flexDirection: "column",
                "& .name":{
                    fontSize: "1rem",
                    fontWeight: 500,
                    color: "rgba(255, 255, 255, 1)"
                },
                "& .description":{
                    fontSize: "0.625rem",
                    fontWeight: 400,
                    color: "rgba(255, 255, 255, 0.7)"
                }
            },
            "&:hover":{
                backgroundColor: "rgba(0, 0, 0, 0.15)"
            }
        }
    }
  }
})

const StyledNoSharedGroupMessage = styled("div")({
    "&.SharedGroups_StyledNoSharedGroupMessage": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        gap: "0.5rem",
        "& > p":{
            fontSize: "1rem",
            fontWeight: 400,
            color: "rgba(255, 255, 255, 0.8)"
        }
    }
})

export default SharedGroups;