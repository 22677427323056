import React from "react";
import { styled } from "@material-ui/core";
interface RadioButtonProps {
    selected: boolean;
    onSelect: () => void;
    label: string;
}


const RadioButton: React.FC<RadioButtonProps> = (props: RadioButtonProps) => {
    const { selected, onSelect, label } = props;

    return (
        <StyledRadioButton className="RadioButton_StyledRadioButton">
            {
                selected && (
                    <div className="icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 5C7.24 5 5 7.24 5 10C5 12.76 7.24 15 10 15C12.76 15 15 12.76 15 10C15 7.24 12.76 5 10 5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z" fill="url(#paint0_linear_23297_36206)" />
                            <defs>
                                <linearGradient id="paint0_linear_23297_36206" x1="0" y1="10" x2="20" y2="10" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FF7F77" />
                                    <stop offset="1" stop-color="#FF6376" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                )
            }
            {
                !selected && (
                    <div className="icon" onClick={onSelect}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.5 10C0.5 4.75614 4.75614 0.5 10 0.5C15.2439 0.5 19.5 4.75614 19.5 10C19.5 15.2439 15.2439 19.5 10 19.5C4.75614 19.5 0.5 15.2439 0.5 10ZM1.5 10C1.5 14.6961 5.30386 18.5 10 18.5C14.6961 18.5 18.5 14.6961 18.5 10C18.5 5.30386 14.6961 1.5 10 1.5C5.30386 1.5 1.5 5.30386 1.5 10Z" stroke="#7A7A7A" />
                        </svg>
                    </div>
                )
            }
            <span>{label}</span>
        </StyledRadioButton>
    )
}

const StyledRadioButton = styled("div")({
    "&.RadioButton_StyledRadioButton": {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        "& span": {
            fontSize: "1rem",
            fontWeight: 400,
            color: "rgba(255, 255, 255, 1)"
        },
        "& > div": {
            cursor: "pointer"
        }
    }
})

export default RadioButton;