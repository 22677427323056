import React from "react";
import { formatDate } from '@fullcalendar/core';

interface CustomDivProps {
    value?: string;
    onClick?: () => void;
    currentDate: Date
}

export const CustomDateField = React.forwardRef((props: CustomDivProps, refObj: React.Ref<HTMLDivElement>) => (
    <div className="selected-date" onClick={props.onClick} ref={refObj}>
        {formatDate(props.currentDate, {
            month: "long",
            year: "numeric"
        })}
        <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.31028 0.377721L10.2188 1.28617L5.34476 6.16016L0.470775 1.28617L1.37925 0.377721L5.34476 4.34324L9.31028 0.377721Z" fill="white" />
        </svg>
    </div>
));