import React from "react";
import { styled } from "@material-ui/styles";
import Button from "../../../../components/src/Button.web";
import GoBack from "../../../../components/src/GoBack.web";
interface CommonHeaderProps {
    redirectTo: ()=> void;
    goBack: ()=>void;
    buttonText: string;
    buttonIcon?: string;
    goBackText: string;
}

const CommonHeader: React.FC<CommonHeaderProps> = (props: CommonHeaderProps) => {
    const { redirectTo, goBack, buttonText, buttonIcon, goBackText } = props;

    return (
        <StyledCommonHeader className="DashboardCommonHeader_StyledCommonHeader">
           <GoBack goBack={goBack} displayText={goBackText} />
           <Button
            text={buttonText}
            icon={buttonIcon}
            onClick={redirectTo}
           />
        </StyledCommonHeader>
    )
}

const StyledCommonHeader = styled("div")({
    "&.DashboardCommonHeader_StyledCommonHeader": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }
})


export default CommonHeader;