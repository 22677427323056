import React from "react";
import { styled } from "@material-ui/styles";
interface Props {
   user: {[key:string]: string}
}


const InvitedUser: React.FC<Props> = (props: Props) => {
    const { user } = props;

    return (
        <StyledInvitedUser className="Appointment_InvitedUser contact">
            {
                user.invite_record.includes("@") ? (
                    <div>
                        <div className="avatar">
                            {user.invite_record[0]}
                        </div>
                        <div className="user-details">
                            <div>{user.invite_record}</div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="avatar">
                            {user.contact_name[0]}
                        </div>
                        <div className="user-details">
                            <div>{user.contact_name}</div>
                            <div>{user.invite_record}</div>
                        </div>
                    </div>
                )
            }
            <div>
                <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.35925 7.99933L1.082 1.72208C0.916407 1.55648 0.83574 1.35937 0.840004 1.13074C0.844282 0.902106 0.929219 0.704996 1.09482 0.539412C1.26041 0.373815 1.45752 0.291016 1.68615 0.291016C1.91477 0.291016 2.11189 0.373815 2.27748 0.539412L8.6573 6.93204C8.80793 7.08268 8.91958 7.25147 8.99223 7.43843C9.06487 7.6254 9.10119 7.81237 9.10119 7.99933C9.10119 8.18629 9.06487 8.37325 8.99223 8.56022C8.91958 8.74718 8.80793 8.91598 8.6573 9.06662L2.26467 15.4592C2.09907 15.6248 1.90409 15.7055 1.67973 15.7012C1.45539 15.697 1.26041 15.612 1.09482 15.4464C0.929219 15.2808 0.846421 15.0837 0.846421 14.8551C0.846421 14.6265 0.929219 14.4294 1.09482 14.2638L7.35925 7.99933Z" fill="white" fill-opacity="0.7" />
                </svg>
            </div>
        </StyledInvitedUser>
    )
}

const StyledInvitedUser = styled("div")({
    "&.Appointment_InvitedUser": {

    }
})

export default InvitedUser;