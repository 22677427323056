import React from "react";
// Customizable Area Start
import { Grid, styled } from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import EventDetail from "./components/EventDetail.web";
import ConfirmationPopUp from "../../../components/src/ConfirmationPopUp.web";
import CreatePostModal from "../../postcreation/src/components/CreatePostModal.web";
import RecentPostCard from "../../activityfeed/src/components/RecentPostCard.web";
import RecentPostCardCommentOnly from "../../activityfeed/src/components/RecentPostCardCommentOnly.web";
// Customizable Area End

import AllEventDetailController, {
  Props
} from "./AllEventDetailController";

export default class AllEvent extends AllEventDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  EventDetailComponent = () => {
    const { eventDetail, userId, isEventRsvpStatusUpdateMode, eventDeleteConfirmationPopup, postDetails, recentPostDetails, isLoadingCommentsList, commentsList, isPostUploading, postLikedUserList } = this.state;
    return (
      <StyledEventDetailContainer>
        <div className="event-detail-back-btn" onClick={() => this.props.navigation.goBack()}>
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99953 11.6553L0.345703 6.00148L5.99953 0.347656L7.05335 1.40148L2.45335 6.00148L7.05335 10.6015L5.99953 11.6553Z" fill="white" />
          </svg>
          Details
        </div>
        <Grid container direction="row" spacing={2}>
          {eventDetail &&
            <Grid item xs={12} sm={12} md={recentPostDetails ? 8 : 12}>
              <EventDetail
                event={eventDetail}
                isRsvpStatusUpdateMode={isEventRsvpStatusUpdateMode}
                onUpdateRsvpStatus={this.onUpdateEventRsvpStatus}
                onRsvpUpdateModeChange={this.onRsvpUpdateModeChange}
                onDeleteEvent={(title: string, eventId: string | number) => this.openEventDeleteModal(title, eventId)}
                userId={userId}
                showRsvpOption={userId !== eventDetail.attributes.account_id}
                isNextEvent={false}
                redirectTo={this.redirectTo}
                onPostCreate={this.openCreatePostModal}
              />
            </Grid>}
            {recentPostDetails &&
            <Grid item xs={12} sm={12} md={recentPostDetails ? 4 : 12}>
              {recentPostDetails.attributes.images_and_videos?.length > 0 ? 
              <RecentPostCard
                recentPost={recentPostDetails}
                onClickComment={this.openPostCommentsModal}
                userId={userId}
                onLikePost={(postId: string) => this.likePost(postId)}
                onDisLikePost={(postId: string) => this.disLikePost(postId)}
                onViewFeed={() => this.redirectTo("AllPosts", { type: "events", id: eventDetail?.id || "" })}
                recentPostLikedUserList={postLikedUserList}
                setLikedUsers={this.setLikedUserList}
                fetchLikedUsers={this.getLikedUsersList}
                isLoadingCommentsList={isLoadingCommentsList}
                onAddComment={this.onAddPostComment}
                onCloseCommentModal={this.closePostCommentsModal}
                onDeleteComment={this.onDeletePostComment}
                commentsList={commentsList}
                handleFetchNextComments={this.handleChangeCommentsPageNumber}
              /> : 
              <RecentPostCardCommentOnly
                recentPostCommentOnly={recentPostDetails}
                onClickComment={this.openPostCommentsModal}
                onLikePostCommentOnly={(postId: string) => this.likePost(postId)}
                onDisLikePostCommentOnly={(postId: string) => this.disLikePost(postId)}
                onViewFeed={() => this.redirectTo("AllPosts", { type: "events", id: eventDetail?.id || "" })}
                isLoadingComments={isLoadingCommentsList}
                onAddCommentInPost={this.onAddPostComment}
                onCloseCommentModal={this.closePostCommentsModal}
                onDeleteCommentFromPost={this.onDeletePostComment}
                commentsList={commentsList}
                handleFetchNextComments={this.handleChangeCommentsPageNumber}
                userId={userId}
              />}
            </Grid>
            }
        </Grid>
        
        <ConfirmationPopUp
          open={Boolean(eventDeleteConfirmationPopup)}
          header={eventDeleteConfirmationPopup?.header as string}
          message={eventDeleteConfirmationPopup?.message as string}
          onAccept={this.onAcceptEventDeleteConfirmation}
          onReject={this.onCloseEventDeleteConfirmationModal}
          onClose={this.onCloseEventDeleteConfirmationModal}
        />
        <CreatePostModal
          postDetails={postDetails}
          isEditMode={false}
          isPostUploading={isPostUploading}
          onCloseCreatePostModal={() => this.closeCreatePostModal()}
          onSubmitPostDetails={this.onSubmitPostDetails}
          handleUpdateFilesUploaded={(number) => this.setState({ numberOfPostFilesUploaded: number })}
        />
      </StyledEventDetailContainer>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <NavigationMenu {...this.props} children={this.EventDetailComponent()} />
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledEventDetailContainer = styled("div")({
  flexDirection: "column",
  display: "flex",
  padding: "1rem 1rem",
  gap: "1.5rem",
  "& > .event-detail-back-btn": {
    fontSize: "1.125rem",
    fontWeight: 500,
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    color: "white",
    cursor: "pointer",
    width: "fit-content"
  },
})
// Customizable Area End
