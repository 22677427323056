import React, { useEffect, useRef, useState } from "react";
import { Box, Menu, IconButton, styled } from "@material-ui/core";
import { MoreIcon } from "../../../activityfeed/src/assets";
import { IComment } from "../../../../components/src/CommonTypes";
import { closeIcon } from "../../../appointmentmanagement/src/assets";
import CommentTextField from "./CommentTextField.web";
import { ModalLoader } from "../../../../components/src";

interface Props {
    commentsList: IComment[];
    open: HTMLImageElement | null;
    userId?: number;
    // isLastComment: boolean;
    handleFetchNextPageComments?: () => void;
    onClose?: () => void;
    onAddComment?:(event:string)=> void;
    onDeleteComment?:(event:string)=> void;
    isLoadingComments?: boolean;
}


const CommentAddModal: React.FC<Props> = (props: Props) => {
    const { commentsList, open, onDeleteComment, onAddComment, onClose, isLoadingComments, userId, handleFetchNextPageComments } = props;
    const [optionCommentMenuDropdownMenuAnchorEle, setOptionCommentMenuDropdownMenuAnchorEle] = useState<HTMLElement | null>(null);
    const [selectedComment, setSelectedComment] = useState<string>("")
    const targetCommentRef: any = useRef<HTMLDivElement>(null);
    const [isLastCommentVisible, setIsLastCommentVisible] = useState(false);

    const openCommentOptionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOptionCommentMenuDropdownMenuAnchorEle(event.currentTarget)
    }

    const closePostOptionMenu = () => {
        setOptionCommentMenuDropdownMenuAnchorEle(null)
    }

    useEffect(() => {
        const observer = new IntersectionObserver((entries: any) => {
            const [entry] = entries;
            setIsLastCommentVisible(entry.isIntersecting);
        }, {
            threshold: 0.5
        });

        if (targetCommentRef?.current) observer.observe(targetCommentRef.current);

        return () => {
            if (targetCommentRef?.current) observer.unobserve(targetCommentRef.current);
        };
    }, [targetCommentRef?.current]);

    useEffect(() => {
        if(isLastCommentVisible){
            handleFetchNextPageComments?.()
        }
    }, [isLastCommentVisible])

    const CommentMenuOptionDropdown = () => {
        return (
            <CustomCommentMenuOptions
                className="Custom_Menu_Comment"
                data-test-id="custom-menu-comment"
                open={Boolean(optionCommentMenuDropdownMenuAnchorEle)}
                onClose={closePostOptionMenu}
                anchorEl={optionCommentMenuDropdownMenuAnchorEle}
                PaperProps={{
                    style: {
                        transform: "translateY(0.5rem)"
                    }
                }}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
            >
                <StyledCommentMenuOptionDropdown id="comment-options-menu" className="CommentMenuOptionDropdown_Web">
                    <Box id="delete-comment-icon" className="item"
                        onClick={() => {
                            if (onDeleteComment) {
                                onDeleteComment(selectedComment);
                                closePostOptionMenu()
                            }
                        }}
                    >
                        <svg id="delete-comment-icon-svg" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.3077 17.5017C2.80898 17.5017 2.38302 17.3251 2.02982 16.9719C1.67661 16.6187 1.5 16.1928 1.5 15.694V3.00176H0.5V1.50179H4.99997V0.617188H11V1.50179H15.5V3.00176H14.5V15.694C14.5 16.1992 14.325 16.6267 13.975 16.9767C13.625 17.3267 13.1974 17.5017 12.6922 17.5017H3.3077ZM13 3.00176H2.99997V15.694C2.99997 15.7838 3.02883 15.8575 3.08652 15.9152C3.14422 15.9729 3.21795 16.0018 3.3077 16.0018H12.6922C12.7692 16.0018 12.8397 15.9697 12.9038 15.9056C12.9679 15.8415 13 15.771 13 15.694V3.00176ZM5.40385 14.0018H6.90382V5.00176H5.40385V14.0018ZM9.09613 14.0018H10.5961V5.00176H9.09613V14.0018Z" fill="white" />
                        </svg>
                        Delete
                    </Box>
                </StyledCommentMenuOptionDropdown>
            </CustomCommentMenuOptions>
        )
    }

    return (
        <>
        <Menu
            open={Boolean(open)}
            anchorEl={open}
            className="Custom_Menu_Comment"
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
            }}
            onClose={onClose}
            PaperProps={{
                style: {
                    transform: "translateX(0.5rem)",
                    background: "transparent",
                    zIndex: 3
                }
            }}
            MenuListProps={{
                style:{
                    padding: "0",
                }
            }}
            getContentAnchorEl={null}
        >
            <StyledPostComments className="Post_Comments_Container">
                <div className="header">
                    <p>Comments</p>
                    <img src={closeIcon} alt="close.svg" onClick={onClose} />
                </div>
                <div className="body">
                    {
                        commentsList && commentsList.length > 0 && (
                            <div className="comments-container">
                                {
                                    commentsList.map((comment, index)=>{
                                        return (
                                            <div ref={index === commentsList.length -1 ? targetCommentRef : null} key={comment.id} className="comment">
                                                <div className="details">
                                                    <div className="name">
                                                        <div className="avatar-text">
                                                            {
                                                                comment.attributes.account_info.full_name[0]
                                                            }
                                                        </div>
                                                        <p>
                                                            {
                                                                comment.attributes.account_info.full_name
                                                            }
                                                            <span>{comment.attributes.raw_created_at}</span>
                                                        </p>

                                                    </div>
                                                    {
                                                        userId === comment.attributes.account_id && (
                                                            <div>
                                                                <IconButton onClick={event => {
                                                                    openCommentOptionMenu(event);
                                                                    setSelectedComment(comment.id)
                                                                }}>
                                                                    <img src={MoreIcon} />
                                                                </IconButton>
                                                                {optionCommentMenuDropdownMenuAnchorEle && CommentMenuOptionDropdown()}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <div className="comment-text">{comment.attributes.comment}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                    {
                        commentsList && commentsList.length === 0 && !isLoadingComments && (
                            <div className="no-comments">No comments found</div>
                        )
                    }
                </div>
                <div className="footer">
                   <CommentTextField onAddComment={onAddComment} />
                </div>
                <ModalLoader isLoading={isLoadingComments} />
            </StyledPostComments>
        </Menu>
        </>
    )
}

const StyledPostComments = styled("div")({
    "&.Post_Comments_Container": {
        width: "26.25rem",
        height: "26.8125rem",
        background: "rgba(35, 64, 75, 1)",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        "& .arrow": {
            position: "absolute",
            width: "1.0625rem",
            height: "0.875rem",
            background: "rgba(35, 64, 75, 1)",
            bottom: "1rem",
            left: "-1.25rem"
        },
        "& .header": {
            position: "relative",
            padding: "1rem",
            // borderBottom: "1px solid #2d4953",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& p": {
                margin: 0,
                fontSize: "1.125rem",
                fontWeight: 500,
                color: "white"
            },
            "& img": {
                position: "absolute",
                right: "1rem",
                top: "50%",
                transform: "translate(-50%,-50%)",
                cursor: "pointer"
            }
        },
        "& .body": {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflowX: "auto",
            gap: "1rem",
            padding: "1rem",
            "& .comments-container":{
                display: "flex",
                flexDirection: "column",
                "& .comment":{
                    display: "flex",
                    flexDirection: "column",
                    borderBottom: "1px solid #2d4953",
                    padding: "0.5rem 0rem",
                    "& .details":{
                        display: "flex",
                        gap: "0.5rem",
                        justifyContent: "space-between",
                        alignItems: "center",
                        "& .name":{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                            "& > p": {
                                fontSize: "0.75rem",
                                fontWeight: 400,
                                color: "rgba(255, 255, 255, 1)",
                                "& > span": {
                                    fontSize: "0.5rem",
                                    color: "rgba(255, 255, 255, 0.8)",
                                    marginLeft: "0.5rem"
                                }
                            }
                        }
                    },
                    "& .comment-text": {
                        fontSize: "0.875rem",
                        fontWeight: 400,
                        color: "rgba(255, 255, 255, 1)",
                        marginLeft: "2.5rem"
                    },
                    "&:last-child":{
                        borderBottom: "none"
                    }
                }
            },
            "& .no-comments":{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "0.875rem",
                color: "white",
                fontWeight: "400",
                flex: 1
            }
        },
        "& .footer": {
            padding: "1rem",
            borderTop: "1px solid #2d4953",
            display: "flex"
        },
    }
})

const StyledCommentMenuOptionDropdown = styled("div")({
    "&.CommentMenuOptionDropdown_Web": {
        display: "flex",
        flexDirection: "column",
        width: "9.8125rem",
        border: "1px solid #19B5B945",
        borderRadius: "0.5rem",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        padding: "0.25rem 0rem",
        "& .item": {
            display: "flex",
            alignItems: "center",
            color: "#ffffff",
            fontSize: "1rem",
            fontWeight: 400,
            gap: "0.5rem",
            cursor: "pointer",
            padding: "0.25rem 0.7rem",
            backgroundColor: "transparent",
            transition: "background-color 0.3s ease-in-out",
            "&:hover": {
                backgroundColor: "#0000001A"
            }
        }
    }
})

const CustomCommentMenuOptions = styled(Menu)({
    "&.Custom_Menu_Comment": {
        "& .MuiList-padding": {
            padding: 0,
        },
        "& .MuiPaper-root": {
            background: "transparent"
        }
    }
})

export default CommentAddModal;