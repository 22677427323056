// Customizable Area Start
import React from "react";
import AddGroupController, {
    Props
} from "./AddGroupController";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { styled } from "@material-ui/styles";
import { EditIcon } from "../../events/src/assets";
import { 
    GoBack, 
    InputText, 
    TextArea, 
    RadioButton,
    SelectEmailDropdown,
    ErrorMessage,
    Chip,
    SelectContacts,
    ViewSelectedEmailAndContact,
    Button,
    SelectInterest
} from "../../../components/src";
import { GroupType } from "./types";
import { emptyCheckBoxIcon, checkedBoxIcon } from "../../appointmentmanagement/src/assets";
// Customizable Area End

export default class AddGroup extends AddGroupController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    ImageInput = () => {
        const { selectedImage, imageError, imageInputRef } = this.state;
        return (
            <>
                <StyledImageInput className="AddGroup_StyledImageInput">
                    <input
                        type="file"
                        ref={imageInputRef}
                        style={{ display: 'none' }}
                        onChange={this.onSelectImage}
                    />
                    {
                        selectedImage && (
                            <div className="image" id="event-image" style={{ backgroundImage: `url(${selectedImage})` }}>
                                <div className="icon" onClick={() => imageInputRef?.current?.click()}>
                                    <img src={EditIcon} alt="" />
                                </div>
                            </div>
                        )
                    }
                    {
                        !selectedImage && (
                            <div className="input" id="image-picker" onClick={() => imageInputRef?.current?.click()}>
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.45224 29.8844C2.61036 29.8844 1.89774 29.5928 1.31441 29.0094C0.731106 28.4261 0.439453 27.7135 0.439453 26.8716V4.56403C0.439453 3.72214 0.731106 3.00953 1.31441 2.4262C1.89774 1.84286 2.61036 1.5512 3.45224 1.5512H17.9394V9.05115H21.2727V12.3845H28.7727V26.8716C28.7727 27.7135 28.481 28.4261 27.8977 29.0094C27.3143 29.5928 26.6017 29.8844 25.7599 29.8844H3.45224ZM5.85612 23.6344H23.4842L18.0035 16.3268L13.324 22.4165L9.9907 18.1538L5.85612 23.6344ZM23.5805 10.0767V6.74341H20.2471V4.24349H23.5805V0.910156H26.0804V4.24349H29.4137V6.74341H26.0804V10.0767H23.5805Z" fill="#26EFF5" />
                                </svg>
                                <p>Add Image</p>
                            </div>
                        )
                    }

                    {
                        imageError && (
                            <div className="error">{imageError}</div>
                        )
                    }
                </StyledImageInput>
            </>
        )
    }

    EmailFiled = ()=>{
        const { email, emailError, openEmailDropdown, emailList } = this.state;
        return (
            <>
                <InputText
                    onChange={this.onEmailChange}
                    value={email}
                    label="Select Email Id"
                    type="text"
                    name="email"
                    isContainerFocused={true}
                    onFocus={this.openSelectEmailDropdown}
                    onEnter={this.onEnterEmail}
                    maxLength={42}
                />
                {
                    openEmailDropdown && (
                        <SelectEmailDropdown
                          parentId="email-menu-container" 
                          onClose={this.closeSelectEmailDropdown} 
                          emails={emailList} 
                          onSelect={this.onSelectEmail} 
                          alreadySelected={this.findItemPresentInEmailAndContactList} 
                        />
                    )
                }
                <ErrorMessage error={{ message: emailError }} />
            </>
        )
    }

    ContactListField = () => {
        const { selectedContactList, openContactList, contactList } = this.state;
        return (
            <>
                <StyledInput className={`AddGroup_StyledInput ${selectedContactList.length > 0 ? "focused" : ""}`} >
                    {
                        selectedContactList.length > 0 && (
                            <label className={"focused"}>Select Contact</label>
                        )
                    }

                    {
                        selectedContactList.length === 0 && (
                            <label id="open-contacts" style={{ cursor: "pointer" }} onClick={this.openContactList}>Select Contact</label>
                        )
                    }
                    
                    {
                        selectedContactList && selectedContactList.length > 0 && (
                            <StyledChipList id="selected-contact-list" className="AddGroup_StyledChipList">
                                <div className="chip-list">
                                    {
                                        selectedContactList.slice(0, 3).map(contact => {
                                            return (
                                                <Chip
                                                    data-test-id="contact-chip"
                                                    key={contact.id}
                                                    displayText={contact.name[0].toUpperCase()}
                                                    id={contact.id}
                                                    onRemove={this.onRemoveContact}
                                                />
                                            )
                                        })
                                    }
                                    {
                                        selectedContactList.length > 3 && (
                                            <div className="extra" onClick={() => this.viewEmailAndContact("contact")}>+{selectedContactList.length - 3} More</div>
                                        )
                                    }
                                </div>
                                <p className="add-btn" onClick={this.openContactList}>Add</p>
                            </StyledChipList>
                        )
                    }
                </StyledInput>
                <SelectContacts
                    open={openContactList}
                    onClose={this.closeContactList}
                    contacts={contactList}
                    filterContacts={selectedContactList}
                    onSearch={this.onSearchContact}
                    onAdd={this.onAddContacts}
                    alreadySelected={this.findItemPresentInEmailAndContactList}
                />
            </>
        )
    }

    SelectedEmailList = () => {
        const {
            selectedEmailList
        } = this.state;
        return (
            <StyledChipList id="selected-email-list" className="AddGroup_StyledChipList">
                <div className="chip-list" style={{ flexWrap: "wrap" }}>
                    {
                        selectedEmailList.slice(0, 3).map(email => {
                            return (
                                <Chip
                                    key={email.email}
                                    displayText={email.full_name ? email.full_name : email.email}
                                    id={email.email}
                                    onRemove={this.onRemoveEmail}
                                />
                            )
                        })
                    }
                    {
                        selectedEmailList.length > 3 && (
                            <div className="extra" onClick={() => this.viewEmailAndContact("email")}>+{selectedEmailList.length - 3} More</div>
                        )
                    }
                </div>
            </StyledChipList>
        )
    }

    AddGroupContainer = () => {
        const { 
            title, 
            description, 
            groupType,
            selectedEmailList,
            viewEmailAndContactData,
            auto_approve,
            interests,
            currentSelectedInterest,
            interestModalOpen,
            selectedInterest
        } = this.state;
        return (
            <StyledAddGroupContainer className="AddGroup_StyledAddGroupContainer_web">
                <GoBack displayText="Add Group" goBack={this.goBack} />
                <form autoComplete="off">
                    <div className="form-row" style={{ justifyContent: "center", alignItems: "center"}}>
                         {
                            this.ImageInput()
                         }
                    </div>
                    <div className="form-row">
                        <div className="form-col">
                            <InputText
                                onChange={(event)=> this.onChangeInputs(event.target.value, "title")}
                                value={title}
                                name="title"
                                label="Title*"
                                type="text"
                                isContainerFocused={true}
                                maxLength={42}
                            />
                        </div>
                        <div className="form-col">
                            <StyledInput className={`AddGroup_StyledInput ${selectedInterest.length > 0 ? "focused" : ""}`} style={{cursor: "pointer"}} onClick={this.onOpenSelectInterestModal}>
                                {
                                    selectedInterest.length > 0 && (
                                        <label className={"focused"}>Select Interest</label>
                                    )
                                }

                                {
                                    selectedInterest.length === 0 && (
                                        <label id="open-interest-modal" style={{ cursor: "pointer" }} onClick={this.onOpenSelectInterestModal}>Select Interest</label>
                                    )
                                }

                                {
                                    selectedInterest && selectedInterest.length > 0 && (
                                        <StyledChipList id="selected-interest-list" className="AddGroup_StyledChipList">
                                            <div className="chip-list">
                                                {
                                                    selectedInterest.slice(0, 3).map(interest=>{
                                                        return (
                                                            <Chip 
                                                              key={interest.id} 
                                                              displayText={interest.attributes.name} 
                                                              id={interest.id} 
                                                              onRemove={this.removeInterestFromSelectedInterest} 
                                                            />
                                                        )
                                                    })
                                                }
                                                {
                                                    selectedInterest.length > 3 && (
                                                        <div className="extra" onClick={this.onOpenSelectInterestModal}>+{selectedInterest.length - 3} More</div>
                                                    )
                                                }
                                            </div>
                                        </StyledChipList>
                                    )
                                }
                                <div style={{position: "absolute", right: "1rem", transform: interestModalOpen ? "rotate(180deg)": "rotate(0deg)"}}>
                                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.99953 7.03575L0.345703 1.38195L1.39953 0.328125L5.99953 4.92812L10.5995 0.328125L11.6534 1.38195L5.99953 7.03575Z" fill="white" />
                                    </svg>
                                </div>
                            </StyledInput>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-col" style={{ width: "100%" }}>
                            <TextArea
                                onChange={(event)=> this.onChangeInputs(event.target.value, "description")}
                                value={description}
                                label="Description"
                                isContainerFocused={true}
                                name="description"
                                maxLength={1024}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-col">
                            <StyledGroupType className="AddGroup_StyledGroupType">
                                <header>Group Type</header>
                                <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                                    <RadioButton selected={groupType === GroupType.PRIVATE} onSelect={() => this.onChangeInputs(GroupType.PRIVATE, "groupType")} label="Private" />
                                    <RadioButton selected={groupType === GroupType.PUBLIC} onSelect={() => this.onChangeInputs(GroupType.PUBLIC, "groupType")} label="Public" />
                                </div>
                            </StyledGroupType>
                        </div>
                    </div>
                    {
                        groupType && (
                            <div className="form-row">
                                <div className="form-col" style={{ position: "relative" }} id="email-menu-container">
                                    {this.EmailFiled()}
                                </div>
                                <div className="form-col">
                                    {this.ContactListField()}
                                </div>
                            </div>
                        )
                    }
                    {
                        selectedEmailList.length > 0 && (
                            <div className="form-row" style={{ marginTop: "-0.5rem" }}>
                                <div className="form-col">
                                    {this.SelectedEmailList()}
                                </div>
                            </div>
                        )
                    }
                    {
                        groupType === GroupType.PUBLIC && (
                            <div className="form-row">
                                <div className="form-col" style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                    {
                                        auto_approve ? (<img id="auto_approve" src={checkedBoxIcon} onClick={()=> this.onChangeAutoApprovalRequest(false)} />) : (<img id="auto_approve" src={emptyCheckBoxIcon} onClick={()=> this.onChangeAutoApprovalRequest(true)} />)
                                    }
                                    <p style={{fontSize: "1rem", fontWeight: 400, color: "rgba(255, 255, 255, 1)"}}>Auto-Approval Request to Join</p>
                                </div>
                            </div>
                        )
                    }
                    <div className="form-row" style={{ justifyContent: "center", alignItems: "center" }}>
                        <Button
                            text="Create Group"
                            buttonStyles={{
                                width: "21.4375rem",
                                background: "linear-gradient(180deg, rgba(243, 127, 120, 0.75) 0%, rgba(247, 83, 103, 0.75) 100%)",
                                color: "rgba(255, 255, 255, 0.75)"
                            }}
                            onClick={this.onCreateGroup}
                            disabled={this.disableCreateBtn()}
                        />
                    </div>
                </form>
                {
                    viewEmailAndContactData && (
                        <ViewSelectedEmailAndContact
                            open={Boolean(viewEmailAndContactData)}
                            onClose={this.closeViewEmailAndContactModal}
                            listData={viewEmailAndContactData.value}
                            type={viewEmailAndContactData.type}
                            onDone={this.onDoneViewEmailAndContact}
                        />
                    )
                }
                {
                    interestModalOpen && (
                        <SelectInterest
                            interests={interests}
                            currentSelectedInterest={currentSelectedInterest}
                            open={interestModalOpen}
                            onClose={this.onCloseSelectInterestModal}
                            setCurrentSelectInterest={this.setCurrentSelectedInterest}
                            onAddInterests={this.onAddInterests}
                            onSubmitNewInterest={this.onSubmitNewInterest}
                        />
                    )
                }
            </StyledAddGroupContainer>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <NavigationMenu {...this.props} children={this.AddGroupContainer()} />
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const StyledAddGroupContainer = styled("div")({
    "&.AddGroup_StyledAddGroupContainer_web": {
        display: "flex",
        flexDirection: "column",
        gap: '1.5rem',
        padding: "1rem",
        boxSizing: "border-box",
        "& > form": {
            userSelect: "none",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "1rem 3rem",
            "& > .form-row": {
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
                "& > .form-col": {
                    width: "49%"
                },
            },
            [`@media screen and (max-width: 1050px)`]: {
                padding: "1rem 0rem",
                "& > .form-row": {
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "1rem",
                    "& > .form-col": {
                        width: "100% !important"
                    },
                    "&:nth-child(6)": {
                        flexDirection: "column-reverse"
                    }
                }
            },
        },
    }
})

const StyledImageInput = styled("div")({
    "&.AddGroup_StyledImageInput": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto",
        "& .input": {
            width: "8.5rem",
            height: "8.6rem",
            background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
            cursor: "pointer",
            border: "0.51px solid rgba(25, 181, 185, 0.27)",
            display: "flex",
            gap: "0.8rem",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "2.04875rem",
            flexDirection: "column",
            "& p": {
                color: "rgba(255, 255, 255, 1)",
                fontSize: "1rem",
                fontWeight: 500
            }
        },
        "& .image": {
            width: "8.5rem",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            height: "8.6rem",
            borderRadius: "2.04875rem",
            "& > .icon": {
                height: "1.25rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
                width: "1.25rem",
                borderRadius: "100%",
                position: "absolute",
                bottom: 0,
                right: 0,
                cursor: "pointer"
            }
        },
        "& .error": {
            color: "rgba(255, 47, 63, 1)",
            fontSize: "0.75rem",
            fontWeight: 400,
        }
    }
})

const StyledGroupType = styled("div")({
    "&.AddGroup_StyledGroupType": {
        display: "flex",
        flexDirection: "column",
        gap: "0.3rem",
        "& > header": {
            fontSize: "0.875rem",
            fontWeight: 400,
            color: "rgba(242, 242, 242, 1)"
        }
    }
})

const StyledInput = styled("div")({
    "&.AddGroup_StyledInput": {
        position: "relative",
        padding: "0rem 0.9rem",
        height: "3rem",
        borderRadius: "100px",
        display: "flex",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        border: "1px solid rgba(28, 150, 154,0.3)",
        transition: "all 0.3s ease-in-out",
        maxHeight: "100%",
        alignItems: "center",
        
        "& input": {
            outline: "none",
            border: "none",
            color: "rgba(255, 255, 255, 1)",
            fontSize: "1rem",
            fontWeight: 400,
            background: "transparent",
            position: "relative",
            zIndex: 1
        },
        "& label": {
            fontSize: "1rem",
            transition: "transform 0.2s ease-in-out",
            position: "absolute",
            color: "white",
            "&.focused": {
                transform: 'translate(-14px,-38px)'
            }
        },
        "&.focused": {
            marginTop: '1rem !important'
        }
    }
})

const StyledChipList = styled("div")({
    "&.AddGroup_StyledChipList": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        "& .chip-list": {
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
            "& .extra": {
                color: "rgb(243, 121, 118)",
                cursor: "pointer",
                fontSize: "0.875rem",
                fontWeight: 500
            }
        },
        "& .add-btn": {
            color: "rgb(243, 121, 118)",
            cursor: "pointer",
            fontSize: "0.875rem",
            fontWeight: 500
        }
    }
})

// Customizable Area End
