
// Customizable Area Start
import React, { useState } from "react";
import FavoriteIcon from '@material-ui/icons/Favorite';
import { Box, Typography, styled } from "@material-ui/core";
import { commentIcon, likeIcon } from "../assets";
import { IComment, IPost } from "../../../../components/src/CommonTypes";
import CommentAddModal from "../../../comments/src/components/CommentAddModal.web";
import LikedUserListModal from "./LikedUserListModal.web";

interface RecentPostDetailProps {
    recentPostCommentOnly: IPost;
    userId?: number;
    onViewFeed: () => void;
    onLikePostCommentOnly: (postId: string) => void;
    onDisLikePostCommentOnly: (postId: string) => void;
    onClickComment: (postId: string) => void;
    commentsList?: IComment[];
    onAddCommentInPost:(event:{text: string, postId: string})=> void;
    onDeleteCommentFromPost:(event:string)=> void;
    isLoadingComments: boolean;
    onCloseCommentModal: () => void;
    handleFetchNextComments?: () => void;
    recentPostCoomentOnlylikedUserList?: { id: string | number, attributes: { like_by_name: string} }[]
    setLikedUsers?: (event?: {[key: string]: string}[])=> void
    fetchLikedUsers?: (postId: number |  string) => void;
}


const RecentPostCardCommentOnly: React.FC<RecentPostDetailProps> = (props: RecentPostDetailProps) => {
    const { recentPostCommentOnly, userId, onViewFeed, onDisLikePostCommentOnly, onLikePostCommentOnly, onClickComment, commentsList, onAddCommentInPost, onDeleteCommentFromPost, isLoadingComments, onCloseCommentModal, handleFetchNextComments, recentPostCoomentOnlylikedUserList, setLikedUsers, fetchLikedUsers } = props;

    const [openCommentModal, setOpenCommentModal] = useState<HTMLImageElement | null>(null);

    const onAddCommentEventHandler = (text: string) => {
        onAddCommentInPost({text, postId: recentPostCommentOnly.id})
    }

    const onClickShowLikedUsersList = ()=>{
        fetchLikedUsers?.(recentPostCommentOnly.id)
    }

    return (
        <StyledRecentPostCommentOnlyDetail className="RecentPostCommentOnly_Container">
            <Box className="recent-post-comment-only-main-container">
                <Box className="recent-post-comment-only-container-header">
                    <Typography className="header" component={"span"}>Recent Post</Typography>
                    <Typography onClick={onViewFeed} className="header link cursor-pointer" component={"span"}>View Feed</Typography>
                </Box>
                <Box maxHeight={"410px"} overflow={"auto"} className="recent-post-comment-only-main-card show-scrollbar">
                    <Box className="recent-post-comment-only-created-details">
                        <Box className="recent-post-comment-only-owner-details">
                            <Box className="recent-avatar">{recentPostCommentOnly.attributes.account_info.full_name[0].toUpperCase()}</Box>
                            <Box className="recent-post-comment-only-creator-detail-box" display={"flex"}>
                                <Typography className="recent-post-comment-only-creator-name" component={"span"}>{recentPostCommentOnly.attributes.account_info.full_name}</Typography>
                                <Typography className="recent-post-comment-only-create-time" component={"span"}>{recentPostCommentOnly.attributes.created_at}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="recent-post-comment-only-main-body">
                        <Box className="recent-post-comment-only-post-description">
                            <Typography component={"span"}>
                                {recentPostCommentOnly.attributes.body}
                            </Typography>
                        </Box>
                        <Box className="recent_post_comment_only_like_comment">
                            <Typography className="text-underline" onClick={onClickShowLikedUsersList} component={"span"} id="likes_count">{recentPostCommentOnly.attributes.total_likes} Likes</Typography>
                            <Typography component={"span"}>.</Typography>
                            <Typography
                                component={"span"}
                            >{recentPostCommentOnly.attributes.comments_count} Comments</Typography>
                        </Box>
                        <Box className="recent_post_comment_only_like_comment_action">
                            {recentPostCommentOnly.attributes.liked_by_me ? <FavoriteIcon className="liked_by_me" onClick={() => onDisLikePostCommentOnly(recentPostCommentOnly.id)} /> : <img id="like_post" src={likeIcon} alt="like" onClick={() => onLikePostCommentOnly(recentPostCommentOnly.id)} />}
                            <img id="post-comment-modal-icon" className="cursor-pointer" src={commentIcon} alt="comment"
                                onClick={(event) => {
                                    onClickComment(recentPostCommentOnly.id);
                                    setOpenCommentModal(event.currentTarget)
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
                <CommentAddModal
                    onClose={() => {
                        setOpenCommentModal(null);
                        onCloseCommentModal();
                    }}
                    commentsList={commentsList as IComment[]}
                    open={openCommentModal}
                    onAddComment={onAddCommentEventHandler}
                    onDeleteComment={onDeleteCommentFromPost}
                    isLoadingComments={isLoadingComments}
                    userId={userId}
                    handleFetchNextPageComments={handleFetchNextComments}
                />
                 {
                recentPostCoomentOnlylikedUserList && (
                    <LikedUserListModal list={recentPostCoomentOnlylikedUserList} open={Boolean(recentPostCoomentOnlylikedUserList)} onClose={()=> setLikedUsers?.(undefined)} />
                )
            }
            </Box>
        </StyledRecentPostCommentOnlyDetail>
    )
}

const StyledRecentPostCommentOnlyDetail = styled("div")({
    "&.RecentPostCommentOnly_Container": {
        display: "flex",
        flexDirection: "column",
        gap: "0.8rem",
        "& .recent-post-comment-only-main-container": {
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            padding: "1rem",
            background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
            borderRadius: "1rem",
            "& .recent-post-comment-only-container-header": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                "& .header": {
                    fontSize: "16px",
                    color: "rgba(255, 255, 255, 0.8)",
                },
                "& .link": {
                    color: "rgba(247, 83, 103, 1) !important"
                }
            }
        },
        "& .recent-post-comment-only-main-card": {
            padding: "20px",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderRadius: "0.5rem",
            "& .recent-post-comment-only-created-details": {
                display: "flex",
                paddingBottom: "20px",
                gap: "0.8rem",
                "& .recent-post-comment-only-owner-details": {
                    display: "flex",
                    alignItems: "center",
                    color: "rgba(255, 255, 255, 1)",
                    gap: "0.5rem",
                    "& .recent-post-comment-only-creator-detail-box": {
                        gap: "12px",
                        alignItems: "center",
                    },
                    "& .recent-avatar": {
                        alignItems: "center",
                        color: "rgba(37, 236, 242, 1)",
                        justifyContent: "center",
                        display: "flex",
                        width: "36px",
                        height: "36px",
                        border: "1px solid rgba(37, 236, 242, 1)",
                        borderRadius: "100%"
                    },
                    "& .recent-post-comment-only-creator-name": {
                        fontSize: "14px",
                    },
                    "& .recent-post-comment-only-create-time": {
                        color: "rgba(255, 255, 255, 0.8)",
                        fontSize: "12px",
                    },
                }
            },
            "& .recent-post-comment-only-main-body": {
                gap: "15px",
                display: "flex",
                flexDirection: "column",
                position: "relative",
                "& .recent-post-comment-only-post-description": {
                    color: "rgba(255, 255, 255, 1)",
                    wordBreak: "break-word",
                    "& span": {
                        fontSize: "14px",
                    }
                },
                "& .recent_post_comment_only_like_comment": {
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    "& span": {
                        fontSize: "12px",
                        color: "rgba(255, 255, 255, 0.8)"
                    }
                },
                "& .recent_post_comment_only_like_comment_action": {
                    alignItems: "center",
                    display: "flex",
                    gap: "15px",
                    position: "relative",
                    "& .liked_by_me": {
                        color: "red"
                    },
                    "& > *": {
                        cursor: "pointer"
                    }
                }
            },
        }
    }
})

export default RecentPostCardCommentOnly;
// Customizable Area End