import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
import { IAppointmentData } from "../../../components/src/CommonTypes";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  appointments: IAppointmentData[];
  loading: boolean;
  // Customizable Area End
}
interface SS {}

export default class UpcomingAppointmentsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAppointmentsApiCallId: string = "";
  rsvpStatusUpdateApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      loading: false,
      appointments: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      if(!token){
        token = await helper.getStorageData("authToken");
      }
      this.setState({
        token: token
      },()=>{
        this.getAppointments()
      })
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if(webApiRequestCallId === this.getAppointmentsApiCallId){
        this.handleGetAppointmentsApiCall(message);
      }else if(webApiRequestCallId === this.rsvpStatusUpdateApiCallId){
        this.handleRsvpStatusUpdate(message)
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getAppointments = async ()=>{
    helper.showLoader();
    this.setState({
      loading: true
    })
    this.getAppointmentsApiCallId = await helper.apiCall({
      method: "GET",
      endPoint: `${webConfigJSON.upcomingAppointmentsApiEndpoint}`,
      contentType: webConfigJSON.dashboardContentType,
      token: this.state.token
    })
  }
  handleGetAppointmentsApiCall = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson && responseJson.data){
      this.setState({
        appointments: responseJson.data
      })
    }else{
      this.setState({
        appointments: []
      })
    }
    helper.hideLoader();
    this.setState({
      loading: false
    })
  }

  onUpdateRsvpStatus = async (status:string,appointmentId:string | number)=>{
    helper.showLoader();
    const body = {
      status
    }
    this.rsvpStatusUpdateApiCallId = await helper.apiCall({
      method:"PATCH",
      endPoint:`bx_block_appointment_management/appointments/${appointmentId}/add_appointment_status`,
      contentType: webConfigJSON.dashboardContentType,
      token: this.state.token,
      body
    })
  }

  handleRsvpStatusUpdate= (message:Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (responseJson && !responseJson.errors) {
      const toastMsg = responseJson.message ? responseJson.message : "Your changes have been saved successfully";
      helper.showSuccessToast(toastMsg);
      this.getAppointments();
    }else{
      const toastMsg = responseJson.errors && responseJson.errors[0] && responseJson.errors[0].message ? responseJson.errors[0].message : "Something went wrong please try again!";
      helper.showErrorToast(toastMsg);
    }
    helper.hideLoader();
  }

  redirectTo = (endpoint: string, params?:{[key:string]:string | number}) => {
    this.props.navigation.navigate(endpoint, params)
  }

  goBack = ()=>{
    this.props.navigation.goBack()
  }
  // Customizable Area End
}
