// Customizable Area Start
import React from "react";
import {
  styled
} from "@material-ui/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import GroupHeader from "./components/GroupHeader.web";
import SearchField from "./components/SearchField.web";
import GroupCardContainer from "./components/GroupCardContainer.web";
import SharedGroups from "./components/SharedGroups.web";
import GroupDetail from "./components/GroupDetail.web";



import GroupsController, {
  Props
} from "./GroupsController";

export default class Groups extends GroupsController {
  constructor(props: Props) {
    super(props);
  }

  NoGroupsMessage = () => {
    return (
      <StyledNoGroupsMessage className="Groups_StyledNoGroupsMessage">
        <header>Chats</header>
        <div className="message">
          <p>No Groups Available</p>
        </div>
      </StyledNoGroupsMessage>
    )
  }

  GroupsContainer = ()=>{
    const { groupSearchValue, groups, sharedGroups, selectedGroup } = this.state;
    return (
      <StyledGroupsContainer className="Groups_StyledGroupsContainer">
        <GroupHeader redirectTo={this.redirectTo} />
        <div className="main-groups-container">
          <div className="left">
            {
              (groups.length > 0 || sharedGroups.length > 0) && (
                <>
                  <div style={{ padding: "0rem 1rem" }}>
                    <SearchField value={groupSearchValue} onChange={(event) => this.changeState("groupSearchValue", event)} />
                  </div>
                  <div className="groups-container">
                    <GroupCardContainer selectedGroup={selectedGroup} onClick={(event) => this.onSelectGroup({ ...event, type: "my-group" })} groups={groups} />
                    <SharedGroups selectedGroup={selectedGroup} onClick={(event) => this.onSelectGroup({ ...event, type: "shared-group" })} groups={sharedGroups} />
                  </div>
                </>
              )
            }
            {
              (groups.length === 0 && sharedGroups.length === 0) && (
                this.NoGroupsMessage()
              )
            }
          </div>
          <div className="right">
            <GroupDetail selectedGroup={selectedGroup} redirectTo={this.redirectTo} onDeleteGroup={this.onDeleteGroup} />
          </div>
        </div>
      </StyledGroupsContainer>
    )
  }


  render() {
    return (
     <NavigationMenu {...this.props} children={this.GroupsContainer()} />
    );
  }
}

const StyledGroupsContainer = styled("div")({
  "&.Groups_StyledGroupsContainer": {
    display: "flex",
    flexDirection: "column",
    gap: '1.5rem',
    padding: "1rem",
    boxSizing: "border-box",
    height: "100%",
    "& .main-groups-container":{
      display: "flex",
      gap:"1rem",
      height: "calc(100% - 4.5rem)",
      boxSizing: "border-box",
      "& > .left, > .right":{
        borderRadius: "0.5rem",
        background: "rgba(35, 64, 75, 1)",
        height: "100%",
        boxSizing: "border-box",
        // overflowX: "hidden"
      },
      "& > .left":{
          // width: "26.875rem",
          width: "40%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          padding: "1rem 0rem",
          boxSizing: "border-box",
          "& > .groups-container":{
            overflow: "scroll",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            flex: 1
          }
      },
      "& > .right":{
        flex: 1,
        flexShrink: 1,
        width: "60%",
        boxSizing: "border-box"
      }
    }
  }
})

const StyledNoGroupsMessage = styled("div")({
  "&.Groups_StyledNoGroupsMessage":{
      padding: "0rem 1rem",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      "& > header":{
        fontSize: "1.25rem",
        fontWeight: 500,
        color: "rgba(255, 255, 255, 1)"
      },
      "& .message":{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        "& > p":{
          fontSize: "1rem",
          fontWeight: 400,
          color: "rgba(255, 255, 255, 0.8)"
        }
      }
  }
})



// Customizable Area End
