// Customizable Area Start
import React from "react";
import GroupPostsListController, { Props } from "./GroupPostsListController";
import { Grid, styled } from "@material-ui/core";
import CustomNoDataComponent from "../../../components/src/CustomNoDataComponent.web";
import GroupPostListCard from "./components/GroupPostListCard.web";
// Customizable Area End

export default class GroupPostsList extends GroupPostsListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  GroupPostList = () => {
    const { 
      userId, 
      currentGroupPostCommentList,
      isLoadingComments,
      userName,
      groupPostLikedUserList,
    } = this.state;
    const { groupPostsList } = this.props;
    return (
      <StyledGroupPostListContainer className="Group_Post_List_Web_Container">
        <div id="top_div"></div>
        <Grid container direction="row" spacing={2}>
          {groupPostsList.length > 0 ? (
            groupPostsList.map((post, index) => (
              <Grid key={post.id} className="group_post_detail_card" item xs={12}>
                <GroupPostListCard 
                  handleFetchNextPagePosts={this.props.handleFetchNextPagePosts} 
                  isLastElement={index === groupPostsList.length - 1} 
                  groupPostDetail={post}
                  userId={userId}
                  comments={currentGroupPostCommentList}
                  isLoadingComments={isLoadingComments}
                  isFromGroupPost
                  userName={userName}
                  onOpenCommentsSection={this.getGroupPostComments}
                  isCommentSectionOpen={!!post.attributes.show_comments_section}
                  onCloseCommentsSection={this.handleHideCommentsSection}
                  onAddComment={this.addGroupPostComment}
                  onLikeGroupPost={this.onLikeGroupPost}
                  fetchLikedUsers={this.getGroupPostLikedUsersList}
                  likedUserList={groupPostLikedUserList}
                  setLikedUsers={this.setLikedUserList}
                  onDeleteComment={(commentId: number | string) => this.onDeleteGroupPostComment(commentId, post.id)}
                />
              </Grid>
            ))
          ) : (
            <CustomNoDataComponent
              titleMsg="No Posts"
              subTitleMsg="No posts available. Stay tuned for the updates."
            />
          )}
        </Grid>
      </StyledGroupPostListContainer>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start

      return (
          <>
              {this.GroupPostList()}
          </>
      );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledGroupPostListContainer = styled("div")({
  "&.Group_Post_List_Web_Container": {
    display: "flex",
    flexDirection: "column",
    padding: "1rem 1rem",
    overflowY: "auto",
  },
});
// Customizable Area End
