import React from "react";
import { styled } from "@material-ui/core";

interface CustomNoDataProps {
    titleMsg: string;
    subTitleMsg: string;
    useCommonEmptyImg?: boolean;
}

const CustomNoDataComponent: React.FC<CustomNoDataProps> = (props: CustomNoDataProps) => {
    const { subTitleMsg, titleMsg, useCommonEmptyImg = true } = props;
    const EmptyImgSVG1 = () => {
        return (
            <svg width="129" height="119" viewBox="0 0 129 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M58.7942 116.104C87.4511 116.104 110.682 92.873 110.682 64.216C110.682 35.5591 87.4511 12.3281 58.7942 12.3281C30.1373 12.3281 6.90625 35.5591 6.90625 64.216C6.90625 92.873 30.1373 116.104 58.7942 116.104Z" fill="white" fillOpacity="0.11" />
                <path d="M86.7866 24.4023H29.3057C25.7228 24.4023 22.8184 27.3068 22.8184 30.8897H93.2739C93.2739 27.3068 90.3694 24.4023 86.7866 24.4023Z" fill="url(#paint0_linear_18874_141575)" />
                <path d="M22.8184 30.7109H93.2739V84.3711C93.2739 86.7568 92.3262 89.0449 90.6392 90.7319C88.9522 92.4189 86.6642 93.3666 84.2784 93.3666H31.8139C29.4281 93.3666 27.1401 92.4189 25.4531 90.7319C23.7661 89.0449 22.8184 86.7568 22.8184 84.3711V30.7109Z" fill="url(#paint1_linear_18874_141575)" />
                <path d="M81.1645 37.4883H34.9535C33.9911 37.4883 33.2109 38.2676 33.2109 39.2289C33.2109 40.1903 33.9911 40.9696 34.9535 40.9696H81.1645C82.1269 40.9696 82.907 40.1903 82.907 39.2289C82.907 38.2676 82.1269 37.4883 81.1645 37.4883Z" fill="url(#paint2_linear_18874_141575)" />
                <path d="M81.1645 52.2812H34.9535C33.9911 52.2812 33.2109 53.0606 33.2109 54.0219C33.2109 54.9832 33.9911 55.7625 34.9535 55.7625H81.1645C82.1269 55.7625 82.907 54.9832 82.907 54.0219C82.907 53.0606 82.1269 52.2812 81.1645 52.2812Z" fill="url(#paint3_linear_18874_141575)" />
                <path d="M81.1645 67.0703H34.9535C33.9911 67.0703 33.2109 67.8496 33.2109 68.811C33.2109 69.7723 33.9911 70.5516 34.9535 70.5516H81.1645C82.1269 70.5516 82.907 69.7723 82.907 68.811C82.907 67.8496 82.1269 67.0703 81.1645 67.0703Z" fill="url(#paint4_linear_18874_141575)" />
                <path d="M81.1645 81.8633H34.9535C33.9911 81.8633 33.2109 82.6426 33.2109 83.6039C33.2109 84.5653 33.9911 85.3446 34.9535 85.3446H81.1645C82.1269 85.3446 82.907 84.5653 82.907 83.6039C82.907 82.6426 82.1269 81.8633 81.1645 81.8633Z" fill="url(#paint5_linear_18874_141575)" />
                <g filter="url(#filter0_d_18874_141575)">
                    <path d="M34.6411 63.8984H10.0344C8.05761 63.8984 6.45508 65.501 6.45508 67.4778V102.649C6.45508 104.626 8.05761 106.228 10.0344 106.228H34.6411C36.6179 106.228 38.2204 104.626 38.2204 102.649V67.4778C38.2204 65.501 36.6179 63.8984 34.6411 63.8984Z" fill="url(#paint6_linear_18874_141575)" />
                </g>
                <g filter="url(#filter1_d_18874_141575)">
                    <path d="M116.367 5.21094H89.1877C87.9212 5.21094 86.8945 6.23764 86.8945 7.50414V20.2789C86.8945 21.5454 87.9212 22.5721 89.1877 22.5721H116.367C117.633 22.5721 118.66 21.5454 118.66 20.2789V7.50414C118.66 6.23764 117.633 5.21094 116.367 5.21094Z" fill="url(#paint7_linear_18874_141575)" />
                </g>
                <path d="M31.8938 81.4922H12.7787C12.1351 81.4922 11.6133 82.014 11.6133 82.6577V82.6614C11.6133 83.3051 12.1351 83.8269 12.7787 83.8269H31.8938C32.5374 83.8269 33.0592 83.3051 33.0592 82.6614V82.6577C33.0592 82.014 32.5374 81.4922 31.8938 81.4922Z" fill="url(#paint8_linear_18874_141575)" />
                <path d="M31.905 97.9219H12.9543C12.3161 97.9219 11.7988 98.4274 11.7988 99.051V99.0546C11.7988 99.6782 12.3161 100.184 12.9543 100.184H31.905C32.5432 100.184 33.0605 99.6782 33.0605 99.0546V99.051C33.0605 98.4274 32.5432 97.9219 31.905 97.9219Z" fill="url(#paint9_linear_18874_141575)" />
                <path d="M31.905 89.3281H12.9543C12.3161 89.3281 11.7988 89.8336 11.7988 90.4572V90.4609C11.7988 91.0845 12.3161 91.59 12.9543 91.59H31.905C32.5432 91.59 33.0605 91.0845 33.0605 90.4609V90.4572C33.0605 89.8336 32.5432 89.3281 31.905 89.3281Z" fill="url(#paint10_linear_18874_141575)" />
                <path d="M19.7677 69.332H14.1818C12.9445 69.332 11.9414 70.3351 11.9414 71.5724V73.1226C11.9414 74.3599 12.9445 75.363 14.1818 75.363H19.7677C21.005 75.363 22.0081 74.3599 22.0081 73.1226V71.5724C22.0081 70.3351 21.005 69.332 19.7677 69.332Z" fill="url(#paint11_linear_18874_141575)" />
                <path d="M93.5974 16.5307C95.0534 16.5307 96.2338 15.3503 96.2338 13.8942C96.2338 12.4382 95.0534 11.2578 93.5974 11.2578C92.1413 11.2578 90.9609 12.4382 90.9609 13.8942C90.9609 15.3503 92.1413 16.5307 93.5974 16.5307Z" fill="white" />
                <path d="M103.019 16.5307C104.475 16.5307 105.656 15.3503 105.656 13.8942C105.656 12.4382 104.475 11.2578 103.019 11.2578C101.563 11.2578 100.383 12.4382 100.383 13.8942C100.383 15.3503 101.563 16.5307 103.019 16.5307Z" fill="white" />
                <path d="M112.441 16.5307C113.897 16.5307 115.078 15.3503 115.078 13.8942C115.078 12.4382 113.897 11.2578 112.441 11.2578C110.985 11.2578 109.805 12.4382 109.805 13.8942C109.805 15.3503 110.985 16.5307 112.441 16.5307Z" fill="white" />
                <path d="M96.8536 65.8945H97.1346C100.366 65.8945 103.006 68.5247 103.006 71.7319V93.6539C103.006 96.8672 100.36 99.4912 97.1346 99.4912H96.8536V74.198C98.0877 74.198 99.0835 73.2019 99.0835 71.9809C99.0835 70.7539 98.0816 69.7638 96.8536 69.7638V65.8945ZM75.3731 65.8945H96.8536V69.7578C95.6195 69.7578 94.6176 70.7539 94.6176 71.9749C94.6176 73.2019 95.6195 74.192 96.8536 74.192V99.4912H75.3731V74.198C76.6072 74.198 77.6091 73.2019 77.6091 71.9809C77.6091 70.7539 76.6072 69.7638 75.3731 69.7638V65.8945ZM75.0859 65.8945H75.367V69.7578C74.1329 69.7578 73.1371 70.7539 73.1371 71.9749C73.1371 73.2019 74.139 74.192 75.367 74.192V99.4912H75.0859C71.8541 99.4912 69.2148 96.8611 69.2148 93.6539V71.7319C69.2148 68.5247 71.8602 65.8945 75.0859 65.8945Z" fill="white" />
                <path d="M96.8536 65.8945H97.1346C100.366 65.8945 103.006 68.5369 103.006 71.759V75.7988H96.8536V74.2304C98.0877 74.2304 99.0835 73.2296 99.0835 72.0031C99.0835 70.7704 98.0816 69.7757 96.8536 69.7757V65.8945ZM75.3731 65.8945H96.8536V69.7757C95.6195 69.7757 94.6176 70.7765 94.6176 72.0031C94.6176 73.2357 95.6195 74.2304 96.8536 74.2304V75.7988H75.3731V74.2304C76.6072 74.2304 77.6091 73.2296 77.6091 72.0031C77.6091 70.7704 76.6072 69.7757 75.3731 69.7757V65.8945ZM75.0859 65.8945H75.367V69.7757C74.1329 69.7757 73.1371 70.7765 73.1371 72.0031C73.1371 73.2357 74.139 74.2304 75.367 74.2304V75.7988H69.2148V71.759C69.2148 68.5369 71.8602 65.8945 75.0859 65.8945Z" fill="url(#paint12_linear_18874_141575)" />
                <path d="M75.3298 64.1445C75.8082 64.1445 76.2007 64.5369 76.2007 65.0151V71.0419C76.2007 71.5202 75.8082 71.9126 75.3298 71.9126C74.8515 71.9126 74.459 71.5202 74.459 71.0419V65.0151C74.459 64.5308 74.8453 64.1445 75.3298 64.1445ZM96.8922 64.1445C97.3706 64.1445 97.7631 64.5369 97.7631 65.0151V71.0419C97.7631 71.5202 97.3706 71.9126 96.8922 71.9126C96.4139 71.9126 96.0214 71.5202 96.0214 71.0419V65.0151C96.0275 64.5308 96.4139 64.1445 96.8922 64.1445Z" fill="white" />
                <path d="M82.1492 95.7144H79.1303V84.319C78.027 85.3511 76.7297 86.116 75.2324 86.6139V83.8698C76.0205 83.6087 76.8752 83.123 77.8027 82.4006C78.7241 81.6781 79.3606 80.8403 79.7062 79.875H82.1552V95.7144H82.1492ZM89.2538 87.2028C88.4718 86.8749 87.9081 86.4196 87.5504 85.8428C87.1988 85.2661 87.017 84.6347 87.017 83.9426C87.017 82.7648 87.4292 81.7935 88.2475 81.0285C89.0659 80.2635 90.2359 79.875 91.7453 79.875C93.2426 79.875 94.4065 80.2575 95.231 81.0285C96.0554 81.7935 96.4737 82.7709 96.4737 83.9426C96.4737 84.6772 96.2858 85.3268 95.9039 85.8975C95.5219 86.4682 94.9885 86.9053 94.3035 87.2028C95.1764 87.5549 95.8432 88.0649 96.2979 88.7387C96.7525 89.4126 96.9829 90.1897 96.9829 91.0761C96.9829 92.5332 96.5161 93.717 95.5886 94.6277C94.6611 95.5383 93.4245 95.9937 91.8847 95.9937C90.4541 95.9937 89.2599 95.6172 88.3082 94.8644C87.1806 93.972 86.6229 92.7578 86.6229 91.2036C86.6229 90.3476 86.8351 89.5644 87.2594 88.8541C87.6777 88.1377 88.3445 87.5913 89.2538 87.2028ZM89.5994 90.9122C89.5994 91.7439 89.8115 92.3935 90.2359 92.861C90.6602 93.3285 91.1937 93.5592 91.8302 93.5592C92.4546 93.5592 92.9698 93.3345 93.376 92.8853C93.7822 92.436 93.9883 91.7925 93.9883 90.9425C93.9883 90.2019 93.7822 89.6069 93.3639 89.1637C92.9456 88.7145 92.4243 88.4898 91.7817 88.4898C91.0421 88.4898 90.4965 88.7448 90.1328 89.2548C89.7812 89.7648 89.5994 90.3172 89.5994 90.9122ZM89.8782 84.1551C89.8782 84.7561 90.048 85.2297 90.3874 85.5636C90.7269 85.9036 91.1816 86.0675 91.7453 86.0675C92.3212 86.0675 92.7759 85.8975 93.1214 85.5575C93.4669 85.2175 93.6367 84.744 93.6367 84.143C93.6367 83.5783 93.4669 83.123 93.1275 82.783C92.788 82.4431 92.3394 82.2731 91.7817 82.2731C91.1997 82.2731 90.739 82.4431 90.3935 82.7891C90.048 83.1352 89.8782 83.5905 89.8782 84.1551Z" fill="#F75367" />
                <g filter="url(#filter2_f_18874_141575)">
                    <ellipse cx="73.5701" cy="84.0102" rx="16.5955" ry="2.45161" fill="#F5696F" fillOpacity="0.56" />
                </g>
                <path d="M54.221 101.005C54.4983 99.8566 56.132 99.8566 56.4093 101.005L57.0019 103.46C57.101 103.871 57.4215 104.191 57.8319 104.29L60.2872 104.883C61.4357 105.16 61.4358 106.794 60.2872 107.071L57.832 107.664C57.4215 107.763 57.101 108.084 57.0019 108.494L56.4093 110.949C56.132 112.098 54.4983 112.098 54.221 110.949L53.6283 108.494C53.5292 108.084 53.2088 107.763 52.7983 107.664L50.3431 107.071C49.1945 106.794 49.1945 105.16 50.3431 104.883L52.7983 104.29C53.2088 104.191 53.5292 103.871 53.6283 103.46L54.221 101.005Z" fill="url(#paint13_linear_18874_141575)" />
                <path d="M16.6722 14.1458C16.9494 12.9972 18.5832 12.9972 18.8604 14.1458L19.4531 16.601C19.5522 17.0115 19.8727 17.332 20.2831 17.4311L22.7383 18.0237C23.8869 18.301 23.8869 19.9347 22.7383 20.212L20.2831 20.8047C19.8727 20.9038 19.5522 21.2242 19.4531 21.6347L18.8604 24.0899C18.5832 25.2385 16.9494 25.2385 16.6722 24.0899L16.0795 21.6347C15.9804 21.2242 15.6599 20.9038 15.2495 20.8047L12.7943 20.212C11.6457 19.9347 11.6457 18.301 12.7943 18.0237L15.2495 17.4311C15.6599 17.332 15.9804 17.0115 16.0795 16.601L16.6722 14.1458Z" fill="url(#paint14_linear_18874_141575)" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M56.7253 13.9695C57.6002 13.9695 58.3094 13.2603 58.3094 12.3855C58.3094 11.5106 57.6002 10.8014 56.7253 10.8014C55.8504 10.8014 55.1412 11.5106 55.1412 12.3855C55.1412 13.2603 55.8504 13.9695 56.7253 13.9695ZM56.7253 15.1576C58.2563 15.1576 59.4975 13.9165 59.4975 12.3855C59.4975 10.8544 58.2563 9.61328 56.7253 9.61328C55.1943 9.61328 53.9531 10.8544 53.9531 12.3855C53.9531 13.9165 55.1943 15.1576 56.7253 15.1576Z" fill="url(#paint15_linear_18874_141575)" />
                <path d="M106.046 57.8316C105.868 57.1319 106.514 56.5015 107.209 56.6969L110.684 57.6735C111.379 57.8689 111.602 58.7435 111.086 59.2478L108.502 61.7693C107.985 62.2736 107.116 62.0295 106.938 61.3298L106.046 57.8316Z" fill="url(#paint16_linear_18874_141575)" />
                <path d="M12.8634 50.8503C13.5633 50.6728 14.1929 51.3196 13.9967 52.0144L13.0155 55.4887C12.8193 56.1836 11.9444 56.4054 11.4407 55.888L8.92253 53.3012C8.41888 52.7838 8.66418 51.9152 9.36407 51.7377L12.8634 50.8503Z" fill="url(#paint17_linear_18874_141575)" />
                <path d="M88.4103 105.589C89.1102 105.411 89.7398 106.058 89.5435 106.753L88.5624 110.227C88.3661 110.922 87.4913 111.144 86.9876 110.626L84.4694 108.039C83.9658 107.522 84.2111 106.654 84.9109 106.476L88.4103 105.589Z" fill="url(#paint18_linear_18874_141575)" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M109.653 37.0438C110.528 37.0438 111.237 36.3345 111.237 35.4597C111.237 34.5848 110.528 33.8756 109.653 33.8756C108.778 33.8756 108.069 34.5848 108.069 35.4597C108.069 36.3345 108.778 37.0438 109.653 37.0438ZM109.653 38.2318C111.184 38.2318 112.425 36.9907 112.425 35.4597C112.425 33.9286 111.184 32.6875 109.653 32.6875C108.122 32.6875 106.881 33.9286 106.881 35.4597C106.881 36.9907 108.122 38.2318 109.653 38.2318Z" fill="url(#paint19_linear_18874_141575)" />
                <path d="M105.76 107.594L112.437 100.232L113.443 101.145C114.418 102.03 115.003 103.266 115.067 104.582C115.131 105.898 114.67 107.185 113.785 108.161C112.899 109.137 111.663 109.721 110.347 109.785C109.031 109.85 107.744 109.388 106.768 108.503L105.762 107.591L105.76 107.594Z" fill="#F5696F" />
                <defs>
                    <filter id="filter0_d_18874_141575" x="0.0264885" y="59.6127" width="47.8371" height="58.3996" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="1.60715" dy="3.75001" />
                        <feGaussianBlur stdDeviation="4.01787" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.104618 0 0 0 0 0.465612 0 0 0 0 0.545833 0 0 0 0.15 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_18874_141575" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_18874_141575" result="shape" />
                    </filter>
                    <filter id="filter1_d_18874_141575" x="83.1445" y="0.389495" width="45.6942" height="31.288" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="3.21429" dy="2.14286" />
                        <feGaussianBlur stdDeviation="3.48215" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.104618 0 0 0 0 0.465612 0 0 0 0 0.545833 0 0 0 0.09 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_18874_141575" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_18874_141575" result="shape" />
                    </filter>
                    <filter id="filter2_f_18874_141575" x="45.8082" y="70.3922" width="55.5242" height="27.2352" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="5.58321" result="effect1_foregroundBlur_18874_141575" />
                    </filter>
                    <linearGradient id="paint0_linear_18874_141575" x1="58.0461" y1="24.4023" x2="58.0461" y2="30.8897" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F37F78" />
                        <stop offset="1" stopColor="#F75367" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_18874_141575" x1="20.7029" y1="77.695" x2="122.487" y2="79.5045" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="#FFEFEF" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_18874_141575" x1="58.059" y1="37.4883" x2="58.059" y2="40.9696" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F37F78" />
                        <stop offset="1" stopColor="#F75367" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_18874_141575" x1="58.059" y1="52.2812" x2="58.059" y2="55.7625" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F37F78" />
                        <stop offset="1" stopColor="#F75367" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_18874_141575" x1="58.059" y1="67.0703" x2="58.059" y2="70.5516" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F37F78" />
                        <stop offset="1" stopColor="#F75367" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_18874_141575" x1="58.059" y1="81.8633" x2="58.059" y2="85.3446" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F37F78" />
                        <stop offset="1" stopColor="#F75367" />
                    </linearGradient>
                    <linearGradient id="paint6_linear_18874_141575" x1="5.5013" y1="95.6407" x2="66.385" y2="100.641" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="#FFF3F3" />
                    </linearGradient>
                    <linearGradient id="paint7_linear_18874_141575" x1="102.777" y1="5.21094" x2="102.777" y2="22.5721" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F37F78" />
                        <stop offset="1" stopColor="#F75367" />
                    </linearGradient>
                    <linearGradient id="paint8_linear_18874_141575" x1="22.3363" y1="81.4922" x2="22.3363" y2="83.8269" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F37F78" />
                        <stop offset="1" stopColor="#F75367" />
                    </linearGradient>
                    <linearGradient id="paint9_linear_18874_141575" x1="22.4297" y1="97.9219" x2="22.4297" y2="100.184" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F37F78" />
                        <stop offset="1" stopColor="#F75367" />
                    </linearGradient>
                    <linearGradient id="paint10_linear_18874_141575" x1="22.4297" y1="89.3281" x2="22.4297" y2="91.59" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F37F78" />
                        <stop offset="1" stopColor="#F75367" />
                    </linearGradient>
                    <linearGradient id="paint11_linear_18874_141575" x1="16.9748" y1="69.332" x2="16.9748" y2="75.363" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F37F78" />
                        <stop offset="1" stopColor="#F75367" />
                    </linearGradient>
                    <linearGradient id="paint12_linear_18874_141575" x1="86.1103" y1="65.8945" x2="86.1103" y2="75.7988" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F37F78" />
                        <stop offset="1" stopColor="#F75367" />
                    </linearGradient>
                    <linearGradient id="paint13_linear_18874_141575" x1="55.3151" y1="96.4727" x2="55.3151" y2="115.482" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F37F78" />
                        <stop offset="1" stopColor="#F75367" />
                    </linearGradient>
                    <linearGradient id="paint14_linear_18874_141575" x1="17.7663" y1="9.61328" x2="17.7663" y2="28.6224" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F37F78" />
                        <stop offset="1" stopColor="#F75367" />
                    </linearGradient>
                    <linearGradient id="paint15_linear_18874_141575" x1="56.7253" y1="9.61328" x2="56.7253" y2="15.1576" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F37F78" />
                        <stop offset="1" stopColor="#F75367" />
                    </linearGradient>
                    <linearGradient id="paint16_linear_18874_141575" x1="105.645" y1="56.2574" x2="111.177" y2="61.9256" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F37F78" />
                        <stop offset="1" stopColor="#F75367" />
                    </linearGradient>
                    <linearGradient id="paint17_linear_18874_141575" x1="14.4382" y1="50.451" x2="8.76278" y2="55.9758" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F37F78" />
                        <stop offset="1" stopColor="#F75367" />
                    </linearGradient>
                    <linearGradient id="paint18_linear_18874_141575" x1="89.9851" y1="105.189" x2="84.3097" y2="110.714" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F37F78" />
                        <stop offset="1" stopColor="#F75367" />
                    </linearGradient>
                    <linearGradient id="paint19_linear_18874_141575" x1="109.653" y1="32.6875" x2="109.653" y2="38.2318" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F37F78" />
                        <stop offset="1" stopColor="#F75367" />
                    </linearGradient>
                </defs>
            </svg>
        )
    }
    const EmptyImgSvg2 = () => {
        return (
            <svg width="111" height="84" viewBox="0 0 111 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M93.9601 3.92666C93.8123 3.34713 94.3473 2.82497 94.9231 2.98677L98.8095 4.07891C99.3852 4.24071 99.5699 4.96511 99.1419 5.38284L96.2529 8.20249C95.8249 8.62022 95.1052 8.41797 94.9575 7.83845L93.9601 3.92666Z" fill="#FFBEBD" />
                <path d="M24.1686 3.66443C24.3983 2.71306 25.7515 2.71306 25.9811 3.66443L26.7541 6.86638C26.8361 7.20636 27.1016 7.4718 27.4416 7.55387L30.6435 8.32681C31.5949 8.55646 31.5949 9.90967 30.6435 10.1393L27.4416 10.9123C27.1016 10.9943 26.8361 11.2598 26.7541 11.5998L25.9811 14.8017C25.7515 15.7531 24.3983 15.7531 24.1686 14.8017L23.3957 11.5998C23.3136 11.2598 23.0482 10.9943 22.7082 10.9123L19.5062 10.1393C18.5549 9.90967 18.5549 8.55646 19.5062 8.32681L22.7082 7.55387C23.0482 7.4718 23.3136 7.20636 23.3957 6.86638L24.1686 3.66443Z" fill="#FFBEBD" />
                <path d="M4.52694 37.7196C4.64098 37.2472 5.31292 37.2473 5.42695 37.7197L5.81075 39.3096C5.8515 39.4784 5.98331 39.6102 6.15213 39.651L7.74206 40.0348C8.21446 40.1488 8.21446 40.8207 7.74205 40.9348L6.15213 41.3186C5.98331 41.3593 5.8515 41.4911 5.81075 41.6599L5.42695 43.2499C5.31291 43.7223 4.64098 43.7223 4.52694 43.2499L4.14314 41.6599C4.10239 41.4911 3.97058 41.3593 3.80177 41.3186L2.21184 40.9348C1.73944 40.8207 1.73944 40.1488 2.21184 40.0348L3.80177 39.651C3.97058 39.6102 4.10239 39.4784 4.14314 39.3096L4.52694 37.7196Z" fill="#FFBEBD" />
                <path d="M64.8947 12.2582C64.8947 13.4382 63.9382 14.3947 62.7582 14.3947C61.5783 14.3947 60.6217 13.4382 60.6217 12.2582C60.6217 11.0783 61.5783 10.1217 62.7582 10.1217C63.9382 10.1217 64.8947 11.0783 64.8947 12.2582Z" stroke="#FFBEBD" strokeWidth="1.16536" />
                <path d="M101.831 71.059C102.06 70.1076 103.414 70.1076 103.643 71.059L104.416 74.2609C104.498 74.6009 104.764 74.8663 105.104 74.9484L108.306 75.7213C109.257 75.951 109.257 77.3042 108.306 77.5339L105.104 78.3068C104.764 78.3889 104.498 78.6543 104.416 78.9943L103.643 82.1962C103.414 83.1476 102.06 83.1476 101.831 82.1962L101.058 78.9943C100.976 78.6543 100.71 78.3889 100.37 78.3068L97.1683 77.5339C96.217 77.3042 96.217 75.951 97.1683 75.7213L100.37 74.9484C100.71 74.8663 100.976 74.6009 101.058 74.2609L101.831 71.059Z" fill="#FFBEBD" />
                <circle cx="90.9985" cy="44.9926" r="2.1365" stroke="#FC9999" strokeWidth="1.16536" />
                <path d="M70.4768 22.2266H70.9881C76.8678 22.2266 81.6694 27.0116 81.6694 32.8465V72.7293C81.6694 78.5753 76.8567 83.3493 70.9881 83.3493H70.4768V37.3332C72.722 37.3332 74.5337 35.5208 74.5337 33.2996C74.5337 31.0673 72.7109 29.266 70.4768 29.266V22.2266ZM31.3971 22.2266H70.4768V29.255C68.2316 29.255 66.4088 31.0673 66.4088 33.2886C66.4088 35.5208 68.2316 37.3221 70.4768 37.3221V83.3493H31.3971V37.3332C33.6423 37.3332 35.4651 35.5208 35.4651 33.2996C35.4651 31.0673 33.6423 29.266 31.3971 29.266V22.2266ZM30.8747 22.2266H31.386V29.255C29.1408 29.255 27.3291 31.0673 27.3291 33.2886C27.3291 35.5208 29.1519 37.3221 31.386 37.3221V83.3493H30.8747C24.995 83.3493 20.1934 78.5642 20.1934 72.7293V32.8465C20.1934 27.0116 25.0061 22.2266 30.8747 22.2266Z" fill="white" />
                <path d="M70.4768 22.2266H70.9881C76.8678 22.2266 81.6694 27.0338 81.6694 32.8958V40.2454H70.4768V37.3921C72.722 37.3921 74.5337 35.5714 74.5337 33.3398C74.5337 31.0972 72.7109 29.2876 70.4768 29.2876V22.2266ZM31.3971 22.2266H70.4768V29.2876C68.2316 29.2876 66.4088 31.1083 66.4088 33.3398C66.4088 35.5825 68.2316 37.3921 70.4768 37.3921V40.2454H31.3971V37.3921C33.6423 37.3921 35.4651 35.5714 35.4651 33.3398C35.4651 31.0972 33.6423 29.2876 31.3971 29.2876V22.2266ZM30.8747 22.2266H31.386V29.2876C29.1408 29.2876 27.3291 31.1083 27.3291 33.3398C27.3291 35.5825 29.1519 37.3921 31.386 37.3921V40.2454H20.1934V32.8958C20.1934 27.0338 25.0061 22.2266 30.8747 22.2266Z" fill="url(#paint0_linear_18440_79257)" />
                <path d="M31.3167 19.0469C32.187 19.0469 32.9011 19.7607 32.9011 20.6308V31.5954C32.9011 32.4654 32.187 33.1793 31.3167 33.1793C30.4465 33.1793 29.7324 32.4654 29.7324 31.5954V20.6308C29.7324 19.7496 30.4353 19.0469 31.3167 19.0469ZM70.5454 19.0469C71.4156 19.0469 72.1297 19.7607 72.1297 20.6308V31.5954C72.1297 32.4654 71.4156 33.1793 70.5454 33.1793C69.6751 33.1793 68.9611 32.4654 68.9611 31.5954V20.6308C68.9722 19.7496 69.6751 19.0469 70.5454 19.0469Z" fill="white" />
                <path d="M43.7263 76.4847H38.234V55.753C36.2268 57.6307 33.8667 59.0223 31.1426 59.928V54.9357C32.5763 54.4607 34.1313 53.5771 35.8187 52.2627C37.4951 50.9484 38.6531 49.4241 39.2817 47.668H43.7373V76.4847H43.7263ZM56.6518 60.9994C55.2291 60.403 54.2035 59.5746 53.5528 58.5253C52.9131 57.476 52.5823 56.3273 52.5823 55.0682C52.5823 52.9254 53.3322 51.1582 54.8211 49.7665C56.31 48.3749 58.4385 47.668 61.1846 47.668C63.9087 47.668 66.0262 48.3638 67.5261 49.7665C69.026 51.1582 69.787 52.9365 69.787 55.0682C69.787 56.4047 69.4451 57.5865 68.7503 58.6247C68.0555 59.663 67.0849 60.4582 65.8387 60.9994C67.4268 61.64 68.64 62.5678 69.4671 63.7938C70.2943 65.0198 70.7134 66.4336 70.7134 68.0462C70.7134 70.697 69.8642 72.8508 68.1768 74.5076C66.4894 76.1644 64.2396 76.9928 61.4383 76.9928C58.8355 76.9928 56.6629 76.308 54.9314 74.9384C52.88 73.3147 51.8654 71.1057 51.8654 68.2782C51.8654 66.7208 52.2514 65.296 53.0234 64.0037C53.7844 62.7004 54.9975 61.7063 56.6518 60.9994ZM57.2805 67.748C57.2805 69.2612 57.6665 70.443 58.4385 71.2935C59.2105 72.1439 60.181 72.5637 61.339 72.5637C62.475 72.5637 63.4124 72.155 64.1513 71.3377C64.8902 70.5203 65.2652 69.3495 65.2652 67.8032C65.2652 66.4557 64.8902 65.3733 64.1293 64.567C63.3683 63.7497 62.4198 63.341 61.2508 63.341C59.9053 63.341 58.9127 63.8049 58.251 64.7327C57.6113 65.6605 57.2805 66.6656 57.2805 67.748ZM57.7878 55.4548C57.7878 56.5482 58.0966 57.4098 58.7142 58.0172C59.3318 58.6358 60.1589 58.934 61.1846 58.934C62.2323 58.934 63.0595 58.6247 63.6881 58.0062C64.3168 57.3877 64.6256 56.5262 64.6256 55.4327C64.6256 54.4055 64.3167 53.5771 63.6991 52.9586C63.0815 52.3401 62.2654 52.0308 61.2508 52.0308C60.192 52.0308 59.3539 52.3401 58.7252 52.9696C58.0966 53.5992 57.7878 54.4276 57.7878 55.4548Z" fill="#F75367" />
                <defs>
                    <linearGradient id="paint0_linear_18440_79257" x1="50.9314" y1="22.2266" x2="50.9314" y2="40.2454" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F37F78" />
                        <stop offset="1" stopColor="#F75367" />
                    </linearGradient>
                </defs>
            </svg>
        )
    }
    return (
        <StyledNoDataComponent id="no-data-component" className="Custom_StyledNoDataComponent">
            <div className="img-div">
                {useCommonEmptyImg ? EmptyImgSvg2() : EmptyImgSVG1()}
            </div>
            <h1>{titleMsg}</h1>
            <p>{subTitleMsg}</p>
        </StyledNoDataComponent>
    )
}

export default CustomNoDataComponent;

const StyledNoDataComponent = styled("div")({
    "&.Custom_StyledNoDataComponent": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "1rem",
        width: "100%",
        "& .img-div": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        "& h1": {
            color: "#ffffff",
            fontSize: "1.5rem",
            fontWeight: 500,
            margin: "1rem 0rem 0.3rem",
            textAlign: "center"
        },
        "& p": {
            color: "#FFFFFFCC",
            fontSize: "1rem",
            fontWeight: 500,
            margin: 0,
            textAlign: "center"
        }
    }
})