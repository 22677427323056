import React, { CSSProperties } from "react";

// Customizable Area Start
import {
  styled,
  Modal,
  Menu
} from "@material-ui/core";

import "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { crossSvg, closeIcon, searchIcon, emptyCheckBoxIcon, checkedBoxIcon, radioCheckedIcon, radioEmptyIcon } from "./assets";
import "./AddAppointment.css";
import { MeetingType } from "./AddAppointmentController";
import LocationMenu from "./components/LocationMenu.web";
// Customizable Area End

import AddAppointmentController, {
  Props,
  configJSON,
} from "./AddAppointmentController";

export default class AddAppointment extends AddAppointmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {
      currentFocusedInputField,
      title,
      selectedEmails,
      email,
      suggestedEmails,
      description,
      meetingType,
      onlineLink,
      reminderFrequency,
      currentSelectedContacts,
      selectedContacts,
      removeEmailList,
      searchEmail,
      removeContactList,
      searchContact,
      editMode
    } = this.state;
    const SuggestedEmailMenu = () => {
      return (
        <SuggestedEmailMenuContainer data-test-id="email-list-menu">
          {
            suggestedEmails && 
            suggestedEmails.length > 0 ? 
            suggestedEmails.map(email => {
              return (
                <div 
                  key={email.id} 
                  className={`email-container ${this.findItemPresentInEmailAndContactList(email.id) ? "selected" : ""}`} 
                  onClick={() => {
                    if(!this.findItemPresentInEmailAndContactList(email.id)){
                      this.onSelectEmail(email)
                    }
                  }}
                >
                  <div className="avatar">{email.full_name[0]}</div>
                  <div className="email">
                    <p>{email.full_name}</p>
                    <p>{email.email}</p>
                  </div>
                </div>
              )
            }) : <p>No data found</p>
          }
        </SuggestedEmailMenuContainer>
      )
    }
    const Chip = (data: string, removeFunction: (id: string | number) => void, keyChip: string | number, dataTestId: string, gap:string = "0.2rem") => {
      return (
        <StyledChip data-test-id="chip" key={keyChip} style={{gap:gap}} onClick={(event)=>{
            event.stopPropagation()
        }}>
          <p>{data}</p>
          <img data-test-id={dataTestId} src={crossSvg} alt="cross.svg" onClick={(event) => {
            event.stopPropagation();
            removeFunction(keyChip)
          }} />
        </StyledChip>
      )
    }
    const SelectedEmailList = () => {
      const visibleEmails = selectedEmails.slice(0, 3);
      const hiddenEmailsCount = selectedEmails.length - visibleEmails.length;
      return (
        <>
          {
            visibleEmails && visibleEmails.length > 0 && visibleEmails.map((email) => {
              return (
                Chip(email.full_name ? email.full_name : email.email, this.onRemoveEmail, email.email, "remove-email")
              )
            })
          }
          {
            hiddenEmailsCount > 0 && (
              <span data-test-id="more-email" onClick={this.openSelectedMenuList} style={{ color: "#F37976", fontSize: "0.875rem", fontWeight: 500, marginRight: "0.1rem", cursor: "pointer", whiteSpace: "nowrap" }}>+{hiddenEmailsCount} more</span>
            )
          }
          {SelectEmailMenu()}
        </>
      )
    }

    const SelectEmailMenu = () => {
      return (
        <Modal data-test-id="email-list-modal" open={this.state.selectedEmailMenuOpen} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <StyledContactList>
            <div className="header">
              <p>Selected Email</p>
              <img src={closeIcon} alt="close.svg" onClick={this.closeSelectedMenuList} />
            </div>
            <div className="body">
              <div className="search-container">
                <img src={searchIcon} alt="search.svg" />
                <input placeholder="Search email" onChange={(event) => this.onSearchEmail(event.target.value)} />
              </div>
              {
                removeEmailList.length > 0 && (
                  <div className="contact-list-container">
                    <div className="contacts">
                      {
                        removeEmailList.filter(item => item.email.email.includes(searchEmail.toLowerCase())).map((item, index: number) => {
                          return (
                            <div className="contact" key={item.email.email} data-test-id="selected-email">
                              <div className="name">
                                <div className="avatar">{item.email.full_name ? item.email.full_name[0] : item.email.email[0]}</div>
                                {item.email.full_name ? item.email.full_name : item.email.email}
                              </div>
                              {
                                item.flag ? <img src={checkedBoxIcon} onClick={() => this.onToggleSelectedEmail(index)} /> : <img src={emptyCheckBoxIcon} onClick={() => this.onToggleSelectedEmail(index)} />
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              }
            </div>
            <div className="footer">
              <button onClick={this.onDoneWithSelectEmails}>
                Done
              </button>
            </div>
          </StyledContactList>
        </Modal>
      )
    }
    const SelectContactMenu = () => {
      const { isAllContactSelected } = this.state;
      return (
        <Modal data-test-id="contact-list-modal" open={this.state.isContactMenuOpen} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <StyledContactList>
            <div className="header">
              <p>Select Contact</p>
              <img src={closeIcon} alt="close.svg" onClick={this.closeContactListMenu} />
            </div>
            <div className="body">
              <div className="search-container">
                <img src={searchIcon} alt="search.svg" />
                <input placeholder="Search name or number" data-test-id="add-contact-modal" onChange={(event) => this.onSearchContact(event.target.value)} />
              </div>
              {
                currentSelectedContacts.length > 0 && (
                  <div className="contact-list-container">
                    <div className="contact-header">
                      <p>Invited</p>
                    </div>
                    <div className="contacts" data-test-id="selected-contact-list">
                      {
                        currentSelectedContacts.map((contact: { name: string, id: number, full_phone_number: string, email: string }) => {
                          return (
                            <div className="contact" key={`${contact.id}-${contact.full_phone_number}`}>
                              <div className="name">
                                <div className="avatar">{contact.name[0]}</div>
                                <div className="contact-full-name">{contact.name}</div>
                              </div>
                              <img src={checkedBoxIcon} onClick={() => this.onRemoveContact(contact.full_phone_number)} />
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              }
              <div className="contact-list-container">
                <div className="contact-header">
                  <p>Contacts</p>
                  <p onClick={this.selectAllContacts} data-test-id="select-all-contact">{isAllContactSelected ? "Unselect All" : "Select All"}</p>
                </div>
                <div className="contacts" data-test-id="contact-list">
                  {
                    this.state.contacts.map((contact: { name: string, id: number, full_phone_number: string, email: string }) => {
                      return (
                        this.isContactSelected(contact.id) ? <React.Fragment key={`${contact.id}-${contact.full_phone_number}`}></React.Fragment> : (
                          <div className={`contact ${this.findItemPresentInEmailAndContactList(contact.id) || this.findItemPresentInEmailAndContactList(contact.full_phone_number) ? "selected" : ""}`} key={`${contact.id}-${contact.full_phone_number}`}>
                            <div className="name">
                              <div className="avatar">{contact.name[0]}</div>
                              <div className="contact-full-name">{contact.name}</div>
                            </div>
                            <img 
                              src={emptyCheckBoxIcon} 
                              onClick={() => {
                                if(!this.findItemPresentInEmailAndContactList(contact.id) && !this.findItemPresentInEmailAndContactList(contact.full_phone_number)){
                                  this.onSelectContact(contact)
                                }
                              }} 
                            />
                          </div>
                        )
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <div className="footer">
              <button onClick={this.onAddContacts} disabled={currentSelectedContacts.length === 0}>
                Add
              </button>
            </div>
          </StyledContactList>
        </Modal>
      )
    }

    const AppointmentTime = () => {
      const {
        description,
        currentFocusedInputField
      } =  this.state;
      return (
        <div>
          <StyledAppointmentTimeContainer style={{ marginTop: currentFocusedInputField === 4 || description ? "1rem" : "" }}>
            <div className="connector-container">
              <div className="dot"></div>
              <div className="dashed"></div>
              <div className="dot"></div>
            </div>
            <div className="time-container">
              <div className="time" id="start-time-field" style={{ cursor: "pointer" }} onClick={() => this.openTimePicker(0)}>
                <p>Start Appointment Time*</p>
                {
                  this.state.appointmentStartTime && <p>{this.state.appointmentStartTime}</p>
                }
              </div>
              <div className="divider"></div>
              <div className="time" id="end-time-field" style={{ cursor: "pointer" }} onClick={() => this.openTimePicker(1)}>
                <p>End Appointment Time*</p>
                {
                  this.state.appointmentEndTime && <p>{this.state.appointmentEndTime}</p>
                }
              </div>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardTimePicker
                  variant="dialog"
                  format="HH:mm"
                  margin="normal"
                  id="time-picker-inline"
                  value={this.state.appointmentStartTime}
                  onChange={(event) => this.onStartTimeSelect(event)}
                  KeyboardButtonProps={{
                    id: "appointment-time"
                  }}
                  style={{ display: "none" }}
                  onAccept={(event) => this.onStartTimeSelect(event)}
                />
              </MuiPickersUtilsProvider>
            </div>
          </StyledAppointmentTimeContainer>
          {ErrorMessage(this.validateFormData().appointmentTime)}
        </div>
      )
    }

    const ErrorMessage = (error: { required?: boolean, message?: string }) => {
      return (
        <div data-test-id="error-message" style={{ color: '#ff3333', fontSize: '0.75rem' }}>{error.message}</div>
      )
    }

    const TitleField = () => {
      return (
        <div>
          <InputField>
            <label data-test-id="titleLabel" className={currentFocusedInputField === 1 || title ? "focused" : ""}>Title*</label>
            <input
              data-test-id="title"
              name="title"
              onFocus={() => this.onFocusChange(1)}
              onBlur={() => this.onFocusChange(undefined)}
              onChange={(event) => {
                const sanitizedValue = event.target.value.replace(/[^a-zA-Z0-9 ]/g, '').replace(/^\s+/, '');
                this.onChangeHandler("title", sanitizedValue)
              }}
              value={title}
              maxLength={42}
            />
          </InputField>
        </div>
      )
    }
    const EmailField = () => {
      return (
        <div>
          <InputField className={this.state.email || currentFocusedInputField === 2 || selectedContacts.length > 0 ? "focused" : ""} ref={this.state.suggestedEmailsMenuAnchorElement}>
            <label className={currentFocusedInputField === 2 || email ? "focused" : ""}>Select Email Id</label>
            <input
              placeholder={currentFocusedInputField === 2 ? "Type email" : ""}
              name="email"
              onFocus={() => this.onFocusChange(2)}
              onChange={this.onChangeEmailHandler}
              value={email}
              style={{ flex: 1 }}
              onKeyDown={this.onEnterEmail}
              maxLength={42}
            />
            {currentFocusedInputField === 2 && SuggestedEmailMenu()}
          </InputField>
          {
              this.state.emailError && (
                ErrorMessage({message: this.state.emailError})
              )
          }
          {
            selectedEmails && selectedEmails.length > 0 && (
              <div style={{ display: "flex", gap: "0.3rem", flexWrap:"wrap", alignItems: "center", marginTop:"0.5rem" }}>
                {
                  SelectedEmailList()
                }
              </div>
            )
          }
        </div>
      )
    }
    const AppointmentDate = () => {
      return (
        <div>
          <InputField style={{ cursor: "pointer" }}>
            <label className={this.state.appointmentDate ? "focused" : ""}>Select Appointment Date*</label>
            <input
              data-test-id="appointment-date"
              value={this.state.formattedDate}
              onClick={() => this.openDatePicker()}
              readOnly
            />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <CustomDatePicker
                variant="inline"
                format="DD MMMM YYYY"
                margin="normal"
                id="appointment-date-picker-inline"
                value={this.state.appointmentDate}
                onChange={this.handleSelectAppointmentDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                  id: "appointment-date-picker",
                }}
                className="customDatePicker"
                style={{ display: "none" }}

                DialogProps={{
                  className: "datePickerDialog",
                }}
                PopoverProps={{
                  className: "add-appointment-date-picker"
                }}
                autoOk={true}

                disablePast
              />
            </MuiPickersUtilsProvider>
          </InputField>
        </div>
      )
    }
    const DescriptionField = () => {
      return (
        <div>
          <TextField className={currentFocusedInputField === 4 || description ? "focused" : ""}>
            <label className={currentFocusedInputField === 4 || description ? "focused" : ""}>Description</label>
            <textarea
              name="description"
              value={description}
              onFocus={() => this.onFocusChange(4)}
              onBlur={() => this.onFocusChange(undefined)}
              maxLength={1024}
              onChange={(event) => {
                const sanitizedValue = event.target.value.replace(/^\s+/, ''); 
                this.onChangeHandler("description", sanitizedValue)
              }}
            />
          </TextField>
        </div>
      )
    }

    const ContactChipList = () => {
      const visibleContacts = selectedContacts.slice(0, 3);
      const hiddenContactsCount = selectedContacts.length - visibleContacts.length;
      return (
        <>
          {
            visibleContacts && visibleContacts.length > 0 && visibleContacts.map((contact) => {
              return (
                Chip(contact.name[0].toUpperCase(), this.onRemoveContactFromSelectedContact, contact.full_phone_number, "remove-contact","0.7rem")
              )
            })
          }
          {
            hiddenContactsCount > 0 && (
              <span data-test-id="more-contacts" onClick={(event)=> {
                event.stopPropagation();
                this.openSelectedContactMenuList()
              }} style={{ color: "#F37976", fontSize: "0.875rem", fontWeight: 500, marginRight: "0.1rem", cursor: "pointer", whiteSpace: "nowrap" }}>+{hiddenContactsCount} more</span>
            )
          }
        </>
      )
    }

    const SelectedContactMenu = () => {
      return (
        <Modal data-test-id="selected-contact-list-modal" open={this.state.selectedContactMenuOpen} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <StyledContactList>
            <div className="header">
              <p>Selected Contacts</p>
              <img src={closeIcon} alt="close.svg" onClick={this.closeSelectedContactMenuList} />
            </div>
            <div className="body">
              <div className="search-container">
                <img src={searchIcon} alt="search.svg" />
                <input placeholder="Search name or number" onChange={(event) => this.onSearchSelectedContact(event.target.value)} />
              </div>
              {
                removeContactList.length > 0 && (
                  <div className="contact-list-container">
                    <div className="contacts" data-test-id="selected-contacts">
                      {
                        removeContactList.filter(contact => contact.contact.name.toLowerCase().includes(searchContact.toLowerCase()) || contact.contact.full_phone_number.includes(searchContact)).map((contact, contactIndex: number) => {
                          return (
                            <div className="contact" key={`${contact.contact.id}-${contact.contact.full_phone_number}`}>
                              <div className="name">
                                <div className="avatar">{contact.contact.name[0]}</div>
                                <div className="contact-full-name">{contact.contact.name}</div>
                              </div>
                              {
                                contact.flag ? <img src={checkedBoxIcon} onClick={() => this.onToggleSelectedContact(contactIndex)} /> : <img src={emptyCheckBoxIcon} onClick={() => this.onToggleSelectedContact(contactIndex)} />
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              }
            </div>
            <div className="footer">
              <button onClick={this.onDoneWithSelectedContacts}>
                Done
              </button>
            </div>
          </StyledContactList>
        </Modal>
      )
    }

    const MapMenuComponent = () => {
      const { mapMenuAnchorElement, searchLocation, predictions, mapMenuParentDivAnchorElement, recentLocations } = this.state;
      return (
        <CustomMenu
          open={Boolean(mapMenuAnchorElement)}
          anchorEl={mapMenuAnchorElement}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          getContentAnchorEl={null}
        >
          <StyledMapMenu className="show-scrollbar" id="map-menu" style={{ width: mapMenuParentDivAnchorElement ? mapMenuParentDivAnchorElement.offsetWidth : "100%" }}>
            <div className="search-container">
              <input placeholder="Search location" value={searchLocation} onKeyDown={(event)=> event.stopPropagation()} onChange={(event) => this.onSearchLocation(event.target.value)} />
            </div>
            {searchLocation && (
              <p>Your Search Location</p>
            )}
            {!searchLocation && predictions.length === 0 && (
              <p>Recent Location</p>
            )}
            {
              !searchLocation && predictions.length === 0 && recentLocations && (
                <LocationMenu locations={recentLocations} onSelect={this.onSelectLocation} />
              )
            }
            {
              predictions && predictions.length > 0 && (
                <LocationMenu locations={predictions} onSelect={this.onSelectLocation} />
              )
            }
          </StyledMapMenu>
        </CustomMenu>
      )
    }

    const ContactField = () => {
      return (
        <div>
          <InputField
            className={selectedContacts.length > 0 ? "focused" : ""}
            data-test-id="contact-field"
            onClick={() => this.openContactListMenu()}
            style={{ cursor: "pointer" }}
          >
            {
              selectedContacts.length > 0 && (
                <label
                  className={selectedContacts.length > 0 ? "focused" : ""}
                >Select Contact*</label>
              )
            }
            {
              selectedContacts.length === 0 && (
                <label
                  data-test-id="contact-list-menu-field"
                  className={selectedContacts.length > 0 ? "focused" : ""}
                  style={{ cursor: "pointer" }}
                >Select Contact*</label>
              )
            }

            {
              selectedContacts && selectedContacts.length > 0 && (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flex: 1, overflowX: "auto", gap: "0.5rem" }}>
                  <div style={{ display: 'flex', gap: '0.3rem', overflowX: 'auto', alignItems: "center" }}>
                    {ContactChipList()}
                  </div>
                  <span data-test-id="add-contacts" onClick={() => this.openContactListMenu()} style={{ color: "#F37976", fontSize: "0.875rem", fontWeight: 500, cursor: "pointer" }}>Add</span>
                </div>
              )
            }
          </InputField>
          {SelectedContactMenu()}
        </div>
      )
    }

    const BackButton = () => {
      return (
        <div className="back-btn-container" onClick={this.goBack}>
          <ArrowBackIosIcon fontSize="small" />
          {editMode ? "Edit Appointment" : "Add Appointment"}
        </div>
      )
    }

    const AddAppointment = () => {
      const { editMode } = this.state;
      return (
        <AddAppointmentContainer>
          <Container>
            {BackButton()}
          </Container>
          <Container className="form-container">
            <form autoComplete="off">
              <div className="row">
                <div className="column">
                  {TitleField()}
                  {ContactField()}
                  {SelectContactMenu()}
                  
                </div>
                <div className="column">
                  {AppointmentDate()}
                  {EmailField()}
                  
                </div>
              </div>
              <div className="row">
                <div className="column">
                {AppointmentTime()}
                </div>
                <div className="column">
                {DescriptionField()}
                </div>
              </div>
              <div className="row">
                <div className="column">
                  <StyledMeetingType>
                    Meeting Type
                    <div className="radio-container">
                      <div className="radio" onClick={() => this.onChangeMeetingType(MeetingType.Offline)}>
                        {
                          meetingType === MeetingType.Offline ? <img src={radioCheckedIcon} /> : <img src={radioEmptyIcon} />
                        }
                        Physical
                      </div>
                      <div className="radio" onClick={() => this.onChangeMeetingType(MeetingType.Online)}>
                        {
                          meetingType === MeetingType.Online ? <img src={radioCheckedIcon} /> : <img src={radioEmptyIcon} />
                        }
                        Online
                      </div>
                    </div>
                  </StyledMeetingType>
                </div>
                <div className="column">
                  {
                    meetingType === MeetingType.Offline && (
                      <div>
                        <InputField className={this.state.selectedLocation ? "focused" : ""} onClick={this.openMapMenu} data-test-id="locationField" id="map-input-field" style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} >
                          {
                            this.state.selectedLocation && (
                              <label style={{ cursor: "pointer" }} className="focused">
                                Choose Location
                              </label>
                            )
                          }
                          <label data-test-id="locationLabel" style={{ cursor: "pointer", position: "relative" }}>
                            {
                              this.state.selectedLocation ? this.state.selectedLocation.structured_formatting.main_text : "Choose Location"
                            }
                          </label>
                          {
                            this.state.selectedLocation && (
                              <img data-test-id="clearLocation" src={closeIcon} style={{ cursor: "pointer" }} onClick={(event)=> {
                                event.stopPropagation()
                                this.clearLocation()
                              }} />
                            )
                          }
                        </InputField>
                        {MapMenuComponent()}
                      </div>
                    )
                  }
                  {
                    meetingType === MeetingType.Online && (
                      <div>
                        <InputField data-test-id="onlineField" className={currentFocusedInputField === 5 || onlineLink ? "focused" : ""}>
                          <label className={currentFocusedInputField === 5 || onlineLink ? "focused" : ""}>Online Link</label>
                          <input
                            name="onlineLink"
                            onFocus={() => this.onFocusChange(5)}
                            onBlur={() => this.onFocusChange(undefined)}
                            onChange={(event) => this.onChangeHandler("onlineLink", event.target.value)}
                            value={onlineLink}
                          />
                        </InputField>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className="row">
                <div className="column">
                  <StyledReminderContainer>
                    Select Reminder Frequency*
                    <div className="reminders-container">
                      {
                        this.reminderArray.map((reminder: number) => {
                          return (
                            <div key={reminder} className={`reminder ${reminderFrequency === reminder ? "active" : ""}`} onClick={() => this.onChangeReminderFrequency(reminder)}>
                              <p>{this.convertSecIntoTimeString(reminder)[0]}</p>
                              <p>{this.convertSecIntoTimeString(reminder)[1]}</p>
                            </div>
                          )
                        })
                      }
                    </div>
                  </StyledReminderContainer>
                </div>
              </div>
              <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <button disabled={!!this.isSubmitButtonDisable()} type="button" className="submit-btn" onClick={this.onScheduleAppointment}>
                  {
                    editMode ? "Update" : "Schedule"
                  }
                </button>
              </div>
            </form>
          </Container>

        </AddAppointmentContainer>
      )
    }
    return (
      <>
        <NavigationMenu navigation={this.props.navigation} id={this.props.id} children={AddAppointment()} />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const AddAppointmentContainer = styled("div")({
  padding: "1rem",
  overflowX: "auto",
  "& .back-btn-container": {
    width: 'fit-content',
    display: "flex",
    alignItems: "center",
    gap: "0.2rem",
    color: "white",
    cursor: "pointer",
    fontWeight: 500
  },
  "& .form-container": {
    display: "flex",
    padding: "2rem 3rem",
    [`@media screen and (max-width: 918px)`]: {
      padding: "1rem 0rem"
    },
  },
  "& form": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "& .row": {
      // width: "100%",
      display: "flex",
      gap: "2%",
      flexWrap: "wrap",
      "& .column": {
        width: "49%",
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
        "& > *": {
          marginTop: "1rem"
        }
      },
      [`@media screen and (max-width: 900px)`]: {
        flexDirection: "column",
        flexWrap: "nowrap",
        "& .column": {
          width: "100%"
        }
      },
    }
  },
})

const Container = styled("div")({
  // width: "100%"
})

const InputField = styled("div")({
  // width: "100%",
  display: "flex",
  maxHeight: "100%",
  alignItems: "center",
  background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
  border: "1px solid rgba(28, 150, 154,0.3)",
  position: "relative",
  padding: "0rem 0.9rem",
  height: "3rem",
  borderRadius: "100px",
  // transition: "all 0.3s ease-in-out",
  "&.focused": {
    marginTop: '1rem !important'
  },
  "& label": {
    position: "absolute",
    transition: "transform 0.2s ease-in-out",
    color: "white",
    fontSize: "1rem",
    "&.focused": {
      // top: "-25px",
      // left:'0'
      transform: 'translate(-14px,-38px)'
    }
  },
  "& input": {
    width: "100%",
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    color: "white",
    fontSize: "1rem",
    zIndex: 1
  },
})

const TextField = styled("div")({
  // width: "100%",
  display: "flex",
  height: "7.25rem",
  alignItems: "center",
  background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
  border: "1px solid rgba(28, 150, 154,0.11)",
  position: "relative",
  padding: "0.8rem 0.9rem",
  borderRadius: "1rem",
  "&.focused": {
    marginTop: '1rem !important'
  },
  "& label": {
    position: "absolute",
    color: "white",
    fontSize: "1rem",
    top: "10%",
    transition: "transform 0.2s ease-in-out",
    "&.focused": {
      transform: 'translate(-14px,-40px)'
    }
  },
  "& textarea": {
    width: "100%",
    height: "100% !important",
    // minWidth: "5rem",
    resize: "none",
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    color: "white",
    fontSize: "1rem",
    zIndex: 1,
    fontFamily:"poppins,san-serif",
    fontWeight:400
  },
})

const SuggestedEmailMenuContainer = styled("div")({
  position: "absolute",
  left: 0,
  top: "100%",
  right: 0,
  background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
  border: "1px solid",
  borderImageSource: "linear-gradient(0deg, rgba(28, 150, 154, 0.11) 0%, rgba(25, 181, 185, 0.27) 100%)",
  boxShadow: "0px 0px 30px 0px #00000080",
  width: "95%",
  borderRadius: "0.5rem",
  padding: "1rem 0.5rem",
  zIndex: 2,
  maxHeight: "10rem",
  overflowY: "auto",
  "& .email-container": {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    color: "#fff",
    fontSize: "0.75rem",
    marginBottom: "1rem",
    cursor: "pointer",
    "& .avatar": {
      width: "2rem",
      height: "2rem",
      borderRadius: "100%",
      border: "1px solid #26EFF5",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#26EFF5",
      fontSize: "0.875rem",
      textTransform: "capitalize"
    },
    "&:last-child": {
      marginBottom: 0
    },
    "& .email":{
      display:"flex",
      flexDirection:"column",
      "& p":{
        margin:0,
        color:"white",
        fontSize:"0.875rem",
        fontWeight:"400",
        textAlign:"left",
        "&:last-child":{
          fontSize:"0.75rem",
          color:"#FFFFFFCC"
        }
      }
    },
    "&.selected":{
      opacity:0.5,
      cursor:"not-allowed"
    }
  },
  "& p": {
    margin: 0,
    fontSize: "0.875rem",
    textAlign: "center",
    color: "#fff"
  }
})

const StyledChip = styled("div")({
  display: "flex",
  alignItems: "center",
  color: "#F37976",
  border: "1px solid #F37976",
  borderRadius: "1rem",
  padding:"0rem 0.5rem",
  height:"1.8125rem",
  justifyContent:"center",
  "& p": {
    margin: 0,
    fontSize: "0.875rem",
    whiteSpace: "nowrap"
  },
  "& img": {
    cursor: "pointer"
  }
})

const StyledContactList = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "26.25rem",
  height: "34.3125rem",
  backgroundColor: "#23404B",
  boxShadow: "0px 0px 20px 0px #00000040",
  borderRadius: "1rem",
  "& .header": {
    position: "relative",
    padding: "1rem",
    borderBottom: "1px solid #2d4953",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      margin: 0,
      fontSize: "1.125rem",
      fontWeight: 500,
      color: "white"
    },
    "& img": {
      position: "absolute",
      right: "1rem",
      top: "50%",
      transform: "translate(-50%,-50%)",
      cursor: "pointer"
    }
  },
  "& .body": {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflowX: "auto",
    gap: "1rem",
    padding: "1rem",
    "& .tab-container": {
      display: "flex",
      justifyContent: "center",
      "& .tab": {
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        width: "13.5rem",
        height: "2.25rem",
        borderRadius: "3.125rem",
        display: "flex",
        padding: "0rem 0.3rem",
        justifyContent: "space-between",
        alignItems: "center",
        "& > div": {
          width: "7rem",
          height: "1.75rem",
          borderRadius: "3.125rem",
          fontSize: "0.875rem",
          fontWeight: 400,
          color: "white",
          opacity: 0.5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          transition: "all 0.3s ease-in-out",
          "&.active": {
            background: "linear-gradient(90deg, #FF7F77 0%, #FF6376 100%)",
            opacity: 1,
            fontWeight: 500
          }
        }
      }
    },
    "& .search-container": {
      display: "flex",
      backgroundColor: "#00000026",
      gap: "0.5rem",
      borderRadius: "6.25rem",
      padding: "0.75rem 1rem",
      "& input": {
        outline: "none",
        border: "none",
        background: "transparent",
        width: "100%",
        color: "white"
      }
    },
    "& .contact-list-container": {
      display: "flex",
      flexDirection: "column",
      "& .contact-header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& > p": {
          margin: 0,
          "&:nth-child(1)": {
            fontSize: "0.75rem",
            fontWeight: 500,
            color: "white"
          },
          "&:nth-child(2)": {
            fontSize: "0.875rem",
            fontWeight: 400,
            color: "#F37F78",
            cursor: "pointer"
          }
        }
      },
      "& .contacts": {
        display: "flex",
        flexDirection: "column",
        marginTop: "1rem",
        gap: "0.5rem",
        "& .contact": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& .name": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "0.875rem",
            color: "white",
            gap: "0.5rem",
            "& .avatar": {
              display: "flex",
              width: "2rem",
              height: "2rem",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #26EFF5",
              color: "#26EFF5",
              borderRadius: "100%",
              textTransform:"capitalize"
            }
          },
          "&.selected":{
            opacity:0.5,
            cursor:"not-allowed"
          },
          "& .contact-full-name": {
            display: "block",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: "258px",
            textTransform: "capitalize"
          },
        }
      }
    }
  },
  "& .footer": {
    padding: "1rem",
    "& button": {
      outline: "none",
      border: "none",
      background: "linear-gradient(180deg, rgba(243, 127, 120, 0.75) 0%, rgba(247, 83, 103, 0.75) 100%)",
      // boxShadow: "0px 0px 20px 0px #0000004D",
      width: "100%",
      borderRadius: "62.5rem",
      color: "white",
      height: "3rem",
      cursor: "pointer",
      fontSize: "1rem",
      fontWeight: 500,
      letterSpacing: "1px",
      "&:disabled": {
        opacity: '0.5',
        cursor: "inherit"
      }
    }
  },
  [`@media screen and (max-width: 600px)`]: {
    width: "80%",
    height: "70%"
  },
})

const CustomDatePicker = styled(KeyboardDatePicker)({
  "&.customDatePicker": {
    "& .MuiInput-underline::before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "none",
    },
  },
});

const StyledAppointmentTimeContainer = styled("div")({
  display: "flex",
  background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
  borderRadius: "1.5rem",
  height: "7rem",
  padding: "1rem 0rem 1rem 1rem",
  gap: "0.5rem",
  "& .connector-container": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0.5rem 0rem",
    "& .dot": {
      width: "0.5rem",
      height: "0.5rem",
      backgroundColor: "#FFFFFF26",
      borderRadius: "100%"
    },
    "& .dashed": {
      // width:"1px",
      display: "flex",
      flex: 1,
      // backgroundColor: "#FFFFFF26",
      border: "1px dashed #FFFFFF26"
    }
  },
  "& .time-container": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    "& .time": {
      fontSize: "1rem",
      color: "#ffffff",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingRight: "1rem",
      "& p": {
        margin: 0
      }
    },
    "& .divider": {
      height: "1px",
      backgroundColor: "#FFFFFF33"
    }
  }
})

const StyledMeetingType = styled("div")({
  display: "flex",
  flexDirection: "column",
  fontSize: "0.875rem",
  color: "#ffffff",
  gap: "0.5rem",
  "& .radio-container": {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    "& .radio": {
      display: "flex",
      gap: "0.5rem",
      fontSize: "1rem"
    }
  }
})

const StyledReminderContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  fontSize: "0.875rem",
  color: "#ffffff",
  "& .reminders-container": {
    display: "flex",
    gap: "1rem",
    flexWrap: 'wrap',
    "& .reminder": {
      width: "3.3125rem",
      height: "3.5rem",
      border: "1px solid rgba(28, 150, 154,0.5)",
      borderRadius: "0.25rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      transition: "all 0.3s ease-in-out",
      "& p": {
        margin: 0,
        "&:nth-child(1)": {
          fontSize: "1rem"
        },
        "&:nth-child(2)": {
          fontSize: "0.75rem"
        }
      },
      "&.active": {
        border: "1px solid rgba(247, 83, 103, 1)",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        color: "rgba(247, 83, 103, 1)"
      }
    }
  }
})

const CustomMenu = styled(Menu)({
  "& .MuiList-padding": {
    padding: 0, // Set padding to 0
  },
  "& .MuiPaper-root": {
    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    borderRadius: "0.5rem",
    border: "1px solid rgba(28, 150, 154,0.11)",
  }
})

const StyledMapMenu = styled("div")({
  padding: "0.5rem 0rem",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  maxHeight:"15rem",
  overflowY:"auto",
  "& .search-container": {
    display: "flex",
    background: "#00000026",
    padding: "0.75rem 1rem",
    "& input": {
      width: "100%",
      border: "none",
      outline: "none",
      fontSize: "0.875rem",
      fontWeight: "400",
      color: "#FFFFFFCC",
      background: "transparent"
    }
  },
  "& > p": {
    margin: 0,
    fontSize: "0.75rem",
    color: "#FFFFFFCC",
    fontWeight: "500",
    paddingLeft: "0.5rem"
  }
})

// Customizable Area End
