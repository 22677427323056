import React from "react";
import { styled } from "@material-ui/core";
interface ChipProps {
    displayText: string;
    id: any;
    onRemove: (id:any) => void;
    gap?: string;
}


const Chip: React.FC<ChipProps> = (props: ChipProps) => {
    const { displayText, id, onRemove, gap="0.7rem" } = props;

    return (
        <StyledChip style={{gap:gap}} className="Common_Chip_StyledChip" onClick={(event)=> event.stopPropagation()}>
            <p className="ellipsis">{displayText}</p>
            <div className="icon" onClick={(event)=> {
                event.stopPropagation();
                onRemove(id)
            }}>
                <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.48434 6.725L0.775391 6.01605L3.29079 3.50065L0.775391 1.00192L1.48434 0.292969L3.99974 2.80837L6.49847 0.292969L7.20742 1.00192L4.69202 3.50065L7.20742 6.01605L6.49847 6.725L3.99974 4.2096L1.48434 6.725Z" fill="#F37976" />
                </svg>
            </div>
        </StyledChip>
    )
}

const StyledChip = styled("div")({
    "&.Common_Chip_StyledChip": {
        display: "flex",
        alignItems: "center",
        border: "1px solid rgba(243, 121, 118, 1)",
        borderRadius: "1rem",
        padding: "0.25rem 0.5rem",
        "& p": {
            fontSize: "0.875rem",
            fontWeight: 400,
            color: "rgba(243, 121, 118, 1)"
        },
        "& .icon": {
            cursor: "pointer"
        }
    }
})

export default Chip;