import React, { useState } from "react";
import { styled, Modal } from "@material-ui/core";
import { ContactList } from "./CommonTypes";
import { closeIcon, searchIcon, checkedBoxIcon, emptyCheckBoxIcon } from "../../blocks/appointmentmanagement/src/assets";
interface SelectContactsProps {
    contacts: ContactList[];
    filterContacts: ContactList[]
    open: boolean;
    onClose: () => void;
    onSearch: (event: string) => void;
    onAdd: (contacts: ContactList[]) => void;
    alreadySelected?: (id: number | string) => void;
}


const SelectContacts: React.FC<SelectContactsProps> = (props: SelectContactsProps) => {
    const { contacts, open, onClose, onSearch, onAdd, alreadySelected } = props;
    const [selected, setSelected] = useState<ContactList[]>([])
    const [allSelected, setAllSelected] = useState<boolean>(false)

    const onSelectContact = (contact: ContactList) => {
        setSelected(prevState => ([...prevState, contact]));
    }

    const onRemoveContact = (contactId: number) => {
        const newContacts: ContactList[] = selected.filter(contact => contact.id !== contactId);
        setSelected(newContacts);
    }

    const selectAllContacts = () => {
        if (allSelected) {
            setSelected([])
        } else {
            setSelected(contacts.filter(contact => !contactSelected(contact.full_phone_number)));
        }
        setAllSelected(prevState => !prevState);
    }

    const isContactSelected = (contactId: number) => {
        if (selected.find(item => item.id === contactId)) {
            return true;
        }
        return false;
    }

    const onAddHandler = () => {
        onAdd(selected)
        setSelected([])
        setAllSelected(false)
    }

    const onCloseHandler = () => {
        onClose();
        setSelected([])
        setAllSelected(false)
    }

    const contactSelected = (id: number | string) => {
        if (alreadySelected) {
            return alreadySelected(id);
        }
        return false;
    }


    return (
        <Modal data-test-id="contact-list-modal" open={open} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <StyledSelectContacts className="SelectContacts_StyledSelectContacts">
                <div className="header">
                    <p>Select Contact</p>
                    <img src={closeIcon} alt="close.svg" onClick={onCloseHandler} />
                </div>
                <div className="body">
                    <div className="search-container">
                        <img src={searchIcon} alt="search.svg" />
                        <input placeholder="Search name or number" data-test-id="add-contact-modal" onChange={(event) => onSearch(event.target.value)} />
                    </div>
                    {
                        selected.length > 0 && (
                            <div className="contact-list-container">
                                <div className="contact-header">
                                    <p>Invited</p>
                                </div>
                                <div className="contacts" data-test-id="selected-contact-list">
                                    {
                                        selected.map((contact: { name: string, id: number, full_phone_number: string, email: string }) => {
                                            return (
                                                <div className={`contact`} key={`${contact.id}-${contact.full_phone_number}`}>
                                                    <div className="name">
                                                        <div className="avatar">{contact.name[0]}</div>
                                                        <div className="user-contact-full-name">{contact.name}</div>
                                                    </div>
                                                    <img src={checkedBoxIcon} onClick={() => onRemoveContact(contact.id)} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                    <div className="contact-list-container">
                        <div className="contact-header">
                            <p>Contacts</p>
                            <p onClick={selectAllContacts} data-test-id="select-all-contact">{allSelected ? "Unselect All" : "Select All"}</p>
                        </div>
                        <div className="contacts" data-test-id="contact-list">
                            {
                                contacts.map((contact) => {
                                    return (
                                        isContactSelected(contact.id) ? <React.Fragment key={`${contact.id}-${contact.full_phone_number}`}></React.Fragment> : (
                                            <div className={`contact ${contactSelected(contact.id) || contactSelected(contact.full_phone_number) ? "selected" : ""}`} key={`${contact.id}-${contact.full_phone_number}`}>
                                                <div className="name">
                                                    <div className="avatar">{contact.name[0]}</div>
                                                    <div className="user-contact-full-name">{contact.name}</div>
                                                </div>
                                                <img
                                                    src={emptyCheckBoxIcon}
                                                    onClick={() => {
                                                        if (!contactSelected(contact.id) && !contactSelected(contact.full_phone_number)) {
                                                            onSelectContact(contact)
                                                        }
                                                    }
                                                    }
                                                />
                                            </div>
                                        )
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <button onClick={onAddHandler} disabled={selected.length === 0}>
                        Add
                    </button>
                </div>
            </StyledSelectContacts>
        </Modal>
    )
}

const StyledSelectContacts = styled("div")({
    "&.SelectContacts_StyledSelectContacts": {
        display: "flex",
        flexDirection: "column",
        width: "26.25rem",
        height: "34.3125rem",
        backgroundColor: "#23404B",
        boxShadow: "0px 0px 20px 0px #00000040",
        borderRadius: "1rem",
        "& .header": {
            position: "relative",
            padding: "1rem",
            borderBottom: "1px solid #2d4953",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& p": {
                margin: 0,
                fontSize: "1.125rem",
                fontWeight: 500,
                color: "white"
            },
            "& img": {
                position: "absolute",
                right: "1rem",
                top: "50%",
                transform: "translate(-50%,-50%)",
                cursor: "pointer"
            }
        },
        "& .body": {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflowX: "auto",
            gap: "1rem",
            padding: "1rem",
            "& .tab-container": {
                display: "flex",
                justifyContent: "center",
                "& .tab": {
                    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
                    width: "13.5rem",
                    height: "2.25rem",
                    borderRadius: "3.125rem",
                    display: "flex",
                    padding: "0rem 0.3rem",
                    justifyContent: "space-between",
                    alignItems: "center",
                    "& > div": {
                        width: "7rem",
                        height: "1.75rem",
                        borderRadius: "3.125rem",
                        fontSize: "0.875rem",
                        fontWeight: 400,
                        color: "white",
                        opacity: 0.5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        transition: "all 0.3s ease-in-out",
                        "&.active": {
                            background: "linear-gradient(90deg, #FF7F77 0%, #FF6376 100%)",
                            opacity: 1,
                            fontWeight: 500
                        }
                    }
                }
            },
            "& .search-container": {
                display: "flex",
                backgroundColor: "#00000026",
                gap: "0.5rem",
                borderRadius: "6.25rem",
                padding: "0.75rem 1rem",
                "& input": {
                    outline: "none",
                    border: "none",
                    background: "transparent",
                    width: "100%",
                    color: "white"
                }
            },
            "& .contact-list-container": {
                display: "flex",
                flexDirection: "column",
                "& .contact-header": {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    "& > p": {
                        margin: 0,
                        "&:nth-child(1)": {
                            fontSize: "0.75rem",
                            fontWeight: 500,
                            color: "white"
                        },
                        "&:nth-child(2)": {
                            fontSize: "0.875rem",
                            fontWeight: 400,
                            color: "#F37F78",
                            cursor: "pointer"
                        }
                    }
                },
                "& .contacts": {
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "1rem",
                    gap: "0.5rem",
                    "& .contact": {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        "& .name": {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "0.875rem",
                            color: "white",
                            gap: "0.5rem",
                            "& .avatar": {
                                display: "flex",
                                width: "2rem",
                                height: "2rem",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px solid #26EFF5",
                                color: "#26EFF5",
                                borderRadius: "100%",
                                textTransform: "capitalize"
                            },
                        },
                        "&.selected": {
                            opacity: 0.5,
                            cursor: "not-allowed"
                        },
                        "& .user-contact-full-name": {
                            display: "block",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: "258px"
                        }
                    }
                }
            }
        },
        "& .footer": {
            padding: "1rem",
            "& button": {
                outline: "none",
                border: "none",
                background: "linear-gradient(180deg, rgba(243, 127, 120, 0.75) 0%, rgba(247, 83, 103, 0.75) 100%)",
                width: "100%",
                borderRadius: "62.5rem",
                color: "white",
                height: "3rem",
                cursor: "pointer",
                fontSize: "1rem",
                fontWeight: 500,
                letterSpacing: "1px",
                "&:disabled": {
                    opacity: '0.5',
                    cursor: "inherit"
                }
            }
        },
        [`@media screen and (max-width: 600px)`]: {
            width: "80%",
            height: "70%"
        },
    }
})

export default SelectContacts;