Object.defineProperty(exports, "__esModule", {
  value: true,
});

//APi Methods
exports.apiMethod = "GET";
exports.apiContentType = "application/json";
exports.apiEndPoint = "activity_feed/activity_feeds";
exports.apiContentType = "application/json";

// Customizable Area Start
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.loadLabel = "Load more...";
exports.headerLabel = "Recent Activity";
exports.done = "Done";
exports.postsEndpoint = "bx_block_posts/posts"
exports.likePostEndPoint = "bx_block_upvotedownvote/upvotedownvotes/upvote";
exports.likeType = "BxBlockPosts::Post";
exports.commentsApiEndpoint = "bx_block_comments/comments";
exports.bestEventsAPIEndpoint = "bx_block_events/best_events";
exports.detectCoordinatesApiEndpoint = "bx_block_maps3/locations/detect_coordinates";
exports.interestApiEndPoint = "account_block/accounts/fetch_interests";
exports.addNewInterestApiEndPoint = "account_block/accounts/add_interests";
exports.groupPostsEndpoint = "bx_block_posts/group_posts";
exports.removeAttachmentEndpoint = "bx_block_posts/posts/remove_attachment";
exports.likedUsersListApiEndpoint = "bx_block_upvotedownvote/upvotedownvotes/likes_list";
exports.commentsListApiEndpoint = "bx_block_comments/comments";
// Customizable Area End
