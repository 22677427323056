import React from "react";
// Customizable Area Start
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { styled } from "@material-ui/styles";
import CommonHeader from "./components/CommonHeader.web";
import { AddIcon } from "./assets";
import CustomNoDataComponent from "../../../components/src/CustomNoDataComponent.web";
import Card from "../../events/src/components/Card.web";
// Customizable Area End
import EventsController, {
    Props
} from "./EventsController";

export default class Events extends EventsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    EventsContainer = () => {
        const { events, loading } = this.state;
        return (
            <StyledEventsContainer className="Dashboard_Events_StyledEventsContainer">
                <CommonHeader redirectTo={()=> this.redirectTo("AddEvent")} goBack={this.goBack} goBackText="Your Events" buttonText="Add Event" buttonIcon={AddIcon} />
                {
                    events.length > 0 && (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div className="card-container">
                                {
                                    events.map(event => {
                                        return (
                                            <div className="card" key={event.id} onClick={()=> this.redirectTo("EventDetail", {eventId: event.id})}>
                                                <Card
                                                    id={event.id}
                                                    title={event.title}
                                                    image={event.image}
                                                    time={event.time}
                                                    location={event.location}
                                                    isTrending={event.isTrending}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                }
                {
                    !loading && events.length === 0 && (
                        <CustomNoDataComponent
                            titleMsg={"No Upcoming Events"}
                            subTitleMsg={"No scheduled events. Stay tuned for the updates."}
                            useCommonEmptyImg={true}
                        />
                    )
                }
            </StyledEventsContainer>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <NavigationMenu {...this.props} children={this.EventsContainer()} />
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledEventsContainer = styled("div")({
    "&.Dashboard_Events_StyledEventsContainer": {
        display: "flex",
        flexDirection: "column",
        gap: '1.5rem',
        padding: "1rem",
        boxSizing: "border-box",
        containerType: "inline-size",
        containerName: "cardContainer",
        "& .card-container": {
            display: "grid",
            gridTemplateColumns: "repeat(6, 1fr)",
            rowGap: "1rem",
            columnGap: "1rem",
            maxWidth: "79.125rem",
            "& .card": {
                minWidth: "11.9375rem",
                maxWidth: "100%",
                height: "16.9375rem",
            }
        },
        [`@container cardContainer (max-width: 1250px)`]: {
            "& .card-container": {
                gridTemplateColumns: "repeat(5, 1fr)",
            }
        },
        [`@container cardContainer (max-width: 1050px)`]: {
            "& .card-container": {
                gridTemplateColumns: "repeat(4, 1fr)",
            }
        },
        [`@container cardContainer (max-width: 800px)`]: {
            "& .card-container": {
                gridTemplateColumns: "repeat(3, 1fr)",
            }
        },
        [`@container cardContainer (max-width: 650px)`]: {
            "& .card-container": {
                gridTemplateColumns: "repeat(2, 1fr)",
            }
        },
        [`@container cardContainer (max-width: 460px)`]: {
            "& .card-container": {
                gridTemplateColumns: "repeat(1, 1fr)",
            }
        },
    }
})
// Customizable Area End
