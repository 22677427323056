import React, { useState } from "react";
import { styled, Menu } from "@material-ui/core";
import { SearchLocation } from "./CommonTypes";
import LocationMenu from "../../blocks/appointmentmanagement/src/components/LocationMenu.web";
interface MapMenuProps {
  anchorEle: HTMLDivElement | null;
  recentLocations: SearchLocation[];
  predictions: SearchLocation[];
  onSearch?: (event: string) => void;
  onSelect?: (event: SearchLocation) => void;
  onClose?: ()=> void;
}


const MapMenu: React.FC<MapMenuProps> = (props: MapMenuProps) => {
  const { anchorEle, predictions, onSearch, onSelect, onClose, recentLocations } = props;
  const [searchString, setSearchString] = useState<string>("")

  const onSearchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (onSearch) {
      onSearch(value);
    }
    setSearchString(value)
  }
  const onSelectHandler = (event: SearchLocation) => {
    if (onSelect) {
      onSelect(event);
    }
  }
  const onCloseHandler = ()=>{
    if(onClose){
      onClose()
    }
  }
  return (
    <CustomMenu
      open={Boolean(anchorEle)}
      anchorEl={anchorEle}
      onClose={onCloseHandler}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      getContentAnchorEl={null}
    >
      <StyledMapMenu id="map-menu" style={{ width: anchorEle ? anchorEle.offsetWidth : "100%" }}>
        <div className="search-container">
          <input 
            placeholder="Search location" 
            onChange={onSearchHandler} 
            onKeyDown={(event)=>{
              event.stopPropagation()
            }}
          />
        </div>
        {searchString && (
          <p>Your Search Location</p>
        )}
        {!searchString && predictions.length === 0 && (
          <p>Recent Location</p>
        )}
        {
          !searchString && predictions.length === 0 && recentLocations && (
            <LocationMenu locations={recentLocations} onSelect={onSelectHandler} />
          )
        }
        {
          predictions && predictions.length > 0 && (
            <LocationMenu locations={predictions} onSelect={onSelectHandler} />
          )
        }
      </StyledMapMenu>
    </CustomMenu>
  )
}

const StyledMapMenu = styled("div")({
  padding: "0.5rem 0rem",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  maxHeight: "15rem",
  overflowY: "auto",
  "& .search-container": {
    display: "flex",
    background: "#00000026",
    padding: "0.75rem 1rem",
    "& input": {
      width: "100%",
      border: "none",
      outline: "none",
      fontSize: "0.875rem",
      fontWeight: "400",
      color: "#FFFFFFCC",
      background: "transparent"
    }
  },
  "& > p": {
    margin: 0,
    fontSize: "0.75rem",
    color: "#FFFFFFCC",
    fontWeight: "500",
    paddingLeft: "0.5rem"
  }
})

const CustomMenu = styled(Menu)({
  "& .MuiList-padding": {
    padding: 0, // Set padding to 0
  },
  "& .MuiPaper-root": {
    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    borderRadius: "0.5rem",
    border: "1px solid rgba(28, 150, 154,0.11)",
  }
})

export default MapMenu;




