export const userProfile = require("../assets/user-profile.svg");
export const logo = require("../assets/logo.png");
export const homeIcon = require("../assets/home.svg");
export const appointmentsIcon = require("../assets/appointments.svg");
export const bucketListIcon = require("../assets/bucket-list.svg");
export const chatIcon = require("../assets/chats.svg");
export const contactUsIcon = require("../assets/contact-us.svg");
export const eventsIcon = require("../assets/events.svg");
export const exploreIcon = require("../assets/explore.svg");
export const groupIcon = require("../assets/group.svg");
export const reminderIcon = require("../assets/reminder.svg");
export const searchIcon = require("../assets/search-icon.svg");
export const expandMoreIcon = require("../assets/expand-more.svg");
export const personIcon = require("../assets/person.svg");
export const logoutIcon = require("../assets/logout.svg");
