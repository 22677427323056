Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.profile = "Add Profile";
exports.signUpBtn = "Sign Up";
exports.alreadyAccount = "Already have an account ?";
exports.signInButton = "Sign in";
exports.conditionPrivacy = " Terms of Use & Privacy Policy";
exports.agreeTie = "I agree to TIE";
exports.imgErrorMsg = "PNG or JPG format and does not exceed 10 MB in size.";
exports.countryCode = "+ 91";
exports.fullNameField = "Full Name*";
exports.userNameField = "Username*";
exports.phoneNumberTextField = "Phone Number*";
exports.selectDateField = "Select Date of Birth";
exports.emailField = "Email";
exports.selectInterest = "Select Interest";
exports.phoneNumberApiEndPoint = "account_block/accounts/get_country_codes";
exports.interestApiEndPoint = "account_block/accounts/fetch_interests";
exports.signUpApiEndPoint = "account_block/accounts";
exports.fileType = "file";
exports.imgType = "image/png, image/jpeg";
exports.typeText = "text";
exports.typeDate = "date";
exports.addNewText = "Add New";
exports.interest = "Enter New Interest";
exports.addValue = "add";
exports.NameMsg = "Name is Mandatory";
exports.countryCodeMsg = "Please select the country code" ;
exports.phoneMsg = 'Please fill the phone number';
exports.phoneValidMsg = 'Please Enter the correct phone number';
exports.nameMandateField = "Full Name*";
exports.phoneMandate = "Phone Number*";
exports.userLabel = "User Name";
exports.emailMandateLabel = "Email";
exports.dateMandate = "Select Date";
exports.interestMandate = "Select Interest*";
exports.selectInterest = " Select Your Interest";
exports.imgErrorMessage = "Please upload JPG and PNG, upto 10 MB.";
exports.addNewInterestApiEndPoint = "account_block/accounts/add_interests";
// Customizable Area End
