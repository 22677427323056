import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { Moment } from "moment/moment";
import * as React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import MomentUtils from "@date-io/moment";
import { Appointment } from "./AppointmentsController";
const { HelperFunctions } = require("../../../components/src/HelperFunctions")
const { ApiCallFunction: apiCallFunction, formatLocation } = require("./ApiCallFunction");
export interface FormErrors {
  [key: string]: object;
}

export interface ContactArrayObject {
  name: string;
  email: string;
  id: number;
  full_phone_number: string;
  isNonRegistered?: boolean;
}

export enum MeetingType{
  Online="online",
  Offline="physical"
}

export interface ResponseData {
  suggestion_list: SuggestedEmail[],
  status: number
}

export interface SuggestedEmail{
  id:number;
  email:string;
  full_name:string;
}

export type SearchLocation = {
  description:string;
  place_id:string;
  structured_formatting:{
    main_text:string;
    secondary_text:string;
  }
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  editMode: boolean;
  available_date: Moment;
  start_time: Moment;
  end_time: Moment;
  id: string | number;
  token: string;
  title: string;
  email: string;
  selectedEmails: SuggestedEmail[];
  description: string;
  appointmentDate: string;
  appointmentStartTime: string;
  appointmentEndTime: string;
  meetingType: string;
  location: string;
  onlineLink:string;
  reminderFrequency: number;
  currentFocusedInputField: number | undefined;
  suggestedEmails: SuggestedEmail[];
  suggestedEmailsMenuAnchorElement: React.RefObject<HTMLDivElement> | null,
  isContactMenuOpen: boolean,
  selectedContacts: ContactArrayObject[],
  selectContactTab: number;
  timePickerIndex:number | null;
  contacts: ContactArrayObject[];
  currentSelectedContacts: ContactArrayObject[];
  isAllContactSelected: boolean;
  isFormTouched: boolean;
  formattedDate: string;
  mapMenuAnchorElement: HTMLElement | null,
  mapMenuParentDivAnchorElement: HTMLElement | null,
  searchLocation: string;
  predictions: SearchLocation[];
  selectedLocation: SearchLocation | null;
  selectedEmailMenuOpen: boolean;
  removeEmailList: {
    email:SuggestedEmail,
    flag:boolean
  }[];
  searchEmail: string;
  selectedContactMenuOpen: boolean;
  removeContactList: {
    contact: ContactArrayObject;
    flag: boolean;
  }[];
  searchContact: string;
  isFormUpdated: boolean;
  recentLocations: SearchLocation[];
  emailError: string;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddAppointmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAppointmentApiCallId: string = "";
  addAppointmentApiCallId: string = "";
  emailSuggestionAPICallId: string = "";
  fetchContactsApiCallId: string = "";
  fetchRegisteredContactListApiCallId: string = "";
  searchContactApiCallId: string = "";
  searchLocationApiCallId?: string = "";
  recentSearchLocationApiCallId?: string = "";
  emailSuggestionDebouncingRef: null | NodeJS.Timeout = null;

  reminderArray = [5,10,30,60,120]

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    const endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);

    this.state = {
      // Customizable Area Start
      editMode: false,
      id: 0,
      start_time: moment(new Date()),
      end_time: moment(endTime),
      available_date: moment(new Date()),
      token: "",
      title: "",
      email: "",
      selectedEmails: [],
      selectedContacts: [],
      appointmentDate: "",
      appointmentStartTime: "",
      appointmentEndTime: "",
      description: "",
      meetingType: MeetingType.Offline,
      location: "",
      onlineLink: "",
      reminderFrequency: 5,
      currentFocusedInputField: undefined,
      suggestedEmails: [],
      suggestedEmailsMenuAnchorElement: React.createRef(),
      isContactMenuOpen: false,
      selectContactTab: 0,
      timePickerIndex: null,
      contacts:[],
      currentSelectedContacts:[],
      isAllContactSelected: false,
      isFormTouched: false,
      formattedDate: "",
      mapMenuAnchorElement: null,
      mapMenuParentDivAnchorElement:null,
      searchLocation: "",
      predictions: [],
      selectedLocation: null,
      selectedEmailMenuOpen: false,
      removeEmailList: [],
      searchEmail: "",
      selectedContactMenuOpen: false,
      removeContactList: [],
      searchContact: "",
      isFormUpdated: false,
      recentLocations: [],
      emailError: ""
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    document.addEventListener("click", this.handleClickOutside, true);
    this.fetchContacts()
    this.addAppointment()
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      await this.setToken(token);
    }
    const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    switch (apiCallId) {
      case this.addAppointmentApiCallId:
        this.handleAddAppointmentCall(message)
        break;
      case this.emailSuggestionAPICallId:
        this.handleEmailSuggestionAPICall(message)
        break;
      case this.fetchContactsApiCallId:
        this.handleFetchContactsAPICall(message);
        break;
      case this.fetchRegisteredContactListApiCallId:
        this.handleFetchRegisteredContactListAPICall(message)
        break;
      case this.searchContactApiCallId:
        this.searchContactApiCallHandler(message)
        break;
      case this.searchLocationApiCallId:
        this.handleSearchLocationCall(message)
        break;
      case this.getAppointmentApiCallId:
        this.handleGetAppointmentCall(message)
        break;
      case this.recentSearchLocationApiCallId:
        this.handleRecentSearchLocationCall(message);
        break;
    }
    // Customizable Area End
  };

  // Customizable Area Start

  async componentWillUnmount(): Promise<void> {
    if (this.emailSuggestionDebouncingRef) {
      clearTimeout(this.emailSuggestionDebouncingRef)
    }
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleGetAppointmentCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && !responseJson.errors && responseJson.data) {
      const appointment:Appointment = responseJson.data;
      if(appointment.attributes){
        this.setState({
          title: appointment.attributes.title,
          selectedEmails: [
            ...appointment.attributes.assignee_email,
            ...this.getInvitedMembers(appointment)
          ],
          selectedContacts: [ ...appointment.attributes.user_contacts.filter(contact=> (!contact.invited_by || contact.invited_by !== "email")).map(contact=>({
            name: contact.name,
            full_phone_number: contact.full_phone_number,
            id: contact.id,
            email: contact.email
          })), ...this.getInvitedNonRegisteredMembers(appointment)],
          appointmentDate: this.format(appointment.attributes.appointment_date, "YYYY-MM-DD"),
          formattedDate: this.format(appointment.attributes.appointment_date, "DD MMMM dddd"),
          appointmentStartTime: appointment.attributes.start_time,
          appointmentEndTime: appointment.attributes.end_time,
          description: appointment.attributes.description,
          meetingType: appointment.attributes.meeting_type,
          selectedLocation:appointment.attributes.location ? {
            description:"",
            place_id:appointment.attributes.location.place_id,
            structured_formatting:{
              main_text: formatLocation(appointment.attributes.location),
              secondary_text: ""
            }
          } : null,
          onlineLink: appointment.attributes.meeting_link,
          reminderFrequency: appointment.attributes.reminder_frequency ? parseInt(appointment.attributes.reminder_frequency) : this.reminderArray[0]
        })
      }
    } else {
      this.redirectTo('Appointments')
    }
    HelperFunctions.hideLoader();
  }

  getInvitedMembers = (appointment: Appointment) => {
    const { invitation_members } = appointment.attributes
    if (invitation_members) {
      const invitedMembersEmail: Array<{id: number, full_name: string, email: string}> = [];
      invitation_members.forEach((member: { [key: string]: string }) => {
        if(this.isValidEmail(member.invite_record)){
          invitedMembersEmail.push({
            id: -1,
            full_name: "",
            email: member.invite_record
          })
        }
      })
      return invitedMembersEmail;
    }
    return []
  }

  getInvitedNonRegisteredMembers = (appointment: Appointment) => {
    const { invitation_members } = appointment.attributes
    if (invitation_members) {
      const invitedMembersContact: ContactArrayObject[] = [];
      invitation_members.forEach((member: { [key: string]: string }) => {
        if(!this.isValidEmail(member.invite_record)){
          invitedMembersContact.push({
            name: member.contact_name,
            full_phone_number: member.invite_record,
            id: parseInt(member.id),
            email: "",
            isNonRegistered: true,
          })
        }
      })
      return invitedMembersContact;
    }
    return []
  }

  searchContactApiCallHandler = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && (responseJson?.accounts?.data || responseJson?.phonebook_contacts?.data)) {
      const accountContactsList = responseJson.accounts?.data?.map((contactItem: { type: string, id: number, attributes: { email: string, full_phone_number:string, full_name:string, id: number } }) => {
        return {
          id: contactItem.attributes.id,
          full_phone_number: contactItem.attributes.full_phone_number,
          name: contactItem.attributes.full_name,
          email: contactItem.attributes.email,
        }
      })
      const phonebookContactsList = responseJson.phonebook_contacts?.data?.map((contactItem: { type: string, id: number, attributes: { phone_number:string, name:string, id: number } }) => {
        return {
          id: contactItem.id,
          full_phone_number: contactItem.attributes.phone_number,
          name: contactItem.attributes.name,
          isNonRegistered: true,
          email: "",
        }
      })
      this.setState({
        contacts: [ ...accountContactsList, ...phonebookContactsList]
      })
    } else {
      this.setState({
        contacts: []
      })
    }
  }

  handleSearchLocationCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && !responseJson.errors && responseJson.predictions) {
      const locations:SearchLocation[] = [];
      responseJson.predictions.map((prediction:SearchLocation)=>{
        const location:SearchLocation = {
          description: prediction.description,
          place_id: prediction.place_id,
          structured_formatting: prediction.structured_formatting
        };
        locations.push(location);
      })
      this.setState({
        predictions: locations
      })
    }else{
      this.setState({
        predictions: []
      })
    }
  }

  handleRecentSearchLocationCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && responseJson.data && responseJson.data.length) {
      const locations:SearchLocation[] = [];
      responseJson.data.map((prediction:{id:string,attributes:{[key:string]:string}})=>{
        const location:SearchLocation = {
          description: "",
          place_id: prediction.attributes.place_id,
          structured_formatting: {
            main_text: prediction.attributes.city,
            secondary_text: `${prediction.attributes.state}, ${prediction.attributes.country}`
          }
        };
        locations.push(location);
      })
      this.setState({
        recentLocations: locations
      })
    }else{
      this.setState({
        recentLocations: []
      })
    }
    HelperFunctions.hideLoader();
  }

  handleAddAppointmentCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    HelperFunctions.hideLoader();
    if (responseJson && !responseJson.errors && responseJson.data) {
      const toastMsg = responseJson.message ? responseJson.message : "Your changes have been saved successfully";
      HelperFunctions.showSuccessToast(toastMsg);
      this.redirectTo('Appointments')
    } else{
      const toastMsg = responseJson.errors && responseJson.errors[0] && responseJson.errors[0].message ? responseJson.errors[0].message : "Something went wrong please try again!";
      HelperFunctions.showErrorToast(toastMsg);
    }
  }

  handleFetchContactsAPICall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.data) {
      const contactData = responseJson.data?.map((contact: { data: { id: string, type: string, attributes: { name: string, phone_number: string }} }) => {
        return {
          name: contact.data.attributes.name,
          id: contact.data.id,
          full_phone_number: contact.data.attributes.phone_number,
          isNonRegistered: true,
        }
      })
      this.setState({
        contacts: contactData
      }, () => {
        this.fetchRegisteredContactsList();
      })
    } else {
      this.setState({
        contacts: []
      }, () => {
        this.fetchRegisteredContactsList();
      })
    }
    HelperFunctions.hideLoader();
  }

  handleFetchRegisteredContactListAPICall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.data?.users) {
      this.setState({
        contacts: [ ...this.state.contacts, ...responseJson.data.users]
      })
    } else {
      this.setState({
        contacts: [ ...this.state.contacts]
      })
    }
  }

  handleEmailSuggestionAPICall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.suggestion_list) {
      this.handleSuccessAPICall(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)), responseJson)
    } else {
      this.handleFailAPICall(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)), responseJson)
    }
  }

  fetchContacts = async () => {
    const userToken = await getStorageData("authToken");
    this.fetchContactsApiCallId = await apiCallFunction({
      method: "GET",
      endPoint: `bx_block_contactslist2/phonebook_contacts`,
      contentType: configJSON.appointmentApiContentType,
      token: userToken
    })
  }

  fetchRegisteredContactsList = async () => {
    const userToken = await getStorageData("authToken");
    this.fetchRegisteredContactListApiCallId = await apiCallFunction({
      method: "GET",
      endPoint: `account_block/accounts/registered_users`,
      contentType: configJSON.appointmentApiContentType,
      token: userToken,
    })
  }

  getAppointment = async (appointmentId: string) => {
    HelperFunctions.showLoader();
    this.getAppointmentApiCallId = await apiCallFunction({
      method: "GET",
      endPoint: `bx_block_appointment_management/appointments/${appointmentId}`,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token
    })
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.state.currentFocusedInputField === 2 && this.state.suggestedEmailsMenuAnchorElement && this.state.suggestedEmailsMenuAnchorElement.current && !this.state.suggestedEmailsMenuAnchorElement.current.contains(event.target as Node)) {
      this.setState({
        currentFocusedInputField: undefined
      })
    }

    const menu = document.getElementById("map-menu");
    if (this.state.mapMenuAnchorElement && menu && !menu.contains(event.target as Node)) {
      this.setState({
        mapMenuAnchorElement: null,
        searchLocation: "",
        predictions: []
      })
    }
  }

  openContactListMenu = () => {
    this.setState({
      isContactMenuOpen: true,
      selectContactTab: 0,
      currentSelectedContacts:[]
    })
    this.fetchContacts()
  }

  closeContactListMenu = () => {
    this.setState({ isContactMenuOpen: false, currentSelectedContacts:[], isAllContactSelected: false })
  }

  onSelectContact = (contact: ContactArrayObject) => {
    this.setState(prevState => {
      return {
        currentSelectedContacts: [...prevState.currentSelectedContacts, contact]
      }
    })
  }

  onAddContacts = ()=>{
    this.setState(prevState => {
      return {
        selectedContacts: [...prevState.selectedContacts, ...prevState.currentSelectedContacts],
        isFormUpdated: true
      }
    })
    this.closeContactListMenu();
  }

  onRemoveContact = (contactPhoneNum: number | string) => {
    this.setState(prevState => {
      return {
        currentSelectedContacts: prevState.currentSelectedContacts.filter(contact => contact.full_phone_number !== contactPhoneNum)
      }
    })
  }

  onRemoveContactFromSelectedContact = (contactPhoneNumber: number | string) => {
    const updatedList = this.state.selectedContacts.filter(contact => contact.full_phone_number !== contactPhoneNumber)
    this.setState({ isFormUpdated: true, selectedContacts: [ ...updatedList]})
  }

  selectAllContacts = ()=>{
    if(!this.state.isAllContactSelected){
      this.setState({
        currentSelectedContacts: this.state.contacts.filter(contact=> !this.findItemPresentInEmailAndContactList(contact.full_phone_number)),
        isAllContactSelected: true
      })
    }else{
      this.setState({
        currentSelectedContacts: [],
        isAllContactSelected: false
      })
    }
  }

  isContactSelected = (contactId: number) => {
    if (this.state.currentSelectedContacts.find(item => item.id === contactId)) {
      return true;
    }
    return false;
  }

  setToken = async (token:string | null) => {
    if(!token){
      token = await getStorageData("authToken");
    }
    if (token) {
      this.setState({ token: token }, () => {
        this.getRecentLocations();
        const appointmentId = this.props.navigation.getParam("id");
        if (appointmentId) {
          this.setState({
            editMode: true,
            id: appointmentId
          }, () => {
            this.getAppointment(appointmentId)
          })
        }
      });
    } else {
      this.redirectTo("EmailAccountLoginBlock");
    }
  }

  redirectTo = (endpoint: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), endpoint);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  onFocusChange = (index: number | undefined) => {
    this.setState({
      currentFocusedInputField: index
    })
  }

  onChangeHandler = (name: string, value: string) => {
    this.setState({
      ...this.state,
      [name]: value,
      isFormUpdated: true
    })
  }


  onSelectEmail = (email: SuggestedEmail) => {
    if (!this.state.selectedEmails.find(item => item.email === email.email)) {
      this.setState(prevState => {
        return {
          selectedEmails: [...prevState.selectedEmails, email],
          currentFocusedInputField: undefined,
          email: "",
          isFormUpdated: true
        }
      })
    } else {
      this.setState({
        currentFocusedInputField: undefined,
        email: "",
        isFormUpdated: true
      })
    }
  }

  onRemoveEmail = (email: string | number) => {
    this.setState((prevState) => {
      return {
        selectedEmails: prevState.selectedEmails.filter(item => item.email !== email),
        isFormUpdated: true
      }
    })
  }

  onChangeEmailHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimValue = event.target.value.trim();
    this.setState({
      email: trimValue,
      emailError: ""
    })
    this.emailSuggestionAPICallId = await apiCallFunction({
      method: "GET",
      endPoint: `account_block/accounts/auto_suggestions?email=${trimValue}`,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token
    })
  }

  onSuggestedMenuClosed = () => {
    this.setState({
      suggestedEmails: [],
      currentFocusedInputField: undefined,
      suggestedEmailsMenuAnchorElement: null
    })
  }

  navigateToAppointments = () => {
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Appointments"
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
  };

  handleSuccessAPICall = (messageId: string, responseJson: ResponseData) => {
    if (messageId === this.emailSuggestionAPICallId) {
      this.setState({
        suggestedEmails: responseJson.suggestion_list
      })
    }
  }

  handleFailAPICall = (messageId: string, responseJson: ResponseData) => {
    if (responseJson.status && responseJson.status === 401) {
      this.redirectTo("EmailAccountLoginBlock")
    } else if (messageId === this.emailSuggestionAPICallId) {
      this.setState({
        suggestedEmails: []
      })
    }
  }

  update(value: Partial<{ [K in keyof S]: S[K] }>) {
    this.setState((state) => ({ ...state, ...value }));
  }

  toMomentDate(value: string | Date | null, format?: string) {
    return moment(value, format);
  }

  toDateFromMoment(value: Moment) {
    return value.toDate();
  }

  onStartTimeSelect = (value: Date | Moment | null)=> {
    if (value) {
      const moment = new MomentUtils();
      const formattedDate = moment.date(value).format("LT")
      if(this.state.timePickerIndex === 0){
        this.setState({ appointmentStartTime: formattedDate, isFormUpdated: true })
      }else if(this.state.timePickerIndex === 1){
        this.setState({ appointmentEndTime: formattedDate, isFormUpdated: true })
      }
    }
  }


  openDatePicker = () => {
    const appointmentDatePicker = document.getElementById("appointment-date-picker");
    appointmentDatePicker?.click();
  }

  openTimePicker = (pickerIndex: number | null) => {
    const appointmentTimePicker = document.getElementById("appointment-time");
    appointmentTimePicker?.click();
    this.setState({
      timePickerIndex: pickerIndex
    })
  }

  onChangeMeetingType = (meetingType: string)=>{
    this.setState({
      meetingType,
      isFormUpdated: true
    })
  }

  handleSelectAppointmentDateChange = (value: Date | Moment | null) => {
    if (value) {
      const moment = new MomentUtils();
      const formattedDate = moment.date(value).format("DD MMMM dddd")
      const date = moment.date(value).format("YYYY-MM-DD")
      this.setState({ formattedDate: formattedDate, appointmentDate: date, isFormUpdated: true })
    }
  }

  convertSecIntoTimeString = (minutes: number)=>{
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    // Format the result based on the calculated hours and minutes
    if (hours > 0) {
        return [hours.toString().padStart(2, "0"), "Hr"];
    } else if (remainingMinutes > 0) {
        return [remainingMinutes.toString().padStart(2, "0"), "Min"];
    }else{
        return ["00","Min"]
    }
  }

  onChangeReminderFrequency = (reminderFrequency: number)=>{
    this.setState({
      reminderFrequency,
      isFormUpdated: true
    })
  }

  validateFormData = ()=>{
    const errors:FormErrors = {
      title:{},
      appointmentDate:{},
      appointmentTime:{},
      contacts:{},
      onlineLink:{}
    };
    const { appointmentStartTime, appointmentEndTime, appointmentDate } = this.state;
    if(appointmentStartTime && appointmentDate && new Date(appointmentDate).toDateString() === new Date().toDateString() && HelperFunctions.isPastTime(appointmentStartTime)){
      errors.appointmentTime = {
        message:'Please select the valid time'
      }
    }else if(appointmentStartTime && appointmentEndTime && HelperFunctions.isStartTimeLessThanEndTime(appointmentStartTime, appointmentEndTime)){
      errors.appointmentTime = {
        message:'End Time cannot be in the past. Please select a valid time'
      }
    }else if(appointmentStartTime && appointmentEndTime && appointmentStartTime === appointmentEndTime){
      errors.appointmentTime = {
        message:' Start and end time must be different. Please choose a valid time range.'
      }
    }
    
    return errors;
  }

  checkValidation = (errors:FormErrors)=>{
    for(let errorKey in errors){
      if(Object.keys(errors[errorKey]).length > 0){
        return false
      }
    }
    return true;
  }

  getDataForPost = ()=> {
    const postData = {
      title: this.state.title.trim(),
      assignee_email: this.state.selectedEmails.filter(email=> email.id !== -1 && email.full_name).map(email=> email.id),
      non_registered_users: [ ...this.state.selectedEmails.filter(email=> email.id === -1).map(email=> email.email), ...this.state.selectedContacts.filter(contact => contact.isNonRegistered).map(contact => contact.full_phone_number)],
      contacts: this.state.selectedContacts.filter(contact => !contact.isNonRegistered).map((contact:ContactArrayObject)=> contact.id),
      appointment_date: this.state.appointmentDate,
      start_time: this.state.appointmentStartTime,
      end_time: this.state.appointmentEndTime,
      description: this.state.description,
      meeting_type: this.state.meetingType,
      location: this.state.meetingType === MeetingType.Offline ? this.state.selectedLocation?.structured_formatting.main_text : "",
      place_id: this.state.meetingType === MeetingType.Offline ? this.state.selectedLocation?.place_id : "",
      meeting_link: this.state.meetingType === MeetingType.Online ? this.state.onlineLink : "",
      reminder_frequency: this.state.reminderFrequency
    }
    return postData;
  }

  onScheduleAppointment = async ()=>{
    if(this.checkValidation(this.validateFormData())){
      HelperFunctions.showLoader();
      if(this.state.editMode){
        this.addAppointmentApiCallId = await apiCallFunction({
          method: "PUT",
          endPoint: `bx_block_appointment_management/appointments/${this.state.id}`,
          contentType: configJSON.appointmentApiContentType,
          body: this.getDataForPost(),
          token: this.state.token
        })
      }else{
        this.addAppointmentApiCallId = await apiCallFunction({
          method: "POST",
          endPoint: "bx_block_appointment_management/appointments",
          contentType: configJSON.appointmentApiContentType,
          body: this.getDataForPost(),
          token: this.state.token
        })
      }
    }else{
      this.setState({
        isFormTouched: true
      })
    }
  }

  format = (value:string, format:string)=>{
    const moment = new MomentUtils();
    const formattedDate = moment.date(value).format(format)
    return formattedDate;
  }

  onSearchContact = async (contact: string) => {
    if(contact.trim() !== ""){
      this.searchContactApiCallId = await apiCallFunction({
        method: "GET",
        endPoint: `${configJSON.searchContactsApiEndPoint}?query=${contact}`,
        contentType: configJSON.appointmentApiContentType,
        token: this.state.token
      })
    }else{
      this.fetchContacts();
    }
  }

  onSearchLocation = async (location: string) => {
    this.setState({
      searchLocation: location
    })
    this.searchLocationApiCallId = await apiCallFunction({
      method: "GET",
      endPoint: `bx_block_maps3/locations?query=${location}`,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token
    })
  }

  openMapMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    const parentDiv = document.getElementById("map-input-field")
    this.setState({
      mapMenuAnchorElement: event.currentTarget,
      mapMenuParentDivAnchorElement: parentDiv
    })
  }

  isSubmitButtonDisable = ()=>{
    const { title, appointmentDate, appointmentStartTime, appointmentEndTime, selectedContacts, onlineLink, selectedLocation, meetingType, editMode, isFormUpdated, selectedEmails } = this.state;
    if(meetingType === MeetingType.Online){
      return (!title || !appointmentDate || !appointmentStartTime || !appointmentEndTime || (selectedContacts.length === 0 && selectedEmails.length === 0) || !onlineLink || (editMode && !isFormUpdated))
    }else if(meetingType === MeetingType.Offline){
      return (!title || !appointmentDate || !appointmentStartTime || !appointmentEndTime || (selectedContacts.length === 0 && selectedEmails.length === 0) || !selectedLocation || (editMode && !isFormUpdated))
    }
  }

  onSelectLocation = (prediction:SearchLocation)=>{
    this.setState({
      selectedLocation: prediction,
      mapMenuAnchorElement: null,
      searchLocation: "",
      predictions: [],
      isFormUpdated: true
    })
  }

  clearLocation = ()=>{
    this.setState({
      selectedLocation: null,
      isFormUpdated: true
    })
  }

  openSelectedMenuList = ()=>{
    const removeEmailList = this.state.selectedEmails.map(email=>({ email, flag: true }));
    this.setState({
      removeEmailList,
      selectedEmailMenuOpen: true,
      isFormUpdated: true
    })
  }

  closeSelectedMenuList = ()=>{
    this.setState({
      removeEmailList: [],
      selectedEmailMenuOpen: false,
      searchEmail: ""
    })
  }

  onToggleSelectedEmail = (emailIndex:number)=>{
    const copyEmails = [...this.state.removeEmailList];
    copyEmails[emailIndex].flag = !copyEmails[emailIndex].flag;
    this.setState({
      removeEmailList: copyEmails
    })
  }

  onDoneWithSelectEmails = ()=>{
    const selectedEmails = this.state.removeEmailList.filter(email=> email.flag).map(email=> email.email);
    this.setState({
      selectedEmails,
      isFormUpdated: true
    })
    this.closeSelectedMenuList()
  }

  onSearchEmail = (email:string)=>{
    this.setState({
      searchEmail: email
    })
  }

  openSelectedContactMenuList = ()=>{
    const removeContactList = this.state.selectedContacts.map(contact=>({ contact, flag: true }));
    this.setState({
      removeContactList,
      selectedContactMenuOpen: true
    })
  }

  closeSelectedContactMenuList = ()=>{
    this.setState({
      removeContactList: [],
      selectedContactMenuOpen: false,
      searchContact: ""
    })
  }

  onToggleSelectedContact = (contactIndex:number)=>{
    const copyContacts = [...this.state.removeContactList];
    copyContacts[contactIndex].flag = !copyContacts[contactIndex].flag;
    this.setState({
      removeContactList: copyContacts
    })
  }

  onDoneWithSelectedContacts = ()=>{
    const selectedContacts = this.state.removeContactList.filter(contact=> contact.flag).map(contact=> contact.contact);
    this.setState({
      selectedContacts,
      isFormUpdated: true
    })
    this.closeSelectedContactMenuList()
  }

  onSearchSelectedContact = (contact:string)=>{
    this.setState({
      searchContact: contact
    })
  }
  addAppointment = ()=>{
    return ""
  }
  getRecentLocations = async ()=>{
    HelperFunctions.showLoader();
    this.recentSearchLocationApiCallId = await apiCallFunction({
      method: "GET",
      endPoint: `bx_block_maps3/locations/recent_location?type=appointment`,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token
    })
  }
  onEnterEmail = (event: React.KeyboardEvent<HTMLInputElement>)=>{
    const value = (event.target as HTMLInputElement).value;
    const {
      selectedEmails,
      selectedContacts
    } = this.state;
    if(event.key === "Enter"){

      //If user enter a invalid email
      if(!this.isValidEmail(value)){
        this.setState({
          emailError: "Please enter a valid email",
          currentFocusedInputField: undefined
        })
        event.currentTarget.blur()
        return;
      }

      //If user enter a already selected email
      if(selectedEmails.find(email=> email.email === value) || selectedContacts.find(contact=> contact.email === value)){
        this.setState({
          emailError: "This email is already selected",
          currentFocusedInputField: undefined
        })
        event.currentTarget.blur()
        return;
      }
      
      this.setState(prevState=>{
        return {
          selectedEmails:[...prevState.selectedEmails, {email: value, id:-1, full_name: "" }],
          currentFocusedInputField: undefined,
          email:"",
          isFormUpdated: true
        }
      })
      event.currentTarget.blur()
    }
  }
  isValidEmail(email: string) {
    // Regular expression for validating an Email
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  }
  findItemPresentInEmailAndContactList = (accountId: number | string) => {
    const { selectedEmails, selectedContacts } = this.state;
    return selectedEmails.find(email=> email.id === accountId) || selectedContacts.find(contact=> contact.id === accountId) || selectedContacts.find(contact=> contact.full_phone_number === accountId);
  }  
  goBack = ()=>{
    this.props.navigation.goBack();
  }
  // Customizable Area End
}
