// Customizable Area Start
import React, { useCallback, useState } from "react";
import { Grid, Tooltip, Typography, styled } from "@material-ui/core";
import { IEventData, IAppointmentData } from "../../../../components/src/CommonTypes";
import { clockIcon, invalidEvtImg } from "../assets";
import GuestList from "./GuestList.web";
import RsvpStatus from "./RsvpStatus.web";
import { UserContact } from "../types";
import InvitedPersonsModal from "./InvitedPersonsModal.web";
const { rearrangeContacts } = require("../../../appointmentmanagement/src/ApiCallFunction");

interface IUpcomingEvent {
    event: IEventData | IAppointmentData,
    onEventUpdateRsvpStatus: (status: string, rsvp_status_id: string | number) => void;
    onTitleClick: () => void;
    type?: "appointment" | "event"
}

const formatEventCardData = (event: IEventData )=>{
    return {
        is_trending: event.attributes.is_trending,
        image_url: event.attributes.image_url,
        title: event.attributes.title,
        start_time_event: event.attributes.start_time_event,
        end_time_event: event.attributes.end_time_event,
        user_contacts: event.attributes.user_contacts,
        account_id: event.attributes.account_id,
        rsvp_status: event.attributes.rsvp_status,
        id: event.id,
        invited_members: event.attributes.invitation_members
    }
}

const formatAppointmentCardData = (event: IAppointmentData )=>{
    return {
        is_trending: false,
        image_url: "",
        title: event.attributes.title,
        start_time_event: event.attributes.start_time,
        end_time_event: event.attributes.end_time,
        user_contacts: event.attributes.user_contacts,
        account_id: event.attributes.account_id,
        rsvp_status: event.attributes.rsvp_status,
        id: event.id,
        invited_members: event.attributes.invitation_members
    }
}

const UpcomingEventCard: React.FC<IUpcomingEvent> = (props: IUpcomingEvent) => {
    const { event, onEventUpdateRsvpStatus, onTitleClick, type = "event" } = props;
    const [invitedPersonsList, setInvitedPersonsList] = useState<{ owner_id: number, invited_users: UserContact[], invitation_members?:{[key:string]: string}[] } | null>(null)
    const [activeInviteesTab, setActiveInviteesTab] = useState<string | null>(null)
    const [isRsvpUpdateMode, setIsRsvpUpdateMode] = useState(false);

    const cardData = type === "event" ? formatEventCardData(event as IEventData) : formatAppointmentCardData(event as IAppointmentData);
    
    const setInvitedContactListAsPerEvent = (list: UserContact[], owner_id: number, invitation_members?:{[key: string]:string}[]) => {
        setInvitedPersonsList({ 
            invited_users: rearrangeContacts(owner_id, list), 
            owner_id, 
            invitation_members
        })
        setActiveInviteesTab(null)
    }

    const closeUserListModal = () => {
        setInvitedPersonsList(null)
        setActiveInviteesTab(null)
    }

    const setActiveInviteesTabValue = (currentTab: string | null) => {
        setActiveInviteesTab(currentTab)
    }

    const onRsvpUpdateModeChange = useCallback(() => {
        setIsRsvpUpdateMode(!isRsvpUpdateMode)
    }, [isRsvpUpdateMode])

    const onUpdateRsvpStatus = (status: string, eventId: string | number) => {
        onEventUpdateRsvpStatus(status, eventId)
        setIsRsvpUpdateMode(false)
    }

    return (
        <StyledUpcomingEventCard className="Events_UpcomingEventCard_Web">
            <Grid container direction="row">
                {
                    cardData.is_trending && (
                        <div data-test-id="upcoming-tending-event" className="trending">Trending</div>
                    )
                }
                <Grid item xs={12}>
                    <Grid className="image_and_title_container">
                        {
                            type === "event" && (
                                <Grid item>
                                    {cardData.image_url ? <img className="upcoming-event-img" data-test-id="upcoming-event-img" src={cardData.image_url} alt="" /> :
                                        <img className="upcoming-event-img" src={invalidEvtImg} alt="" />}
                                </Grid>
                            )
                        }
                        <Grid className="title-time-grid" item xs={type === "appointment" ? 12 : 9}>
                            <Tooltip placement="top" title={cardData.title}>
                                <p onClick={onTitleClick} data-test-id="upcoming-event-title" className="title">{cardData.title}</p>
                            </Tooltip>
                            <div className="time">
                                <img className="clock-icon" src={clockIcon} alt="Event Time" />
                                <Tooltip title={`${cardData.start_time_event} - ${cardData.end_time_event}`}>
                                    <Typography component={"span"} className="ellipsis">{cardData.start_time_event} - {cardData.end_time_event}</Typography>
                                </Tooltip>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="title_divider"></Grid>
                <Grid item xs={12}>
                    <GuestList 
                      isUpcomingEvent 
                      contact_list={cardData.user_contacts} 
                      owner_id={cardData.account_id} 
                      setInvitedUsers={setInvitedContactListAsPerEvent} 
                      invited_users={cardData.invited_members}
                    />
                </Grid>
                <Grid item xs={12} className="title_divider"></Grid>
                <Grid item xs={12}>
                    <RsvpStatus
                        rsvp_status={cardData.rsvp_status}
                        unique_id={cardData.id}
                        isRsvpStatusUpdateMode={isRsvpUpdateMode}
                        onUpdateRsvpStatus={(status: string, eventId: string | number) => onUpdateRsvpStatus(status, eventId)}
                        onRsvpUpdateModeChange={() => onRsvpUpdateModeChange()}
                    />
                </Grid>
            </Grid>
            <InvitedPersonsModal 
            invitedUsersList={invitedPersonsList} 
            activeTab={activeInviteesTab} 
            onCloseModal={closeUserListModal} 
            setActiveTab={setActiveInviteesTabValue} />
        </StyledUpcomingEventCard>
    )
}

const StyledUpcomingEventCard = styled("div")({
    "&.Events_UpcomingEventCard_Web": {
        width: "100%",
        height: "360px",
        borderRadius: "1rem",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        border: "1px solid #19B5B945",
        boxShadow: "0px 0px 16px 0px #0000001F",
        padding: "12px",
        boxSizing: "border-box",
        position: "relative",
        overflow: "hidden",
        "& .upcoming-event-img": {
            width: "61px",
            height: "61px",
            borderRadius: "1rem",
        },
        "& p": {
            marginBottom: "0.5rem"
        },
        "& .title": {
            color: "#FFFFFF",
            fontWeight: 500,
            fontSize: "0.875rem",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            display: "block",
            cursor: "pointer"
        },
        "& .time": {
            color: "#FFFFFFCC",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
            padding: "0rem 10px",
            height: "2rem",
            borderRadius: "0.5rem",
            backgroundColor: "#00000033",
            width: "fit-content",
            "& > span": {
                fontSize: "14px",
            }
        },
        "& .clock-icon": {
            marginRight: "10px",
        },
        "& .price": {
            background: "rgba(255, 255, 255, 0.12)",
            fontWeight: 500,
            color: "rgba(255, 255, 255, 0.8)",
            fontSize: "0.875rem",
            padding: "0.3rem 0.7rem",
            borderRadius: "1rem",
            marginTop: "0.3rem"
        },
        "& .trending": {
            position: "absolute",
            background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
            display: "flex",
            width: "5.5rem",
            alignItems: "center",
            justifyContent: "center",
            height: "1.125rem",
            color: "rgba(255, 255, 255, 1)",
            fontSize: "0.5rem",
            fontWeight: 700,
            top: 0,
            left: 0,
            transform: "rotate(-45deg) translate(-27%,-70%)"
        },
        "& .title_divider": {
            height: "1px",
            backgroundColor: "#FFFFFF73",
            opacity: 0.1,
            margin: "13px 0px"
        },
        "& .image_and_title_container":{
            display: "flex",
            gap: "1.5rem"
        }
        
    }
})

export default UpcomingEventCard;
// Customizable Area End