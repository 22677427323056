import React, { useState } from "react";
import { styled } from "@material-ui/styles";
import { SendIcon, AttachFileIcon } from "../assets";
interface Props {
    onAddComment?:(event:string)=> void;
}


const CommentTextField: React.FC<Props> = (props: Props) => {
    const { onAddComment } = props;
    const [text, setText] = useState("");

    const onAddCommentHandler = ()=>{
      if(!text || text.trim() === "") return;
      if(onAddComment) onAddComment(text);
      setText("")
    }

    return (
        <StyledCommentTextField className="CommentTextField_StyledCommentTextField">
            <input
                placeholder="Add a comment"
                value={text}
                maxLength={240}
                onChange={event => {
                    const sanitizedValue = event.target.value.replace(/^\s+/, '');
                    setText(sanitizedValue);
                }}
                onKeyDown={(event)=> event.stopPropagation()}
            />
            <div className="actions">
                <img src={SendIcon} onClick={onAddCommentHandler} />
            </div>
        </StyledCommentTextField>
    )
}

const StyledCommentTextField = styled("div")({
  "&.CommentTextField_StyledCommentTextField":{
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    border: "1px solid rgba(25, 181, 185, 0.27)",
    borderRadius: "6.25rem",
    height: "3rem",
    padding: "0rem 1rem",
    width: "100%",
    boxSizing: "border-box",
    flexShrink: 0,
    "& input":{
        width: "100%",
        border: "none",
        outline: "none",
        fontSize: "1rem",
        fontWeight: 400,
        color: "rgba(255, 255, 255, 0.8)",
        background: "transparent"
    },
    "& .actions":{
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
        "& > img":{
            cursor: "pointer"
        }
    }
  }
})

export default CommentTextField;