// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const { ApiCallFunction: apiCallFunction } = require("../../appointmentmanagement/src/ApiCallFunction");
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
import { IEventData } from "../../../components/src/CommonTypes";
import { UserContact } from "./types";
const { HelperFunctions } = require("../../../components/src/HelperFunctions");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    upcominEventsList: IEventData[];
    isRsvpStatusUpdateMode: boolean;
    eventsInvitedUserList: {
        owner_id: number;
        invited_users: UserContact[]
    } | null;
    activeTab: string | null;
    optionInviteesAnchorEle: HTMLElement | null;
    meetingTitle: string;
    selectedEventId: string | number;
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class UpcomingEventsListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getUpcomingEventsListApiId?: string;
    rsvpStatusUpdateApiCallId?: string;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            upcominEventsList: [],
            isRsvpStatusUpdateMode: false,
            eventsInvitedUserList: null,
            activeTab: null,
            optionInviteesAnchorEle: null,
            meetingTitle: "",
            selectedEventId: "",
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        // Customizable Area End
    }

    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = message.getData(getName(MessageEnum.SessionResponseToken));
            if (!token) {
                token = await getStorageData("authToken");
            }
            this.setToken(token);
        }

        const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            switch (apiCallId) {
                case this.rsvpStatusUpdateApiCallId:
                    this.handleRsvpStatusUpdate(message);
                    break;
                case this.getUpcomingEventsListApiId:
                    this.handleGetUpcomingEventsCall(message);
                    break;
            }
        }
        // Customizable Area End
    };

    // Customizable Area Start

    handleRsvpStatusUpdate = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson && !responseJson.errors) {
            this.setState({
                isRsvpStatusUpdateMode: false
            }, () => {
                this.getUpcomingEventsList();
            })
        } else {
            const eventRsvpErr = responseJson?.errors?.[0]?.message ? responseJson.errors[0].message : "Something went wrong, please try again!";
            HelperFunctions.showErrorToast(eventRsvpErr);
        }
        HelperFunctions.hideLoader();
    }

    handleGetUpcomingEventsCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (!responseJson?.errors && responseJson?.data) {
            this.setState({
                upcominEventsList: responseJson.data,
            })
        } else {
            this.setState({
                upcominEventsList: [],
            })
        }
        HelperFunctions.hideLoader();
    }

    setToken = async (token: string | null) => {
       this.setState({ token }, () => {
        this.getUpcomingEventsList();
       })
    }

    onUpdateEventRsvpStatus = async (status: string, eventId: string | number) => {
        HelperFunctions.showLoader();
        const body = {
            status
        }
        this.rsvpStatusUpdateApiCallId = await apiCallFunction({
            token: this.state.token,
            method: configJSON.patchApiMethodType,
            endPoint: `${configJSON.eventsEndpoint}/${eventId}/add_event_status`,
            contentType: configJSON.validationApiContentType,
            body
        })
    }

    getUpcomingEventsList = async () => {
        HelperFunctions.showLoader();
        this.getUpcomingEventsListApiId = await apiCallFunction({
            method: configJSON.validationApiMethodType,
            contentType: configJSON.appointmentApiContentType,
            endPoint: `${configJSON.upcomingEventsAPIEndpoint}`,
            token: this.state.token
        })
    }

    redirectTo = (endpoint: string, params?: { [key: string]: string | number }) => {
        this.props.navigation.navigate(endpoint, params)
    }

    // Customizable Area End
}