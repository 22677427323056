import React from "react";
import { styled, Modal } from "@material-ui/core";
interface ConfirmationPopUpProps {
    open: boolean;
    onClose: () => void;
    header: string;
    message: string;
    onAccept: () => void;
    onReject: () => void;
}


const ConfirmationPopUp: React.FC<ConfirmationPopUpProps> = (props: ConfirmationPopUpProps) => {
    const { open, onClose, header, message, onAccept, onReject } = props;

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
            <StyledConfirmationPopUp className="ConfirmationPopup_StyledConfirmationPopUp">
                <p>{header}</p>
                <p>{message}</p>
                <div className="actions">
                    <button onClick={onAccept}>Yes</button>
                    <button onClick={onReject}>No</button>
                </div>
            </StyledConfirmationPopUp>
        </Modal>
    )
}

const StyledConfirmationPopUp = styled("div")({
    "&.ConfirmationPopup_StyledConfirmationPopUp": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        background: "linear-gradient(0deg, #23404B, #23404B)",
        borderRadius: "1.5rem",
        width: "20.4375rem",
        height: "12.125rem",
        padding: "1rem 2rem",
        boxSizing: "border-box",
        "& p": {
            fontSize: "0.875rem",
            fontWeight: 500,
            color: "white",
            textAlign: "center",
            margin: 0,
            "&:first-child": {
                fontSize: "1.125rem"
            }
        },
        "& .actions": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
            "& button": {
                border: "none",
                outline: "none",
                width: "8.6875rem",
                padding: "1rem 0rem",
                fontSize: "1rem",
                fontWeight: "500",
                borderRadius: "62.5rem",
                cursor: "pointer",
                "&:first-child": {
                    background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
                    color: "white"
                },
                "&:last-child": {
                    border: "1px solid #F75367",
                    color: "#F75367",
                    backgroundColor: "transparent"
                }
            }
        }
    }
})

export default ConfirmationPopUp;