import React, { } from "react";
import { IReminderItem } from "../types";
import { Modal, styled, Typography, Box } from "@material-ui/core";
import { blueClockIcon, closeIcon, deleteIcon, markAsCompleteRedIcon, whiteEditIcon } from "../assets";
const { HelperFunctions: helper } = require("../../../../components/src/HelperFunctions");

interface ReminderDetailProps {
    reminder: IReminderItem | null,
    onEdit: (reminderId: string) => void;
    onMarkAsComplete: (reminderId: string) => void;
    onDelete: (title: string, reminderId: string) => void;
    onCloseUserListModal: () => void;
}

const ReminderDetailsPopup: React.FC<ReminderDetailProps> = (props: ReminderDetailProps) => {
    const { reminder, onMarkAsComplete, onEdit, onDelete, onCloseUserListModal } = props;

    return (
        <Modal open={!!reminder} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <StyledReminderDetails id="reminder-details-modal">
                {reminder &&
                    <>
                        <Box className="reminder-detail-header">
                            <p>{reminder.attributes.title}</p>
                            <img src={closeIcon} alt="close.svg" onClick={onCloseUserListModal} />
                        </Box>
                        <Box className="reminder-detail-body">
                            <Box className="reminder-time">
                                <Box className="reminder-time-info">
                                    <img src={blueClockIcon} alt="time" />
                                    {`${helper.formatDate(reminder.attributes.date_reminder, "DD MMMM dddd")}, ${reminder?.attributes.time_reminder}`}
                                </Box>
                            </Box>
                            <Box borderBottom={"1px solid #2d4953"} className="reminder-description">
                                <Typography className="desc-header" component="p">
                                    Description
                                </Typography>
                                <Typography className="desc-detail" component="span">
                                    {reminder.attributes.description}
                                </Typography>
                            </Box>
                            <Box className="reminder-description">
                                <Typography className="desc-header" component="p">
                                    Reminder Frequency
                                </Typography>
                                <Typography className="desc-detail" component="span">
                                    {reminder.attributes.reminder_frequency}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="reminder-detail-action">
                            <Box className="reminder-mark-complete" onClick={() => onMarkAsComplete(reminder.id as string)} display={"flex"} alignItems={"center"} marginRight={"15px"}>
                                <img src={markAsCompleteRedIcon} alt="mark as complete" />
                                <Typography component={"span"}>Mark As Complete</Typography>
                            </Box>
                        {!reminder.attributes.lapsed_status && <>
                            <Box marginRight={"15px"}>
                                <button data-test-id="reminder-detail-dlt-btn" className="reminder-detail-dlt-btn" onClick={() => onDelete(reminder.attributes.title, reminder.id as string)}>
                                    <img src={deleteIcon} />
                                    <p>Delete</p>
                                </button>
                            </Box>
                            <Box>
                                <button className="reminder-detail-edit-btn" onClick={() => onEdit(reminder.id as string)}>
                                    <img src={whiteEditIcon} alt="" />
                                    <p>Edit</p>
                                </button>
                            </Box>
                        </>}
                        </Box>
                    </>
                }
            </StyledReminderDetails>
        </Modal>
    )
}

const StyledReminderDetails = styled("div")({
    display: "flex",
    flexDirection: "column",
    maxWidth: "95%",
    width: "560px",
    height: "460px",
    backgroundColor: "#23404B",
    maxHeight: "80%",
    boxShadow: "0px 0px 20px 0px #00000040",
    borderRadius: "1rem",
    "& .reminder-detail-header": {
        position: "relative",
        display: "flex",
        padding: "1rem",
        borderBottom: "1px solid #2d4953",
        justifyContent: "center",
        alignItems: "center",
        "& p": {
            margin: 0,
            fontSize: "1.125rem",
            fontWeight: 500,
            color: "white"
        },
        "& img": {
            position: "absolute",
            right: "1rem",
            top: "50%",
            transform: "translate(-50%,-50%)",
            cursor: "pointer"
        },
    },
    "& .reminder-detail-body": {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        overflowY: "auto",
        padding: "20px 25px",
        "& .reminder-time": {
            display: "flex",
            gap: "2rem",
            boxSizing: "border-box",
            borderBottom: "1px solid #2d4953",
            paddingBottom: "16px",
            "& .reminder-time-info": {
                display: "flex",
                padding: "0rem 1rem",
                backgroundColor: "#00000033",
                gap: "0.5rem",
                alignItems: "center",
                color: "white",
                borderRadius: "0.5rem",
                height: "2.3125rem",
                boxSizing: "border-box",
                overflow: "hidden",
                fontSize: "0.875rem",
                fontWeight: 400,
                "&:first-child": {
                    flexShrink: 0,
                    fontWeight: 500
                },
            },
        },
        "& .reminder-description": {
            paddingBottom: "16px",
            "& .desc-header": {
                fontSize: "14px",
                fontWeight: 400,
                color: "rgba(255, 255, 255, 0.8)"
            },
            "& .desc-detail": {
                fontSize: "16px",
                fontWeight: 400,
                color: "#FFFFFF",
                wordBreak: "break-word"
            }
        }
    },
    "& .reminder-detail-action": {
        position: "relative",
        display: "flex",
        padding: "25px",
        borderTop: "1px solid #2d4953",
        justifyContent: "flex-end",
        alignItems: "center",
        "& button": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "3rem",
            padding: "0.75rem",
            gap: "0.25rem",
            borderRadius: "62.5rem",
            background: "transparent",
            outline: "none",
            border: "none",
            cursor: "pointer",
        },
        "& .reminder-mark-complete": {
            cursor: "pointer",
            "& span": {
                fontSize: "16px",
                fontWeight: 500,
                color: "rgba(247, 83, 103, 1)",
            }
        },
        "& .reminder-detail-dlt-btn": {
            border: "1px solid rgba(247, 83, 103, 1)",
            width: "108px",
            "& p": {
                transform: "translateY(1px)",
                color: "rgba(247, 83, 103, 1)",
                fontSize: "16px",
                fontWeight: 500
            }
        },
        "& .reminder-detail-edit-btn": {
            background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
            width: "86px",
            "& p": {
                color: "rgba(255, 255, 255, 1)",
                fontSize: "16px",
                fontWeight: 500
            }
        }
    }
})

export default ReminderDetailsPopup;