Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.exampleAPiEndPoint = "https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_trending/trendings";
exports.exampleAPiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.patchApiMethod = "PATCH";

exports.description = "Description";
exports.price = "Price";
exports.discount = "Discount";
exports.availbleQuantity = "Availble Quantity";
exports.trendingList = "Trending List";
exports.TrendingProductDetails ='TrendingProductDetails';
exports.TrendingDetails ='TrendingDetails';
exports.demoDetailsData ={
  description:
    "Jeans are a type of pants or trousers made from denim or dungaree cloth",
  stock_qty: 80,
  price: 900.0,
  discount:100
};
exports.bestEventsEndpoint = "bx_block_events/best_events";



// Customizable Area End