export const types = {}
export interface EventLocation {
    id: number;
    latitude: number;
    longitude: number;
    van_id: number | null;
    address: string | null;
    locationable_type: string;
    locationable_id: number;
    place_id: string;
    country: string;
    state: string;
    city: string;
}

export interface UserContact {
    id: number;
    name: string;
    full_phone_number: string;
    email: string;
    rsvp_status: string | null;
    invited_by?: string | null
}

interface EventAttributes {
    title: string;
    date_time_event: string;
    start_time_event: string;
    end_time_event: string;
    location: EventLocation;
    venue_details: string;
    description: string;
    event_type: string;
    event_capacity: string;
    is_ticket_price: boolean;
    ticket_price: string;
    created_by: string;
    status: string;
    image_url: string;
    assignee_email: string[];
    user_contacts: UserContact[];
    click_count: number;
    account_id: number;
    rsvp_status: string | null;
    invitation_members?:{[key: string]: string}[]
}

export interface EventData {
    id: string;
    type: string;
    attributes: EventAttributes;
}

export interface CardData {
    title: string;
    image_url: string;
    time_and_date: string;
}

export enum EventType {
    PRIVATE = "private",
    PUBLIC = "public"
}