// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
import { IGroup } from "./types";
import { IPost } from "../../../components/src/CommonTypes";

export interface Group{
  name:string;
  description:string;
  group_name:string | undefined | null;
  id:number;
}

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  token: string;
  groupSearchValue: string;
  groups: IGroup[];
  sharedGroups: IGroup[];
  selectedGroup: IGroup | null;
  groupPostslist: IPost[];
  pageNum: number;
  isAllGroupFetched: boolean;
}

interface SS {
  id: any;
}

export default class GroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getGroupsApiCallId: string = "";
  getSharedGroupsApiCallId: string = "";
  deleteGroupApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      groupSearchValue: "",
      groups: [],
      selectedGroup: null,
      sharedGroups: [],
      groupPostslist: [],
      pageNum: 1,
      isAllGroupFetched: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      if (!token) {
        token = await helper.getStorageData("authToken");
      }
      this.setState({
        token: token
      })
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      switch (webApiRequestCallId) {
        case this.getGroupsApiCallId:
          this.handlerGetGroupsApiCall(message)
          break;
        case this.getSharedGroupsApiCallId:
          this.handlerGetSharedGroupsApiCall(message)
          break;
        case this.deleteGroupApiCallId:
          this.handleDeleteGroupApiCall(message)
          break;
      }
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getGroups();
    this.getSharedGroups();
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  redirectTo = (endpoint: string, params?: { [key: string]: string | number }) => {
    this.props.navigation.navigate(endpoint, params)
  }

  changeState(objectID: string, value: string): void {
    this.setState((prevState => {
      return {
        ...prevState,
        [objectID]: value
      }
    }))
  }

  getGroups = async () => {
    helper.showLoader();
    const token = await helper.getStorageData("authToken")
    this.getGroupsApiCallId = await helper.apiCall({
      method: "GET",
      contentType: webConfigJSON.validationApiContentType,
      token,
      endPoint: `${webConfigJSON.groupApiEndPoint}?query=my_groups`
    })
  }

  handlerGetGroupsApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data) {
      const groups = responseJson.data.map((item: { attributes: IGroup }) => {
        const { attributes } = item;
        return {
          id: attributes.id,
          name: attributes.name,
          description: attributes.description,
          group_type: attributes.group_type,
          image_url: attributes.image_url,
          members_count: attributes.members_count,
          members: attributes.members,
          type: "my-group"
        }
      })
      this.setState({
        groups
      })
    } else {
      this.setState({
        groups: []
      })
    }
    helper.hideLoader();
  }

  getSharedGroups = async ()=>{
    helper.showLoader();
    const token = await helper.getStorageData("authToken")
    this.getSharedGroupsApiCallId = await helper.apiCall({
      method: "GET",
      contentType: webConfigJSON.validationApiContentType,
      token,
      endPoint: `${webConfigJSON.groupApiEndPoint}?query=shared_groups`
    })
  }

  handlerGetSharedGroupsApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data) {
      const groups = responseJson.data.map((item: { attributes: IGroup }) => {
        const { attributes } = item;
        return {
          id: attributes.id,
          name: attributes.name,
          group_type: attributes.group_type,
          image_url: attributes.image_url,
          description: attributes.description,
          members_count: attributes.members_count,
          members: attributes.members,
          type: "shared-group"
        }
      })
      this.setState({
        sharedGroups: groups
      })
    } else {
      this.setState({
        sharedGroups: []
      })
    }
    helper.hideLoader();
  }

  onSelectGroup = (group: IGroup)=>{
    this.setState({
      selectedGroup: group
    })
  }

  onDeleteGroup = async () => {
    const {
      selectedGroup
    } = this.state;
    if (selectedGroup) {
      helper.showLoader();
      this.deleteGroupApiCallId = await helper.apiCall({
        method: "DELETE",
        contentType: webConfigJSON.validationApiContentType,
        token: this.state.token,
        endPoint: `${webConfigJSON.groupApiEndPoint}/${selectedGroup.id}`
      })
    }
  }


  handleDeleteGroupApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson && responseJson.message){
      helper.showSuccessToast(responseJson.message)
      this.setState({
        selectedGroup: null
      })
      this.getGroups();
    }else{
      const {
        errors
      } = responseJson;
      if(errors && errors.length){
        helper.showErrorToast(errors[0].message);
      }else{
        helper.showSuccessToast("Something went wrong! please try again")
      }
    }
    helper.hideLoader();
  }
   
}

// Customizable Area End
