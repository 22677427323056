import React, { useState, useEffect, useRef } from "react";
import { styled } from "@material-ui/core";
import EventCard from "./Card.web";
import CustomNoDataComponent from "../../../../components/src/CustomNoDataComponent.web";
import { EVENT_TYPES } from "../../../../components/src/CommonTypes";

interface CardScrollWrapperProps {
    cardsList: {
        id: string | number,
        image: string,
        title: string,
        time: string,
        location: string,
        price: string,
        isTrending: boolean
    }[];
    typeOfEvent: string;
    redirectTo: (endpoint: string, params?:{[key:string]:string | number}) => void;
    showNoDataMessage?: boolean;
    type?: "appointment"
}


const CardScrollWrapper: React.FC<CardScrollWrapperProps> = (props: CardScrollWrapperProps) => {
    const { cardsList, typeOfEvent, redirectTo, showNoDataMessage = true, type } = props;
    const [offset, setOffset] = useState(0);
    const [nextButtonVisibility, setNextButtonVisibility] = useState(false);
    const lastElementRef = useRef<HTMLDivElement>(null);
    const onClickPrevHandler = () => {
        if ((offset - 100) >= 0) {
            setOffset(offset - 100);
        } else {
            setOffset(0)
        }
    }
    const onClickNextHandler = () => {
        setOffset(offset + 100);
    }

    const handleResize = () => {
        setOffset(0)
    };


    useEffect(() => {
        window.addEventListener('resize', handleResize);
        const observer = new IntersectionObserver((entries) => {
            if (entries && entries[0] && entries[0].isIntersecting) {
                setNextButtonVisibility(true)
            } else {
                setNextButtonVisibility(false)
            }
        }, {
            threshold: 0.5
        })
        if (lastElementRef && lastElementRef.current) {
            observer.observe(lastElementRef.current)
        }
        return () => {
            window.removeEventListener('resize', handleResize);
            if (lastElementRef && lastElementRef.current) {
                observer.unobserve(lastElementRef.current)
            }
        };
    }, [lastElementRef?.current, cardsList]);
    return (
        <StyledCardScrollWrapper id="card-scroll-container" className="StyledCardScrollWrapper_Web">
            <div className='prev' onClick={onClickPrevHandler} style={{ display: offset === 0 ? "none" : "" }}>
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.99953 11.6553L0.345703 6.00148L5.99953 0.347656L7.05335 1.40148L2.45335 6.00148L7.05335 10.6015L5.99953 11.6553Z" fill="white" />
                </svg>
            </div>
            <div className='next' onClick={onClickNextHandler} style={{ display: (nextButtonVisibility || cardsList.length === 0) ? "none" : "" }}>
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.00047 0.344693L7.6543 5.99852L2.00047 11.6523L0.946646 10.5985L5.54665 5.99852L0.946646 1.39852L2.00047 0.344693Z" fill="white" />
                </svg>
            </div>
            <div style={{ overflow: "auto" }}>
                <div className='c-slider' style={{ transform: `translateX(-${offset}%)` }}>
                    {
                        cardsList.length > 0 && cardsList.map((card, card_index) => {
                            if (card_index !== cardsList.length - 1) {
                                return (
                                    <div onClick={() => redirectTo("EventDetail", { eventId: card.id })} key={card.id} className='card-container'>
                                        <EventCard type={type} id={card.id} title={card.title} image={card.image} location={card.location} time={card.time} isTrending={card.isTrending} />
                                    </div>
                                )
                            } else {
                                return (
                                    <div onClick={() => redirectTo("EventDetail", { eventId: card.id })} ref={lastElementRef} key={card.id} className='card-container'>
                                        <EventCard type={type} id={card.id} title={card.title} image={card.image} location={card.location} time={card.time} isTrending={card.isTrending} />
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
            {
                cardsList.length === 0 && showNoDataMessage && 
                <div className="no-data">
                    <CustomNoDataComponent titleMsg={typeOfEvent === EVENT_TYPES.UPCOMING ? "No Upcoming Events" : "No Trending Events"} subTitleMsg={typeOfEvent === EVENT_TYPES.UPCOMING ? "No scheduled events. Stay tuned for the updates." : "No scheduled and trending events. Stay tuned for the updates."} useCommonEmptyImg={typeOfEvent === EVENT_TYPES.UPCOMING} />
                </div>
            }
        </StyledCardScrollWrapper>
    )
}

const StyledCardScrollWrapper = styled("div")({
    "&.StyledCardScrollWrapper_Web": {
        position: "relative",
        width: "100% !important",
        display: "flex",
        "& .prev,& .next": {
            position: "absolute",
            top: "50%",
            zIndex: 2,
            color: "white",
            cursor: "pointer",
            width: "1.5rem",
            height: "1.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(35, 64, 75, 1)",
            borderRadius: "100%"
        },
        "& .next": {
            right: 0,
            transform: "translate(50%,-50%)",
        },
        "& .prev": {
            transform: "translate(-50%,-50%)",
        },
        "& .c-slider": {
            display: "flex",
            width: "100%",
            transition: "transform 0.3s ease-in-out",
            "& .card-container": {
                flex: "0 0 11.9375rem",
                width: "11.9375rem",
                height: "15.125rem",
                padding: "0rem 0.7rem",
                "&:first-child": {
                    paddingLeft: 0
                },
                "&:last-child": {
                    paddingRight: 0
                }
            }
        },
        "& .no-data": {
            width: "100%",
        }
    }
})

export default CardScrollWrapper;