import React from "react";
import { styled } from "@material-ui/core";
const logo = require("./logo.gif");
interface CustomLoaderProps {
    show: boolean;
}


const CustomLoader: React.FC<CustomLoaderProps> = (props: CustomLoaderProps) => {
    const { show } = props;
    return (
            <StyledCustomLoader className="custom-loader-component" style={{ display: show ? "block" : "none"}} id="custom-loader-main">
                <div className="custom-loader">
                    <img src={logo} alt="custom-loader"  />
                </div>
            </StyledCustomLoader>
    )
}

const StyledCustomLoader = styled("div")({
    "&.custom-loader-component": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1400,
        position: "absolute",
        width: "100%",
        height: "100vh",
        "& .custom-loader": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
            "& > img": {
                widht: "150px",
                height: "150px"
            }
        }
    }
})

export default CustomLoader;