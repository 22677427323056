import React from "react";
import { styled } from "@material-ui/styles";
import { Button } from "../../../../components/src";
import { AddIcon } from "../../../dashboard/src/assets";
interface Props {
    redirectTo: (endpoint: string, params?:{[key:string]:string | number})=> void;
}


const GroupHeader: React.FC<Props> = (props: Props) => {
    const { redirectTo } = props;

    return (
        <StyledGroupHeader className="GroupHeader_StyledGroupHeader">
            <p>Groups</p>
            <Button
                text="Add New Group"
                icon={AddIcon}
                onClick={()=> redirectTo("AddGroup")}
            />
        </StyledGroupHeader>
    )
}

const StyledGroupHeader = styled("div")({
  "&.GroupHeader_StyledGroupHeader":{
     display: "flex",
     alignItems: "center",
     justifyContent: "space-between",
     "& > p":{
        fontSize: "1.25rem",
        fontWeight: 500,
        color: "rgba(255, 255, 255, 1)"
     }
  }
})

export default GroupHeader;