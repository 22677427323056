import React, { useState, useEffect } from "react";
import { styled } from "@material-ui/styles";
import { IGroup } from "../types";
import { Button } from "../../../../components/src";
import GroupImage from "./GroupImage.web";
import GroupPostCreation from "../../../postcreation/src/GroupPostCreation.web";
import GroupInfo from "../GroupInfo.web";

interface Props {
    selectedGroup: IGroup | null;
    redirectTo: (endpoint: string, params?:{[key:string]:string | number})=> void;
    onDeleteGroup?: ()=> void;
}


const GroupDetail: React.FC<Props> = (props: Props) => {
    const { selectedGroup, redirectTo, onDeleteGroup } = props;
    const [openGroupInfo, setOpenGroupInfo] = useState(false);
    const NoGroupSelectedMessage = () => {
        return (
            <StyledNoGroupSelectedMessage className="Groups_NoGroupSelectedMessage_StyledNoGroupSelectedMessage">
                <div className="message">
                    <p>Send messages to the friend or group</p>
                    <Button
                        text="Add New Group"
                        type="outlined"
                        buttonStyles={{ height: "2.25rem", fontSize: "1rem" }}
                        onClick={()=> redirectTo("AddGroup")}
                    />
                </div>
            </StyledNoGroupSelectedMessage>
        )
    }
    const GroupDetailComponent = () => {
        return (
            <StyledGroupDetailComponent className="Groups_GroupDetailComponent_StyledGroupDetailComponent">
                <div className={`group-details ${openGroupInfo ? "info-open": ""}`}>
                    <div className="header" onClick={()=> {
                        setOpenGroupInfo(true)
                    }}>
                        <GroupImage image_url={selectedGroup?.image_url} />
                        <div className="name ellipsis">{selectedGroup?.name}</div>
                    </div>
                    <div className="details">
                        <GroupPostCreation groupDetail={selectedGroup} />
                    </div>
                </div>
                {
                    openGroupInfo && selectedGroup && (
                        <div className="group-info">
                            <GroupInfo groupDetail={selectedGroup} onClose={()=> setOpenGroupInfo(false)} onDeleteGroup={onDeleteGroup} />
                        </div>
                    )
                }
            </StyledGroupDetailComponent>
        )
    }
    useEffect(()=>{
      setOpenGroupInfo(false)
    },[selectedGroup])
    return (
        <StyledGroupDetail className="Groups_GroupDetail_StyledGroupDetail">
            {
                !selectedGroup && (
                    NoGroupSelectedMessage()
                )
            }
            {
                selectedGroup && (
                    GroupDetailComponent()
                )
            }
        </StyledGroupDetail>
    )
}

const StyledGroupDetail = styled("div")({
    "&.Groups_GroupDetail_StyledGroupDetail": {
        display: "flex",
        height: "100%",
        boxSizing: "border-box"
    }
})

const StyledNoGroupSelectedMessage = styled("div")({
    "&.Groups_NoGroupSelectedMessage_StyledNoGroupSelectedMessage": {
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        boxSizing: "border-box",
        "& .message":{
            display: "flex",
            flexDirection: "column",
            gap: "0.5em",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            "& > p":{
                fontSize: "1rem",
                fontWeight: 400,
                color: "rgba(255, 255, 255, 0.8)"
            }
        }
    }
})

const StyledGroupDetailComponent = styled("div")({
    "&.Groups_GroupDetailComponent_StyledGroupDetailComponent": {
        display: "flex",
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        "& > .group-details": {
            display: "flex",
            height: "100%",
            width: "100%",
            boxSizing: "border-box",
            flexDirection: "column",
            "& > .header": {
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: "4.75rem",
                gap: "0.5rem",
                padding: "0rem 1rem",
                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                boxSizing: "border-box",
                cursor: "pointer",
                "& .name": {
                    fontWeight: 500,
                    color: "rgba(255, 255, 255, 1)",
                    fontSize: "1.125rem",
                    boxSizing: "border-box",
                    maxWidth: "calc(100% - 5.2rem)"
                }
            },
            "& > .details": {
                display: "flex",
                flex: 1,
                position: "relative"
                // width: "100%",
                // boxSizing: "border-box",
            },
            "&.info-open":{
                width: "50%",
                borderRight: "1px solid rgba(255, 255, 255, 0.1)"
            }
        },
        "& > .group-info":{
            display: "flex",
            height: "100%",
            width: "50%",
            boxSizing: "border-box",
            flexDirection: "column",
        }
       
    }
})

export default GroupDetail;