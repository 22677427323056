import React from "react";
import { styled } from "@material-ui/styles";
import { CircularProgress } from "@material-ui/core";
interface Props {
    isLoading?: boolean;
}


const ModalLoader: React.FC<Props> = (props: Props) => {
    const { isLoading } = props;

    return (
        <>
            {
                isLoading && (
                    <StyledModalLoader className="ModalLoader_StyledModalLoader">
                        <CircularProgress  />
                    </StyledModalLoader>
                )
            }
        </>
    )
}

const StyledModalLoader = styled("div")({
    "&.ModalLoader_StyledModalLoader": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiCircularProgress-colorPrimary":{
            color: "#F75367 !important"
        }
    }
})

export default ModalLoader;