import React, { useEffect } from "react";
import { styled, Typography, Grid, Box, IconButton } from "@material-ui/core";
import { IReminderAttribute, IReminderItem } from "../types";
import { checkedBoxIcon, deleteIcon, editIcon, emptyCheckBoxIcon } from "../assets";
const { useInView } = require("react-intersection-observer");

interface ReminderProps {
    reminder: {
        id: number | string,
        type: string,
        attributes: IReminderAttribute
    },
    onSelectReminder: (reminderId: string) => void;
    isLastReminderElement: boolean;
    onRedirect: (endpoint: string, params?: { [key: string]: string | number }) => void;
    onClickDeleteIcon: (title: string, reminderId: string) => void;
    onOpenReminderDetails: (reminder: IReminderItem) => void;
    handleFetchNextPageReminders?: () => void;
}

const ReminderCard: React.FC<ReminderProps> = (props: ReminderProps) => {
    const { reminder, onSelectReminder, onRedirect, onClickDeleteIcon, onOpenReminderDetails, handleFetchNextPageReminders, isLastReminderElement } = props;
    const [endOfReminders, isEndOfRemindersVisible] = useInView({
        threshold: 0,
      });

    useEffect(() => {
        if(isEndOfRemindersVisible){
            handleFetchNextPageReminders?.()
        }
    }, [isEndOfRemindersVisible])
    
    const TimeIcon = () => {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_18738_114703" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                    <rect width="16" height="16" fill={reminder.attributes.lapsed_status ? "rgba(255, 0, 0, 1)" : "#D9D9D9"} />
                </mask>
                <path d="M10.3147 11.0153L11.0173 10.3128L8.49932 7.79466V4.66405H7.49935V8.19995L10.3147 11.0153ZM8.00045 14.3307C7.12449 14.3307 6.30114 14.1645 5.53038 13.832C4.75962 13.4996 4.08916 13.0484 3.51902 12.4785C2.94886 11.9086 2.49749 11.2385 2.1649 10.468C1.83231 9.69763 1.66602 8.87445 1.66602 7.9985C1.66602 7.12254 1.83224 6.29918 2.16468 5.52843C2.49713 4.75766 2.94829 4.08721 3.51818 3.51706C4.08808 2.94691 4.75824 2.49554 5.52867 2.16295C6.29908 1.83036 7.12226 1.66406 7.99822 1.66406C8.87417 1.66406 9.69753 1.83029 10.4683 2.16273C11.239 2.49517 11.9095 2.94634 12.4796 3.51623C13.0498 4.08613 13.5012 4.75629 13.8338 5.52671C14.1664 6.29712 14.3327 7.12031 14.3327 7.99626C14.3327 8.87222 14.1664 9.69557 13.834 10.4663C13.5015 11.2371 13.0504 11.9076 12.4805 12.4777C11.9106 13.0479 11.2404 13.4992 10.47 13.8318C9.69959 14.1644 8.8764 14.3307 8.00045 14.3307ZM7.99933 13.3307C9.47711 13.3307 10.7354 12.8113 11.7743 11.7724C12.8132 10.7335 13.3327 9.47516 13.3327 7.99738C13.3327 6.5196 12.8132 5.26127 11.7743 4.22238C10.7354 3.18349 9.47711 2.66405 7.99933 2.66405C6.52155 2.66405 5.26322 3.18349 4.22433 4.22238C3.18544 5.26127 2.666 6.5196 2.666 7.99738C2.666 9.47516 3.18544 10.7335 4.22433 11.7724C5.26322 12.8113 6.52155 13.3307 7.99933 13.3307Z" fill={reminder.attributes.lapsed_status ? "rgba(255, 0, 0, 1)" : "#D9D9D9"} fillOpacity="0.8" />
            </svg>
        )
    }

    const selectReminder = () => {
        if (reminder.attributes.reminder_status !== "completed") {
            onSelectReminder(reminder.id as string)
        }
    }

    const isReminderCardDisabled = () => {
        return reminder.attributes.reminder_status === "completed"
    }

    const openReminderDetails = () => {
        if (!isReminderCardDisabled()) {
            onOpenReminderDetails(reminder)
        }
    }

    return (
        <StyledReminderCard>
            <Grid ref={isLastReminderElement ? endOfReminders : null} container direction="row">
                <Grid className={(reminder.attributes.lapsed_status || reminder.attributes.reminder_status === "completed") ? "checkbox border-red" : "checkbox border-green"} item>
                    <Box className={`select-checkbox ${reminder.attributes.reminder_status === "completed" ? "cursor-none" : "cursor-pointer"}`} id="select-checkbox" onClick={() => selectReminder()}>
                        {reminder.attributes.isSelected ? <img src={checkedBoxIcon} /> : <img src={emptyCheckBoxIcon} />}
                    </Box>
                </Grid>
                <Grid className="reminder-details" item>
                    <Box mb={"4px"}>
                        <Typography onClick={() => openReminderDetails()} className={`reminder-title ${!isReminderCardDisabled() ? "cursor-pointer" : ""}`} component={"span"}>
                            {reminder.attributes.title}
                        </Typography>
                    </Box>
                    <Box mb={"4px"}>
                        <Typography className="reminder-desc" component={"span"}>
                            {reminder.attributes.description}
                        </Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box mr={"5px"}>
                            {TimeIcon()}
                        </Box>
                        <Typography className={`reminder-time ${reminder.attributes.lapsed_status ? "time-red" : ""}`} component={"span"}>
                            {reminder.attributes.time_reminder}
                        </Typography>
                    </Box>
                </Grid>
                {!reminder.attributes.lapsed_status && <Grid className="action-icons" item >
                    <Box className="action-icons-box" display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                        <IconButton disableRipple disabled={isReminderCardDisabled()} className={`icons ${isReminderCardDisabled() ? "cursor-none" : "cursor-pointer"}`} onClick={() => onRedirect("EditReminder", { id: reminder.id })}>
                            <img src={editIcon} alt="edit" />
                        </IconButton>
                        <IconButton id="delete-reminder" disableRipple disabled={isReminderCardDisabled()} className={`icons ${isReminderCardDisabled() ? "cursor-none" : "cursor-pointer"}`} onClick={() => onClickDeleteIcon(reminder.attributes.title, reminder.id as string)}>
                            <img src={deleteIcon} alt="delete" />
                        </IconButton>
                    </Box>
                </Grid>}
            </Grid>
        </StyledReminderCard>
    )
}

const StyledReminderCard = styled("div")({
    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    border: "1px solid #19B5B945",
    borderRadius: "16px",
    padding: "8px 6px",
    "& .select-checkbox": {
        paddingLeft: "10px",
        paddingTop: "4px",
    },
    "& .reminder-details": {
        width: "85%",
        paddingLeft: "12px",
        "& .reminder-title": {
            fontSize: "18px",
            fontWeight: 500,
        },
        "& .reminder-desc": {
            fontSize: "12px",
            fontWeight: 400,
            color: "rgba(255, 255, 255, 0.8)",
        },
        "& .reminder-time": {
            fontSize: "14px",
            fontWeight: 400,
        },
        "& .time-red": {
            color: "rgba(255, 0, 0, 1)"
        },
        "& .time-regular": {
            color: "rgba(255, 255, 255, 0.8)",
        },
        [`@media screen and (max-width: 950px)`]: {
            width: "75%"
        },
    },
    "& .checkbox": {
        paddingRight: "5px",
        width: "5%",
        [`@media screen and (max-width: 950px)`]: {
            width: "15%"
        },
    },
    "& .border-green": {
        borderRight: "2px solid rgba(66, 255, 153, 1)"
    },
    "& .border-red": {
        borderRight: "2px solid rgba(255, 0, 0, 1)"
    },
    "& .action-icons": {
        width: "10%"
    },
    "& .icons": {
        padding: "2px",
        "&:hover": {
            backgroundColor: "inherit"
        }
    },
})

export default ReminderCard;