export const crossSvg = require("../assets/cross.svg")
export const closeIcon = require("../assets/close.svg")
export const searchIcon = require("../assets/search.svg")
export const emptyCheckBoxIcon = require("../assets/empty-checkbox.svg")
export const checkedBoxIcon = require("../assets/checkedBox.svg");
export const radioCheckedIcon = require("../assets/radio-checked.svg");
export const radioEmptyIcon = require("../assets/radio-empty.svg");
export const CalendarIcon = require("../assets/calender-icon.svg");
export const PlusIcon = require("../assets/plus-icon.svg");
export const ClockIcon = require("../assets/clock-icon.svg");
export const LocationIcon = require("../assets/location-icon.svg");
export const CrossIcon2 = require("../assets/cross-icon-2.svg");
export const CheckIcon = require("../assets/check-icon.svg");
export const CheckIcon2 = require("../assets/check-icon-2.svg");
export const ChatsIcon = require("../assets/chats-icon.svg");
export const PostsIcon = require("../assets/posts-icon.svg");
export const EditIcon = require("../assets/edit-icon.svg");
export const DeleteIcon = require("../assets/delete-icon.svg");
