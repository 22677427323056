import React, { useState } from "react";
import { styled, Modal } from "@material-ui/core";
import { closeIcon, searchIcon, emptyCheckBoxIcon, checkedBoxIcon } from "../../blocks/appointmentmanagement/src/assets";
interface ViewSelectedEmailAndContactProps {
    open: boolean;
    onClose: ()=>void;
    listData: any[];
    type: "email" | "contact";
    onDone: (event: any)=>void;
}


const ViewSelectedEmailAndContact: React.FC<ViewSelectedEmailAndContactProps> = (props: ViewSelectedEmailAndContactProps) => {
    const { open, onClose, listData, type, onDone } = props;
    const [searchString, setSearchString] = useState("");

    const changeDataFormat = (data: any[])=>{      
      return data.map(d=> ({flag: true, value:d}));
    }
    const [items, setItems] = useState(changeDataFormat(listData));

    const onCloseHandler = ()=>{
        onClose()
    }
    const onSearchHandler = (event: React.ChangeEvent<HTMLInputElement>)=>{
        const value = event.target.value;
        setSearchString(value)
    }
    const onDoneHandler = ()=>{
        setSearchString("")
        const result = items.filter(item=> item.flag).map(item=> item.value);
        onDone(result);
    }
    const onToggleSelection = (itemIndex: number)=>{
       const copyItems = JSON.parse(JSON.stringify(items));
       copyItems[itemIndex].flag = !copyItems[itemIndex].flag;
       setItems(copyItems);
    }

    const filterList = ()=>{
      if(type === "contact"){
        return items.filter(item => item.value.name.toLowerCase().includes(searchString.toLowerCase()) || item.value.full_phone_number.includes(searchString))
      }else if(type === "email"){
        return items.filter(item=> item.value.email.includes(searchString.toLowerCase()));
      }
      return []
    }
    
    return (
        <Modal data-test-id="selected-contact-list-modal" open={open} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <StyledViewSelectedEmailAndContact>
            <div className="header">
              <p>{type === "contact" ? "Selected Contacts" : "Selected Emails"}</p>
              <img src={closeIcon} alt="close.svg" onClick={onCloseHandler} />
            </div>
            <div className="body">
              <div className="search-container">
                <img src={searchIcon} alt="search.svg" />
                <input placeholder={`${type === "contact" ? "Search name or number" : "Search email"}`} value={searchString} onChange={onSearchHandler} />
              </div>
              {
                items.length > 0 && (
                  <div className="contact-list-container">
                    <div className="contacts" data-test-id="selected-contacts">
                      {
                        filterList().map((listItem, contactIndex: number) => {
                          return (
                            <div className="contact" key={contactIndex}>
                              <div className="name">
                                <div className="avatar">{type === "contact" ? listItem.value.name[0] : listItem.value.email[0]}</div>
                                {type === "contact" ? listItem.value.name : listItem.value.email}
                              </div>
                              {
                                listItem.flag ? <img src={checkedBoxIcon} onClick={() => onToggleSelection(contactIndex)} /> : <img src={emptyCheckBoxIcon} onClick={() => onToggleSelection(contactIndex)} />
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              }
            </div>
            <div className="footer">
              <button onClick={onDoneHandler}>
                Done
              </button>
            </div>
          </StyledViewSelectedEmailAndContact>
        </Modal>
    )
}

const StyledViewSelectedEmailAndContact = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "26.25rem",
    height: "34.3125rem",
    backgroundColor: "#23404B",
    boxShadow: "0px 0px 20px 0px #00000040",
    borderRadius: "1rem",
    "& .header": {
      position: "relative",
      padding: "1rem",
      borderBottom: "1px solid #2d4953",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& p": {
        margin: 0,
        fontSize: "1.125rem",
        fontWeight: 500,
        color: "white"
      },
      "& img": {
        position: "absolute",
        right: "1rem",
        top: "50%",
        transform: "translate(-50%,-50%)",
        cursor: "pointer"
      }
    },
    "& .body": {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      overflowX: "auto",
      gap: "1rem",
      padding: "1rem",
      "& .tab-container": {
        display: "flex",
        justifyContent: "center",
        "& .tab": {
          background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
          width: "13.5rem",
          height: "2.25rem",
          borderRadius: "3.125rem",
          display: "flex",
          padding: "0rem 0.3rem",
          justifyContent: "space-between",
          alignItems: "center",
          "& > div": {
            width: "7rem",
            height: "1.75rem",
            borderRadius: "3.125rem",
            fontSize: "0.875rem",
            fontWeight: 400,
            color: "white",
            opacity: 0.5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            transition: "all 0.3s ease-in-out",
            "&.active": {
              background: "linear-gradient(90deg, #FF7F77 0%, #FF6376 100%)",
              opacity: 1,
              fontWeight: 500
            }
          }
        }
      },
      "& .search-container": {
        display: "flex",
        backgroundColor: "#00000026",
        gap: "0.5rem",
        borderRadius: "6.25rem",
        padding: "0.75rem 1rem",
        "& input": {
          outline: "none",
          border: "none",
          background: "transparent",
          width: "100%",
          color: "white"
        }
      },
      "& .contact-list-container": {
        display: "flex",
        flexDirection: "column",
        "& .contact-header": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& > p": {
            margin: 0,
            "&:nth-child(1)": {
              fontSize: "0.75rem",
              fontWeight: 500,
              color: "white"
            },
            "&:nth-child(2)": {
              fontSize: "0.875rem",
              fontWeight: 400,
              color: "#F37F78",
              cursor: "pointer"
            }
          }
        },
        "& .contacts": {
          display: "flex",
          flexDirection: "column",
          marginTop: "1rem",
          gap: "0.5rem",
          "& .contact": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "& .name": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "0.875rem",
              color: "white",
              gap: "0.5rem",
              "& .avatar": {
                display: "flex",
                width: "2rem",
                height: "2rem",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #26EFF5",
                color: "#26EFF5",
                borderRadius: "100%",
                textTransform:"capitalize"
              }
            }
          }
        }
      }
    },
    "& .footer": {
      padding: "1rem",
      "& button": {
        outline: "none",
        border: "none",
        background: "linear-gradient(180deg, rgba(243, 127, 120, 0.75) 0%, rgba(247, 83, 103, 0.75) 100%)",
        // boxShadow: "0px 0px 20px 0px #0000004D",
        width: "100%",
        borderRadius: "62.5rem",
        color: "white",
        height: "3rem",
        cursor: "pointer",
        fontSize: "1rem",
        fontWeight: 500,
        letterSpacing: "1px",
        "&:disabled": {
          opacity: '0.5',
          cursor: "inherit"
        }
      }
    },
    [`@media screen and (max-width: 600px)`]: {
      width: "80%",
      height: "70%"
    },
})



export default ViewSelectedEmailAndContact;