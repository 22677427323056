import React from "react";
// Customizable Area Start
import { styled } from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import EventHeader from "./components/EventHeader.web";
import EventDetail from "./components/EventDetail.web";
import CardScrollWrapper from "./components/CardScrollWrapper.web"
import { EVENT_TYPES } from "../../../components/src/CommonTypes";
import ConfirmationPopUp from "../../../components/src/ConfirmationPopUp.web";
import CreatePostModal from "../../postcreation/src/components/CreatePostModal.web";
import { Carousel } from "../../../components/src";
// Customizable Area End

import EventsController, {
  Props
} from "./EventsController";

export default class AllEvent extends EventsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  EventContainer = () => {
    const { 
      onGoingEvents, 
      eventsCardList, 
      bestEventsCardsList, 
      confirmationPopUpData, 
      userId, 
      predictions, 
      recentLocations, 
      eventFilters, 
      addPostDetails, 
      selectedNearByLocationCoordinates 
    } = this.state;
    return (
      <StyledEventsContainer className="Events_Web_Container">
        <EventHeader 
          redirectTo={this.redirectTo} 
          onSearchLocation={this.onSearchLocation} 
          predictions={predictions} 
          recentLocations={recentLocations} 
          onApplyFilter={this.applyFilter}
          filter={eventFilters}
          onNearByLocationSelectGetCoordinates={this.getLocationCoordinates}
          selectedNearByLocationCoordinates={selectedNearByLocationCoordinates}

        />
        {onGoingEvents && onGoingEvents.length > 0 && (
          <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <header className="ongoing-event-header">Ongoing Events</header>
            <Carousel totalItems={onGoingEvents.length}>
              {
                onGoingEvents.map(event => {
                  return (
                    <EventDetail
                      event={event}
                      isRsvpStatusUpdateMode={this.state.isRsvpStatusUpdateMode}
                      onRsvpUpdateModeChange={this.onRsvpUpdateModeChange}
                      onUpdateRsvpStatus={this.onUpdateRsvpStatus}
                      onDeleteEvent={this.openDeleteModal}
                      userId={userId}
                      redirectTo={this.redirectTo}
                      onPostCreate={this.onOpenCreatePostModal}
                    />
                  )
                })
              }
            </Carousel>
          </div>
        )}
        <div className="upcoming-events">
          <div className="header">
            <header>Upcoming Events</header>
            <button data-test-id="view-all-upcoming-events-btn" onClick={() => this.redirectTo("UpcomingEvents")}>View All</button>
          </div>
          <div className="events">
            <CardScrollWrapper redirectTo={this.redirectTo} typeOfEvent={EVENT_TYPES.UPCOMING} cardsList={eventsCardList} />
          </div>
        </div>
        <div className="upcoming-events">
          <div className="header">
            <header>
                The Best Event
              <span>Monday To Sunday we got you covered </span>
            </header>
            <button data-test-id="view-all-best-events-btn" onClick={() => this.redirectTo("BestEvents")}>View All</button>
          </div>
          <div className="events">
            <CardScrollWrapper redirectTo={this.redirectTo} typeOfEvent={EVENT_TYPES.BEST} cardsList={bestEventsCardsList} />
          </div>
        </div>
        <ConfirmationPopUp
          open={Boolean(confirmationPopUpData)}
          header={confirmationPopUpData?.header as string}
          message={confirmationPopUpData?.message as string}
          onAccept={this.onAcceptConfirmation}
          onReject={this.onCloseConfirmationModal}
          onClose={this.onCloseConfirmationModal}
        />
        <CreatePostModal
          postDetails={addPostDetails}
          onCloseCreatePostModal={() => this.onCloseCreatePostModal()}
          isEditMode={false}
          handleUpdateFilesUploaded={() =>  {}}
          onSubmitPostDetails={this.onSubmitPostDetails}
        />
      </StyledEventsContainer>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <NavigationMenu {...this.props} children={this.EventContainer()} />
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledEventsContainer = styled("div")({
  "&.Events_Web_Container": {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    padding: "1rem 1.5rem",
    "& .upcoming-events": {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      "& .header": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& > header": {
          fontSize: "1rem",
          fontWeight: 500,
          color: "rgba(255, 255, 255, 1)",
          "& span": {
            color: "rgba(255, 255, 255, 1)",
            fontSize: "0.75rem",
            fontWeight: 400,
            display: "block"
          }
        },
        "& > button": {
          outline: "none",
          border: "none",
          fontSize: "0.875rem",
          fontWeight: 500,
          color: "rgba(247, 83, 103, 1)",
          background: "transparent",
          cursor: "pointer"
        }
      },
      "& .events": {
        width: "100%",
        // padding:"0rem 1rem",
        boxSizing: "border-box"
      }
    },
    "& .ongoing-event-header":{
      fontSize: "1rem",
      fontWeight: 500,
      color: "rgba(255, 255, 255, 1)",
    }
  }
})
// Customizable Area End
