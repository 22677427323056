// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    userInfo: {
        full_name: string;
        email: string;
        phone_number: string;
        image_url: string;
    } | null;
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ProfileDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            userInfo: null
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        const userInfo = await helper.getUserData();
        if(userInfo){
            this.setState({
                userInfo: userInfo
            })
        }
        
        // Customizable Area End
    }

    receive = async (from: string, message: Message) => {
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let authToken = message.getData(getName(MessageEnum.SessionResponseToken));
            if (!authToken) {
                authToken = await getStorageData("authToken");
            }
            this.setToken(authToken);
        }
    };

    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };


    // Customizable Area Start

    setToken = async (token: string | null) => {
       this.setState({ token })
    }

    // Customizable Area End
}