export const types = {};
export enum GroupType {
    PRIVATE = "private_group",
    PUBLIC = "public_group"
}

export const ReverseGroupType:{[key: string]: string} = {
  private_group: "Private",
  public_group: "Public"
}

export interface IGroup{
    id: number;
    name: string;
    description: string;
    image_url: string;
    group_type: string;
    type?: "my-group" | "shared-group",
    members_count?: number;
    members?: GroupMember[];
    request_pending?: GroupMember[];
    request_pending_count?: number;
    is_admin?: boolean;
}

interface GroupMember {
    id: number;
    type: string;
    attributes: GroupMemberAttributes;
  }
  
  interface GroupMemberAttributes {
    id: number;
    joined_status: string;
    account_id: number;
    group_id: number;
    joined_by_invitation: boolean;
    role: string;
    account_info: AccountInfo;
  }
  
  interface AccountInfo {
    id: number;
    full_phone_number: string;
    user_name: string;
    full_name: string;
    image_url: string;
  }