import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
import { EventData } from "../../../blocks/events/src/types";
import { IEventCard, IAppointmentData } from "../../../components/src/CommonTypes";
import moment from "moment";
import { IReminders } from "../../tasks/src/types";


export interface ReminderData{
  formatted_date: string;
  desktop_date: string;
  isToday: boolean;
  reminders: {
    id: number | string;
    title: string;
    description: string;
    time: string;
    date: string;
    status: string;
  }[]
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  events: IEventCard[];
  appointments: IEventCard[];
  reminders: ReminderData[];
  confirmationPopUpData: {
    header: string;
    message: string;
    type: string;
    id: string;
  } | null;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getEventsApiCallId: string = "";
  getAppointmentsApiCallId: string = "";
  getRemindersApiCallId: string = "";
  deleteReminderApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      events: [],
      appointments: [],
      reminders: [],
      confirmationPopUpData: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      if(!token){
        token = await helper.getStorageData("authToken");
      }
      this.setState({
        token: token
      },()=>{
        this.getEvents()
        this.getAppointments()
        this.getReminders()
      })
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      switch(webApiRequestCallId) {
        case this.getEventsApiCallId:
          this.handleGetEventsApiCall(message);
          break;
        case this.getAppointmentsApiCallId:
          this.handleGetAppointmentsApiCall(message);
          break;
        case this.getRemindersApiCallId:
          this.handleGetRemindersApiCall(message);
          break; 
        case this.deleteReminderApiCallId:
          this.handleDeleteReminderApiCall(message);
          break;
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getEvents = async ()=>{
    helper.showLoader();
    this.setState({
      loading: true
    })
    this.getEventsApiCallId = await helper.apiCall({
      method: "GET",
      endPoint: `${webConfigJSON.upcomingEventsAPIEndpoint}?page_no=1&per_page=10`,
      contentType: webConfigJSON.dashboardContentType,
      token: this.state.token
    })
  }
  handleGetEventsApiCall = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson && responseJson.data){
      const cardsList = responseJson.data.map((item: EventData) => {
        const cardObj: IEventCard = {
          id: item.id,
          image: item.attributes.image_url || "",
          isTrending: false,
          location: item.attributes.location?.city || "",
          price: `₹${item.attributes.ticket_price}`,
          time: this.formatEventCardsTime(item.attributes.date_time_event, item.attributes.start_time_event),
          title: item.attributes.title,
        };
        return cardObj;
      });
      this.setState({
        events: cardsList
      })
    }else{
      this.setState({
        events: []
      })
    }
    helper.hideLoader();
    this.setState({
      loading: false
    })
  }

  formatEventCardsTime = (date: string, time: string) => {
    const dateTime = moment(date + " " + time);
    return dateTime.format("DD MMM, ddd hh:mm A");
  }

  getAppointments = async ()=>{
    helper.showLoader();
    this.setState({
      loading: true
    })
    this.getAppointmentsApiCallId = await helper.apiCall({
      method: "GET",
      endPoint: `${webConfigJSON.upcomingAppointmentsApiEndpoint}?page_no=1&per_page=10`,
      contentType: webConfigJSON.dashboardContentType,
      token: this.state.token
    })
  }
  handleGetAppointmentsApiCall = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson && responseJson.data){
      const cardsList = responseJson.data.map((item: IAppointmentData) => {
        const cardObj: IEventCard = {
          id: item.id,
          image: "",
          isTrending: false,
          location: "",
          price: "",
          time: this.formatEventCardsTime(item.attributes.appointment_date, item.attributes.start_time),
          title: item.attributes.title,
        };
        return cardObj;
      });
      this.setState({
        appointments: cardsList
      })
    }else{
      this.setState({
        appointments: []
      })
    }
    helper.hideLoader();
    this.setState({
      loading: false
    })
  }

  getReminders = async ()=>{
    helper.showLoader();
    this.setState({
      loading: true
    })
    this.getRemindersApiCallId = await helper.apiCall({
      method: "GET",
      endPoint: `${webConfigJSON.upcomingRemindersApiEndPoint}?page_no=1&per_page=10&start_date=${helper.formatDate(new Date(), "YYYY-MM-DD")}`,
      contentType: webConfigJSON.dashboardContentType,
      token: this.state.token
    })
  }

  handleGetRemindersApiCall = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson && responseJson.reminders){
      const cardsList:ReminderData[] = []
      responseJson.reminders.forEach((item: IReminders) => {
        const cardObj: ReminderData = {
          formatted_date: item.date,
          desktop_date: item.desktop_date,
          isToday: this.isToday({ desktop_date: item.desktop_date, month: item.month, year: item.year }),
          reminders: []
        };
        cardObj.reminders = item.reminders.filter(reminder=> !reminder.attributes.lapsed_status).map(reminder=>{
          return {
            id: reminder.id,
            title: reminder.attributes.title,
            description: reminder.attributes.description,
            time: reminder.attributes.time_reminder,
            date: reminder.attributes.date_reminder,
            status: reminder.attributes.reminder_status
          }
        })
        if(cardObj.reminders.length > 0){
          cardsList.push(cardObj)
        }
      });
      this.setState({
        reminders: cardsList
      })
    }else{
      this.setState({
        reminders: []
      })
    }
    helper.hideLoader();
    this.setState({
      loading: false
    })
  }

  isToday(dateObject:{desktop_date: string, month: number, year: number}) {
    const today = new Date();

    // Get today's date components
    const todayDate = today.getDate();
    const todayMonth = today.getMonth() + 1; // Months are zero-indexed in JavaScript
    const todayYear = today.getFullYear();

    // Compare with dateObject
    return !!dateObject.desktop_date && dateObject.desktop_date.split(" ").length > 0 && parseInt(dateObject.desktop_date.split(" ")[0]) === todayDate &&
      dateObject.month === todayMonth &&
      dateObject.year === todayYear;
  }

  openConfirmationPopup = (itemId?:string, title?: string)=>{
    this.setState({
      confirmationPopUpData: {
        header: "Delete Appointment?",
        message: `Are you sure that you want to delete ${title}?`,
        type: "delete",
        id: itemId as string
      }
    })
  }

  onCloseConfirmationPopup = ()=>{
    this.setState({
      confirmationPopUpData: null
    })
  }

  deleteReminder = async (reminderId?: string)=>{
    helper.showLoader();
    this.deleteReminderApiCallId = await helper.apiCall({
      method: "DELETE",
      endPoint: `${webConfigJSON.reminders}/${reminderId}`,
      contentType: webConfigJSON.dashboardContentType,
      token: this.state.token
    })
    this.setState({
      confirmationPopUpData: null
    })
  }

  handleDeleteReminderApiCall = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson && !responseJson.errors){
      this.getReminders();
    }
  }

  redirectTo = (endpoint: string, params?:{[key:string]:string | number}) => {
    this.props.navigation.navigate(endpoint, params)
  }
  // Customizable Area End
}
