import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Appointment, UserContact } from "./AppointmentsController";
const { ApiCallFunction: apiCallFunction, rearrangeContacts } = require("./ApiCallFunction");
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
const { HelperFunctions } = require("../../../components/src/HelperFunctions");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  id: string | number;
  token: string;
  appointment: Appointment | null;
  isRsvpStatusUpdateMode: boolean;
  invitedUserList: {
    owner_id:number;
    invited_users: UserContact[],
    invitation_members: {[key: string]: string}[]
  } | null;
  activeTab: string | null;
  optionAnchorEle: HTMLElement | null;
  isDeleteModalOpen: boolean;
  meetingTitle: string;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AppointmentDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAppointmentApiCallId?: string;
  rsvpStatusUpdateApiCallId?: string;
  deleteAppointmentApiCallId?: string
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: 0,
      token: "",
      appointment: null,
      isRsvpStatusUpdateMode: false,
      invitedUserList: null,
      activeTab: null,
      optionAnchorEle: null,
      isDeleteModalOpen: false,
      meetingTitle: ""
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    document.addEventListener("click", this.closeOptionMenu, true);
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      if(!token){
        token = await getStorageData("authToken");
      }
      this.setToken(token);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAppointmentApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.handleGetAppointmentCall(message);
    }else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.rsvpStatusUpdateApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.handleRsvpStatusUpdate(message)
    }else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteAppointmentApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.handleDeleteAppointment(message)
    }

    // Customizable Area End
  };

  // Customizable Area Start
  async componentWillUnmount(): Promise<void> {
    document.removeEventListener("click", this.closeOptionMenu, true);
  }

  handleRsvpStatusUpdate= (message:Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (responseJson && !responseJson.errors) {
      this.setState({
        isRsvpStatusUpdateMode: false
      },()=>{
        this.getAppointment(this.state.id)
      })
    }else{
      const toastMsg = responseJson.errors && responseJson.errors[0] && responseJson.errors[0].message ? responseJson.errors[0].message : "Something went wrong please try again!";
      HelperFunctions.showErrorToast(toastMsg);
    }
    HelperFunctions.hideLoader();
  }

  handleGetAppointmentCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && !responseJson.errors && responseJson.data) {
      const appointment:Appointment = responseJson.data;
      if(appointment.attributes){
        this.setState({
          appointment
        })
      }
    } else {
      this.redirectTo('Appointments')
    }
    HelperFunctions.hideLoader();
  }

  handleDeleteAppointment = (message:Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (responseJson && !responseJson.errors) {
      this.setState({
        isDeleteModalOpen: false
      },()=>{
        const toastMsg = responseJson.message ? responseJson.message : "Your changes have been saved successfully";
        HelperFunctions.showSuccessToast(toastMsg);
        this.redirectTo("Appointments")
      })
    }else{
      const toastMsg = responseJson.errors && responseJson.errors[0] && responseJson.errors[0].message ? responseJson.errors[0].message : "Something went wrong please try again!";
      HelperFunctions.showErrorToast(toastMsg);
    }
    HelperFunctions.hideLoader();
  }

  setToken = async (token:string | null) => {
    if(!token){
      token = await getStorageData("authToken");
    }
    if (token) {
      this.setState({ token: token }, () => {
        const appointmentId = this.props.navigation.getParam("id");
        if (appointmentId) {
          this.setState({
            id: appointmentId
          }, () => {
            this.getAppointment(appointmentId)
          })
        }
      });
    } else {
      this.redirectTo("Appointments");
    }
  }


  setInvitedUsersForAppointmentDetails = (users:UserContact[], owner_id:number, invitation_members?:{[key: string]: string}[])=>{
    this.setState({
      invitedUserList: {
        owner_id,
        invited_users: rearrangeContacts(owner_id, users),
        invitation_members: invitation_members ? invitation_members : []
      },
      activeTab:null
    })
  }

  onUpdateRsvpStatus = async (status:string,appointmentId:string | number)=>{
    HelperFunctions.showLoader();
    const body = {
      status
    }
    this.rsvpStatusUpdateApiCallId = await apiCallFunction({
      method:"PATCH",
      endPoint:`bx_block_appointment_management/appointments/${appointmentId}/add_appointment_status`,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token,
      body
    })
  }

  onRsvpUpdateModeChange = ()=>{
    this.setState(prevState=>{
      return {
        isRsvpStatusUpdateMode: !prevState.isRsvpStatusUpdateMode
      }
    })
  }

  getAppointment = async (appointmentId:string | number) => {
    HelperFunctions.showLoader();
    this.getAppointmentApiCallId = await apiCallFunction({
      method: "GET",
      endPoint: `bx_block_appointment_management/appointments/${appointmentId}`,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token
    })
  }

  redirectTo = (endpoint: string, params?:{[key:string]:string | number}) => {
    this.props.navigation.navigate(endpoint, params)
  }

  closeUserListModal = ()=>{
    this.setState({
      invitedUserList: null,
      activeTab:null
    })
  }

  setActiveTab = (currentTab:string | null)=>{
    this.setState({
      activeTab: currentTab
    })
  }

  openOptionMenu = (event:React.MouseEvent<HTMLDivElement>)=>{
    this.setState({
      optionAnchorEle: event.currentTarget
    })
  }

  closeOptionMenu = (event:MouseEvent)=>{
    const menu = document.getElementById("options-menu");
    if (menu && !menu.contains(event.target as Node)) {
      this.setState({
        optionAnchorEle: null
      })
    }
  }

  openDeleteModal = (meetingTitle:string)=>{
    this.setState({
      isDeleteModalOpen: true,
      optionAnchorEle: null,
      meetingTitle
    })
  }

  closeDeleteModal = ()=>{
    this.setState({
      isDeleteModalOpen: false,
      meetingTitle: ""
    })
  }

  onDeleteAppointment = async ()=>{
    HelperFunctions.showLoader();
    this.deleteAppointmentApiCallId = await apiCallFunction({
      method:"DELETE",
      endPoint:`bx_block_appointment_management/appointments/${this.state.appointment?.id}`,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token
    })
  }

  goBack = ()=>{
    this.props.navigation.goBack();
  }
  // Customizable Area End
}