import React, { useState } from "react";
import { styled } from "@material-ui/core";
interface TextAreaProps {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onFocus?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    name?: string;
    isInputFocused?: boolean;
    label: string;
    isFormTouched?: boolean;
    error?: { [key: string]: string }
    containerClasses?: string;
    isContainerFocused?: boolean;
    readonly?: boolean;
    maxLength?: number;
    textAreaStyles?: React.CSSProperties
    textAreaContainerStyles?: React.CSSProperties
}


const TextArea: React.FC<TextAreaProps> = (props: TextAreaProps) => {
    const {
        value,
        name,
        onChange,
        isInputFocused,
        label,
        isFormTouched,
        error,
        containerClasses,
        isContainerFocused,
        readonly,
        onFocus,
        onBlur,
        maxLength,
        textAreaStyles,
        textAreaContainerStyles
    } = props;
    const [focused, setFocused] = useState(false);
    const onFocusHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (readonly) return;
        setFocused(true)
        if (onFocus) {
            onFocus(event)
        }
    }
    const onBlurHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (readonly) return;
        setFocused(false);
        if (onBlur) {
            onBlur(event)
        }
    }

    return (
        <StyledTextArea style={textAreaContainerStyles} className={`TextArea_Component ${containerClasses} ${isContainerFocused && (focused || value) ? "focused" : ""}`}>
            <label className={focused || isInputFocused || value ? "focused" : ""}>{label}</label>
            <textarea
                name={name}
                onFocus={onFocusHandler}
                onBlur={onBlurHandler}
                onChange={onChange}
                value={value}
                readOnly={readonly}
                maxLength={maxLength}
                style={textAreaStyles}
            />
            {
                isFormTouched && error && error["required"] && (
                    <div style={{ color: '#ff3333', fontSize: '0.75rem' }}>*this field is required</div>
                )
            }
            {
                isFormTouched && error && error["message"] && (
                    <div style={{ color: '#ff3333', fontSize: '0.75rem' }}>{error.message}</div>
                )
            }
            {
                maxLength && (
                    <p className="limit">{maxLength - value.length} characters</p>
                )
            }
        </StyledTextArea>
    )
}

const StyledTextArea = styled("div")({
    "&.TextArea_Component": {
        display: "flex",
        flexDirection: "column",
        height: "7.25rem",
        border: "1px solid rgba(28, 150, 154,0.11)",
        position: "relative",
        padding: "0.8rem 0.9rem",
        borderRadius: "1rem",
        // alignItems: "center",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        transition: "all 0.3s ease-in-out",
        "&.focused": {
            marginTop: '1rem !important'
        },
        "& label": {
            fontSize: "1rem",
            top: "10%",
            position: "absolute",
            color: "white",
            transition: "transform 0.2s ease-in-out",
            "&.focused": {
                transform: 'translate(-14px,-40px)'
            }
        },
        "& textarea": {
            color: "white",
            fontSize: "1rem",
            zIndex: 1,
            fontFamily: "poppins,san-serif",
            fontWeight: 400,
            resize: "none",
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            width: "100%",
            height: "100% !important",
        },
        "& .limit":{
            fontSize: "0.625rem",
            fontWeight: 400,
            color: "rgba(255, 255, 255, 0.8)",
            textAlign: "right"
        }
    }
})

export default TextArea;