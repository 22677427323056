import React, { useState } from "react";
import { styled } from "@material-ui/styles";
interface Props {
    children?: React.ReactNode;
    totalItems: number;
}


const Carousel: React.FC<Props> = (props: Props) => {
    const { children, totalItems } = props;
    const [currentItem, setItem] = useState<number>(0);

    const onPrev = () => {
        if (currentItem > 0) {
            setItem(currentItem - 1)
        }
    }

    const onNext = () => {
        if (currentItem < totalItems - 1) {
            setItem(currentItem + 1)
        }
    }

    return (
        <StyledCarousel className="Carousel_StyledCarousel">
            {
                totalItems > 1 && (
                    <div className={`prev ${currentItem === 0 ? "disabled": ""}`} onClick={onPrev}>
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.99953 11.6553L0.345703 6.00148L5.99953 0.347656L7.05335 1.40148L2.45335 6.00148L7.05335 10.6015L5.99953 11.6553Z" fill="white" />
                        </svg>
                    </div>
                )
            }
            <div className="carousel-container">
                <div className="scroll-container" style={{ transform: `translateX(-${currentItem * 100}%)` }}>
                    {children}
                </div>
            </div>
            {
                totalItems > 1 && (
                    <div className={`next ${currentItem === totalItems-1 ? "disabled": ""}`} onClick={onNext}>
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.00047 0.344693L7.6543 5.99852L2.00047 11.6523L0.946646 10.5985L5.54665 5.99852L0.946646 1.39852L2.00047 0.344693Z" fill="white" />
                        </svg>
                    </div>
                )
            }
        </StyledCarousel>
    )
}

const StyledCarousel = styled("div")({
    "&.Carousel_StyledCarousel": {
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        "& > .carousel-container": {
            display: "flex",
            overflow: "hidden",
            width: "100%",
            "& > .scroll-container": {
                display: "flex",
                overflow: "visible",
                transition: "transform 0.3s ease-in-out",
                width: "100%",
                alignItems: "center",
                "& > *": {
                    width: "100%",
                    flexShrink: 0
                }
            }
        }
    },
    "& .next,.prev": {
        color: "white",
        cursor: "pointer",
        width: "1.5rem",
        height: "1.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        borderRadius: "100%",
        flexShrink: 0,
        "&.disabled":{
            opacity: 0.5,
            cursor: "not-allowed"
        }
    }
})

export default Carousel;