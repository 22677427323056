import React from "react";
// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import GoBack from "../../../components/src/GoBack.web";
import InputText from "../../../components/src/TextInput.web";
import TextArea from "../../../components/src/TextArea.web";
import ReminderFrequency from "./components/ReminderFrequency.web";
import ErrorMessage from "../../../components/src/ErrorMessage.web";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions")
import { styled } from "@material-ui/styles";
// Customizable Area End

import AddTaskController, {
  Props
} from "./AddTaskController";

export default class AddTask extends AddTaskController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ReminderContainer = () => {
    const { title, reminderDate, description, reminderTime, frequency, editMode } = this.state;
    return (
      <div style={ReminderContainerStyles.container}>
        <GoBack displayText={editMode ? "Edit Reminder" : "Add Reminder"} goBack={this.goBack} />
        <StyledForm autoComplete="off" className="add-reminder-form">
          <div className="form-row">
            <div className="form-col">
              <InputText
                onChange={(event) => {
                  const sanitizedValue = event.target.value.replace(/[^a-zA-Z0-9 ]/g, '').replace(/^\s+/, '');
                  this.onInputChangeHandler("title", sanitizedValue)
                }}
                value={title}
                label="Title*"
                type="text"
                isContainerFocused={true}
                maxLength={42}
              />
            </div>
            <div className="form-col">
              <InputText
                value={reminderDate ? helper.formatDate(reminderDate, "DD MMMM, dddd") : reminderDate}
                label="Select Date*"
                type="text"
                isContainerFocused={true}
                readonly={true}
                onChange={() => { }}
                onClick={this.openDatePicker}
              />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  format="DD MMMM YYYY"
                  margin="normal"
                  id="text_id"
                  value={reminderDate}
                  onChange={this.onDateChangeHandler}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                    id: "mui-date-picker"
                  }}
                  className="customDatePicker"
                  style={{ display: "none" }}
                  DialogProps={{
                    className: "datePickerDialog",
                  }}
                  PopoverProps={{
                    className: "add-appointment-date-picker"
                  }}
                  autoOk={true}
                  disablePast
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className="form-row" style={{alignItems:"flex-start"}}>
            <div className="form-col" style={{display: "flex", flexDirection: "column", justifyContent: "space-between", gap:"1rem"}}>
              <div className="sub-col">
                <InputText
                  onChange={() => { }}
                  value={reminderTime}
                  label="Select Time*"
                  type="text"
                  readonly={true}
                  isContainerFocused={true}
                  isTimePicker={true}
                  onTimeChange={(event)=> this.onInputChangeHandler("reminderTime", event)}
                  format="LT"
                />
                {
                  this.validateFormData().reminderTime && (
                    <ErrorMessage error={this.validateFormData().reminderTime} />
                  )
                }
              </div>
              <div className="sub-col">
                <ReminderFrequency onChange={(event)=> this.onInputChangeHandler("frequency", event)} selectedValue={frequency} />
              </div>
            </div>
            <div className="form-col">
              <TextArea
                onChange={(event) => this.onInputChangeHandler("description", event.target.value)}
                value={description}
                label="Description"
                isContainerFocused={true}
                name="description"
                maxLength={1024}
                textAreaContainerStyles={{height:"8rem"}}
              />
            </div>
          </div>
          <div className="form-row" style={{ alignItems: "center", justifyContent: "center" }}>
            <button type="button" className="submit-btn" disabled={this.disableSubmitButton()} onClick={this.onSubmitHandler}>
              {
                editMode ? "Update" : "Add Reminders"
              }
            </button>
          </div>
        </StyledForm>
      </div>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <NavigationMenu {...this.props} children={this.ReminderContainer()} />
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ReminderContainerStyles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    padding: "1rem 1.5rem",
  }
}
const StyledForm = styled("form")({
  "&.add-reminder-form": {
    userSelect: "none",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    padding: "1rem 3rem",
    "& > .form-row": {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
      "& > .form-col": {
        width: "49%"
      },
    },
    "& .submit-btn": {
      width: "21.4375rem",
      height: "3rem",
      background: "linear-gradient(180deg, rgba(243, 127, 120, 0.75) 0%, rgba(247, 83, 103, 0.75) 100%)",
      borderRadius: "62.5rem",
    },
    [`@media screen and (max-width: 1050px)`]: {
      padding: "1rem 0rem",
      "& > .form-row": {
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "1rem",
        "& > .form-col": {
          width: "100% !important"
        }
      }
    },
  }
})
// Customizable Area End
