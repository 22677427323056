import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");

export interface ApiPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}

export interface PhoneNumberApiData {
  flag: string;
  name: string;
  phone_code: string;
  length: Array<number>;
};

export interface PhoneNumberSuccessResponse {
  data: Array<PhoneNumberApiData>
};

export interface ValidLoginApiSuccessResponse {
  meta: {
    token: string;
  };
  message: string;
};

export interface ApiFailureResponse {
  errors: Array<ApiFailureResponseError>
};

export interface ApiFailureResponseError {
  message: string
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  phoneNumberGetData: PhoneNumberApiData[];
  phoneNumberData: string;
  phoneCodeData: string;
  phoneNumberLength: number;
  codeValue: string;
  countryCode: string;
  dropdownOpen: boolean;
  isCountryData: string;
  selectedCountry: PhoneNumberApiData | null;
  errorMessage: string;
  errorPhoneNumber: string;
  inputLabel: string;
  errorResponse: string;
  signInBtnDisabled: boolean;
  countryCodeMenuRef: null | HTMLElement;
  searchStringForCountry: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  phoneNumberLengthApiCallId: string = "";
  loginApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationOTPPayload),
      getName(MessageEnum.PhoneNumber),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      phoneNumberGetData: [],
      phoneNumberData: "",
      phoneCodeData: "+91",
      phoneNumberLength: 0,
      codeValue: '',
      countryCode: "",
      dropdownOpen: false,
      isCountryData: "+91",
      selectedCountry: {
        flag: "https://flagcdn.com/64x48/in.png",
        name: "India",
        phone_code: "+91",
        length: [
          10
        ]
      },
      errorMessage: "",
      errorPhoneNumber: "",
      inputLabel: "",
      errorResponse: "",
      signInBtnDisabled: true,
      countryCodeMenuRef: null,
      searchStringForCountry: ""
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    await this.checkToken(false);
    this.getAllPhoneNumberLength();
    // Customizable Area End
  }

  // Customizable Area Start

  checkToken = async (bool: boolean)=>{
    const token = await getStorageData('authToken');
    if(token || bool){
      const navigation = new Message(getName(MessageEnum.NavigationMessage));
      navigation.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
      navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(navigation);
    }

    const otpAuthData = await helper.getStorageData("otpAuthData", true);
    if(otpAuthData && otpAuthData.phoneNumber && otpAuthData.countryCode){
      this.setState({
        phoneNumberData: otpAuthData.phoneNumber,
        isCountryData: otpAuthData.countryCode 
      })
      await helper.removeStorageData("otpAuthData")
    }
  }

  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const payLoadData = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      if(payLoadData && payLoadData.phoneNumber && payLoadData.countryCode){
        this.setState({
          phoneNumberData: payLoadData.phoneNumber,
          isCountryData: payLoadData.countryCode 
        })
      }
      
    } 

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.errors) {
        if (responseJson.errors[0]?.message) {
          this.setState({ errorPhoneNumber: responseJson.errors[0].message  });
        }
        this.apiResponseFailureCall(apiRequestCallId, responseJson);

      }

      else if (responseJson && !responseJson.errors) {
        this.apiResponseSucessCell(apiRequestCallId, responseJson);
        this.apiResponseSucessCellLogin(apiRequestCallId, responseJson);
      }
      // } else if (responseJson && responseJson.errors) {
      //   this.apiResponseFailureCall(apiRequestCallId, responseJson);
      // }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  isStringNullOrBlank(stringVallue: string) {
    return stringVallue === null || stringVallue.length === 0;
  }

  validateField = () => {
    let validation = true;

    if (this.isStringNullOrBlank(this.state.isCountryData)) {
      this.setState({ errorPhoneNumber: "Please select the country code" })
      validation = false;
    }
    else if (!this.isStringNullOrBlank(this.state.isCountryData) && this.isStringNullOrBlank(this.state.phoneNumberData)) {
      this.setState({ errorPhoneNumber: 'Please fill the phone number' })
      validation = false;
    }
    else if (this.state.phoneNumberData.length !== this.state.selectedCountry?.length[0]) {
      this.setState({ errorPhoneNumber: 'Please Enter the correct phone number' })
      validation = false;
    }
    return validation;
  }

  handleLogin = async () => {
    let isFieldValidate = this.validateField();
    if (isFieldValidate) {
      this.setState({
        signInBtnDisabled: false
      })
      const bodyData = {
        data: {
          type: "sms_account",
          attributes: {
            full_phone_number: this.state.isCountryData + this.state.phoneNumberData
          }
        }
      };

      this.loginApiCallId = await this.apiCallFunction({
        contentType: "application/json",
        endPoint: configJSON.loginApiEndPoint,
        method: configJSON.loginAPiMethod,
        body: bodyData
      })
    }
    else {
      this.setState({
        signInBtnDisabled: true
      })
    }
  }

  handleNumberInput = (number: string) => {
    let phoneNumber = number;

    phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
    this.setState({phoneNumberData : phoneNumber });
    if(this.state.phoneNumberData.length>0){
      this.setState({signInBtnDisabled  : false , errorPhoneNumber : ""});
    }
   
  };

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  apiCallFunction = async (data: ApiPayloadType) => {
    let { method, endPoint, body, type = "", contentType } = data;
    const header = {
      token: "",
      "Content-Type": contentType,
    };
    let apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? apiRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : apiRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    return apiRequestMessage.messageId;
  };

  apiResponseSucessCell = async (
    apiRequestCallId: string,
    responseJson:
      PhoneNumberSuccessResponse) => {
    if (apiRequestCallId === this.phoneNumberLengthApiCallId) {
      this.phoneNumberLengthSucessCallBack(responseJson);
    }
  };

  apiResponseSucessCellLogin = async (
    apiRequestCallId: string,
    responseJson: ValidLoginApiSuccessResponse) => {
    if (apiRequestCallId === this.loginApiCallId) {
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(getName(MessageEnum.NavigationTargetMessage), 'OTPInputAuth');
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      await helper.setStorageData("otpAuthData", JSON.stringify({token: responseJson.meta.token, countryCode: this.state.isCountryData, phoneNumber: this.state.phoneNumberData}));
      this.send(message);
    }
  };

  apiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {
    if (apiRequestCallId === this.phoneNumberLengthApiCallId) {
      this.phoneNumberLengthFailureCallBack(responseJson);
    }
  };

  getAllPhoneNumberLength = async () => {
    this.phoneNumberLengthApiCallId = await this.apiCallFunction({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.phoneNumberApiEndPoint,
    });
  };

  phoneNumberLengthSucessCallBack = (response: PhoneNumberSuccessResponse) => {
    this.setState({ phoneNumberGetData: response.data })
  };

  phoneNumberLengthFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({ errorResponse: response.errors[0].message })
  };

  navigateToSignUp = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountRegistrationBlock");
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };


  toggleDropdown = (e: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      countryCodeMenuRef: e.currentTarget,
      dropdownOpen: true
    });
  };

  handleCountrySelect = (country: PhoneNumberApiData, code: string) => {
    this.setState({
      selectedCountry: country,
      dropdownOpen: false,
      isCountryData: code,
      countryCodeMenuRef: null,
      searchStringForCountry: ""
    });
  };

  handleFocus = () => {
    this.setState({ inputLabel: configJSON.phoneMandateField });
  };

  handleBlur = (blurEvent: React.FocusEvent<HTMLInputElement>) => {
    const { value } = blurEvent.target;
    if (value === "") {
      this.setState({ inputLabel: "" });
    }
  };
  onSearchCountry = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const value = event.target.value;
    this.setState({
      searchStringForCountry: value
    })
  }

  onCloseCountryDropdown = ()=>{
    this.setState({ countryCodeMenuRef: null, dropdownOpen: false, searchStringForCountry: "" });
  }
  // Customizable Area End  
}
