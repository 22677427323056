// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { IGroup } from "../../groups/src/types";
import { IPost } from "../../../components/src/CommonTypes";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    groupDetail: IGroup | null;
    postImages?: Array<{ selectedUrl: string, imgInvalid: boolean, type: string }>;
    uploadedFiles?: Array<File>
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    attachmentAnchorElement: HTMLImageElement | null;
    inputRef: React.RefObject<HTMLInputElement>;
    inputVideoRef: React.RefObject<HTMLInputElement>;
    inputAllRef: React.RefObject<HTMLInputElement>;
    postImages: Array<{ selectedUrl: string, imgInvalid: boolean, type: string }>
    uploadedFiles: Array<File>;
    selectedImageIndex: number;
    message: string;
    isPostUploading: boolean;
    currentUploadingBlobIds: string[];
    groupPostsList: IPost[];
    postPageNum: number;
    isAllGroupPostsFetched: boolean;
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class GroupPostCreationController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    uploadFileApiCallIds: string[] = [];
    createGroupPostApiCallId: string = "";
    getAllGroupPostsListApiId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            attachmentAnchorElement: null,
            inputRef: React.createRef<HTMLInputElement>(),
            inputVideoRef: React.createRef<HTMLInputElement>(),
            inputAllRef: React.createRef<HTMLInputElement>(),
            postImages: [],
            uploadedFiles: [],
            selectedImageIndex: 0,
            message: "",
            isPostUploading: false,
            currentUploadingBlobIds: [],
            groupPostsList: [],
            isAllGroupPostsFetched: false,
            postPageNum: 1,
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        if(this.props && this.props.postImages && this.props.uploadedFiles){
            this.setState({
                postImages: this.props.postImages,
                uploadedFiles: this.props.uploadedFiles
            })
        }
        this.getGroupPostsList()
        // Customizable Area End
    }

    async componentDidUpdate(prevProps: Readonly<Props>) {
        const { groupDetail } = prevProps;
        if(groupDetail && this.props.groupDetail && groupDetail.id !== this.props.groupDetail.id){
           this.clearImages();
           this.setState({ postPageNum: 1, groupPostsList: [] }, () => {
            this.getGroupPostsList();
           })
        }
    }

    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = message.getData(getName(MessageEnum.SessionResponseToken));
            if (!token) {
                token = await helper.getStorageData("authToken");
            }
            this.setToken(token);
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            switch(webApiRequestCallId){
                case this.createGroupPostApiCallId:
                 this.handleCreatePostApiCall(message);
                 break;
                case this.getAllGroupPostsListApiId:
                 this.handleGetGroupPostListCall(message);
                 break;
            }
      
            if(this.uploadFileApiCallIds.includes(webApiRequestCallId)){
              this.handlerUploadFileApiCall(message);
            }
          }

        // Customizable Area End
    };

    // Customizable Area Start

    setToken = async (token: string | null) => {
       this.setState({ token })
    }

    scrollToTopOnAddPost = () => {
        const topDiv = document.getElementById("top_div");
        if(topDiv){
            topDiv.scrollIntoView({
                block: "center",
                behavior: "smooth",
              })
        }
    }

    onOpenAttachmentPopup = (event: React.MouseEvent<HTMLImageElement>)=>{
        this.setState({
            attachmentAnchorElement: event.currentTarget
        })
    }

    onCloseAttachmentPopup = ()=>{
        this.setState({
            attachmentAnchorElement: null
        })
    }

    onAddImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { postImages, uploadedFiles } = this.state;
        if (event.target.files?.length) {
            const files = event.target.files;
            const keys = Object.keys(files);
            const resultImageArr: Array<{ selectedUrl: string, imgInvalid: boolean, type: string }> = [...postImages];
            const filesUploaded: Array<File> = [...uploadedFiles];
            const promises = keys?.map(async (key: any) => {
                const file = files[key];
                const result = await helper.covertFileToObjectUrl(file);
                if (!result.imgInvalid) {
                    resultImageArr.push(result);
                    filesUploaded.push(file);
                }
            })
            await Promise.allSettled(promises);
            const validImagesArr = resultImageArr.filter((item) => !item.imgInvalid).slice(0, 10);

            this.setState({
                postImages: [...validImagesArr],
                uploadedFiles: [...filesUploaded]
            })
            this.onCloseAttachmentPopup()
            event.target.value = "";
        }
    }

    removeFile = (fileIndex: number) => {
        this.setState((prevState) => {
            const { selectedImageIndex, postImages, uploadedFiles } = prevState;
            let newSelectImageIndex = selectedImageIndex;
    
            if (fileIndex < newSelectImageIndex) {
                newSelectImageIndex--;
            } else if (fileIndex === newSelectImageIndex) {
                newSelectImageIndex = newSelectImageIndex - 1 >= 0 ? newSelectImageIndex - 1 : 0; // Adjust for boundary case
            }

            this.changeSelectedImageIndex(newSelectImageIndex)
    
            return {
                postImages: postImages.filter((_, itemIndex) => itemIndex !== fileIndex),
                uploadedFiles: uploadedFiles.filter((_, itemIndex) => itemIndex !== fileIndex)
            };
        });
    }

    changeSelectedImageIndex = (fileIndex: number)=>{
        this.setState({
            selectedImageIndex: fileIndex
        })
    }

    onChangeHandler = (value: string)=>{
       const sanitizedValue = value.replace(/^\s+/, '');
       this.setState({
          message: sanitizedValue
       })
    }

    clearImages = ()=>{
        this.setState({
            postImages: [],
            uploadedFiles: [],
            selectedImageIndex: 0,
            message: ""
        })
    }

    onClickSend = async () => {
        const { uploadedFiles } = this.state;
        helper.showLoader();

        if (uploadedFiles && uploadedFiles.length) {
            uploadedFiles.forEach(async (file) => {
                const formData = new FormData();
                formData.append("media", file);

                const apiId = await helper.uploadFilesToServer({
                    body: formData,
                    type: "formData",
                    token: this.state.token
                });
                this.uploadFileApiCallIds.push(apiId)
            })
        } else {
            this.onCreatePost();
        }
    }

    handlerUploadFileApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.blob_id) {
            this.setState(prevState => {
                return {
                    currentUploadingBlobIds: [...prevState.currentUploadingBlobIds, responseJson.blob_id]
                }
            }, () => {
                const { currentUploadingBlobIds, uploadedFiles } = this.state;
                if (uploadedFiles.length === currentUploadingBlobIds.length) {
                    this.onCreatePost();
                }
            })
        }else{
            if(responseJson){
                const {
                   errors
                } = responseJson;
                if(errors && errors.length){
                    errors.forEach((error: {message: string}) => {
                        helper.showErrorToast(error.message);
                    });
                }
            }else{
                helper.showErrorToast("Something went wrong! please try again");
            }
            helper.hideLoader();
        }
    }

    onCreatePost = async () => {
        const { currentUploadingBlobIds, message } = this.state;
        const { groupDetail } = this.props;
        const body: { body?: string; blob_ids?: string[] } = {};

        if(message){
            body.body = message
        }

        if(currentUploadingBlobIds && currentUploadingBlobIds.length){
            body.blob_ids = [currentUploadingBlobIds.join(",")]
        }

        if(groupDetail){
            this.createGroupPostApiCallId = await helper.apiCall({
                method: configJSON.PostAPiMethod,
                token: this.state.token,
                endPoint: `${configJSON.groupPostApiEndpoint}?group_id=${groupDetail.id}`,
                body: body,
                contentType: configJSON.exampleApiContentType,
            });
        }
        
        this.setState({
            postImages: [],
            uploadedFiles: [],
            selectedImageIndex: 0,
            currentUploadingBlobIds: [],
            message: ""
        });
        this.uploadFileApiCallIds = []
    };

    handleCreatePostApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJson && responseJson.data){
            this.handleAddCreatedPost(responseJson.data);
        }else{
            if(responseJson){
                const {
                   errors
                } = responseJson;
                if(errors && errors.length){
                    errors.forEach((error: { message: string }) => {
                        helper.showErrorToast(error.message);
                    });
                }
            }else{
                helper.showErrorToast("Something went wrong! please try again");
            }
        }
        helper.hideLoader();
    }

    handleChangePageNumber = () => {
        if (!this.state.isAllGroupPostsFetched) {
          this.setState({ postPageNum: this.state.postPageNum + 1 }, () => this.getGroupPostsList());
        }
    };
    
    handleGetGroupPostListCall = (message: Message) => {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson?.meta?.next_page) {
          this.setState({ isAllGroupPostsFetched: true });
        }
        if (responseJson?.data) {
          this.setState({
            groupPostsList: [...this.state.groupPostsList, ...responseJson.data],
          });
        } else {
          this.setState({ groupPostsList: [] });
        }
        helper.hideLoader();
    };
    
    getGroupPostsList = async () => {
        helper.showLoader();
        const { groupDetail } = this.props;
        const token = await helper.getStorageData("authToken")
        this.getAllGroupPostsListApiId = await helper.apiCall({
          method: configJSON.validationApiMethodType,
          contentType: configJSON.validationApiContentType,
          token: token,
          endPoint: `${configJSON.groupPostApiEndpoint}?group_id=${groupDetail?.id}&per_page=10&page_no=${this.state.postPageNum}`,
        });
    };

    handleUpdateLikeGroupPost = (postId: number | string, action?: string) => {
        const updatedPostList = this.state.groupPostsList.map((item) => {
            if(item.id === postId){
                if(item.attributes.liked_by_me) {
                    item.attributes.total_likes -= 1
                } else {
                    item.attributes.total_likes += 1
                }
                item.attributes.liked_by_me = !item.attributes.liked_by_me;
            }
            return item
        })
        this.setState({ groupPostsList: updatedPostList })
    }

    handleUpdatePostCommentsCount = (postId: number | string, action?: string) => {
        const updatedPostList = this.state.groupPostsList.map((item) => {
            if(item.id === postId){
                if(action === "decrease") {
                    item.attributes.comments_count -= 1
                } else {
                    item.attributes.comments_count += 1
                }
            }
            return item
        })
        this.setState({ groupPostsList: updatedPostList })
    }

    handleShowCommentSection = (postId: number | string) => {
        const updatedPostList = this.state.groupPostsList.map((item) => {
            if(item.id === postId){
                item.attributes.show_comments_section = true
            } else { 
                item.attributes.show_comments_section = false
            }
            return item
        })
        this.setState({ groupPostsList: updatedPostList })
    }

    handleHideCommentSection = (postId: number | string) => {
        const updatedPostList = this.state.groupPostsList.map((item) => {
            if(item.id === postId){
                item.attributes.show_comments_section = false
            }
            return item
        })
        this.setState({ groupPostsList: updatedPostList })
    }
    
    handleAddCreatedPost = (postDetail: IPost) => {
        this.setState({ groupPostsList: [{ ...postDetail}, ...this.state.groupPostsList]});
        this.scrollToTopOnAddPost();
    }

    // Customizable Area End
}