// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
const {
    HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");
import { IEventCard, IEventData, IInterest, SearchLocation } from "../../../components/src/CommonTypes";
import { LatLngExpression } from 'leaflet';
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    bestEvents: IEventCard[];
    filterAnchorEle: HTMLButtonElement | null;
    recentLocations: SearchLocation[];
    predictions: SearchLocation[];
    selectedNearByLocationCoordinates: LatLngExpression | null;
    interests: IInterest[];
    currentSelectedInterest: IInterest[];
    selectedInterest: IInterest[];
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export const groupData = [
    {
        id: 1,
        title: "Graphic Coper",
        description: "Keval: You not have any builder code on your dawd dawd dawd"
    },
    {
        id: 2,
        title: "Graphic Coper",
        description: "Keval: You not have any builder code on your dawd dawd dawd"
    },
    {
        id: 3,
        title: "Graphic Coper",
        description: "Keval: You not have any builder code on your dawd dawd dawd"
    },
    {
        id: 4,
        title: "Graphic Coper",
        description: "Keval: You not have any builder code on your dawd dawd dawd"
    },
    {
        id: 5,
        title: "Graphic Coper",
        description: "Keval: You not have any builder code on your dawd dawd dawd"
    },
    {
        id: 6,
        title: "Graphic Coper",
        description: "Keval: You not have any builder code on your dawd dawd dawd"
    },
    {
        id: 7,
        title: "Graphic Coper",
        description: "Keval: You not have any builder code on your dawd dawd dawd"
    },
    {
        id: 8,
        title: "Graphic Coper",
        description: "Keval: You not have any builder code on your dawd dawd dawd"
    }
]

export const trendingPosts = [
    {
        "id": "2",
        "type": "event",
        "attributes": {
            "id": 2,
            "description": null,
            "body": "You should not have any builder code on your personal or other Git Repo. private if you did for some specific reasons...... more",
            "account_id": 9,
            "created_at": "1 minutes ago",
            "updated_at": "2024-08-29T11:28:15.497+05:30",
            "model_name": "BxBlockPosts::Post",
            "account_info": {
                "full_name": "Manas R",
                "full_phone_number": "919635765113",
                "country_code": 91,
                "phone_number": 9635765113,
                "email": "",
                "activated": true,
                "image_url": null,
                "created_at": "2024-08-29T11:27:05.995+05:30",
                "interests": [
                    {
                        "id": "8",
                        "type": "interests",
                        "attributes": {
                            "name": "dd",
                            "created_at": "2024-08-29T11:26:57.085+05:30"
                        }
                    }
                ]
            },
            "images_and_videos": [
                {
                    id: 1,
                    url: "https://wallpapers.com/images/featured/link-pictures-16mi3e7v5hxno9c4.jpg?type=image"
                },
                {
                    id: 2,
                    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvnbO0qRs8GP4j4_TmIFZYb0DDsoMdjaJWTw&s&type=image"
                }
            ],
            "total_likes": 1,
            "liked_by_me": true,
            "comments_count": 0,
            event_name: "Design Meetup"
        }
    },
    {
        "id": "3",
        "type": "group",
        "attributes": {
            "id": 2,
            "body": "awdawda",
            "account_id": 333,
            "description": null,
            "created_at": "2 minutes ago",
            "model_name": "BxBlockPosts::Post",
            "updated_at": "2024-08-29T11:28:15.497+05:30",
            "account_info": {
                "full_name": "Manas SR",
                "full_phone_number": "919635765143",
                "country_code": 91,
                "phone_number": 9635765113,
                "email": "dd",
            },
            "images_and_videos": [],
            "liked_by_me": true,
            "total_likes": 3,
            "comments_count": 0,
            event_name: "Graphic Cooper"
        }
    }
]

export default class ExploreController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getBestEventApiCallId?: string;
    recentSearchLocationApiCallId?: string;
    searchLocationApiCallId?: string;
    getPlaceCoordinatesApiId?: string;
    fetchInterestsApiCallId?: string;
    addNewInterestApiCallId?: string;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            bestEvents: [],
            filterAnchorEle: null,
            recentLocations: [],
            predictions: [],
            selectedNearByLocationCoordinates: null,
            interests: [],
            currentSelectedInterest: [],
            selectedInterest: []
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        // Customizable Area End
    }

    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = message.getData(getName(MessageEnum.SessionResponseToken));
            if (!token) {
                token = await getStorageData("authToken");
            }
            this.setToken(token);
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            switch (webApiRequestCallId) {
                case this.getBestEventApiCallId:
                    this.handleGetBestEventsApiCall(message);
                    break;
                case this.recentSearchLocationApiCallId:
                    this.handleRecentSearchLocationCall(message);
                    break;
                case this.searchLocationApiCallId:
                    this.handleSearchLocationCall(message);
                    break;
                case this.getPlaceCoordinatesApiId:
                    this.handleLocationCoordinatesApiCall(message);
                    break;
                case this.fetchInterestsApiCallId:
                    this.handleFetchInterestsAPICall(message);
                    break;
                case this.addNewInterestApiCallId:
                    this.handleAddNewInterestApiCall(message);
                    break;
            }
        }

        // Customizable Area End
    };

    // Customizable Area Start

    setToken = async (token: string | null) => {
        this.setState({ token }, () => {
            this.getBestEvents();
            this.getRecentLocations();
            this.fetchInterests();
        })
    }

    getBestEvents = async () => {
        helper.showLoader();
        this.getBestEventApiCallId = await helper.apiCall({
            method: configJSON.apiMethod,
            endPoint: `${configJSON.bestEventsAPIEndpoint}`,
            contentType: configJSON.apiContentType,
            token: this.state.token
        })
    }

    handleGetBestEventsApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.data) {
            const eventData = responseJson.data.map((event: IEventData) => {
                return {
                    image: event.attributes.image_url,
                    title: event.attributes.title,
                    id: event.id,
                    location: event.attributes.location?.city,
                    time: helper.addDateAndTime(event.attributes.date_time_event, event.attributes.start_time_event, "DD MMM, ddd hh:mm A"),
                    price: "",
                    isTrending: false,
                }
            })
            this.setState({
                bestEvents: eventData
            })
        }
        helper.hideLoader();
    }

    redirectTo = (endpoint: string, params?: { [key: string]: string | number }) => {
        this.props.navigation.navigate(endpoint, params)
    }

    openFilterPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            filterAnchorEle: event.currentTarget
        })
        this.getRecentLocations()
    }

    closeFilterPopup = () => {
        this.setState({
            filterAnchorEle: null
        })
    }

    getRecentLocations = async () => {
        this.recentSearchLocationApiCallId = await helper.apiCall({
            method: configJSON.apiMethod,
            token: this.state.token,
            endPoint: `bx_block_maps3/locations/recent_location?type=event`,
            contentType: configJSON.apiContentType
        })
    }

    handleRecentSearchLocationCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.data && responseJson.data.length) {
            const recentLocations: SearchLocation[] = [];
            responseJson.data.map((prediction: {attributes: { [key: string]: string }, id: string}) => {
                const location: SearchLocation = {
                    place_id: prediction.attributes.place_id,
                    structured_formatting: {
                        main_text: prediction.attributes.city,
                        secondary_text: `${prediction.attributes.state}, ${prediction.attributes.country}`
                    },
                    description: "",
                };
                recentLocations.push(location);
            })
            this.setState({
                recentLocations: recentLocations
            })
        } else {
            this.setState({
                recentLocations: []
            })
        }
    }

    onSearchLocation = async (searchLocationString: string) => {
        this.searchLocationApiCallId = await helper.apiCall({
            method: configJSON.apiMethod,
            token: this.state.token,
            endPoint: `bx_block_maps3/locations?query=${searchLocationString}`,
            contentType: configJSON.apiContentType
        })
    }

    handleSearchLocationCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson && !responseJson.errors && responseJson.predictions) {
            const predictions: SearchLocation[] = [];
            responseJson.predictions.map((prediction: SearchLocation) => {
                const location: SearchLocation = {
                    place_id: prediction.place_id,
                    structured_formatting: prediction.structured_formatting,
                    description: prediction.description,
                };
                predictions.push(location);
            })
            this.setState({
                predictions: predictions
            })
        } else {
            this.setState({
                predictions: []
            })
        }
    }

    handleLocationCoordinatesApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.coordinates && responseJson.coordinates.length) {
            this.setState({ selectedNearByLocationCoordinates: responseJson.coordinates })    
        }
    }

    getLocationCoordinates = async (place: string) => {
        this.getPlaceCoordinatesApiId = await helper.apiCall({
            method: configJSON.apiMethod,
            token: this.state.token,
            endPoint: `${configJSON.detectCoordinatesApiEndpoint}?address=${place}`,
            contentType: configJSON.apiContentType
        })
    }

    fetchInterests = async ()=>{
        this.fetchInterestsApiCallId = await helper.apiCall({
            method: configJSON.apiMethod,
            contentType: configJSON.apiContentType,
            token: this.state.token,
            endPoint: configJSON.interestApiEndPoint
        })
    }

    handleFetchInterestsAPICall = (message: Message) => {
        const responseData = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseData && responseData.data) {
            this.setState({
                interests: responseData.data
            })
        } else {
            this.setState({
                interests: []
            })
        }
    }

    onSubmitNewInterest = async (event: string) => {
        this.addNewInterestApiCallId = await helper.apiCall({
            contentType: configJSON.apiContentType,
            method: configJSON.postApiMethod,
            endPoint: configJSON.addNewInterestApiEndPoint,
            body: {
                name: event
            }
        })
    }

    handleAddNewInterestApiCall = (message: Message) => {
        const responseData = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseData && responseData.data) {
            const interest = responseData.data;
            interest.attributes.manual = true;
            this.setState((prevState) => {
                return {
                    currentSelectedInterest: [...prevState.currentSelectedInterest, interest]
                }
            })
            this.fetchInterests();
        }
    }

    onAddInterest = (interests: IInterest[])=>{
        this.setState({
            selectedInterest: interests
        })
    }

    // Customizable Area End
}