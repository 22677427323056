Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.deleteApiMethod = "DELETE";
exports.patchApiMethod = "PATCH";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";
exports.eventCalendarDates = "bx_block_events/events/calender_events";
exports.eventsFilterList = "bx_block_events/events";
exports.allCalendarEvents = "bx_block_calendar/calendars/show_details";
exports.reminderEndpoint = "bx_block_automaticreminders/reminders";
exports.markReminderCompleted = "bx_block_automaticreminders/reminders/mark_as_completed";
// Customizable Area End