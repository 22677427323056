import React from "react";

// Customizable Area Start
import { Box, Button, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { imgload } from "./assets";

// Customizable Area End

import SplashscreenController, { Props } from "./SplashscreenController";

const theme = createTheme({
	palette: {
		primary: {
			main: "#0000ff",
			contrastText: "#fff",
		},
	},
});

export default class Splashscreen extends SplashscreenController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}
	// Customizable Area Start
	// Customizable Area End
	render() {
		return (
			// Customizable Area Start
			// Required for all blocks

			<ThemeProvider theme={theme}>
				{/* {this.props.isOpen ?  */}
					<Box style={webStyle.outerContainer}>
					<Box style={webStyle.mainContainer}>
						<Box style={webStyle.logoBoxSize}>
							<img
								src={imgload}
								alt="image-section"
								style={webStyle.logoImage}
							/>
						</Box>
						{!navigator.onLine && 
							<Box style={webStyle.bottomContainer}>
								<div style={webStyle.errorMessageContainer}>
									<Typography style={webStyle.errorHead}>Oops!</Typography>
									<Typography style={webStyle.errorMessage}>
										No internet connection found. Check your connection or try
										again
									</Typography>
								</div>
							<div style={webStyle.buttonStyle}>
								<Button style={webStyle.button} onClick={this.handleNavigateSignin}>Try Again</Button>
							</div>
							</Box>
						}
					</Box>
				</Box> 
				{/* <></> */}
				{/* } */}
			</ThemeProvider>

			// Customizable Area End
		);
	}
}

// Customizable Area Start
const webStyle = {
	outerContainer: {
		width: "100%",
		height: "100%",
		background: "#0F2631",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden"
	},
	mainContainer: {
		display: "flex",
		flexDirection: "column" as "column",
		justifyContent: "center" as "center",
		alignItems: "center" as "center"
	},
	logoBoxSize: {
		width: "500px",
		height: "500px"
	},
	logoImage: {
		height: "100%",
		width: "100%",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		objectFit: "cover" as "cover"
	},
	bottomContainer: {
		width: "342px",
		display: "flex",
		flexDirection: "column" as "column",
		justifyContent: "center",
		alignItems: "center",
		gap: "10px"
	},
	errorMessageContainer: {
		maxWidth: "342px",
		display: "flex",
		flexDirection: "column" as "column",
		justifyContent: "center",
		textAlign: "center" as "center",
		alignItems: "center",
		gap: "4px",
		padding: "10px",
		fontFamily: "Poppins",
		color: "#FFFFFF"
	},
	errorHead: {
		fontSize: "16px",
		fontWeight: 600,
		lineHeight: "26px",
		color: "#FFFFFF"
	},
	errorMessage: {
		fontFamily: "poppins",
		fontSize: "14px",
		fontWeight: 400,
		lineHeight: "21px",
		color: "#FFFFFF",
		alignSelf: "center" as "center"
	},
	buttonStyle: {
		width: "109px",
		height: "48px",
		borderRadius: "8px",
		whiteSpace: "nowrap",
		textTransform: "capitalize" as "capitalize",
		backgroundImage: "linear-gradient( #F37F78 , #F75367 )",
		borderImageSlice: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	button: {
		width: "107px",
		height: "46px",
		fontFamily:"Poppins",
		fontSize: "16px",
		lineHeight: "24px",
		background: "#0F2631",
		color: "#F75367",
		borderRadius: "8px",
		textTransform: "capitalize" as "capitalize",
		padding: "10px 16px 10px 16px",
		
	},
};


// Customizable Area End
