// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import { IReminderItem, IReminders } from "./types";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
const { ApiCallFunction: apiCall } = require("../../appointmentmanagement/src/ApiCallFunction");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  token: string | null;
  currentDate: Date;
  remindersForDatePicker: {
    date: string;
    reminder: number;
  }[];
  isReminderDatePickerOpen: boolean;
  daysOfWeek: {
    date: string;
    day: string;
    isSelected: boolean;
    isCurrentMonth: boolean;
    fullDate: Date
  }[];
  reminders: Array<IReminders>;
  reminderItems: IReminderItem[];
  completedReminders: number;
  toDoReminders: number;
  lastLapsedFalseElement: IReminderItem | null;
  deleteReminderConfirmPopUpData: {
    header: string;
    message: string;
    type: string;
    appointmentId: string;
  } | null;
  selectedReminderDetails: IReminderItem | null;
  selectedDeleteReminderId: string | null;
  reminderPageNum: number;
  isAllRemindersFetched: boolean;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class RemindersController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getRemindersInDateRangeForDatePickerApiCallId: string = "";
  getRemindersListApiCallId: string = "";
  markReminderAsCompletedApiCallId: string = "";
  deleteReminderApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentDate: new Date(),
      isReminderDatePickerOpen: false,
      remindersForDatePicker: [],
      token: null,
      daysOfWeek: [],
      reminders: [],
      toDoReminders: 0,
      completedReminders: 0,
      lastLapsedFalseElement: null,
      reminderItems: [],
      deleteReminderConfirmPopUpData: null,
      selectedReminderDetails: null,
      reminderPageNum: 1,
      isAllRemindersFetched: false,
      selectedDeleteReminderId: null,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      if (!token) {
        token = await getStorageData("authToken")
      }
      this.setToken(token)
    }
    const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      switch (apiCallId) {
        case this.getRemindersInDateRangeForDatePickerApiCallId:
          this.setRemindersForDatePicker(message);
          break;
        case this.getRemindersListApiCallId:
          this.handleWeeklyRemindersCall(message);
          break;
        case this.markReminderAsCompletedApiCallId: 
          this.handleMarkAsCompleteCall(message);
          break;
        case this.deleteReminderApiCallId:
          this.handleDeleteReminderCall(message);
          break;
      }
    }
    // Customizable Area End
  }

  setToken = (token: string | null) => {
    this.setState({ token: token }, () => {
      this.getStartAndEndOfWeek();
    });
  }

  redirectTo = (endpoint: string, params?: { [key: string]: string | number }) => {
    this.props.navigation.navigate(endpoint, params)
  }

  formatDate = (date: Date) => {
    let currDay = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let year = date.getFullYear();
    return `${currDay}-${month}-${year}`;
  }

  isReminderDate = (date: Date) => {
    return this.state.remindersForDatePicker.some(reminder => new Date(reminder.date).toDateString() === date.toDateString() && (reminder.reminder > 0));
  };

  dayClassName = (date: Date) => {
    return this.isReminderDate(date) ? 'reminder-date' : '';
  };

  IsEqualDate = (dateValue1: Date, dateValue2: Date) => {
    return (dateValue1.getDate() === dateValue2.getDate() && dateValue1.getMonth() === dateValue2.getMonth() && dateValue1.getFullYear() === dateValue2.getFullYear())
  }

  isPastDate = (dateValue: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return dateValue < today;
  }

  goToSelectedDate = (selectedDate: Date | null) => {
    if (selectedDate) {
      this.setState({
        currentDate: selectedDate,
        reminderPageNum: 1,
        isAllRemindersFetched: false,
      })
    }
  }

  handleChangePageNumber = () => {
    if(!this.state.isAllRemindersFetched){
      this.setState({ reminderPageNum: this.state.reminderPageNum + 1}, () => this.getWeeklyRemindersList(this.state.currentDate))
    }
  } 

  handleGoToNextWeek = () => {
    const { daysOfWeek } = this.state;
    const lastWeekDate = daysOfWeek[daysOfWeek.length - 1];
    const selectedDate = moment(lastWeekDate.fullDate).add(1, "day").toDate();
    this.setState({ currentDate: selectedDate, reminderPageNum: 1, isAllRemindersFetched: false }, () => {
      this.getStartAndEndOfWeek(selectedDate)
    })
  }

  handleGoToPreviousWeek = () => {
    const { daysOfWeek } = this.state;
    const firstWeekDate = daysOfWeek[0];
    const selectedDate = moment(firstWeekDate.fullDate).subtract(7, "days").toDate();
    this.setState({ reminderPageNum: 1, currentDate: selectedDate, isAllRemindersFetched: false }, () => {
      this.getStartAndEndOfWeek(selectedDate)
    })
  }

  handleOnClickDateFromWeekDay = (selectedDate: Date) => {
    const updatedWeekdays = this.state.daysOfWeek.map((item) => {
      if (this.IsEqualDate(item.fullDate, selectedDate)) {
        item.isSelected = true;
      } else{
        item.isSelected = false;
      }
      return item;
    })
    this.setState({
      currentDate: selectedDate,
      daysOfWeek: updatedWeekdays,
      reminderPageNum: 1,
      isAllRemindersFetched: false,
    }, () => {
      const weekEnd = moment(selectedDate).clone().endOf('week');
      this.getWeeklyRemindersList(selectedDate, weekEnd.toDate())
    })
  }

  onDatePickerChange = (currentDate: Date) => {
    const { startDate, endDate } = this.getStartAndEndOfMonth(currentDate);
    this.getRemindersForDatePicker(startDate, endDate)
  }

  onDateSelectFromDatePicker = (selectedDate: Date) => {
    this.setState({
      isReminderDatePickerOpen: false
    })
    this.getStartAndEndOfWeek(selectedDate);
  }

  getStartAndEndOfMonth = (date: Date) => {
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return { startDate, endDate };
  };

  getStartAndEndOfWeek = (date?: Date) => {
    const selectedDate = date ? date : new Date();
    const weekStart = moment(selectedDate).clone().startOf('week')
    const weekEnd = moment(selectedDate).clone().endOf('week');
    const weekDays: { date: string, day: string, isSelected: boolean, isCurrentMonth: boolean, fullDate: Date }[] = [];

    for (let i = 0; i <= 6; i++) {
      const formattedDate = moment(weekStart).add(i, 'days').toDate();
      const day = moment(weekStart).add(i, 'days').format("DD ,dddd");
      const dayDate = day.split(",");
      weekDays.push({
        date: dayDate[0].trim(),
        day: dayDate[1].trim(),
        isSelected: this.IsEqualDate(selectedDate, formattedDate),
        isCurrentMonth: moment(formattedDate).isSame(new Date(), 'month'),
        fullDate: formattedDate,
      });
    }
    this.setState({ daysOfWeek: weekDays }, () => {
      this.getWeeklyRemindersList(selectedDate, weekEnd.toDate())
    });
  };

  toggleDatePicker = () => {
    this.setState((prevState) => {
      return {
        isReminderDatePickerOpen: !prevState.isReminderDatePickerOpen
      }
    })
  }

  clickedOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    const datePicker = document.getElementById("date-picker");

    if (datePicker && !datePicker.contains(event.target as Node)) {
      this.setState({
        isReminderDatePickerOpen: false
      })
    }
  }

  isAnyReminderSelected = () => {
      return this.state.reminders.some(reminder => reminder.reminders.some((item) => item.attributes.isSelected));
  }

  isReminderCardDisabled = (reminderItem: IReminderItem) => {
    return reminderItem.attributes.reminder_status === "completed"
  }

  getPositionOfCard = (reminderItem: IReminderItem) => {
    const { lastLapsedFalseElement } = this.state;
    if (reminderItem.id === lastLapsedFalseElement?.id) {
      return "relative"
    }
    return "unset"
  }

  handleOpenReminderDetails = (reminder: IReminderItem) => {
    this.setState({
      selectedReminderDetails: reminder
    })
  }

  onCloseReminderDetailModal = () => {
    this.setState({
      selectedReminderDetails: null
    })
  }

  handleUnselectAllReminders = () => {
    const { reminders } = this.state;
    const reminderList = reminders.map((reminder) => {
      const reminderItems = reminder.reminders.map((item) => {
        item.attributes.isSelected = false;
        return item;
      })
      reminder.reminders = [ ...reminderItems];
      return reminder;
    });
    this.setState({ reminders: reminderList })
  }

  handleSelectAllReminders = () => {
    const { reminders } = this.state;
    const reminderList = reminders.map((reminder) => {
      const reminderItems = reminder.reminders.map((item) => {
        if (item.attributes.reminder_status !== "completed") {
          item.attributes.isSelected = true;
        }
        return item;
      })
      reminder.reminders = [...reminderItems];
      return reminder;
    });
    this.setState({ reminders: reminderList })
  }

  handleGetNumberOfSelectedReminders = () => {
    let count = 0;
    const  { reminders } = this.state;
    reminders.forEach((reminder) => {
      reminder.reminders.forEach((item) => {
        if(item.attributes?.isSelected) {
          count += 1;
        }
        return item;
      })
      return reminder;
    });
    return count;
  }

  handleSelectReminder = (reminderId: string) => {
    const { reminders } = this.state;
    const reminderList = reminders.map((reminder) => {
      const reminderItems = reminder.reminders.map((item) => {
        if(item.id === reminderId) {
          item.attributes.isSelected = !item.attributes.isSelected;
        }
        return item;
      })
      reminder.reminders = [ ...reminderItems];
      return reminder;
    });
    this.setState({ reminders: reminderList })
  }

  handleSetLastLapsedReminderId = (reminders: IReminderItem[]) => {
    const lastElement = reminders.find((item: IReminderItem) => !item.attributes.lapsed_status);
    this.setState({ lastLapsedFalseElement: lastElement || null });
  }

  handleSetReminderHeaderDetails = (reminders: IReminderItem[]) => {
    let toDoList = 0, completedReminders = 0;
    reminders.forEach((reminderItem) => {
        if(reminderItem.attributes.reminder_status === "completed") {
          completedReminders += 1;
        } else if(reminderItem.attributes.reminder_status === "pending"){
          toDoList += 1;
        }
    })
    this.setState({ toDoReminders: toDoList, completedReminders: completedReminders })
  }

  setRemindersForDatePicker = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.days?.length > 0) {
      this.setState({
        remindersForDatePicker: responseJson.days
      })
    } else {
      this.setState({
        remindersForDatePicker: []
      })
    }
    helper.hideLoader();
  }

  handleWeeklyRemindersCall = (message:Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.meta?.next_page) {
      this.setState({ isAllRemindersFetched: true })
    }
    if (responseJson?.reminders?.length) {
      const { reminderPageNum } = this.state;
      let remindersList: IReminderItem[] = [];
      let reminders : IReminders[] = [];
      if(reminderPageNum !== 1) {
        reminders = [ ...this.state.reminders]
      }
      this.setState({
        reminders: [ ...reminders, ...responseJson.reminders],
      }, () => {
        this.state.reminders.forEach((item) => {
          remindersList = [ ...remindersList, ...item.reminders];
        })
        this.handleSetLastLapsedReminderId(remindersList)
      });
      this.handleSetReminderHeaderDetails(remindersList)
    } else {
      this.setState({ reminders: [], toDoReminders: 0, completedReminders: 0 });
    }
    helper.hideLoader();
  }

  handleMarkAsCompleteCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors) {
      let count = 0;
       const updatedReminders = this.state.reminders.map((reminder) => {
        reminder.reminders.forEach((item) => {
          if(item.attributes.isSelected) {
            item.attributes.reminder_status = "completed";
            item.attributes.isSelected = false;
            count += 1;
          }
          return item;
        })
        return reminder;
       })
       this.setState({ reminders: updatedReminders, completedReminders: this.state.completedReminders + count, toDoReminders: this.state.toDoReminders - count })
       helper.showSuccessToast("Reminder(s) marked as completed!")
    } else {
      const toastMsg = responseJson?.errors?.[0]?.message ? responseJson.errors[0].message : "Something went wrong, please try again!";
      helper.showErrorToast(toastMsg);
    }
    helper.hideLoader();
  }

  handleDeleteReminderCall = (message:Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (responseJson && !responseJson.errors) {
      const updatedReminders = this.state.reminders.map((reminder) => {
        reminder.reminders = reminder.reminders.filter((item) => item.id !== this.state.selectedDeleteReminderId)
        return reminder
      })
      this.setState({ reminders: updatedReminders, selectedDeleteReminderId: null, completedReminders: this.state.completedReminders - 1, toDoReminders: this.state.toDoReminders - 1 })
      helper.showSuccessToast("Reminder deleted successfully!");
    } else {
      const toastMsg = responseJson?.errors?.[0]?.message ? responseJson.errors[0].message : "Something went wrong, please try again!";
      helper.showErrorToast(toastMsg);
    }
    helper.hideLoader();
  }

  openReminderDeleteModal = (title: string, reminderId?: string | number) => {
    this.setState({
      deleteReminderConfirmPopUpData: {
        header: "Delete Reminder?",
        message: `Are you sure that you want to delete ${title}?`,
        type: "delete",
        appointmentId: reminderId as string
      }
    })
  }

  getRemindersForDatePicker = async (start_date: Date, end_date: Date) => {
    const reminderStartDateInString = this.formatDate(start_date);
    const reminderEndDateInString = this.formatDate(end_date);
    const { token } = this.state;
    const messageId = await apiCall({
      method: "GET",
      endPoint: `${configJSON.calendarRemindersEndpoint}?start_date=${reminderStartDateInString}&end_date=${reminderEndDateInString}`,
      contentType: configJSON.exampleApiContentType,
      token
    })
    this.getRemindersInDateRangeForDatePickerApiCallId = messageId;
  }

  getWeeklyRemindersList = async(start_date: Date, end_date?: Date) => {
    const weekStartDate = this.formatDate(start_date);
    helper.showLoader();
    const { token } = this.state;
    this.getRemindersListApiCallId = await apiCall({
      method: "GET",
      endPoint: `${configJSON.filterRemindersEndpoint}?start_date=${weekStartDate}&page_no=${this.state.reminderPageNum}&per_page=15`,
      contentType: configJSON.exampleApiContentType,
      token
    })
  }

  markRemindersAsComplete = async(reminderId?: string) => {
    const { reminders, token } = this.state;
    helper.showLoader();
    let selectedIds: Array<number | string> = [];
    if(!reminderId){
      reminders.forEach((reminder) => {
        reminder.reminders.forEach((item) => {
          if(item.attributes.isSelected){
            selectedIds.push(item.id as number)
          }
        })
      })
    } else {
      const selectedReminderList = reminders.map((reminder) => {
        const selectedReminderItems = reminder.reminders.map((item) => {
          if(item.id === reminderId) {
            item.attributes.isSelected = !item.attributes.isSelected;
          }
          return item;
        })
        reminder.reminders = [ ...selectedReminderItems];
        return reminder;
      });
      selectedIds.push(reminderId);
      this.setState({
        reminders: selectedReminderList,
        selectedReminderDetails: null,
      })
    }
    const body = {
      ids: selectedIds
    }
    this.markReminderAsCompletedApiCallId = await apiCall({
      method: configJSON.patchApiMethod,
      endPoint: configJSON.markReminderCompleted,
      contentType: configJSON.apiContentType,
      token,
      body
    })
  }

  onCloseDeleteConfirmationModal = () => {
    this.setState({
      deleteReminderConfirmPopUpData: null,
    })
  }

  onAcceptDeleteConfirmation = async () => {
    if (this.state.deleteReminderConfirmPopUpData) {
      const { type, appointmentId } = this.state.deleteReminderConfirmPopUpData;
      helper.showLoader();
      if (type === "delete") {
        this.setState({
          selectedDeleteReminderId: appointmentId,
          deleteReminderConfirmPopUpData: null,
          selectedReminderDetails: null,
        }, async() => {
          this.deleteReminderApiCallId = await apiCall({
            method: configJSON.deleteApiMethod,
            endPoint:`${configJSON.remindersEndpoint}/${appointmentId}`,
            contentType: configJSON.apiContentType,
            token: this.state.token
          })
        })
      }
    }
  }

  // Customizable Area End
}
