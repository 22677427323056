// App.js - WEB
import React, { Component } from 'react';
import "./firebase";
import { View } from 'react-native';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ActivityFeed from "../../blocks/activityfeed/src/ActivityFeed";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Share from "../../blocks/share/src/Share";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Avatar from "../../blocks/avatar/src/Avatar";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Gamification from "../../blocks/educationalgamification/src/Gamification";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Sorting from "../../blocks/sorting/src/Sorting";
import Videos from "../../blocks/videos/src/Videos";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import ContactList from "../../blocks/contactlist/src/ContactList";
import Upvotedownvote from "../../blocks/upvotedownvote/src/Upvotedownvote";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import Splitpayments2 from "../../blocks/splitpayments2/src/Splitpayments2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Interactivefaqs from "../../blocks/interactivefaqs/src/Interactivefaqs";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen.web";
import EventRegistration from "../../blocks/eventregistration/src/EventRegistration";
import Emojis from "../../blocks/emojis/src/Emojis";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Download from "../../blocks/download/src/Download";
import DownloadUpload from "../../blocks/download/src/DownloadUpload";
import CreateComment from "../../blocks/comments/src/CreateComment";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountRegistrationBlock from "../../blocks/email-account-registration/src/EmailAccountRegistrationBlock.web";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Location from "../../blocks/location/src/Location";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import Trending from "../../blocks/trending/src/Trending";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Maps from "../../blocks/maps/src/Maps";
import Geofence from "../../blocks/geofence/src/Geofence";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth.web";
import AppointmentDetails from "../../blocks/appointmentmanagement/src/AppointmentDetails.web";
import Events from "../../blocks/events/src/Events.web";
import AddEventDetail from "../../blocks/events/src/AddEventDetail.web";
import UpcomingEventsList from "../../blocks/events/src/UpcomingEventsList.web";
import BestEventsList from "../../blocks/trending/src/BestEventsList.web";
import AllEventDetail from "../../blocks/events/src/AllEventDetail.web";
import Reminders from "../../blocks/tasks/src/Reminders.web";
import AddReminder from "../../blocks/tasks/src/AddTask.web";
import CustomLoader from '../../components/src/CustomLoader.web';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import UpcomingAppointments from "../../blocks/dashboard/src/UpcomingAppointments.web";
import DashboardEvents from "../../blocks/dashboard/src/Events.web";
import AddGroup from "../../blocks/groups/src/AddGroup.web";
import ContactUsNew from "../../blocks/contactus/src/ContactUsNew.web";
import Groups from "../../blocks/groups/src/Groups.web";
import AllPostsList from "../../blocks/activityfeed/src/AllPostsList.web";
import Explore from "../../blocks/activityfeed/src/Explore.web";
import UserProfile from "../../blocks/user-profile-basic/src/UserProfile.web";



const routeMap = {
  UserProfile: {
    component: UserProfile,
    path: "/profile",
    exact: true
  },
  Groups: {
    component: Groups,
    path: "/groups",
    exact: true
  },
  AddGroup: {
    component: AddGroup,
    path: "/groups/add-group"
  },
  AccountGroups: {
    component: AccountGroups,
    path: "/AccountGroups"
  },
  StripePayments: {
    component: StripePayments,
    path: "/StripePayments"
  },
  ActivityFeed: {
    component: ActivityFeed,
    path: "/ActivityFeed"
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: "/SocialMediaAccountLogin"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration"
  },
  Share: {
    component: Share,
    path: "/Share"
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: "/Uploadmedia3"
  },
  Avatar: {
    component: Avatar,
    path: "/Avatar"
  },
  PhotoLibrary: {
    component: PhotoLibrary,
    path: "/PhotoLibrary"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Gamification: {
    component: Gamification,
    path: "/Gamification"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  Videos: {
    component: Videos,
    path: "/Videos"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  ContactList: {
    component: ContactList,
    path: "/ContactList"
  },
  Upvotedownvote: {
    component: Upvotedownvote,
    path: "/Upvotedownvote"
  },
  ReviewApprovalAdmin: {
    component: ReviewApprovalAdmin,
    path: "/ReviewApprovalAdmin"
  },
  ReviewApprovalBasicUser: {
    component: ReviewApprovalBasicUser,
    path: "/ReviewApprovalBasicUser"
  },
  Splitpayments2: {
    component: Splitpayments2,
    path: "/Splitpayments2"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  Interactivefaqs: {
    component: Interactivefaqs,
    path: "/Interactivefaqs"
  },
  AddInteractivefaqs: {
    component: AddInteractivefaqs,
    path: "/AddInteractivefaqs"
  },
  // Splashscreen:{
  //  component:Splashscreen,
  // path:"/Splashscreen"},
  EventRegistration: {
    component: EventRegistration,
    path: "/EventRegistration"
  },
  Emojis: {
    component: Emojis,
    path: "/Emojis"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Download: {
    component: Download,
    path: "/Download"
  },
  DownloadUpload: {
    component: DownloadUpload,
    path: "/DownloadUpload"
  },
  Comment: {
    component: Comment,
    path: "/Comment"
  },
  CreateComment: {
    component: CreateComment,
    path: "/CreateComment"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  ContactUsNew: {
    component: ContactUsNew,
    path: "/Contact-Us"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  Paymentadmin2: {
    component: Paymentadmin2,
    path: "/Paymentadmin2"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Appointments: {
    component: Appointments,
    path: "/appointments",
    exact: true
  },
  AddAppointment: {
    component: AddAppointment,
    path: "/appointments/add-appointment"
  },
  EditAppointment: {
    component: AddAppointment,
    path: "/appointments/edit-appointment/:id"
  },
  AppointmentDetails: {
    component: AppointmentDetails,
    path: "/appointments/details/:id"
  },
  UpcomingEvents: {
    component: UpcomingEventsList,
    path: "/events/upcoming-events"
  },
  BestEvents: {
    component: BestEventsList,
    path: "/events/best-events"
  },
  EventDetail: {
    component: AllEventDetail,
    path: "/events/details/:eventId"
  },
  Events: {
    component: Events,
    path: "/events",
    exact: true
  },
  AddEvent: {
    component: AddEventDetail,
    path: "/events/add-event"
  },
  EditEvent: {
    component: AddEventDetail,
    path: "/events/edit/:id"
  },
  AddReminder: {
    component: AddReminder,
    path: "/reminders/add-reminder"
  },
  Reminders: {
    component: Reminders,
    path: "/reminders",
    exact: true
  },
  EditReminder: {
    component: AddReminder,
    path: "/reminders/edit-reminder/:id"
  },
  AllPosts: {
    component: AllPostsList,
    path: "/all-posts/:type/:id"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: "/ShoppingCartOrders"
  },
  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: "/AddShoppingCartOrderItem"
  },
  Location: {
    component: Location,
    path: "/Location"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  EmailAccountRegistrationBlock: {
    component: EmailAccountRegistrationBlock,
    path: "/EmailAccountRegistrationBlock"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  Invitefriends: {
    component: Invitefriends,
    path: "/Invitefriends"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  Tasks: {
    component: Tasks,
    path: "/Tasks"
  },
  TaskList: {
    component: TaskList,
    path: "/TaskList"
  },
  Task: {
    component: Task,
    path: "/Task"
  },
  PostCreation: {
    component: PostCreation,
    path: "/PostCreation"
  },
  Posts: {
    component: Posts,
    path: "/Posts"
  },
  PostDetails: {
    component: PostDetails,
    path: "/PostDetails"
  },
  CarouselDisplay: {
    component: CarouselDisplay,
    path: "/CarouselDisplay"
  },
  Trending: {
    component: Trending,
    path: "/Trending"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  Maps: {
    component: Maps,
    path: "/Maps"
  },
  Geofence: {
    component: Geofence,
    path: "/Geofence"
  },
  Chat: {
    component: Chat,
    path: "/Chat"
  },
  ViewChat: {
    component: ViewChat,
    path: "/ViewChat"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Schedule/:type"
  },
  OrderManagement: {
    component: OrderManagement,
    path: "/OrderManagement"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Dashboard: {
    component: Dashboard,
    path: '/dashboard',
    exact: true
  },
  DashboardUpcomingAppointments: {
    component: UpcomingAppointments,
    path: '/dashboard/upcoming-appointments',
    exact: true
  },

  DashboardEvents: {
    component: DashboardEvents,
    path: '/dashboard/events',
    exact: true
  },
  Explore: {
    component: Explore,
    path: '/explore',
    exact: true
  },
  Home: {
    component: Splashscreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};


class App extends Component {
  render() {
    return (
      <BuilderProvider>
        <View style={{ height: '100%', width: '100%', position: "relative" }}>
          {/* <TopNav /> */}
          <CustomLoader />
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
          <ToastContainer position='top-center' />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;