// Customizable Area Start
import React from "react";
import {
    Box,
    Grid,
    Typography,
    styled
} from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import UpcomingEventsListController, {
    Props
} from "./UpcomingEventsListController";
import UpcomingEventCard from "./components/UpcomingEventCard.web";
import CustomNoDataComponent from "../../../components/src/CustomNoDataComponent.web";
import { PlusIcon } from "./assets";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
// Customizable Area End

export default class UpcomingEventsList extends UpcomingEventsListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    UpcomingEventsList = () => {
        const { upcominEventsList, isRsvpStatusUpdateMode } = this.state;
        return (
            <StyledUpcomingEventsContainer>
                <Grid container direction="row">
                    <Grid item xs={12} sm={6}>
                        <div data-test-id="upcoming-events-list-back-btn" className="back-btn" onClick={() => this.redirectTo("Events")}>
                            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.99953 11.6553L0.345703 6.00148L5.99953 0.347656L7.05335 1.40148L2.45335 6.00148L7.05335 10.6015L5.99953 11.6553Z" fill="white" />
                            </svg>
                            Upcoming Events
                        </div>
                    </Grid>
                    <Grid className="add-btn-grid" item xs={12} sm={6}>
                        <button data-test-id="add-event-btn" className="add-btn" onClick={() => this.redirectTo("AddEvent")}>
                            <img src={PlusIcon} alt="" />
                            <p>Add Event</p>
                        </button>
                    </Grid>
                </Grid>
                <Grid className="upcoming-events-list-container">
                    {upcominEventsList.length > 0 ?
                        upcominEventsList.map((upcomingEvent) =>
                            <Grid key={upcomingEvent.id} className="upcoming-event-card" item>
                                <Box mb={"10px"}>
                                    <Typography className="upcoming-event-title ellipsis" component={"p"}>
                                        {helper.formatDate(upcomingEvent.attributes.date_time_event, 'D MMMM / dddd')}
                                    </Typography>
                                </Box>
                                <UpcomingEventCard 
                                event={upcomingEvent} 
                                onEventUpdateRsvpStatus={this.onUpdateEventRsvpStatus}
                                onTitleClick={() =>  this.redirectTo("EventDetail", {eventId: upcomingEvent.id})}
                                />
                            </Grid>
                        )
                        : <CustomNoDataComponent titleMsg="No Upcoming Events" subTitleMsg="No scheduled events. Stay tuned for the updates." />
                    }
                </Grid>
            </StyledUpcomingEventsContainer>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <NavigationMenu {...this.props} children={this.UpcomingEventsList()} />
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledUpcomingEventsContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    padding: "1rem 1rem",
    gap: "1.5rem",
    "& .ellipsis": {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    "& .back-btn": {
        fontSize: "1.125rem",
        fontWeight: 500,
        color: "white",
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
        cursor: "pointer",
        width: "fit-content"
    },
    "& .upcoming-event-title": {
        fontSize: "16px",
        color: "#FFFFFF",
    },
    "& .add-btn-grid":{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    "& .add-btn": {
        background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        height: "3rem",
        padding: "0.75rem",
        border: "1px solid #F75367",
        gap: "0.25rem",
        borderRadius: "62.5rem",
        "& p": {
            color: "rgba(255, 255, 255, 1)",
            fontSize: "1rem",
            fontWeight: 500
        }
    },
    "& .upcoming-events-list-container":{
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        rowGap: "1.5rem",
        columnGap: "1.5rem",
        [`@media screen and (max-width: 1555px)`]: {
            gridTemplateColumns: "repeat(3, 1fr)",
        },
        [`@media screen and (max-width: 1230px)`]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        [`@media screen and (max-width: 780px)`]: {
            gridTemplateColumns: "repeat(1, 1fr)",
        },
    }
})
// Customizable Area End
