import React from "react";
import { styled } from "@material-ui/core";
import { UserContact } from "../types";
const { getUsers } = require("../../../appointmentmanagement/src/ApiCallFunction");
import ContactList from "../../../appointmentmanagement/src/components/ContactList.web";
interface GuestListProps {
    contact_list: UserContact[];
    owner_id: number;
    isUpcomingEvent?: boolean;
    setInvitedUsers: (list:UserContact[], owner_id: number, invitation_members?:{[key:string]: string}[])=> void;
    invited_users?: {[key:string]: string}[]
}


const GuestList: React.FC<GuestListProps> = (props: GuestListProps) => {
    const { contact_list, owner_id, isUpcomingEvent = false, setInvitedUsers, invited_users } = props;
    
    return (
        <StyledGuestList className="Event_StyledGuestList_Web">
            <div className="guest_header">
                {
                    invited_users ? contact_list.length + invited_users.length : contact_list.length
                }
                {" "}
                Guests
            </div>
            <div className={!isUpcomingEvent ? "guest_list" : "upcoming-evt-guest-list"}>
                <div style={{
                    gap: !isUpcomingEvent ? "4.5rem" : "1.5rem"
                }} className="guest_details">
                    <div className="guest_no-of-people">
                        <p>{getUsers("accepted", contact_list)}</p>
                        <p>Going</p>
                    </div>
                    <div className="guest_no-of-people">
                        <p>
                            {
                                invited_users ? getUsers(null, contact_list) + invited_users.length : getUsers(null, contact_list)
                            }
                        </p>
                        <p>Awaited</p>
                    </div>
                    <div className="guest_no-of-people">
                        <p>{getUsers("rejected", contact_list)}</p>
                        <p>Not Going</p>
                    </div>
                </div>
                <div className="guest_contact-list">
                    {!isUpcomingEvent && <div className="guest_divider"></div>}
                    <ContactList invited_users={invited_users} list={contact_list} owner_id={owner_id} setInvitedUsers={() => setInvitedUsers(contact_list, owner_id, invited_users)} />
                </div>
            </div>
        </StyledGuestList>
    )
}

const StyledGuestList = styled("div")({
    "&.Event_StyledGuestList_Web": {
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        "& .guest_header": {
            fontSize: "0.875rem",
            color: "#FFFFFFCC",
            fontWeight: 500
        },
        "& .guest_details": {
            display: "flex",
            "& .guest_no-of-people": {
                display: "flex",
                flexDirection: "column",
                textWrap: "no-wrap",
                "& p": {
                    margin: 0,
                    textWrap: "no-wrap",
                    "&:first-child": {
                        fontSize: "1rem",
                        color: "white"
                    },
                    "&:last-child": {
                        color: "#FFFFFFCC",
                        fontSize: "0.75rem"
                    }
                }
            },
            [`@media screen and (max-width: 960px)`]: {
                flexWrap: "wrap",
                gap: "2rem"
            },
        },
        "& .guest_contact-list": {
            display: "flex",
            gap: "2.25rem",
            alignItems: "center",
            "& .guest_divider": {
                height: "100%",
                width: "1px",
                backgroundColor: "#FFFFFF73",
                opacity: 0.1
            },
        },
        "& .guest_list": {
            display: "flex",
            gap: "2.25rem"
        },
        "& .upcoming-evt-guest-list": {
            display: "flex",
            flexDirection: "column",
            gap: "1rem"
        }
    }
})

export default GuestList;