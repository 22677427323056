// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
import { IComment } from "../../../components/src/CommonTypes";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  commentsList?: IComment[];
  isCommentsLoading?: boolean;
  userId: number | undefined;
  handleFetchNextPageComments?: () => void;
  onDeleteComment?: (commentId: string | number) => void;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  token: string | null;
  optionMenuAnchor: HTMLElement | null;
  selectedCommentItem: IComment | null;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CommentsListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      optionMenuAnchor: null,
      selectedCommentItem: null,
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };


  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      if (!token) {
        token = await getStorageData("authToken");
      }
      this.setToken(token);
    }

    // Customizable Area End
  };

  setToken = async (token: string | null) => {
    this.setState({ token });
  };

  closeCommentListOptionMenu = () => {
    this.setState({ optionMenuAnchor: null, selectedCommentItem: null })
  }

  openCommentListOptionMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ optionMenuAnchor: event.currentTarget })
  }

  // Customizable Area End
}
