// Customizable Area Start
import React from "react";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import ContactUsNewController, {
    Props
} from "./ContactUsNewController";
import { Box, Grid, Typography, styled } from "@material-ui/core";
// Customizable Area End

export default class ContactUsNew extends ContactUsNewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { contactEmail, contactName, issueDescription, contactEmailError, currentFocusedInputTextField } = this.state;

        const ErrorMessage = (error: { required?: boolean, message?: string }) => {
            return (
                <div data-test-id="error-message" style={{ color: '#ff3333', fontSize: '0.75rem', width: "85%" }}>{error.message}</div>
            )
        }

        const NameField = () => {
            return (
                <Box display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                    <InputTextField>
                        <label data-test-id="nameLabel" className={currentFocusedInputTextField === 1 || contactName ? "focused" : ""}>Name</label>
                        <input
                            data-test-id="contact-name"
                            name="contactName"
                            onFocus={() => this.onFocusChange(1)}
                            onBlur={() => this.onFocusChange(undefined)}
                            onChange={(event) => {
                                const sanitizedValue = event.target.value.replace(/[^a-zA-Z0-9 ]/g, '').replace(/^\s+/, '');
                                this.onChangeHandler("contactName", sanitizedValue)
                            }}
                            value={contactName}
                            maxLength={42}
                        />
                    </InputTextField>
                </Box>
            )
        }

        const ContactEmailField = () => {
            return (
                <Box flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                    <InputTextField>
                        <label className={currentFocusedInputTextField === 2 || contactEmail ? "focused" : ""}>Email Id</label>
                        <input
                            name="contactEmail"
                            data-test-id="email-field"
                            onFocus={() => this.onFocusChange(2)}
                            onBlur={this.handleCheckEmailIsValid}
                            onChange={this.onChangeContactEmailHandler}
                            value={contactEmail}
                            style={{ flex: 1 }}
                        />
                    </InputTextField>
                    {
                        contactEmailError && (
                            ErrorMessage({ message: contactEmailError })
                        )
                    }
                </Box>
            )
        }

        const DescriptionField = () => {
            return (
                <Box mt={"20px"}>
                    <DescTextField className={currentFocusedInputTextField === 3 || issueDescription ? "focused" : ""}>
                        <label className={currentFocusedInputTextField === 3 || issueDescription ? "focused" : ""}>Query</label>
                        <textarea
                            name="issueDescription"
                            id="issue-description"
                            value={issueDescription}
                            onFocus={() => this.onFocusChange(3)}
                            onBlur={() => this.onFocusChange(undefined)}
                            maxLength={2048}
                            onChange={(event) => {
                                const sanitizedValue = event.target.value.replace(/^\s+/, '');
                                this.onChangeHandler("issueDescription", sanitizedValue)
                            }}
                        />
                        <Typography id="no-of-characters" component={"span"}>{this.getNumberOfCharactersRemaining()} characters</Typography>
                    </DescTextField>
                </Box>
            )
        }

        const ContactUs = () => {
            return (
                <StyledContactUsContainer>
                    <Box>
                        <Typography className="contact-us-header" component={"p"}>Contact Us</Typography>
                    </Box>
                    <Grid spacing={3} className="contact-us-main-grid" container direction="row">
                        <Grid item xs={12} md={5}>
                            <Typography id="assist-header" component={"p"}>We are here to assist you.</Typography>
                            <Typography component={"p"}>Whether you have a question, feedback, or need support, feel free to reach out to us using the contact form</Typography>
                            <Typography component={"p"}>Our team strives to respond to inquiries promptly and provide the assistance you need.</Typography>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <form autoComplete="off">
                                <Grid spacing={2} container direction="row">
                                    <Grid item id="name-field" xs={12} sm={12} md={6}>
                                        {NameField()}
                                    </Grid>
                                    <Grid item id="email-field" xs={12} sm={12} md={6}>
                                        {ContactEmailField()}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {DescriptionField()}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box mt={"20px"} className="row" display="flex" justifyContent="center" alignItems="center">
                                            <button disabled={!!this.isSubmitBtnDisabled()} type="button" className="contact-submit-btn" onClick={this.submitContactInfo}>
                                                Submit
                                            </button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </StyledContactUsContainer>
            )
        }

        return (
            <NavigationMenu {...this.props} children={ContactUs()} />
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledContactUsContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    padding: "1rem 3rem",
    "& .contact-us-header": {
        fontSize: "1.125rem",
        fontWeight: 500,
        color: "white",
    },
    "& .contact-us-main-grid": {
        backgroundColor: "rgba(35, 64, 75, 1)",
        borderRadius: "16px",
        marginTop: "20px",
        padding: "30px 25px 15px 25px",
        "& #assist-header": {
            color: "rgba(255, 255, 255, 1)",
            fontSize: "24px",
            fontWeight: 500,
            marginBottom: "25px",
        },
        "& p": {
            color: "rgba(255, 255, 255, 1)",
            fontSize: "16px",
            marginBottom: "15px",
        },
        "& button": {
            outline: "none",
            border: "none",
            background: "linear-gradient(180deg, rgba(243, 127, 120, 0.75) 0%, rgba(247, 83, 103, 0.75) 100%)",
            // boxShadow: "0px 0px 20px 0px #0000004D",
            width: "300px",
            borderRadius: "62.5rem",
            color: "white",
            height: "3rem",
            cursor: "pointer",
            fontSize: "1rem",
            fontWeight: 500,
            letterSpacing: "1px",
            "&:disabled": {
                opacity: '0.5',
                cursor: "inherit"
            }
        }
    },
    "& .ellipsis": {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    "& form": {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        "& .row": {
            display: "flex",
            gap: "2%",
            flexWrap: "wrap",
        }
    },
    [`@media screen and (max-width: 912px)`]: {
        "& #email-field": {
            marginTop: "25px"
        }
    },
})

const InputTextField = styled("div")({
    display: "flex",
    maxHeight: "100%",
    alignItems: "center",
    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    border: "1px solid rgba(28, 150, 154,0.3)",
    position: "relative",
    padding: "0rem 0.9rem",
    height: "3rem",
    borderRadius: "100px",
    width: "85%",
    "&.focused": {
        marginTop: '1rem !important'
    },
    "& label": {
        position: "absolute",
        transition: "transform 0.2s ease-in-out",
        color: "white",
        fontSize: "1rem",
        "&.focused": {
            transform: 'translate(-14px,-38px)'
        }
    },
    "& input": {
        width: "100%",
        backgroundColor: "transparent",
        border: "none",
        outline: "none",
        color: "white",
        fontSize: "1rem",
        zIndex: 1
    },
    [`@media screen and (max-width: 912px)`]: {
        width: "98%",
    },
})

const DescTextField = styled("div")({
    display: "flex",
    height: "7.25rem",
    alignItems: "center",
    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    border: "1px solid rgba(28, 150, 154,0.11)",
    position: "relative",
    padding: "0.8rem 0.9rem",
    borderRadius: "1rem",
    "&.focused": {
        marginTop: '1rem !important'
    },
    "& label": {
        position: "absolute",
        color: "white",
        fontSize: "1rem",
        top: "10%",
        transition: "transform 0.2s ease-in-out",
        "&.focused": {
            transform: 'translate(-14px,-40px)'
        }
    },
    "& textarea": {
        width: "100%",
        height: "100% !important",
        resize: "none",
        backgroundColor: "transparent",
        border: "none",
        outline: "none",
        color: "white",
        fontSize: "1rem",
        zIndex: 1,
        fontFamily: "poppins,san-serif",
        fontWeight: 400
    },
    "& #no-of-characters": {
        color: "rgba(255, 255, 255, 0.8)",
        fontSize: "12px",
        position: "absolute",
        right: "10px",
        bottom: "10px"
    }
})
// Customizable Area End
