import React from "react";

interface ErrorMessageProps {
    error:{[key:string]: string}
}


const ErrorMessage: React.FC<ErrorMessageProps> = (props: ErrorMessageProps) => {
    const { error } = props;


    if(!error || !error.message){
        return (
            <></>
        )
    }
    return (
        <div style={{ color: '#ff3333', fontSize: '0.75rem', textAlign: "left" }}>{error.message}</div>
    )
}

export default ErrorMessage;