import React from "react";
import { styled } from "@material-ui/core";
import { CheckIcon2 } from "../../../appointmentmanagement/src/assets";
interface RsvpStatusProps {
    isRsvpStatusUpdateMode: boolean;
    rsvp_status: string | null;
    unique_id: number | string;
    onUpdateRsvpStatus?: (status: string, rsvp_status_id: string | number)=> void;
    onRsvpUpdateModeChange?: ()=> void;
}


const RsvpStatus: React.FC<RsvpStatusProps> = (props: RsvpStatusProps) => {
    const {isRsvpStatusUpdateMode,rsvp_status, unique_id, onUpdateRsvpStatus,onRsvpUpdateModeChange} = props;
    const UpdateRsvpStatus = (rsvp_status_id: string | number) => {
        return (
            <StyledUpdateRsvpStatus>
                <button id="status_yes" onClick={() => {onUpdateRsvpStatus && onUpdateRsvpStatus("accepted", rsvp_status_id)}}>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.55042 11.6534L0.21582 6.31881L1.28505 5.24961L5.55042 9.51499L14.7158 0.349609L15.785 1.41881L5.55042 11.6534Z" fill="#F75367" />
                    </svg>
                    Yes
                </button>
                <button id="status_no" onClick={() => {onUpdateRsvpStatus && onUpdateRsvpStatus("rejected", rsvp_status_id)}}>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.22651 9.83555L0.163086 8.77213L3.93619 4.99902L0.163086 1.25092L1.22651 0.1875L4.99961 3.9606L8.74771 0.1875L9.81114 1.25092L6.03804 4.99902L9.81114 8.77213L8.74771 9.83555L4.99961 6.06245L1.22651 9.83555Z" fill="#F75367" />
                    </svg>
                    No
                </button>
            </StyledUpdateRsvpStatus>
        )
    }
    const RsvpTextForNextAppointment = (status: string | null) => {
        return (
            <>
                {
                    status === "accepted" && (
                        <div className="rsvp_status">
                            <img src={CheckIcon2} />
                            Yes, I will Attend
                        </div>
                    )
                }
                {
                    status === "rejected" && (
                        <div className="rsvp_status">
                            No, I will not Attend
                        </div>
                    )
                }
                <button onClick={onRsvpUpdateModeChange}>Change</button>
            </>
        )
    }
    return (
        <StyledRsvpStatus>
            <header>RSVP Status</header>
            <div className="rsvp_status-container">
            {
                rsvp_status === null && !isRsvpStatusUpdateMode && (
                    UpdateRsvpStatus(unique_id)
                )
            }
            {
                isRsvpStatusUpdateMode && (
                    UpdateRsvpStatus(unique_id)
                )
            }
            {
                rsvp_status !== null && !isRsvpStatusUpdateMode && (
                    RsvpTextForNextAppointment(rsvp_status)
                )
            }
            </div>
        </StyledRsvpStatus>
    )
}

const StyledRsvpStatus = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    gap: "0.5rem",
    "& header": {
        fontSize: "0.875rem",
        color: "#FFFFFFCC",
        fontWeight: 500
    },
    "& .rsvp_status-container": {
        display: "flex",
        gap: "1rem",
        "& .rsvp_status": {
            color: "white",
            fontSize: "0.875rem",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
            gap: "0.3rem"
        },
        "& button": {
            color: "#F75367",
            fontSize: "1rem",
            fontWeight: 500,
            cursor: "pointer",
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
        }
    }
})

const StyledUpdateRsvpStatus = styled("div")({
    display: "flex",
    gap: "1rem",
    "& > button": {
        height: "2.25rem",
        display: "flex",
        justifyContent: "center",
        fontSize: "1rem",
        fontWeight: "500",
        alignItems: "center",
        gap: "0.5rem",
        borderRadius: "62.5rem",
        outline: "none",
        border: "1px solid #F75367 !important",
        color: "#F75367",
        width: "5.3125rem",
    }
})

export default RsvpStatus;