import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react";
import {
  getStorageData,
} from "../../../framework/src/Utilities";
const { subscribeToNotifications } = require("../../../web/src/notifications"); 
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");

interface PayloadObject {
  token: string;
  phoneNumber: string;
  otpPin: number;
  countryCode: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  otp: string[];
  focusedInput: number;
  timer: number;
  fiveMinuteTimer: number;
  apiResponseError: string;
  apiResponseStatus: number | null
  errors: any;
  errorsFive: any;
  isTimerActive: boolean;
  sendOtpClickCount: any;
  isFiveMinuteTimerActive: any;
  isResendDisabled: boolean;
  submitDisabled: boolean;
  loginPayloadData: PayloadObject | null;
  resendOTPApiLoading: boolean;
  resendOtpClickCount: number;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpAuthApiCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  labelInfo: string = "";
  submitButtonColor: any = configJSON.submitButtonColor;
  timerId: any;
  timerInterval: NodeJS.Timeout | null = null;
  fiveMinuteTimerInterval: NodeJS.Timeout | null = null;
  otpApiCallId: string = "";
  sendOTPAgainAPICallId: string = "";
  otpInputs = Array.from({ length: 6 }, () =>
    React.createRef<HTMLInputElement>()
  );

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: ["", "", "", "", "", ""],
      focusedInput: 0,
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false,
      timer: 0,
      fiveMinuteTimer: 0,
      apiResponseError: "",
      apiResponseStatus: null,
      errors: {},
      errorsFive: {},
      isTimerActive: false,
      sendOtpClickCount: "",
      isFiveMinuteTimerActive: "",
      isResendDisabled: false,
      submitDisabled: false,
      loginPayloadData: null,
      resendOTPApiLoading: false,
      resendOtpClickCount: 0
    };
    this.timerId = null;
    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    this.submitOtp = this.submitOtp.bind(this);
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const payLoadData = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );

      this.setPayloadData(payLoadData);
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.otpApiCallId != null &&
      this.otpApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.handleSuccessApiCall(
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage)),
          responseJson
        );
        helper.showSuccessToast("Logged in successfully!");
      } else if (responseJson && responseJson.errors) {
        this.handleFailApiCall(
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage)),
          responseJson
        );
        helper.showErrorToast("Something went wrong! Please try again.");
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.sendOTPAgainAPICallId != null &&
      this.sendOTPAgainAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        responseJson &&
        responseJson.errors &&
        responseJson.errors[0] &&
        responseJson.errors[0].message
      ) {
        this.handleFailApiCall(
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage)),
          responseJson
        );
        this.setState({
          resendOTPApiLoading: false
        })
      } else {
        this.startTimer();
        this.setState({
          otpAuthToken: responseJson.meta.token
        })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    // Focus the first input field when component mounts
    super.componentDidMount();
    this.checkToken();
    if (this.otpInputs[this.state.focusedInput].current) {
      this.otpInputs[this.state.focusedInput].current?.focus();
    }
  }

  checkToken = async () => {
    const payLoadData = await helper.getStorageData("otpAuthData", true);
    if(payLoadData && payLoadData.token){
      this.setPayloadData(payLoadData);
      await helper.removeStorageData("otpAuthData");
    }else{
      this.handleNavigateMobileRegister();
    }
  };

  async componentWillUnmount() {
    super.componentWillUnmount();
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }

  handleSuccessApiCall = async (apiCallId: string, responseJson: any) => {
    if (apiCallId === this.otpApiCallId) {
      subscribeToNotifications(responseJson.meta.token, responseJson.data.attributes)
      const navigation = new Message(getName(MessageEnum.NavigationMessage));
      navigation.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "Dashboard"
      );
      navigation.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(navigation);
    }
  };

  handleFailApiCall = (apiCallId: string, responseJson: any) => {
    if (apiCallId === this.otpApiCallId) {
      const { errors } = responseJson;

      if (errors[0] && errors[0].token) {
        this.setState({
          apiResponseError: errors[0].token,
        });
      } else if (errors[0] && errors[0].message) {
        this.setState({
          apiResponseError: errors[0].message,
        });
        this.setTimerErrorMessage(errors[0].message);
      }
    } else if (apiCallId === this.sendOTPAgainAPICallId) {
      const errMessage = responseJson.errors[0].message;
      this.setState({
        apiResponseError: errMessage,
      });
      this.setTimerErrorMessage(errMessage);
    }
  };

  setTimerErrorMessage = (errMessage: string) => {
    if (errMessage === "You have entered wrong code. Please try again") {
      if (this.timerInterval !== null) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
        this.setState({
          isTimerActive: false,
          timer:0
        });
      } else {
        this.setState({
          isTimerActive: false,
          timer:0
        });
      }
    } else if (errMessage === "Try after 5 minutes") {
      this.startTimer(300);
    }
  };

  setPayloadData = (payLoadData: PayloadObject) => {
    this.setState({
      loginPayloadData: payLoadData,
      otpAuthToken: payLoadData.token
    });
  };

  getFormattedTime = () => {
    const minutes = Math.floor(this.state.timer / 60);
    const seconds = this.state.timer % 60;
    return `${minutes >= 10 ? minutes : "0" + minutes}:${
      seconds >= 10 ? seconds : "0" + seconds
    }`;
  };

  async submitOtp() {
    this.setState({
      apiResponseError: "",
      apiResponseStatus: null
    });
    let isFieldValidate = this.customValidate();

    if (isFieldValidate) {
      // this.setState({
      //   submitDisabled: false
      // })

      const header = {
        "Content-Type": "application/json",
        token: this.state.otpAuthToken,
      };
      const bodyData = {
        full_phone_number: `${this.state.loginPayloadData?.countryCode}${this.state.loginPayloadData?.phoneNumber}`,
        pin: this.state.otp.join(""),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.otpApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "account_block/accounts/sms_confirmations"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  handleOTPInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    this.setState({
      apiResponseError: "",
    });
    const { value } = event.target;

    // Validate input to allow only numeric characters (0-9)
    if (!/^\d*$/.test(value)) {
      return; // If input is not numeric, do not update the state
    }

    const newOTP = [...this.state.otp];
    newOTP[index] = value;

    this.setState({ otp: newOTP }, () => {
      // Move focus to the next input after a digit is entered
      if (value !== "" && index < 5) {
        this.focusNextInput(index + 1);
      }
    });
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    this.setState({
      apiResponseError: "",
    });
    // Move focus to the previous input on backspace if current input is empty
    if (event.key === "Backspace" && this.state.otp[index] === "" && index > 0) {
      this.focusNextInput(index - 1);
    }
  };

  focusNextInput = (index: number) => {
    if (this.otpInputs[index].current) {
      if (this.otpInputs[index].current) {
        this.otpInputs[index].current?.focus();
      }
      this.setState({ focusedInput: index });
    }
  };

  handleSendOtp = () => {
    if (this.state.isTimerActive || this.state.resendOTPApiLoading) {
      return;
    }
    this.setState(prevState=>{
      return {
        apiResponseError: "",
        apiResponseStatus: null,
        resendOTPApiLoading: true,
        resendOtpClickCount: prevState.resendOtpClickCount + 1
      }
    });
    this.handleResendOtp();
  };

  handleDisable = () => {
    return this.state.submitDisabled ? 0.4 : 1;
  };

  btnSubmitOTPProps = {
    onPress: () => this.submitOtp(),
  };

  txtMobilePhoneOTPWebProps = {
    onChangeText: (text: string) => {
      return text;
    },
  };

  txtMobilePhoneOTPMobileProps = {
    ...this.txtMobilePhoneOTPWebProps,
    keyboardType: "numeric",
  };

  txtMobilePhoneOTPProps = this.isPlatformWeb()
    ? this.txtMobilePhoneOTPWebProps
    : this.txtMobilePhoneOTPMobileProps;

  handlePasteOtp = (event: React.ClipboardEvent<HTMLInputElement>) => {
    this.setState({
      apiResponseError: "",
    });
    const pastedData = event.clipboardData.getData("text/plain");

    // Validate pasted data to allow only numeric characters (0-9)
    if (!/^\d*$/.test(pastedData)) {
      return;
    }

    // Update OTP state based on the pasted data
    const newOTP = pastedData.substring(0, 6).split(""); // Limit to 6 characters and convert to array
    const copyOtp = [...this.state.otp]; // Make a copy of current OTP state

    for (let i = 0; i < Math.min(newOTP.length, 6); i++) {
      copyOtp[i] = newOTP[i];
    }
    this.setState({ otp: copyOtp });

    // Focus the last filled input field
    const lastFilledIndex = Math.min(newOTP.length - 1, 5);
    this.focusNextInput(lastFilledIndex);
  };

  startTimer = (timerLimit: number = 20) => {
    if (this.timerInterval !== null) {
      clearInterval(this.timerInterval);
      this.timerInterval = null;
    }
    this.setState({ timer: timerLimit, isTimerActive: false });
    this.timerInterval = setInterval(() => {
      const { timer } = this.state;
      if (timer > 0) {
        this.setState({ timer: timer - 1, isTimerActive: true, resendOTPApiLoading: false });
      } else {
        if (this.timerInterval !== null) {
          clearInterval(this.timerInterval);
          this.timerInterval = null;
          this.setState({ isTimerActive: false, timer:0 });
        }
      }
    }, 1000);
  };

  handleResendOtp = () => {
    const header = {
      "Content-Type": "application/json",
      token: "",
    };

    const bodyData = {
      data: {
        type: "sms_account",
        attributes: {
          full_phone_number: `${this.state.loginPayloadData?.countryCode}${this.state.loginPayloadData?.phoneNumber}`,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendOTPAgainAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_login/logins"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNavigateMobileRegister = async () => {
      const navigation = new Message(getName(MessageEnum.NavigationMessage));
      navigation.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "EmailAccountLoginBlock"
      );
      navigation.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      if(this.state.loginPayloadData){
        await helper.setStorageData("otpAuthData", JSON.stringify(this.state.loginPayloadData));
      }
      this.send(navigation);
  };

  customValidate = () => {
    const otp = this.state.otp.join("");
    if (otp === "") {
      this.setState({
        errors: {
          message: "Please Provide Valid Otp",
        },
      });
    } else if (otp.length < 6) {
      this.setState({
        errors: {
          message: "Enter Valid OTP",
        },
      });
    } else {
      this.setState({
        errors: {},
      });
    }
    return this.state.errors;
  };

  // Customizable Area End
}
