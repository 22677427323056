// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const caretRight = require("../assets/caret_right.png");
export const emptyCheckBoxIcon = require("../assets/empty-checkbox.svg")
export const checkedBoxIcon = require("../assets/checkedBox.svg");
export const deleteIcon = require("../assets/image_delete.png")
export const editIcon = require("../assets/image_edit.png");
export const selectAllIcon = require("../assets/select-all.png");
export const markAsCompleteIcon = require("../assets/mark.png");
export const crossIcon = require("../assets/cross.svg");
export const closeIcon = require("../assets/close.svg");
export const whiteEditIcon = require("../assets/edit-icon.svg");
export const blueClockIcon = require("../assets/clock-icon.svg");
export const markAsCompleteRedIcon = require("../assets/mark-red.png");
// Customizable Area End