import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { EmailList, ContactList, IInterest } from "../../../components/src/CommonTypes";
import { GroupType } from "./types";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");

type ContactResponse = { 
    type: string, 
    id: number, 
    attributes: { 
        email: string, 
        full_phone_number: string, 
        full_name: string, 
        id: number 
    } 
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    id: string;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: string;
    loading: boolean;
    imageInputRef: React.RefObject<HTMLInputElement> | null;
    selectedImage: string | null;
    imageError: string | null;
    base64Image: File | null;
    isFormUpdated: boolean;
    title: string;
    description: string;
    groupType: string;
    email: string;
    emailError: string;
    openEmailDropdown: boolean;
    selectedEmailList: EmailList[];
    emailList: EmailList[];
    contactList: ContactList[];
    selectedContactList: ContactList[];
    openContactList: boolean;
    viewEmailAndContactData: {
        value: EmailList[] | ContactList[];
        type: "email" | "contact";
    } | null;
    auto_approve: boolean;
    interests: IInterest[];
    selectedInterest: IInterest[];
    currentSelectedInterest: IInterest[];
    interestModalOpen: boolean;
    // Customizable Area End
}
interface SS { }

export default class AddGroupController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    emailSuggestionAPICallId: string = "";
    searchContactApiCallId: string = "";
    fetchContactsApiCallId: string = "";
    fetchInterestsApiCallId: string = "";
    addnewInterestApiCallId: string = "";
    createGroupApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            token: "",
            loading: false,
            imageInputRef: React.createRef<HTMLInputElement>(),
            selectedImage: null,
            imageError: null,
            base64Image: null,
            isFormUpdated: false,
            title: "",
            description: "",
            groupType: "",
            email: "",
            emailError: "",
            openEmailDropdown: false,
            selectedEmailList: [],
            emailList: [],
            selectedContactList: [],
            openContactList: false,
            viewEmailAndContactData: null,
            contactList: [],
            auto_approve: true,
            interests: [],
            selectedInterest: [],
            currentSelectedInterest: [],
            interestModalOpen: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = message.getData(getName(MessageEnum.SessionResponseToken));
            if (!token) {
                token = await helper.getStorageData("authToken");
            }
            this.setState({
                token: token
            },()=>{
                this.fetchContacts();
                this.fetchInterests();
            })
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            switch (webApiRequestCallId) {
                case this.emailSuggestionAPICallId:
                    this.handleEmailSuggestionAPICall(message)
                    break;
                case this.fetchContactsApiCallId:
                    this.handleFetchContactsAPICall(message)
                    break;
                case this.searchContactApiCallId:
                    this.searchContactApiCallHandler(message)
                    break;
                case this.fetchInterestsApiCallId:
                    this.handleFetchInterestsAPICall(message)
                    break;
                case this.addnewInterestApiCallId:
                    this.handleAddNewInterestApiCall(message)
                    break;
                case this.createGroupApiCallId:
                    this.handleCreateGroupApiCall(message);
                    break;
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start

    onSelectImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const result = await helper.covertToObjectUrl(event);

        if (!result.imgSizeExceed) {
            this.setState({
                selectedImage: result.selectedImage,
                imageError: null,
                isFormUpdated: true
            })
            if(event.target.files){
                this.setState({
                    base64Image: event.target.files[0]
                })
            }
            
        } else {
            this.setState({
                imageError: "PNG or JPG format and does not exceed 10 MB in size."
            })
        }
    }

    onChangeInputs = (value: string, name: string) => {
        const sanitizedValue = value.replace(/^\s+/, '');
        this.setState(prevState => ({ ...prevState, [name]: sanitizedValue, isFormUpdated: true }))
    }

    redirectTo = (endpoint: string, params?: { [key: string]: string | number }) => {
        this.props.navigation.navigate(endpoint, params)
    }

    goBack = () => {
        this.props.navigation.goBack()
    }

    onEmailChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        this.setState({ email: value, emailError: "" })
        this.emailSuggestionAPICallId = await helper.apiCall({
            method: "GET",
            contentType: webConfigJSON.validationApiContentType,
            token: this.state.token,
            endPoint: `account_block/accounts/auto_suggestions?email=${value}`
        })
    }

    handleEmailSuggestionAPICall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.suggestion_list) {
            this.setState({
                emailList: responseJson.suggestion_list
            })
        } else {
            this.setState({
                emailList: []
            })
        }
    }

    openSelectEmailDropdown = () => {
        this.setState(() => ({ openEmailDropdown: true }))
    }

    closeSelectEmailDropdown = () => {
        this.setState(() => ({ openEmailDropdown: false }))
    }

    onEnterEmail = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        event.currentTarget.blur()
        //If user enter a invalid email
        if (!helper.isValidEmail(value)) {
            this.setState({
                openEmailDropdown: false,
                emailError: "*Please enter a valid email"
            })
            return;
        }

        //If user enter a already selected email
        if (this.state.selectedEmailList.find(email => email.email === value)) {
            this.setState({
                openEmailDropdown: false,
                emailError: "*This email already selected"
            })
            return;
        }

        this.setState(prevState => {
            return {
                selectedEmailList: [...prevState.selectedEmailList, { email: value, id: -1, full_name: "" }],
                isFormUpdated: true,
                emailError: "",
                openEmailDropdown: false,
                email: ""
            }
        })
    }

    onSelectEmail = (email: EmailList) => {
        if (!this.state.selectedEmailList.find(item => item.email === email.email)) {
            this.setState(prevState => {
                return {
                    openEmailDropdown: false,
                    isFormUpdated: true,
                    selectedEmailList: [...prevState.selectedEmailList, email],
                    email: ""
                }
            })
        } else {
            this.setState({
                openEmailDropdown: false,
                email: ""
            })
        }
    }

    onRemoveEmail = (emailId: string) => {
        const newEmailList = this.state.selectedEmailList.filter(email => email.email !== emailId);
        this.setState({
            isFormUpdated: true,
            selectedEmailList: newEmailList
        })
    }

    findItemPresentInEmailAndContactList = (accountId: number | string) => {
        const { selectedEmailList, selectedContactList } = this.state;
        const selected = !!(selectedEmailList.find(email => email.id === accountId) || selectedContactList.find(contact => contact.full_phone_number === accountId));
        return selected;
    }

    openContactList = () => {
        this.setState({
            openContactList: true
        })
        this.fetchContacts()
    }



    onRemoveContact = (contactId: number) => {
        const newContactList: ContactList[] = this.state.selectedContactList.filter(contact => contact.id !== contactId);
        this.setState({
            isFormUpdated: true,
            selectedContactList: newContactList
        })
    }

    viewEmailAndContact = (type: "email" | "contact") => {
        if (type === "email") {
            this.setState({
                viewEmailAndContactData: { type, value: this.state.selectedEmailList }
            })
        } else if (type === "contact") {
            this.setState({
                viewEmailAndContactData: { type, value: this.state.selectedContactList }
            })
        }
    }

    closeContactList = () => {
        this.setState({
            openContactList: false
        })
    }

    onSearchContact = async (contact: string) => {
        this.searchContactApiCallId = await helper.apiCall({
            method: "GET",
            contentType: webConfigJSON.validationApiContentType,
            token: this.state.token,
            endPoint: `${webConfigJSON.searchContactsApiEndPoint}?query=${contact}`
        })
    }

    searchContactApiCallHandler = (message: Message) => {
        const response = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (response && response.data) {
            const contactData = response.data.map((contact: ContactResponse) => {
                return {
                    id: contact.attributes.id,
                    name: contact.attributes.full_name,
                    email: contact.attributes.email,
                    full_phone_number: contact.attributes.full_phone_number
                }
            })

            this.setState({
                contactList: contactData
            })
        } else {
            this.setState({
                contactList: []
            })
        }
    }

    onAddContacts = (contacts: ContactList[]) => {
        this.setState(prevState => {
            return {
                selectedContactList: [...prevState.selectedContactList, ...contacts],
                isFormUpdated: true,
                openContactList: false
            }
        })
    }

    fetchContacts = async () => {
        this.fetchContactsApiCallId = await helper.apiCall({
            method: "GET",
            contentType: webConfigJSON.validationApiContentType,
            token: this.state.token,
            endPoint: `account_block/accounts/registered_users`
        })
    }

    handleFetchContactsAPICall = (message: Message) => {
        const response = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (response && response.data && response.data.users) {
            this.setState({
                contactList: response.data.users
            })
        } else {
            this.setState({
                contactList: []
            })
        }
    }

    closeViewEmailAndContactModal = () => {
        this.setState({
            viewEmailAndContactData: null
        })
    }

    onDoneViewEmailAndContact = (value: EmailList[] | ContactList[]) => {
        const { viewEmailAndContactData } = this.state;

        if (viewEmailAndContactData && viewEmailAndContactData.type === "email") {
            this.setState({
                selectedEmailList: value as EmailList[],
                viewEmailAndContactData: null,
                isFormUpdated: true
            })
        } else if (viewEmailAndContactData && viewEmailAndContactData.type === "contact") {
            this.setState({
                selectedContactList: value as ContactList[],
                isFormUpdated: true,
                viewEmailAndContactData: null,
            })
        }
    }

    onChangeAutoApprovalRequest = (value: boolean)=>{
        this.setState({
            auto_approve: value
        })
    }

    onOpenSelectInterestModal = () => {
        this.setState({
            interestModalOpen: true,
            currentSelectedInterest: this.state.selectedInterest
        })
    }

    onCloseSelectInterestModal = () => {
        this.setState({
            interestModalOpen: false,
            currentSelectedInterest: []
        })
    }

    fetchInterests = async ()=>{
        this.fetchInterestsApiCallId = await helper.apiCall({
            method: "GET",
            contentType: webConfigJSON.validationApiContentType,
            token: this.state.token,
            endPoint: webConfigJSON.interestApiEndPoint
        })
    }

    handleFetchInterestsAPICall = (message: Message) => {
        const response = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (response && response.data) {
            this.setState({
                interests: response.data
            })
        } else {
            this.setState({
                interests: []
            })
        }
    }

    setCurrentSelectedInterest = (interests: IInterest[])=>{
        this.setState({
            currentSelectedInterest: interests
        })
    }

    onAddInterests = (interests: IInterest[])=>{
        this.setState({
            selectedInterest: interests,
            interestModalOpen: false,
            currentSelectedInterest: []
        })
    }

    onSubmitNewInterest = async (event: string) => {
        this.addnewInterestApiCallId = await helper.apiCall({
            contentType: webConfigJSON.validationApiContentType,
            method: "POST",
            endPoint: webConfigJSON.addNewInterestApiEndPoint,
            body: {
                name: event
            }
        })
    }

    handleAddNewInterestApiCall = (message: Message) => {
        const response = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (response && response.data) {
            const modifiedResponseData = response.data;
            modifiedResponseData.attributes.manual = true;
            this.setState((prevState) => {
                return {
                    currentSelectedInterest: [...prevState.currentSelectedInterest, modifiedResponseData]
                }
            })
            this.fetchInterests();
        }
    }

    removeInterestFromSelectedInterest = (interestId: string)=>{
       this.setState(prevState=>{
         return {
            selectedInterest: prevState.selectedInterest.filter(interest=> interest.id !== interestId)
         }
       })
    }

    onCreateGroup = async ()=>{
        this.createGroupApiCallId = await helper.apiCall({
            method: "POST",
            token: this.state.token,
            endPoint: webConfigJSON.groupApiEndPoint,
            body: this.getFormDataForPost(),
            type: "formData"
        })
    }

    handleCreateGroupApiCall = (message: Message) => {
        const response = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (response && response.data) {
            this.redirectTo("Groups")
            helper.showSuccessToast("Successfully Group Created")
        }else{
            const toastMsg = response && response.error && response.error[0] ? response.error[0] : "Something went wrong please try again!"
            helper.showErrorToast(toastMsg)
        }
    }

    getFormDataForPost = ()=>{
        const {
          title,
          description,
          selectedInterest,
          base64Image,
          groupType,
          selectedEmailList,
          selectedContactList,
          auto_approve
        } = this.state;
        const bodyData = new FormData();

        if(base64Image){
            bodyData.append("group[image]", base64Image);
        }
        bodyData.append("group[name]", title);
        bodyData.append("group[description]", description);
        bodyData.append("group[group_type]", groupType);
        bodyData.append("group[interest_ids]", JSON.stringify(selectedInterest.map(interest=> interest.id)));
        bodyData.append("group[member_ids]", JSON.stringify([
            ...selectedEmailList.filter(email=> email.id !== -1).map(email=> email.id),
            ...selectedContactList.map(contact=> contact.id)
        ]));
        if(groupType === GroupType.PUBLIC){
            bodyData.append("group[auto_approve]", JSON.stringify(auto_approve));
        }
        return bodyData;
    }

    disableCreateBtn = ()=>{
        const {
            title,
            selectedInterest,
            groupType,
            selectedEmailList,
            selectedContactList
        } = this.state;

        if(!title.trim() || selectedInterest.length === 0 || !groupType || (selectedContactList.length === 0 && selectedEmailList.length === 0)){
            return true;
        }
    }

    // Customizable Area End
}
