import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import moment from "moment";
import { EventInput } from '@fullcalendar/core';
import FullCalendar from "@fullcalendar/react";
const { ApiCallFunction: apiCall } = require("../../appointmentmanagement/src/ApiCallFunction")
import { getStorageData } from "../../../framework/src/Utilities";
import { IAppointmentData, IEventData, IReminderItem } from "../../../components/src/CommonTypes";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTime: number;
  selectedDate: string;
  timeSlots: object[];
  serviceProviderId: string;
  serviceProviderSchedule: [
    {
      attributes: {
        start_time: string;
        end_time: string;
      }
    }
  ] | null;
  details: {
    [key: string]: string;
  };
  token: string | null;
  calendarRef: React.RefObject<FullCalendar> | null,
  currentCalendarView: "dayGridMonth" | "timeGridWeek" | "timeGridDay";
  currentDate: Date;
  filterMenuAnchorEle: HTMLElement | null;
  events: EventInput[];
  eventsForDatePicker: {
    date: string;
    appointments: number;
    events: number;
    reminders: number;
    schedules: number;
  }[];
  isDatePickerOpen: boolean;
  typeOfScheduling: string;
  selectedReminderItem: IReminderItem | null;
  deleteReminderConfirmModalData: {
    header: string;
    message: string;
    type: string;
    appointmentId: string;
  } | null;
  remindersList: IReminderItem[];
  calendarFilters: {
    appointments: boolean;
    events: boolean;
    reminders: boolean;
    schedule: boolean;
  }
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {
  
  // Customizable Area Start
  serviceProviderDetailApiId?: string;
  serviceProviderScheduleApiId?: string;
  setCalendarApiCallId?: string;
  getAllItemsForDatepickerApiId: string = "";
  getAllCalendarItemsInDateRangeApiCallId?: string;
  deleteReminderApiCallId: string = "";
  markReminderCompleteApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage), 
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      selectedTime: 0,
      selectedDate: moment().format("YYYY-MM-DD"),
      timeSlots: [],
      serviceProviderId: "",
      serviceProviderSchedule: [
        {
          attributes: {
            start_time: "",
            end_time: ""
          }
        }
      ],
      details: {},
      token: null,
      calendarRef: React.createRef<FullCalendar>(),
      currentCalendarView: "timeGridDay",
      currentDate: new Date(),
      filterMenuAnchorEle: null,
      events: [],
      eventsForDatePicker: [],
      isDatePickerOpen: false,
      typeOfScheduling: "",
      selectedReminderItem: null,
      deleteReminderConfirmModalData: null,
      remindersList: [],
      calendarFilters:{
        appointments: true,
        events: true,
        reminders: true,
        schedule: true
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      
    }
    const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id){
      switch(apiCallId) {
        case this.setCalendarApiCallId:
          this.setCalendarRef(message);
          break;
        case this.getAllCalendarItemsInDateRangeApiCallId:
          this.setEvents(message);
          break;
        case this.getAllItemsForDatepickerApiId:
          this.setEventsForDatePicker(message);
          break;
        case this.markReminderCompleteApiId:
          this.handleMarkAsCompleteApiCall(message);
          break;
        case this.deleteReminderApiCallId:
          this.handleDeleteReminderApiCall(message);
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.handleSetScheduleType();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  handleOpenReminderDetailsModal = (reminder: IReminderItem) => {
    this.setState({
      selectedReminderItem: reminder
    })
  }

  onCloseReminderDetailModal = () => {
    this.setState({
      selectedReminderItem: null
    })
  }

  openReminderDeleteModal = (title: string, reminderId?: string | number) => {
    this.setState({
      deleteReminderConfirmModalData: {
        header: "Delete Reminder?",
        message: `Are you sure that you want to delete ${title}?`,
        type: "delete",
        appointmentId: reminderId as string
      }
    })
  }

  onCloseDeleteReminderConfirmationModal = () => {
    this.setState({
      deleteReminderConfirmModalData: null,
    })
  }

  onAcceptDeleteConfirmation = async () => {
    if (this.state.deleteReminderConfirmModalData) {
      const { type, appointmentId } = this.state.deleteReminderConfirmModalData;
      if (type === "delete") {
        this.setState({
          deleteReminderConfirmModalData: null,
          selectedReminderItem: null,
        })
        helper.showLoader();
        this.deleteReminderApiCallId = await apiCall({
          method: configJSON.deleteApiMethod,
          endPoint:`${configJSON.reminderEndpoint}/${appointmentId}`,
          contentType: configJSON.validationApiContentType,
          token: this.state.token
        })
      }
    }
  }

  markRemindersAsComplete = async(reminderId?: string) => {
    const { token } = this.state;
    this.onCloseReminderDetailModal();
    helper.showLoader();
    const body = {
      ids: [reminderId]
    }
    this.markReminderCompleteApiId = await apiCall({
      method: configJSON.patchApiMethod,
      contentType: configJSON.validationApiContentType,
      endPoint: configJSON.markReminderCompleted,
      token,
      body
    })
  }

  handleMarkAsCompleteApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors) {
       helper.showSuccessToast("Reminder marked as completed!")
    } else {
      const toastMsg = responseJson?.errors?.[0]?.message ? responseJson.errors[0].message : "Something went wrong, please try again!";
      helper.showErrorToast(toastMsg);
    }
    helper.hideLoader();
  }

  handleDeleteReminderApiCall = (message:Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (responseJson && !responseJson.errors) {
      this.handleCallDataListApi();
      helper.showSuccessToast("Reminder deleted successfully!");
    } else {
      const toastMsg = responseJson?.errors?.[0]?.message ? responseJson.errors[0].message : "Something went wrong, please try again!";
      helper.showErrorToast(toastMsg);
    }
    helper.hideLoader();
  }

  handleCallDataListApi = () => {
    const calenderApi = this.state.calendarRef?.current?.getApi();
    this.getAllItemsInDateRange(calenderApi?.view.currentStart as Date, calenderApi?.view.currentEnd as Date)
  }

  handleSetScheduleType = async() => {
    const token = await getStorageData("authToken");
    const paramScheduleType = this.props.navigation?.getParam("type");
    if(paramScheduleType){
      this.setState({ typeOfScheduling: paramScheduleType, token: token }, () => {
        this.handleCallDataListApi()
      })
    }
  }

  setEvents = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.week_data?.length > 0) {
      const events = this.formatEventsData(responseJson);
      this.setState({
        events: events,
      })
    } else {
      this.setState({
        events: []
      })
    }
    helper.hideLoader();
  }

  formatEventsData = (responseJson: { week_data: {events: IEventData[], appointments: IAppointmentData[], reminders: IReminderItem[]}[] }) => {
    const calendarItems: EventInput[] = [];
    let reminders : IReminderItem[] = [];
    responseJson.week_data.forEach((data) => {
      data?.events?.forEach((eventItem) => {
        const eventObj: EventInput = {}
        eventObj.id = eventItem.id;
        eventObj.title = eventItem.attributes.title;
        eventObj.description = eventItem.attributes.description;
        eventObj.start = `${eventItem.attributes.date_time_event}T${this.convertTo24HourFormat(eventItem.attributes.start_time_event)}`;
        eventObj.end = `${eventItem.attributes.date_time_event}T${this.convertTo24HourFormat(eventItem.attributes.end_time_event)}`
        eventObj.status = eventItem.attributes.status;
        eventObj.eventType = "event";
        calendarItems.push(eventObj);
      })
      data?.appointments?.forEach((appointmentItem) => {
        const appointmentObj: EventInput = {}
        appointmentObj.id = appointmentItem.id;
        appointmentObj.title = appointmentItem.attributes.title;
        appointmentObj.description = appointmentItem.attributes.description;
        appointmentObj.start = `${appointmentItem.attributes.appointment_date}T${this.convertTo24HourFormat(appointmentItem.attributes.start_time)}`;
        appointmentObj.end = `${appointmentItem.attributes.appointment_date}T${this.convertTo24HourFormat(appointmentItem.attributes.end_time)}`
        appointmentObj.status = appointmentItem.attributes.status;
        appointmentObj.status = appointmentItem.attributes.status;
        appointmentObj.eventType = "appointment";
        calendarItems.push(appointmentObj);
      })
      data?.reminders?.forEach((reminderItem) => {
        const reminderObj: EventInput = {}
        reminderObj.id = reminderItem.id || "0";
        reminderObj.title = reminderItem.attributes.title;
        reminderObj.description = reminderItem.attributes.description;
        reminderObj.start = `${reminderItem.attributes.date_reminder}T${this.convertTo24HourFormat(reminderItem.attributes.time_reminder)}`;
        reminderObj.end = `${reminderItem.attributes.date_reminder}T${this.convertTo24HourFormat(reminderItem.attributes.time_reminder)}`
        reminderObj.status = reminderItem.attributes.reminder_status;
        reminderObj.eventType = "reminder";
        calendarItems.push(reminderObj);
      })
      if(data.reminders){
        reminders = [...reminders, ...data.reminders];
      }else{
        reminders = [...reminders];
      }
    })
    this.setState({ remindersList: reminders })
    return calendarItems;
  }

  setEventsForDatePicker = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.days && responseJson.days.length > 0) {
      this.setState({
        eventsForDatePicker: responseJson.days
      })
    } else {
      this.setState({
        eventsForDatePicker: []
      })
    }
  }

  setCalendarRef = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({
      calendarRef: responseJson
    })
  }

  calendarProps = {
    minDate: moment(),
    onSelectDate: (selectedDate: string) => {}
  };

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  setMonthView = () => {
    const calenderApi = this.state.calendarRef?.current?.getApi();
    calenderApi?.changeView("dayGridMonth");
    this.setState({
      currentCalendarView: "dayGridMonth"
    })
    this.handleCallDataListApi();
  }

  setWeekView = () => {
    const calenderApi = this.state.calendarRef?.current?.getApi();
    calenderApi?.changeView("timeGridWeek");
    this.setState({
      currentCalendarView: "timeGridWeek"
    })
    this.handleCallDataListApi();
  }

  setDayView = () => {
    const calenderApi = this.state.calendarRef?.current?.getApi();
    calenderApi?.changeView("timeGridDay");
    this.setState({
      currentCalendarView: "timeGridDay"
    })
    this.handleCallDataListApi();
  }

  goToNext = () => {
    const calenderApi = this.state.calendarRef?.current?.getApi();
    calenderApi?.next();
    const newDate = calenderApi?.getDate();
    if (newDate) {
      this.setState({
        currentDate: newDate
      })
    }
    this.handleCallDataListApi();
  }

  goToPrev = () => {
    const calenderApi = this.state.calendarRef?.current?.getApi();
    calenderApi?.prev();
    const newDate = calenderApi?.getDate();
    if (newDate) {
      this.setState({
        currentDate: newDate
      })
    }
    this.handleCallDataListApi();
  }

  goToCurrentDate = () => {
    const calenderApi = this.state.calendarRef?.current?.getApi();
    const currentDate = new Date();
    calenderApi?.gotoDate(currentDate);
    calenderApi?.changeView("timeGridDay");
    this.setState({
      currentDate: currentDate,
      currentCalendarView: "timeGridDay"
    })
    this.handleCallDataListApi();
  }

  goToSelectedDate = (selectedDate: Date | null) => {
    if (selectedDate) {
      const calenderApi = this.state.calendarRef?.current?.getApi();
      calenderApi?.gotoDate(selectedDate);
      this.setState({
        currentDate: selectedDate
      })
    }
  }

  redirectTo = (endpoint: string, params?: { [key: string]: string | number }) => {
    this.props.navigation.navigate(endpoint, params)
  }

  // Function to check if a date has an event
  getNumberOfEventItemsOnADate = (date: Date) => {
    const event = this.state.eventsForDatePicker.find(event => new Date(event.date).toDateString() === date.toDateString());
    let numberOfEventItems: {colorClass: string}[] = [];
    if (event) {
      if (event?.appointments > 0) {
        numberOfEventItems.push({colorClass: "appointment-box"});
      }
      if (event?.events > 0) {
        numberOfEventItems.push({colorClass: "event-box"});
      }
      if (event?.reminders > 0) {
        numberOfEventItems.push({colorClass: "reminder-box"});
      }
      if(event.schedules > 0) {
        numberOfEventItems.push({colorClass: "schedule-box"});
      }
    }
    return numberOfEventItems;
  };

  isEventDate = (date: Date) => {
    return this.state.eventsForDatePicker.some(event => new Date(event.date).toDateString() === date.toDateString() && (event.appointments > 0 || event.events > 0 || event.reminders > 0));
  };

  // Function to apply custom class to event dates
  dayClassName = (date: Date) => {
    return this.isEventDate(date) ? 'event-date' : '';
  };

  IsEqualDate = (dateValue1: Date, dateValue2: Date) => {
    return (dateValue1.getDate() === dateValue2.getDate() && dateValue1.getMonth() === dateValue2.getMonth() && dateValue1.getFullYear() === dateValue2.getFullYear())
  }

  isPastDate = (dateValue: Date) => {
    const today = new Date();
    // Remove the time part by setting hours, minutes, seconds, and milliseconds to 0
    today.setHours(0, 0, 0, 0);
    return dateValue < today;
  }

  getAllItemsInDateRange = async (start_date: Date, end_date: Date, forDatePicker?: boolean) => {
    const startDateInString = this.formatDate(start_date);
    const endDateInString = this.formatDate(end_date);
    let endPoint;
    const baseEndpoint = forDatePicker ? 'bx_block_calendar/calendars' : configJSON.allCalendarEvents;
    let queryParams = `start_date=${startDateInString}&end_date=${endDateInString}`;
    const { calendarFilters } = this.state;
    if(calendarFilters.appointments){
      queryParams += '&query_type[]=appointments'
    }
    if(calendarFilters.events){
      queryParams += '&query_type[]=events'
    }
    if(calendarFilters.reminders){
      queryParams += '&query_type[]=reminders'
    }
    if(calendarFilters.schedule){
      queryParams += '&query_type[]=schedule'
    }
    if (forDatePicker) {
      endPoint = `${baseEndpoint}?show_only_dot=true&${queryParams}`;
    } else {
      endPoint = `${baseEndpoint}?${queryParams}`;
    }
    const callId = await apiCall({
      method: "GET",
      endPoint,
      contentType: configJSON.exampleApiContentType,
      token: this.state.token,
    })

    if (!forDatePicker) {
      helper.showLoader();
      this.getAllCalendarItemsInDateRangeApiCallId = callId;
    } else {
      this.getAllItemsForDatepickerApiId = callId;
    }
  }

  formatDate = (date: Date) => {
    let currDay = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    let year = date.getFullYear();
    return `${currDay}-${month}-${year}`;
  }

  convertTo24HourFormat = (timeValue: string) => {
    const [timePart, modifier] = timeValue.split(' ');
    let [hours, minutes] = timePart.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = `${parseInt(hours, 10) + 12}`;
    }

    return `${String(hours).padStart(2, '0')}:${minutes}`;
  };

  onDatePickerChange = (currentDate: Date)=> {
    const { startDate, endDate } = this.getStartAndEndOfMonth(currentDate);
    this.getAllItemsInDateRange(startDate, endDate, true);
  }

  onDateSelectFromDatePicker = (selectedDate: Date)=> {
    this.setState({
      isDatePickerOpen: false
    })
    const { startDate, endDate } = this.getStartAndEndOfMonth(selectedDate);
    this.getAllItemsInDateRange(startDate, endDate);
  }

  getStartAndEndOfMonth = (date: Date) => {
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return { startDate, endDate };
  };

  toggleDatePicker = ()=>{
    this.setState((prevState)=>{
      return {
        isDatePickerOpen: !prevState.isDatePickerOpen
      }
    })
  }

  clickedOutside = (event: React.MouseEvent<HTMLDivElement>)=>{
    const datePicker = document.getElementById("date-picker");
    
    if (datePicker && !datePicker.contains(event.target as Node)) {
      this.setState({
        isDatePickerOpen: false
      })
    }
  }

  viewDidMount = () => {
    const timeSlots = document.querySelectorAll('.fc-timegrid-slot');
    timeSlots.forEach((slot, index) => {
      // Check if current slot is the middle slot
      const isMiddleSlot = (index+1) % 8 === 3 || (index+1) % 8 === 4;
      const isEndSlot = (index+1) % 8 === 7 || (index+1) % 8 === 0;

      // Add general custom class to all slots
      slot.classList.add('custom-slot');

      // Add specific class for middle slots
      if (isMiddleSlot) {
        slot.classList.add('middle-slot');
      }

       // Add specific class for end slots
       if (isEndSlot) {
        slot.classList.add('end-slot');
      }
    });
  };

  goToAppointmentDetails = (type: string, dataId:string | number)=>{
    if (type === "event") {
      this.props.navigation.navigate("EventDetail", { eventId: dataId })
    } else if (type === "appointment") {
      this.props.navigation.navigate("AppointmentDetails", { id: dataId })
    } else if(type === "reminder") {
      const { remindersList } = this.state;
      const reminder = remindersList.find((item) => item.id === dataId);
      if(reminder){
        this.handleOpenReminderDetailsModal(reminder)
      }
    }
  }

  goBack = ()=>{
    this.props.navigation.goBack();
  }

  handleCalendarFilters = (type: string, value: boolean)=>{
    const { calendarFilters } = this.state;
    this.setState({
      calendarFilters: {
        ...calendarFilters,
        [type]: value
      }
    },()=>{
      this.handleCallDataListApi()
    })
    
  }
  // Customizable Area End
}