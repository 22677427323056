import React from "react";
// Customizable Area Start
import { styled } from "@material-ui/core";
import GoBack from "../../../components/src/GoBack.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import InputText from "../../../components/src/TextInput.web";
import StartAndEndTime from "../../../components/src/StartAndEndTime.web";
import TextArea from "../../../components/src/TextArea.web";
import RadioButton from "../../../components/src/RadioButton.web";
import { EditIcon } from "./assets";
const { HelperFunctions } = require("../../../components/src/HelperFunctions")
import { EventType } from "./types";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import SelectEmailDropdown from "../../../components/src/SelectEmailDropdown.web";
import SelectContacts from "../../../components/src/SelectContacts.web";
import Chip from "../../../components/src/Chip.web";
import ErrorMessage from "../../../components/src/ErrorMessage.web";
import MapMenu from "../../../components/src/MapMenu.web";
import ViewSelectedEmailAndContact from "../../../components/src/ViewSelectedEmailAndContact.web";
// Customizable Area End

import AddEventDetailController, {
  Props
} from "./AddEventDetailController";

export default class AllEvent extends AddEventDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ImageInput = () => {
    const { selectedImage, imageError, imageInputRef } = this.state;
    return (
      <>
        <StyledImageInput>
          {
            !selectedImage && (
              <div className="input" id="image-picker" onClick={() => imageInputRef?.current?.click()}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.45224 29.8844C2.61036 29.8844 1.89774 29.5928 1.31441 29.0094C0.731106 28.4261 0.439453 27.7135 0.439453 26.8716V4.56403C0.439453 3.72214 0.731106 3.00953 1.31441 2.4262C1.89774 1.84286 2.61036 1.5512 3.45224 1.5512H17.9394V9.05115H21.2727V12.3845H28.7727V26.8716C28.7727 27.7135 28.481 28.4261 27.8977 29.0094C27.3143 29.5928 26.6017 29.8844 25.7599 29.8844H3.45224ZM5.85612 23.6344H23.4842L18.0035 16.3268L13.324 22.4165L9.9907 18.1538L5.85612 23.6344ZM23.5805 10.0767V6.74341H20.2471V4.24349H23.5805V0.910156H26.0804V4.24349H29.4137V6.74341H26.0804V10.0767H23.5805Z" fill="#26EFF5" />
                </svg>
                <p>Add Image</p>
              </div>
            )
          }
          {
            selectedImage && (
              <div className="image" id="event-image" style={{ backgroundImage: `url(${selectedImage})` }}>
                <div className="icon" onClick={() => imageInputRef?.current?.click()}>
                  <img src={EditIcon} alt="" />
                </div>
              </div>
            )
          }
          <input
            type="file"
            ref={imageInputRef}
            style={{ display: 'none' }}
            onChange={this.onSelectImage}
          />
          {
            imageError && (
              <div className="error">{imageError}</div>
            )
          }
        </StyledImageInput>
      </>
    )
  }
  EmailAndContactField = () => {
    const {
      email,
      selectedContactList,
      openEmailDropdown,
      emailList,
      contactList,
      openContactList
    } = this.state;
    return (
      <div className="form-row" style={{ alignItems: "flex-start", marginTop: openEmailDropdown || email || selectedContactList.length > 0 ? "1rem" : "", transition: "all 0.3s ease-in-out" }}>
        <div className="form-col" style={{ position: "relative" }} id="email-menu-container">
          <InputText
            onChange={this.onEmailChange}
            value={email}
            label="Select Email Id"
            type="text"
            name="email"
            onFocus={this.openSelectEmailDropdown}
            onEnter={this.onEnterEmail}
            maxLength={42}
          />
          {
            openEmailDropdown && (
              <SelectEmailDropdown emails={emailList} onSelect={this.onSelectEmail} alreadySelected={this.findItemPresentInEmailAndContactList} />
            )
          }
          <ErrorMessage error={{message: this.state.emailErrorMessage}} />
        </div>
        <div className="form-col">
          <StyledInput style={{ cursor: "pointer" }} id="open-contacts" onClick={this.openContactList}>
            {
              selectedContactList.length === 0 && (
                <label className={selectedContactList.length > 0 ? "focused" : ""} style={{ cursor: "pointer" }}>Select Contact</label>
              )
            }
            {
              selectedContactList.length > 0 && (
                <label className={selectedContactList.length > 0 ? "focused" : ""}>Select Contact</label>
              )
            }
            {
              selectedContactList && selectedContactList.length > 0 && (
                <StyledChipList>
                  <div className="list">
                    {
                      selectedContactList.slice(0, 3).map(contact => {
                        return (
                          <Chip data-test-id="contact-chip" key={contact.id} displayText={contact.name[0].toUpperCase()} id={contact.id} onRemove={this.onRemoveContact} />
                        )
                      })
                    }
                    {
                      selectedContactList.length > 3 && (
                        <div className="extra" onClick={(event) => {
                           event.stopPropagation();
                           this.viewEmailAndContact("contact")
                        }}>+{selectedContactList.length - 3} More</div>
                      )
                    }
                  </div>
                  <p className="add-btn" onClick={this.openContactList}>Add</p>
                </StyledChipList>
              )
            }
          </StyledInput>
          <SelectContacts
            open={openContactList}
            onClose={this.closeContactList}
            contacts={contactList}
            filterContacts={selectedContactList}
            onSearch={this.onSearchContact}
            onAdd={this.onAddContacts}
            alreadySelected={this.findItemPresentInEmailAndContactList}
          />
        </div>
      </div>
    )
  }
  SelectedEmailList = () => {
    const {
      selectedEmailList
    } = this.state;
    return (
      <div className="form-row" style={{ marginTop: "-0.5rem" }}>
        <div className="form-col">
          <StyledChipList>
            <div className="list" style={{ flexWrap: "wrap" }}>
              {
                selectedEmailList.slice(0, 3).map(email => {
                  return (
                    <Chip key={email.email} displayText={email.full_name ? email.full_name : email.email} id={email.email} onRemove={this.onRemoveEmail} />
                  )
                })
              }
              {
                selectedEmailList.length > 3 && (
                  <div className="extra" onClick={() => this.viewEmailAndContact("email")}>+{selectedEmailList.length - 3} More</div>
                )
              }
            </div>
          </StyledChipList>
        </div>
      </div>
    )
  }
  Capacity = () => {
    const {
      eventCapacity,
      currentFocusIndex
    } = this.state;
    return (
      <div className="form-row">
        <div className="form-col">
          <StyledInput className={`${currentFocusIndex === 0 || eventCapacity ? "focused": ""}`}>
            <label className={`${currentFocusIndex === 0 || eventCapacity ? "focused": ""}`}>Event Capacity</label>
            <StyledCapacity>
              <div className="capacity">
                {
                  <input 
                   type="number" 
                   onChange={this.checkIsNumber} 
                   value={eventCapacity} 
                   name="eventCapacity"
                   onFocus={()=> this.onFocusChange(0)} 
                   onBlur={()=> this.onFocusChange(null)} 
                  />
                }
              </div>
              <div className="actions">
                <div className="btn" id="decrease-capacity" onClick={() => {
                  if (eventCapacity === "") {
                    this.setCapacity("0")
                  } else {
                    this.setCapacity(`${parseInt(eventCapacity) - 1}`)
                  }
                }}>
                  <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 2V0H14V2H0Z" fill="white" />
                  </svg>
                </div>
                <div className="btn" id="increase-capacity" onClick={() => {
                  if (eventCapacity === "") {
                    this.setCapacity("0")
                  } else {
                    this.setCapacity(`${parseInt(eventCapacity) + 1}`)
                  }
                }}>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.25 7.74995H0.5V6.25H6.25V0.5H7.74995V6.25H13.5V7.74995H7.74995V13.5H6.25V7.74995Z" fill="white" />
                  </svg>
                </div>
              </div>
            </StyledCapacity>
          </StyledInput>
          {
            this.validateFormData().eventCapacity && (
              <ErrorMessage error={this.validateFormData().eventCapacity} />
            )
          }
        </div>
      </div>
    )
  }
  AddEventContainer = () => {
    const {
      title,
      eventDate,
      selectedLocation,
      venueDetails,
      startTime,
      endTime,
      description,
      eventType,
      selectedEmailList,
      mapMenuAnchorEle,
      viewEmailAndContactData,
      predictions,
      recentLocations,
      editMode
    } = this.state;
    return (
      <StyledAddEventContainer>
        <GoBack displayText={editMode ? "Edit Event" : "Add Event"} goBack={this.goBack} />
        <form autoComplete="off">
          <div className="form-row" style={{ marginBottom: "1.25rem" }}>
            {this.ImageInput()}
          </div>
          <div className="form-row">
            <div className="form-col">
              <InputText onChange={this.onChangeInputs} value={title} maxLength={42} label="Title*" type="text" name="title" isContainerFocused={true} />
            </div>
            <div className="form-col">
              <InputText
                value={eventDate ? HelperFunctions.formatDate(eventDate, "DD MMMM, dddd") : eventDate}
                label="Select Event Date*"
                type="text"
                isContainerFocused={true}
                readonly={true}
                onChange={this.onChangeInputs}
                onClick={this.openDatePicker}
              />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  format="DD MMMM YYYY"
                  margin="normal"
                  id="text_id"
                  value={eventDate}
                  onChange={this.onDateChangeHandler}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                    id: "mui-date-picker"
                  }}
                  className="customDatePicker"
                  style={{ display: "none" }}
                  DialogProps={{
                    className: "datePickerDialog",
                  }}
                  PopoverProps={{
                    className: "add-appointment-date-picker"
                  }}
                  autoOk={true}
                  disablePast
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <InputText
                onChange={this.onChangeInputs}
                value={selectedLocation ? selectedLocation.structured_formatting.main_text : ""}
                label="Choose Location*"
                type="text"
                isContainerFocused={true}
                readonly={true}
                onClick={(event)=> this.openMapMenu(event as React.MouseEvent<HTMLDivElement>)}
                style={{cursor:"pointer"}}
                onClearInput={()=>this.onSelectLocation(null)}
                inputStyles={{cursor:"pointer"}}
              />
              <MapMenu 
                anchorEle={mapMenuAnchorEle} 
                predictions={predictions} 
                recentLocations={recentLocations}
                onClose={this.onCloseMapMenu}
                onSelect={this.onSelectLocation} 
                onSearch={this.onSearchLocation}
              />
            </div>
            <div className="form-col">
              <InputText
                onChange={this.onChangeInputs}
                value={venueDetails}
                name="venueDetails"
                label="Venue Details*"
                type="text"
                isContainerFocused={true}
                maxLength={100}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <StartAndEndTime
                startTime={startTime}
                endTime={endTime}
                startTile="Start Event Time*"
                endTitle="End Event Time"
                onChange={(event1, event2) => {
                  if (event1 === 0) {
                    this.onChangeDateAndTime(event2, "startTime")
                  } else {
                    this.onChangeDateAndTime(event2, "endTime")
                  }
                }}
              />
              {
                this.validateFormData().eventTime && (
                  <ErrorMessage error={this.validateFormData().eventTime} />
                )
              }
            </div>
            <div className="form-col">
              <TextArea
                onChange={this.onChangeInputs}
                value={description}
                label="Description"
                isContainerFocused={true}
                name="description"
                maxLength={1024}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-col">
              <StyledEventType>
                <header>Event Type</header>
                <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                  <RadioButton selected={eventType === EventType.PRIVATE} onSelect={() => this.onChangeEventType(EventType.PRIVATE)} label="Private" />
                  <RadioButton selected={eventType === EventType.PUBLIC} onSelect={() => this.onChangeEventType(EventType.PUBLIC)} label="Public" />
                </div>
              </StyledEventType>
            </div>
          </div>
          {
            eventType && (
              this.EmailAndContactField()
            )
          }
          {
            selectedEmailList.length > 0 && (
              this.SelectedEmailList()
            )
          }

          {
            eventType === EventType.PUBLIC && (
              this.Capacity()
            )
          }
          <div className="form-row" style={{ justifyContent: "center", alignItems:"center" }}>
            <button type="button" disabled={this.isSubmitButtonDisable()} className="submit-btn" onClick={this.onSubmit}>
              {
                editMode ? "Update" : "Create Event"
              }
            </button>
          </div>
        </form>
        {
          viewEmailAndContactData && (
            <ViewSelectedEmailAndContact
              open={Boolean(viewEmailAndContactData)}
              onClose={this.closeViewEmailAndContactModal}
              listData={viewEmailAndContactData?.value}
              type={viewEmailAndContactData?.type}
              onDone={this.onDoneViewEmailAndContact}
            />
          )
        }
      </StyledAddEventContainer>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <NavigationMenu {...this.props} children={this.AddEventContainer()} />
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledAddEventContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "1.5rem",
  padding: "1rem 1.5rem",
  "& > form": {
    userSelect:"none",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    padding: "1rem 3rem",
    "& > .form-row": {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
      "& > .form-col": {
        width: "49%"
      },
    },
    [`@media screen and (max-width: 1050px)`]: {
      padding:"1rem 0rem",
      "& > .form-row":{
        flexDirection:"column",
        alignItems:"flex-start",
        gap:"1rem",
        "& > .form-col":{
          width:"100% !important"
        },
        "&:nth-child(6)":{
          flexDirection:"column-reverse"
        }
      }
    },
  },
  "& .submit-btn": {
    outline: "none",
    border: "none",
    background: "linear-gradient(180deg, rgba(243, 127, 120, 0.75) 0%, rgba(247, 83, 103, 0.75) 100%)",
    width: "21.4375rem",
    height: "3rem",
    fontSize: "1rem",
    fontWeight: 500,
    color: "rgba(255, 255, 255, 0.75)",
    opacity: 1,
    userSelect:"none",
    "&:disabled": {
      opacity: 0.7
    }
  },
})

const StyledImageInput = styled("div")({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  margin: "auto",
  "& .input": {
    width: "8.5rem",
    height: "8.6rem",
    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    border: "0.51px solid rgba(25, 181, 185, 0.27)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "2.04875rem",
    gap: "0.8rem",
    cursor: "pointer",
    "& p": {
      color: "rgba(255, 255, 255, 1)",
      fontSize: "1rem",
      fontWeight: 500
    }
  },
  "& .image": {
    width: "8.5rem",
    height: "8.6rem",
    borderRadius: "2.04875rem",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    "& > .icon": {
      background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
      width: "1.25rem",
      height: "1.25rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      position: "absolute",
      bottom: 0,
      right: 0,
      cursor: "pointer"
    }
  },
  "& .error": {
    fontSize: "0.75rem",
    fontWeight: 400,
    color: "rgba(255, 47, 63, 1)"
  }
})

const StyledEventType = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.3rem",
  "& > header": {
    fontSize: "0.875rem",
    fontWeight: 400,
    color: "rgba(242, 242, 242, 1)"
  }
})

const StyledInput = styled("div")({
  position: "relative",
  padding: "0rem 0.9rem",
  height: "3rem",
  borderRadius: "100px",
  display: "flex",
  maxHeight: "100%",
  alignItems: "center",
  background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
  border: "1px solid rgba(28, 150, 154,0.3)",
  transition: "all 0.3s ease-in-out",
  "& input": {
    outline: "none",
    border: "none",
    background: "transparent",
    color: "rgba(255, 255, 255, 1)",
    fontSize: "1rem",
    fontWeight: 400,
    zIndex:1,
    position: "relative"
  },
  "& label": {
    position: "absolute",
    color: "white",
    fontSize: "1rem",
    transition: "transform 0.2s ease-in-out",
    "&.focused": {
      transform: 'translate(-14px,-38px)'
    }
  },
  "&.focused": {
    marginTop: '1rem !important'
  }
})

const StyledCapacity = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  "& .capacity": {
    fontSize: "1rem",
    fontWeight: 400,
    color: "rgba(255, 255, 255, 1)"
  },
  "& .actions": {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    "& .btn": {
      cursor: "pointer",
      padding: "0.1rem"
    }
  }
})

const StyledChipList = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  "& .list": {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    "& .extra": {
      fontSize: "0.875rem",
      fontWeight: 500,
      color: "rgb(243, 121, 118)",
      cursor: "pointer"
    }
  },
  "& .add-btn": {
    fontSize: "0.875rem",
    fontWeight: 500,
    color: "rgb(243, 121, 118)",
    cursor: "pointer"
  }
})

// Customizable Area End
