import firebase from 'firebase';
import 'firebase/messaging';

const firebaseAPI = firebase.initializeApp({
    apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
    authDomain: "rnmasterapp-c11e9.firebaseapp.com",
    databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
    projectId: "rnmasterapp-c11e9",
    storageBucket: "rnmasterapp-c11e9.appspot.com",
    messagingSenderId: "649592030497",
    appId: "1:649592030497:web:7728bee3f2baef208daa60",
    measurementId: "G-FYBCF3Z2W3"
});

export const messaging = firebase.messaging();

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');
