import React from "react";
// Customizable Area Start
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { styled } from "@material-ui/styles";
import CommonHeader from "./components/CommonHeader.web";
import { AddIcon } from "./assets";
import UpcomingEventCard from "../../events/src/components/UpcomingEventCard.web";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
import CustomNoDataComponent from "../../../components/src/CustomNoDataComponent.web";
// Customizable Area End
import UpcomingAppointmentsController, {
    Props
} from "./UpcomingAppointmentsController";

export default class UpcomingAppointments extends UpcomingAppointmentsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    AppointmentsContainer = () => {
        const { appointments, loading } = this.state;
        return (
            <StyledAppointmentsContainer className="Dashboard_UpcomingAppointments_StyledAppointmentsContainer">
                <CommonHeader redirectTo={()=> this.redirectTo("AddAppointment")} goBack={this.goBack} goBackText="Upcoming Appointments" buttonText="Add Appointments" buttonIcon={AddIcon} />
                {
                    appointments.length > 0 && (
                        <div className="cards-container">
                            {
                                appointments.map(appointment => {
                                    return (
                                        <div className="card" key={appointment.id}>
                                            <header>{helper.formatDate(appointment.attributes.appointment_date, 'D MMMM / dddd')}</header>
                                            <UpcomingEventCard
                                                event={appointment}
                                                onEventUpdateRsvpStatus={this.onUpdateRsvpStatus}
                                                onTitleClick={() => this.redirectTo("AppointmentDetails", { id: appointment.id })}
                                                type="appointment"
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                }
                {
                    !loading && appointments.length === 0 && (
                        <CustomNoDataComponent
                            titleMsg={"No Upcoming Appointments"}
                            subTitleMsg={"No scheduled Appointments. Stay tuned for the updates."}
                            useCommonEmptyImg={true}
                        />
                    )
                }
            </StyledAppointmentsContainer>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <NavigationMenu {...this.props} children={this.AppointmentsContainer()} />
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledAppointmentsContainer = styled("div")({
    "&.Dashboard_UpcomingAppointments_StyledAppointmentsContainer":{
        display: "flex",
        flexDirection: "column",
        gap:'1.5rem',
        padding: "1rem",
        boxSizing:"border-box",
        "& .cards-container":{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            rowGap: "1rem",
            columnGap: "1rem",
            "& .card":{
                boxSizing:"border-box",
                "& > header":{
                    fontSize: "0.875rem",
                    fontWeight: 400,
                    color: "rgba(255, 255, 255, 0.8)",
                    marginBottom: "0.5rem"
                }
            },
            [`@media screen and (max-width: 1315px)`]: {
                gridTemplateColumns: "repeat(3, 1fr)",
            },
            [`@media screen and (max-width: 1070px)`]: {
                gridTemplateColumns: "repeat(2, 1fr)",
            },
            [`@media screen and (max-width: 780px)`]: {
                gridTemplateColumns: "repeat(1, 1fr)",
            },
        },
        
    }
})
// Customizable Area End
