import React from "react";
// Customizable Area Start
import {
  styled,
  Modal
} from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Appointment from "./components/Appointment.web";
// Customizable Area End

import AppointmentDetailsController, {
  Props
} from "./AppointmentDetailsController";

export default class AppointmentDetails extends AppointmentDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  DeleteModal = () => {
    const { isDeleteModalOpen, meetingTitle } = this.state;
    return (
      <Modal
       open={isDeleteModalOpen}
       onClose={this.closeDeleteModal}
       style={{display:"flex",justifyContent:"center",alignItems:"center"}}
      >
        <StyledDeleteModal>
          <p>Delete Appointment?</p>
          <p>Are you sure that you want to delete {meetingTitle}?</p>
          <div className="actions">
            <button id="delete-btn" onClick={this.onDeleteAppointment}>Yes</button>
            <button id="cancel-btn" onClick={this.closeDeleteModal}>No</button>
          </div>
        </StyledDeleteModal>
      </Modal>
    )
  }

  AppointmentsComponent = () => {
    const { appointment, isRsvpStatusUpdateMode, invitedUserList, activeTab, optionAnchorEle } = this.state;
    return (
      <StyledAppointmentsContainer>
        <div className="back-btn" onClick={this.goBack}>
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99953 11.6553L0.345703 6.00148L5.99953 0.347656L7.05335 1.40148L2.45335 6.00148L7.05335 10.6015L5.99953 11.6553Z" fill="white" />
          </svg>
          Details
        </div>
        {
          appointment && (
            <Appointment
              redirectTo={this.redirectTo}
              appointment={appointment}
              onUpdateRsvpStatus={this.onUpdateRsvpStatus}
              setInvitedUsers={this.setInvitedUsersForAppointmentDetails}
              onRsvpUpdateModeChange={this.onRsvpUpdateModeChange}
              activeTab={activeTab}
              closeUserListModal={this.closeUserListModal}
              setActiveTab={this.setActiveTab}
              isRsvpStatusUpdateMode={isRsvpStatusUpdateMode}
              invitedUserList={invitedUserList}
              openOptionMenu={this.openOptionMenu}
              optionDropdownMenuAnchorEle={optionAnchorEle}
              openDeleteModal={this.openDeleteModal}
            />
          )
        }
        {this.DeleteModal()}
      </StyledAppointmentsContainer>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <NavigationMenu {...this.props} children={this.AppointmentsComponent()} />
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledAppointmentsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "1rem 1rem",
  gap: "1.5rem",
  "& .ellipsis": {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  "& > .back-btn": {
    fontSize: "1.125rem",
    fontWeight: 500,
    color: "white",
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    cursor: "pointer",
    width: "fit-content"
  },
})
const StyledDeleteModal = styled("div")({
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  justifyContent:"space-around",
  background: "linear-gradient(0deg, #23404B, #23404B)",
  borderRadius:"1.5rem",
  width:"20.4375rem",
  height:"12.125rem",
  padding:"1rem 2rem",
  boxSizing:"border-box",
  "& p":{
    fontSize:"0.875rem",
    fontWeight:500,
    color:"white",
    textAlign:"center",
    margin:0,
    "&:first-child":{
      fontSize:"1.125rem"
    }
  },
  "& .actions":{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    gap:"1rem",
    "& button":{
      border:"none",
      outline:"none",
      width:"8.6875rem",
      padding:"1rem 0rem",
      fontSize:"1rem",
      fontWeight:"500",
      borderRadius:"62.5rem",
      cursor:"pointer",
      "&:first-child":{
        background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
        color:"white"
      },
      "&:last-child":{
        border: "1px solid #F75367",
        color:"#F75367",
        backgroundColor:"transparent"
      }
    }
  }
})
// Customizable Area End
