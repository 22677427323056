import React, { useState } from "react";
import { styled } from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Moment } from "moment/moment";

interface InputTextProps {
    value: string | number;
    onChange: (event: React.ChangeEvent<HTMLInputElement>, sanitizedValue?: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    type: string;
    name?: string;
    isInputFocused?: boolean;
    label: string;
    isFormTouched?: boolean;
    error?: { [key: string]: string }
    containerClasses?: string;
    isContainerFocused?: boolean;
    readonly?: boolean;
    onClick?: (event?: React.MouseEvent<HTMLDivElement>) => void;
    maxLength?: number;
    style?: React.CSSProperties;
    onClearInput?: () => void;
    inputStyles?: React.CSSProperties;
    isTimePicker?: boolean;
    onTimeChange?: (event: string)=>void;
    format?: string;
    onEnter?: (event: React.KeyboardEvent<HTMLInputElement>)=> void;
    regex?: string;
}


const InputText: React.FC<InputTextProps> = (props: InputTextProps) => {
    const {
        value,
        name,
        onChange,
        isInputFocused,
        label,
        containerClasses,
        isContainerFocused,
        readonly,
        type,
        onClick,
        onFocus,
        onBlur,
        maxLength,
        style,
        onClearInput,
        inputStyles,
        isTimePicker,
        format,
        onTimeChange,
        onEnter,
    } = props;

    const [focused, setFocused] = useState(false);
    const [openTimePicker, setOpenTimePicker] = useState(false);

    const onFocusHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (readonly) {
            event.currentTarget.blur()
            return;
        }
        setFocused(true)
        if (onFocus) {
            onFocus()
        }
    }
    const onBlurHandler = () => {
        if (readonly) return;
        setFocused(false);
        if (onBlur) {
            onBlur()
        }
    }

    const onClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
        if (onClick) {
            onClick(event);
        }
        if (isTimePicker) {
            setOpenTimePicker(true)
        }
    }

    const onTimeSelect = (value: Date | Moment | null)=> {
        if (value && onTimeChange) {
          const moment = new MomentUtils();
          const formattedTime = moment.date(value).format(format)
          onTimeChange(formattedTime)
        }
    }

    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>)=>{
        if (event.key === "Enter") {
            if(onEnter){
                onEnter(event)
            }
        }
    }

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>)=>{
        onChange(event);
    }

    return (
        <>
            <StyledInputText style={style} className={`TextInput_StyledInputText ${containerClasses} ${isContainerFocused && (focused || value) ? "focused" : ""}`} onClick={onClickHandler}>
                <label className={focused || isInputFocused || value ? "focused" : ""}>{label}</label>
                <input
                    name={name}
                    onFocus={onFocusHandler}
                    onBlur={onBlurHandler}
                    onChange={onChangeHandler}
                    value={value}
                    readOnly={readonly}
                    type={type}
                    maxLength={maxLength}
                    style={inputStyles}
                    onKeyDown={onKeyDownHandler}
                />
                {
                    onClearInput && value && (
                        <div
                            style={{ cursor: "pointer", padding: "0.23rem" }}
                            onClick={(event) => {
                                event.stopPropagation();
                                onClearInput()
                            }}
                        >
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.227 9.83555L0.163574 8.77213L3.93667 4.99902L0.163574 1.25092L1.227 0.1875L5.0001 3.9606L8.7482 0.1875L9.81162 1.25092L6.03852 4.99902L9.81162 8.77213L8.7482 9.83555L5.0001 6.06245L1.227 9.83555Z" fill="white" />
                            </svg>
                        </div>
                    )
                }
            </StyledInputText>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardTimePicker
                    open={openTimePicker}
                    onClose={() => setOpenTimePicker(false)}
                    variant="dialog"
                    format="HH:mm"
                    margin="normal"
                    id="time-picker-inline"
                    value={value}
                    onChange={onTimeSelect}
                    KeyboardButtonProps={{
                        id: "appointment-time"
                    }}
                    style={{ display: "none" }}
                    onAccept={onTimeSelect}
                />
            </MuiPickersUtilsProvider>
        </>
    )
}

const StyledInputText = styled("div")({
    "&.TextInput_StyledInputText": {
        position: "relative",
        padding: "0rem 0.9rem",
        height: "3rem",
        borderRadius: "100px",
        display: "flex",
        maxHeight: "100%",
        alignItems: "center",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        border: "1px solid rgba(28, 150, 154,0.3)",
        transition: "all 0.3s ease-in-out",
        "& label": {
            position: "absolute",
            color: "white",
            fontSize: "1rem",
            transition: "transform 0.2s ease-in-out",
            "&.focused": {
                transform: 'translate(-14px,-38px)'
            }
        },
        "& input": {
            outline: "none",
            color: "white",
            fontSize: "1rem",
            zIndex: 1,
            width: "100%",
            backgroundColor: "transparent",
            border: "none",
        },
        "&.focused": {
            marginTop: '1rem !important'
        }
    }
})

export default InputText;