import React from "react";
import { styled } from "@material-ui/core";
import { CalendarIcon, PlusIcon } from "../assets";

interface AppointmentHeaderProps {
    redirectTo: (destination: string, params?:{[key:string]:string | number}) => void,
    isListView?: boolean
}

const AppointmentHeader: React.FC<AppointmentHeaderProps> = (props: AppointmentHeaderProps) => {
    const { redirectTo, isListView = true } = props;
    return (
        <StyledAppointmentsHeader className="AppointmentHeader_StyledAppointmentsHeader">
            <div className="text">
                <p>Appointments</p>
            </div>
            <div className="actions-btn">
                {
                    isListView && (
                        <button onClick={() => redirectTo("Scheduling", { type: "appointments" })}>
                            <img src={CalendarIcon} />
                            <p>Calendar View</p>
                        </button>
                    )
                }
                {
                    !isListView && (
                        <button onClick={() => redirectTo("Appointments")}>
                            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.3077 17C1.80257 17 1.375 16.825 1.025 16.475C0.675 16.125 0.5 15.6974 0.5 15.1923V1.8077C0.5 1.30257 0.675 0.875 1.025 0.525C1.375 0.175 1.80257 0 2.3077 0H15.6923C16.1974 0 16.625 0.175 16.975 0.525C17.325 0.875 17.5 1.30257 17.5 1.8077V15.1923C17.5 15.6974 17.325 16.125 16.975 16.475C16.625 16.825 16.1974 17 15.6923 17H2.3077ZM2.3077 15.5H8.25V1.49998H2.3077C2.23077 1.49998 2.16024 1.53203 2.09612 1.59613C2.03202 1.66024 1.99997 1.73077 1.99997 1.8077V15.1923C1.99997 15.2692 2.03202 15.3397 2.09612 15.4038C2.16024 15.4679 2.23077 15.5 2.3077 15.5ZM9.74995 15.5H15.6923C15.7692 15.5 15.8397 15.4679 15.9038 15.4038C15.9679 15.3397 16 15.2692 16 15.1923V8.49998H9.74995V15.5ZM9.74995 7H16V1.8077C16 1.73077 15.9679 1.66024 15.9038 1.59613C15.8397 1.53203 15.7692 1.49998 15.6923 1.49998H9.74995V7Z" fill="#F75367" />
                            </svg>
                            <p>Grid View</p>
                        </button>
                    )
                }
                <button data-test-id="btnNavigateToAddAppointments" onClick={() => redirectTo("AddAppointment")}>
                    <img src={PlusIcon} />
                    <p>Add Appointments</p>
                </button>
            </div>
        </StyledAppointmentsHeader>
    )
}

export default AppointmentHeader;


const StyledAppointmentsHeader = styled("div")({
    "&.AppointmentHeader_StyledAppointmentsHeader": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "1rem",
        "& .text": {
            display: "flex",
            flexDirection: "column",
            "& p": {
                margin: 0,
                "&:first-child": {
                    fontSize: "1.25rem",
                    color: "#ffffff",
                    fontWeight: 500
                }
            }
        },
        "& .actions-btn": {
            display: "flex",
            gap: "1rem",
            "& button": {
                height: "3rem",
                padding: "0.75rem",
                borderRadius: "62.5rem",
                outline: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.2rem",
                cursor: "pointer",
                "& p": {
                    margin: 0,
                    fontSize: "1rem",
                    fontWeight: 500,
                },
                "&:first-child": {
                    border: "1px solid #F75367",
                    color: "#F75367",
                    backgroundColor: "transparent",
                    "& p": {
                        transform: "translateY(1px)"
                    }
                },
                "&:last-child": {
                    border: "none",
                    background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
                    color: "white",
                    boxShadow: "0px 0px 20px 0px #0000004D"
                }
            },
            [`@media screen and (max-width: 850px)`]: {
                flexDirection: "column"
            },
        },
        [`@media screen and (max-width: 550px)`]: {
            flexDirection: "column",
            gap: "1rem"
        },
    }
})