// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Groups";
exports.labelBodyText = "Groups Body";
exports.groupListEndPoint = "bx_block_groups/groups";
exports.btnExampleTitle = "CLICK ME";
exports.userList = "User List";
exports.groups = "Groups";
exports.createGroup = "Create Group";
exports.enterGroupName = "Enter the Group Name";
exports.groupName = "Group Name";
exports.groupDesc = "Group Description";
exports.create = "Create";
exports.cancel = "Cancel";
exports.email = "Email";
exports.name = "Name";
exports.createUser = "Create User";
exports.enterUserName = "Enter the User Name";
exports.userName = "User Name";
exports.userEmail = "User Email";
exports.users = "Users";
exports.edit = 'Edit';
exports.added = "Added";
exports.select = "Select";
exports.createGroupEndpoint = "bx_block_groups/groups/create";
exports.applicationJSONcontentType = "application/json";
exports.userListEndpoint = "bx_block_user/users/users_list";
exports.addUserToGroupEndpoint = "bx_block_groups/groups/add_user";
exports.removeUserFromGroupEndPoint = "bx_block_groups/groups/remove_user";
exports.createUserEndPoint = "bx_block_groups/groups/create_user";
exports.back = "Back";
exports.searchContactsApiEndPoint = "bx_block_appointment_management/appointments/search_contacts";
exports.interestApiEndPoint = "account_block/accounts/fetch_interests";
exports.addNewInterestApiEndPoint = "account_block/accounts/add_interests";
exports.groupApiEndPoint = "bx_block_groups/groups";
// Customizable Area End
