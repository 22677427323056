// Customizable Area Start
import React from "react";
import {
    Grid,
    styled
} from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import BestEventsListController, {
    Props
} from "./BestEventsListController";
import BestEventCard from "./components/BestEventCard.web";
import CustomNoDataComponent from "../../../components/src/CustomNoDataComponent.web";
import { PlusIcon } from "./assets";
// Customizable Area End

export default class BestEventsList extends BestEventsListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    BestEventsList = () => {
        const { bestEventsList } = this.state;
        return (
            <StyledBestEventsContainer>
                <Grid container direction="row">
                    <Grid item xs={12} sm={6}>
                        <div data-test-id="best-events-list-back-btn" className="back-btn" onClick={() => this.redirectTo("Events")}>
                            <svg id="best-event" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.99953 11.6553L0.345703 6.00148L5.99953 0.347656L7.05335 1.40148L2.45335 6.00148L7.05335 10.6015L5.99953 11.6553Z" fill="white" />
                            </svg>
                            The Best Event
                        </div>
                    </Grid>
                    <Grid className="add-btn-grid" item xs={12} sm={6}>
                        <button data-test-id="add-event-btn" className="add-btn" onClick={() => this.redirectTo("AddEvent")}>
                            <img src={PlusIcon} alt="" />
                            <p>Add Event</p>
                        </button>
                    </Grid>
                </Grid>
                <Grid container direction="row" spacing={2}>
                    {bestEventsList.length > 0 ?
                        bestEventsList.map((bestEvent) =>
                            <Grid key={bestEvent.id} className="best-event-card" item xs={12} sm={6} md={4} lg={2} xl={2}>
                                <BestEventCard 
                                id={bestEvent.id}
                                image={bestEvent.image}
                                location={bestEvent.location}
                                time={bestEvent.time}
                                title={bestEvent.title}
                                isTrending={bestEvent.isTrending}
                                price={bestEvent.price}
                                onCardClick={() =>  this.redirectTo("EventDetail", {eventId: bestEvent.id})}
                                />
                            </Grid>
                        )
                        : <CustomNoDataComponent titleMsg="No Trending Events" subTitleMsg="No scheduled and trending events. Stay tuned for the updates." useCommonEmptyImg={false} />
                    }
                </Grid>
            </StyledBestEventsContainer>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <NavigationMenu {...this.props} children={this.BestEventsList()} />
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledBestEventsContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    padding: "1rem 1rem",
    gap: "1.5rem",
    "& .ellipsis": {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    "& .back-btn": {
        fontSize: "1.125rem",
        fontWeight: 500,
        color: "white",
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
        cursor: "pointer",
        width: "fit-content"
    },
    "& .best-event-title": {
        fontSize: "16px",
        color: "#FFFFFF",
    },
    "& .add-btn-grid":{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    "& .add-btn": {
        background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        height: "3rem",
        padding: "0.75rem",
        border: "1px solid #F75367",
        gap: "0.25rem",
        borderRadius: "62.5rem",
        "& p": {
            color: "rgba(255, 255, 255, 1)",
            fontSize: "1rem",
            fontWeight: 500
        }
    }
})
// Customizable Area End
