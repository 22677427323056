import React from "react";
import { styled } from "@material-ui/core";
import { CheckIcon, CrossIcon2 } from "../assets";
import { UserContact } from "../AppointmentsController";

interface ContactListProps {
    setInvitedUsers: (list:UserContact[], owner_id: number)=> void;
    list: UserContact[];
    invited_users?: {[key:string]: string}[]
    owner_id: number
}

const ContactList: React.FC<ContactListProps> = (props: ContactListProps) => {
    const { setInvitedUsers, list, owner_id, invited_users } = props;
    const formatData = () => {
        const items: {
            name: string;
            rsvp_status: string | null;
        }[] = [];
        list.forEach(user => {
            items.push({
                name: user.name,
                rsvp_status: user.rsvp_status
            })
        })
        invited_users?.forEach(user => {
            items.push({
                name: user.invite_record.includes("@") ? user.invite_record : user.contact_name,
                rsvp_status: null
            })
        })
        return items;
    }
    const userList = formatData()
    
    
    return (
        <StyleContactList id="invited-users" onClick={() => setInvitedUsers(list, owner_id)} className="Appointments_ContactList_StyledContactList">
            {
                userList.filter((user, listIndex: number) => listIndex <= 3).map((user, listID: number) => {
                    return (
                        <div className="avatar" key={listID}>
                            {
                                user.name[0].toUpperCase()
                            }
                            {
                                user.rsvp_status === "accepted" && (
                                    <div className="icon">
                                        <img src={CheckIcon} />
                                    </div>
                                )
                            }
                            {
                                user.rsvp_status === "rejected" && (
                                    <div className="icon not-going">
                                        <img src={CrossIcon2} />
                                    </div>
                                )
                            }
                        </div>
                    )
                })
            }
            {
                userList.length > 4 && (
                    <div className="avatar extra">+{userList.length - 4}</div>
                )
            }
        </StyleContactList>
    )
}

export default ContactList;


const StyleContactList = styled("div")({
    "&.Appointments_ContactList_StyledContactList": {
        display: "flex",
        gap: "0.35rem",
        "& .avatar": {
            width: "2rem",
            height: "2rem",
            cursor: "pointer",
            border: "1px solid #25ECF2",
            borderRadius: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#25ECF2",
            position: "relative",
            zIndex: 1,
            fontSize: "0.875rem",
            "& .icon": {
                width: "0.75rem",
                height: "0.75rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                right: 0,
                bottom: 0,
                transform: "translate(25%,25%)",
                backgroundColor: "#00FF7F",
                borderRadius: "100%",
                zIndex: 4,
                "&.not-going": {
                    backgroundColor: "#FF2F3F"
                }
            },
            "&.extra": {
                background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
                color: "white",
                border: "1px solid #F75367"
            }
        }
    }
})