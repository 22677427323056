import React from "react";
import { styled } from "@material-ui/styles";
import GroupCard from "./GroupCard.web";
import { IGroup } from "../types";
import { CardScroll } from "../../../../components/src";

interface Props {
    groups: IGroup[];
    onClick: (event: IGroup)=> void;
    selectedGroup: IGroup | null;
}


const GroupCardContainer: React.FC<Props> = (props: Props) => {
    const { groups, onClick, selectedGroup } = props;
    return (
        <>
            {
                groups && groups.length > 0 && (
                    <StyledGroupCardContainer className="GroupCardContainer_StyledGroupCardContainer">
                        <p>My Groups</p>
                        <div className="groups">
                            <CardScroll>
                                {
                                    groups.map(group => {
                                        return (
                                            <GroupCard selected={!!selectedGroup && selectedGroup.id === group.id} onClick={onClick} group={group} key={group.id} />
                                        )
                                    })
                                }
                            </CardScroll>
                        </div>
                    </StyledGroupCardContainer>
                )
            }
        </>
    )
}

const StyledGroupCardContainer = styled("div")({
  "&.GroupCardContainer_StyledGroupCardContainer":{
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    "& > p":{
        fontSize: "0.75rem",
        fontWeight: 500,
        color: "rgba(255, 255, 255, 1)",
        marginLeft: "1rem"
    },
    "& .groups":{
        display: "flex",
        gap: "1rem",
        padding: "0rem 0.1rem"
    }
  }
})

export default GroupCardContainer;