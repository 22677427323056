import React, { useState, useRef, useMemo, useEffect } from 'react';
import { MapContainer, TileLayer } from '@monsonjeremy/react-leaflet';
import { Marker, useMapEvents, useMap } from '@monsonjeremy/react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { LatLngExpression, LeafletMouseEvent, Marker as LeafletMarker } from 'leaflet';
import 'leaflet/dist/leaflet.css';
const mapMarkerIcon = require("./map-marker-icon.svg") 
const mapMarkerShadowIcon = require("./map-marker-shadow.svg") 


interface MapLocationProps {
    latLng: LatLngExpression | null;
    onChangeMapLocation: (postion: LatLngExpression) => void;
}


const MapLocation: React.FC<MapLocationProps> = (props: MapLocationProps) => {
    const { latLng, onChangeMapLocation } = props;
    const [position, setPosition] = useState<LatLngExpression | null>(null);

    const onPositionChange = (position: LatLngExpression) => {
        setPosition(position)
        onChangeMapLocation(position)
    }

    useEffect(() => {
        if (latLng) {
            setPosition(latLng)
        } else {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    onPositionChange([latitude, longitude])
                },
                () => {
                    // Fallback position if user denies geolocation or if there's an error
                    onPositionChange([51.505, -0.09]);
                }
            );
        }
    }, [latLng])

    return (
        <>
            {
                position ? (
                    <MapContainer center={position} zoom={13} style={{ height: '100%', width: '100%' }} zoomControl={false}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <DraggableMarker position={position} onPositionChange={onPositionChange} />
                    </MapContainer>
                ) : (
                    <div>Loading</div>
                )
            }
        </>
    )
}

export default MapLocation;


interface DraggableMarkerProps {
    position: LatLngExpression;
    onPositionChange: (position: LatLngExpression) => void
}

// Fix the default icon issue with Leaflet in React

// Define custom icon
const customIcon = new L.Icon({
    iconUrl: mapMarkerIcon as any,
    shadowUrl: mapMarkerShadowIcon as any
});

const DraggableMarker: React.FC<DraggableMarkerProps> = (props: DraggableMarkerProps) => {
    const { position, onPositionChange } = props;
    const markerRef = useRef<LeafletMarker>(null);
    const map = useMap();

    const eventHandlers = useMemo(() => ({
        dragend() {
            const marker = markerRef.current;
            if (marker != null) {
                onPositionChange(marker.getLatLng());
            }
        },
    }), []);

    useMapEvents({
        click(event: LeafletMouseEvent) {
        onPositionChange(event.latlng);
      },
    });
  
    useEffect(()=>{
      map.flyTo(position);
    },[position])
  
    return (
      <Marker
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
        draggable={true}
        icon={customIcon}
      >
      </Marker>
    );
  };
  